import ASSIGNMENT_CONDITION_KEY_DICT from '~/server_shared/generated-types/ASSIGNMENT_CONDITION_KEY_DICT';
import ASSIGNMENT_CONDITION_OPERATOR_DICT from '~/server_shared/generated-types/ASSIGNMENT_CONDITION_OPERATOR_DICT';
import ASSIGNMENT_RULE_ENTITY_DICT from '~/server_shared/generated-types/ASSIGNMENT_RULE_ENTITY_DICT';
import ASSIGNMENT_RULE_METHOD_DICT from '~/server_shared/generated-types/ASSIGNMENT_RULE_METHOD_DICT';
import ASSIGNMENT_RULE_STATIC_ASSIGNEES_DICT from '~/server_shared/generated-types/ASSIGNMENT_RULE_STATIC_ASSIGNEES_DICT';
import AUTO_COVERAGES_DICT from '~/server_shared/generated-types/AUTO_COVERAGES_DICT';
import AUTO_LIABILITY_INDICATORS_DICT from '~/server_shared/generated-types/AUTO_LIABILITY_INDICATORS_DICT';
import AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST from '~/server_shared/generated-types/AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST';
import AUTO_OPEN_FNOL_PARTIES_REQUIRED from '~/server_shared/generated-types/AUTO_OPEN_FNOL_PARTIES_REQUIRED';
import AUTO_PASSENGER_POSITIONS from '~/server_shared/generated-types/AUTO_PASSENGER_POSITIONS';
import AUTO_TICKETS_VIOLATION from '~/server_shared/generated-types/AUTO_TICKETS_VIOLATION';
import BOOL4_OPTIONS from '~/server_shared/generated-types/BOOL4_OPTIONS';
import CATASTROPHE_TYPES from '~/server_shared/generated-types/CATASTROPHE_TYPES';
import CLAIM_NUMBERING_SCHEME_OPTIONS from '~/server_shared/generated-types/CLAIM_NUMBERING_SCHEME_OPTIONS';
import CLAIM_SEARCH from '~/server_shared/generated-types/CLAIM_SEARCH';
import CLAIM_TYPES_CONFIGURATION from '~/server_shared/generated-types/CLAIM_TYPES_CONFIGURATION';
import CLOSING_REASON_CATEGORIES from '~/server_shared/generated-types/CLOSING_REASON_CATEGORIES';
import COMMUNICATION_CHANNEL_DICT from '~/server_shared/generated-types/COMMUNICATION_CHANNEL_DICT';
import IMPORTED_CONFIGURATION_FEATURES from '~/server_shared/generated-types/CONFIGURATION_FEATURES';
import CONSTANTS from '~/server_shared/generated-types/CONSTANTS';
import CONTACT_ADDRESS_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_ADDRESS_CHANGE_REASONS';
import CONTACT_EMAIL_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_EMAIL_CHANGE_REASONS';
import CONTACT_GOV_ID_VALIDATION_REGEXES from '~/server_shared/generated-types/CONTACT_GOV_ID_VALIDATION_REGEXES';
import CONTACT_IDENTITY_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_IDENTITY_CHANGE_REASONS';
import CONTACT_PHONE_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_PHONE_CHANGE_REASONS';
import COUNTRIES_DICT from '~/server_shared/generated-types/COUNTRIES_DICT';
import COUNTRY_TO_STATE_MAP from '~/server_shared/generated-types/COUNTRY_TO_STATE_MAP';
import COVERAGE_CONFIGURATION_LIMITS from '~/server_shared/generated-types/COVERAGE_CONFIGURATION_LIMITS';
import COVERAGE_DECISION_DICT from '~/server_shared/generated-types/COVERAGE_DECISION_DICT';
import COVERAGES_ISO_CODES from '~/server_shared/generated-types/COVERAGES_ISO_CODES';
import CPT_CODES_PLACE_OF_SERVICES from '~/server_shared/generated-types/CPT_CODES_PLACE_OF_SERVICES';
import CPT_TRANSACTION_TYPES from '~/server_shared/generated-types/CPT_TRANSACTION_TYPES';
import CRIF_AND_SIRA_EXCLUDED_SUB_ORGS from '~/server_shared/generated-types/CRIF_AND_SIRA_EXCLUDED_SUB_ORGS';
import CUSTOM_FIELD_TYPES from '~/server_shared/generated-types/CUSTOM_FIELD_TYPES';
import CUSTOM_FNOL_FIELDS_TYPES from '~/server_shared/generated-types/CUSTOM_FNOL_FIELDS_TYPES';
import DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT from '~/server_shared/generated-types/DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT';
import DAMAGE_ASSESSMENT_EXCEPTION_CODES from '~/server_shared/generated-types/DAMAGE_ASSESSMENT_EXCEPTION_CODES';
import DATA_EXPORT from '~/server_shared/generated-types/DATA_EXPORT';
import DEFAULT_INCIDENT_DETAILS_FIELDS from '~/server_shared/generated-types/DEFAULT_INCIDENT_DETAILS_FIELDS';
import DEFAULT_MOI_METHODS from '~/server_shared/generated-types/DEFAULT_MOI_METHODS';
import DENY_MEDICAL_BILL_REASONS_DICT from '~/server_shared/generated-types/DENY_MEDICAL_BILL_REASONS_DICT';
import DUPLICATE_PAYMENTS_VERIFICATION_ACTION from '~/server_shared/generated-types/DUPLICATE_PAYMENTS_VERIFICATION_ACTION';
import EXTENT_OF_INJURY_TYPES_DICT from '~/server_shared/generated-types/EXTENT_OF_INJURY_TYPES_DICT';
import FINANCIAL_STATUS_DICT from '~/server_shared/generated-types/FINANCIAL_STATUS_DICT';
import GL_COVERAGE_DICT from '~/server_shared/generated-types/GL_COVERAGE_DICT';
import GLOBAL_ADDRESSES_OPTIONS from '~/server_shared/generated-types/GLOBAL_ADDRESSES_OPTIONS';
import HOME_COVERAGES_DICT from '~/server_shared/generated-types/HOME_COVERAGES_DICT';
import INDEPENDENT_MEDICAL_SPECIALISTS_DICT from '~/server_shared/generated-types/INDEPENDENT_MEDICAL_SPECIALISTS_DICT';
import INTEGRATION_STATUS_DICT from '~/server_shared/generated-types/INTEGRATION_STATUS_DICT';
import INVOLVED_DICT_PER_CLAIM_TYPE from '~/server_shared/generated-types/INVOLVED_DICT_PER_CLAIM_TYPE';
import LANGUAGES_DICT from '~/server_shared/generated-types/LANGUAGES_DICT';
import LIABILITY_LIEN_TYPES_DICT from '~/server_shared/generated-types/LIABILITY_LIEN_TYPES_DICT';
import LIGHT_DUTY_POSITIONS_DICT from '~/server_shared/generated-types/LIGHT_DUTY_POSITIONS_DICT';
import LIGHT_DUTY_STATUSES_DICT from '~/server_shared/generated-types/LIGHT_DUTY_STATUSES_DICT';
import LOAD_BALANCING from '~/server_shared/generated-types/LOAD_BALANCING';
import LOB_ISO_CODES from '~/server_shared/generated-types/LOB_ISO_CODES';
import LOCATION_TYPES_DICT from '~/server_shared/generated-types/LOCATION_TYPES_DICT';
import LOSS_CAUSE_ISO_CODES from '~/server_shared/generated-types/LOSS_CAUSE_ISO_CODES';
import LOSS_TYPES from '~/server_shared/generated-types/LOSS_TYPES';
import MARSHMALLOW_FAULTS_LIST from '~/server_shared/generated-types/MARSHMALLOW_FAULTS_LIST';
import MARSHMALLOW_JOURNEY_PURPOSE_LIST from '~/server_shared/generated-types/MARSHMALLOW_JOURNEY_PURPOSE_LIST';
import MARSHMALLOW_SPEEDS_LIST from '~/server_shared/generated-types/MARSHMALLOW_SPEEDS_LIST';
import MARSHMALLOW_VEHICLE_DAMAGE_LEVELS_LIST from '~/server_shared/generated-types/MARSHMALLOW_VEHICLE_DAMAGE_LEVELS_LIST';
import MARSHMALLOW_WEATHER_CONDITIONS_DICT from '~/server_shared/generated-types/MARSHMALLOW_WEATHER_CONDITIONS_DICT';
import MEDICAL_BILL_PROVIDER_PROFESSIONS from '~/server_shared/generated-types/MEDICAL_BILL_PROVIDER_PROFESSIONS';
import MGM_EOB_CODES from '~/server_shared/generated-types/MGM_EOB_CODES';
import MGM_GL_COMPENSATION_DICT from '~/server_shared/generated-types/MGM_GL_COMPENSATION_DICT';
import MGM_GL_COVERAGE_DECISION_DICT from '~/server_shared/generated-types/MGM_GL_COVERAGE_DECISION_DICT';
import MGM_LABEL_DICT from '~/server_shared/generated-types/MGM_LABEL_DICT';
import MGM_WC_BODY_PART_CODE_DICT from '~/server_shared/generated-types/MGM_WC_BODY_PART_CODE_DICT';
import MGM_WC_CAUSE_CODE_DICT from '~/server_shared/generated-types/MGM_WC_CAUSE_CODE_DICT';
import MGM_WC_INJURY_CODE_DICT from '~/server_shared/generated-types/MGM_WC_INJURY_CODE_DICT';
import MOI_EXPERTISE_TYPES from '~/server_shared/generated-types/MOI_EXPERTISE_TYPES';
import MOI_STATUSES from '~/server_shared/generated-types/MOI_STATUSES';
import MOR_OPTIONS_DICT from '~/server_shared/generated-types/MOR_OPTIONS_DICT';
import NOTE_SUBJECT from '~/server_shared/generated-types/NOTE_SUBJECT';
import NOTIFICATIONS_ENTITY_STATUSES from '~/server_shared/generated-types/NOTIFICATIONS_ENTITY_STATUSES';
import NOTIFICATIONS_RULES_RECIPIENTS from '~/server_shared/generated-types/NOTIFICATIONS_RULES_RECIPIENTS';
import NOTIFICATIONS_RULES_TIMEFRAMES from '~/server_shared/generated-types/NOTIFICATIONS_RULES_TIMEFRAMES';
import NOTIFICATIONS_SCHEDULES from '~/server_shared/generated-types/NOTIFICATIONS_SCHEDULES';
import NOTIFICATIONS_SCOPES from '~/server_shared/generated-types/NOTIFICATIONS_SCOPES';
import ORGANIZATION_TYPES from '~/server_shared/generated-types/ORGANIZATION_TYPES';
import OSHA_300_CLASSIFICATION_OPTIONS from '~/server_shared/generated-types/OSHA_300_CLASSIFICATION_OPTIONS';
import OSHA_300_INJURY_OPTIONS from '~/server_shared/generated-types/OSHA_300_INJURY_OPTIONS';
import PAYMENT_ADDITIONAL_REQUIRED_FIELDS from '~/server_shared/generated-types/PAYMENT_ADDITIONAL_REQUIRED_FIELDS';
import PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3 from '~/server_shared/generated-types/PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3';
import PAYMENT_METHODS from '~/server_shared/generated-types/PAYMENT_METHODS';
import PAYMENT_METHODS_DETAILS_DICT from '~/server_shared/generated-types/PAYMENT_METHODS_DETAILS_DICT';
import PAYMENT_PRE_SUBMIT_CHECKS from '~/server_shared/generated-types/PAYMENT_PRE_SUBMIT_CHECKS';
import PERFORMANCE_QA from '~/server_shared/generated-types/PERFORMANCE_QA';
import PET_COVERAGE_DICT from '~/server_shared/generated-types/PET_COVERAGE_DICT';
import PET_PERILS_DICT from '~/server_shared/generated-types/PET_PERILS_DICT';
import PIP_HCRA_DICT from '~/server_shared/generated-types/PIP_HCRA_DICT';
import PROPRIETARY_FORMAT_VIDEO_FILE from '~/server_shared/generated-types/PROPRIETARY_FORMAT_VIDEO_FILE';
import RECIPIENTS_TYPES from '~/server_shared/generated-types/RECIPIENTS_TYPES';
import REPORT_TYPES_1099 from '~/server_shared/generated-types/REPORT_TYPES_1099';
import ROLES_TYPES_DICT from '~/server_shared/generated-types/ROLES_TYPES_DICT';
import SIU_CRITERIA_DICT from '~/server_shared/generated-types/SIU_CRITERIA_DICT';
import SUPERVISOR_APPROVAL_STATUS_DICT from '~/server_shared/generated-types/SUPERVISOR_APPROVAL_STATUS_DICT';
import TRAVEL_COVERAGE_DICT from '~/server_shared/generated-types/TRAVEL_COVERAGE_DICT';
import TREATMENT_SURGERIES_DICT from '~/server_shared/generated-types/TREATMENT_SURGERIES_DICT';
import TSC_AUTO_LIABILITY_INDICATORS_DICT from '~/server_shared/generated-types/TSC_AUTO_LIABILITY_INDICATORS_DICT';
import TWILIO_ADJUSTER_STATUS_DICT from '~/server_shared/generated-types/TWILIO_ADJUSTER_STATUS_DICT';
import TWILIO_CONFIGURATION from '~/server_shared/generated-types/TWILIO_CONFIGURATION';
import UK_COUNTRIES_DICT from '~/server_shared/generated-types/UK_COUNTRIES_DICT';
import UK_DRIVER_LICENSES_TYPES from '~/server_shared/generated-types/UK_DRIVER_LICENSES_TYPES';
import UK_VEHICLE_DAMAGED_CAR_PARTS_DICT from '~/server_shared/generated-types/UK_VEHICLE_DAMAGED_CAR_PARTS_DICT';
import VEHICLE_DAMAGED_CAR_PARTS_DICT from '~/server_shared/generated-types/VEHICLE_DAMAGED_CAR_PARTS_DICT';
import WATER_DAMAGES_SOURCES from '~/server_shared/generated-types/WATER_DAMAGES_SOURCES';
import WC_COVERAGE_DICT from '~/server_shared/generated-types/WC_COVERAGE_DICT';

// this manipulation is done in order to maintain the usage of strings as ff after the ff enum was converted to object with metadata
export const CONFIGURATION_FEATURES_NAMES = Object.fromEntries(
  Object.entries(IMPORTED_CONFIGURATION_FEATURES).map((entry) => [entry[0], entry[1].name])
) as {
  [K in keyof typeof IMPORTED_CONFIGURATION_FEATURES]: (typeof IMPORTED_CONFIGURATION_FEATURES)[K]['name'];
};

export const CONFIGURATION_FEATURES_DATA = IMPORTED_CONFIGURATION_FEATURES;

// These exports are kept for legacy files where imports where not updated
// TODO: https://fivesigma.atlassian.net/browse/NGTPA-15173 - codemod to remove these
// No need to add new exports here, instead import directly from server_shared/generated-types/[NAME]
// Make sure to run  npm run generate-enums-file after modifying the server-shared/enums.json file
export {
  ASSIGNMENT_CONDITION_KEY_DICT,
  ASSIGNMENT_CONDITION_OPERATOR_DICT,
  ASSIGNMENT_RULE_ENTITY_DICT,
  ASSIGNMENT_RULE_METHOD_DICT,
  ASSIGNMENT_RULE_STATIC_ASSIGNEES_DICT,
  AUTO_COVERAGES_DICT,
  AUTO_LIABILITY_INDICATORS_DICT,
  AUTO_LIABILITY_PERCENTAGE_OF_INSURE_LIABILITY_LIST,
  AUTO_OPEN_FNOL_PARTIES_REQUIRED,
  AUTO_PASSENGER_POSITIONS,
  AUTO_TICKETS_VIOLATION,
  BOOL4_OPTIONS,
  CATASTROPHE_TYPES,
  CLAIM_NUMBERING_SCHEME_OPTIONS,
  CLAIM_SEARCH,
  CLAIM_TYPES_CONFIGURATION,
  CLOSING_REASON_CATEGORIES,
  COMMUNICATION_CHANNEL_DICT,
  CONSTANTS,
  CONTACT_ADDRESS_CHANGE_REASONS,
  CONTACT_EMAIL_CHANGE_REASONS,
  CONTACT_GOV_ID_VALIDATION_REGEXES,
  CONTACT_IDENTITY_CHANGE_REASONS,
  CONTACT_PHONE_CHANGE_REASONS,
  COUNTRIES_DICT,
  COUNTRY_TO_STATE_MAP,
  COVERAGE_CONFIGURATION_LIMITS,
  COVERAGE_DECISION_DICT,
  COVERAGES_ISO_CODES,
  CPT_CODES_PLACE_OF_SERVICES,
  CPT_TRANSACTION_TYPES,
  CRIF_AND_SIRA_EXCLUDED_SUB_ORGS,
  CUSTOM_FIELD_TYPES,
  CUSTOM_FNOL_FIELDS_TYPES,
  DAMAGE_ASSESSMENT_CATEGORY_CODES_DICT,
  DAMAGE_ASSESSMENT_EXCEPTION_CODES,
  DATA_EXPORT,
  DEFAULT_INCIDENT_DETAILS_FIELDS,
  DEFAULT_MOI_METHODS,
  DENY_MEDICAL_BILL_REASONS_DICT,
  DUPLICATE_PAYMENTS_VERIFICATION_ACTION,
  EXTENT_OF_INJURY_TYPES_DICT,
  FINANCIAL_STATUS_DICT,
  GL_COVERAGE_DICT,
  GLOBAL_ADDRESSES_OPTIONS,
  HOME_COVERAGES_DICT,
  INDEPENDENT_MEDICAL_SPECIALISTS_DICT,
  INTEGRATION_STATUS_DICT,
  INVOLVED_DICT_PER_CLAIM_TYPE,
  LANGUAGES_DICT,
  LIABILITY_LIEN_TYPES_DICT,
  LIGHT_DUTY_POSITIONS_DICT,
  LIGHT_DUTY_STATUSES_DICT,
  LOAD_BALANCING,
  LOB_ISO_CODES,
  LOCATION_TYPES_DICT,
  LOSS_CAUSE_ISO_CODES,
  LOSS_TYPES,
  MARSHMALLOW_FAULTS_LIST,
  MARSHMALLOW_JOURNEY_PURPOSE_LIST,
  MARSHMALLOW_SPEEDS_LIST,
  MARSHMALLOW_VEHICLE_DAMAGE_LEVELS_LIST,
  MARSHMALLOW_WEATHER_CONDITIONS_DICT,
  MEDICAL_BILL_PROVIDER_PROFESSIONS,
  MGM_EOB_CODES,
  MGM_GL_COMPENSATION_DICT,
  MGM_GL_COVERAGE_DECISION_DICT,
  MGM_LABEL_DICT,
  MGM_WC_BODY_PART_CODE_DICT,
  MGM_WC_CAUSE_CODE_DICT,
  MGM_WC_INJURY_CODE_DICT,
  MOI_EXPERTISE_TYPES,
  MOI_STATUSES,
  MOR_OPTIONS_DICT,
  NOTE_SUBJECT,
  NOTIFICATIONS_ENTITY_STATUSES,
  NOTIFICATIONS_RULES_RECIPIENTS,
  NOTIFICATIONS_RULES_TIMEFRAMES,
  NOTIFICATIONS_SCHEDULES,
  NOTIFICATIONS_SCOPES,
  ORGANIZATION_TYPES,
  OSHA_300_CLASSIFICATION_OPTIONS,
  OSHA_300_INJURY_OPTIONS,
  PAYMENT_ADDITIONAL_REQUIRED_FIELDS,
  PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3,
  PAYMENT_METHODS,
  PAYMENT_METHODS_DETAILS_DICT,
  PAYMENT_PRE_SUBMIT_CHECKS,
  PERFORMANCE_QA,
  PET_COVERAGE_DICT,
  PET_PERILS_DICT,
  PIP_HCRA_DICT,
  PROPRIETARY_FORMAT_VIDEO_FILE,
  RECIPIENTS_TYPES,
  REPORT_TYPES_1099,
  ROLES_TYPES_DICT,
  SIU_CRITERIA_DICT,
  SUPERVISOR_APPROVAL_STATUS_DICT,
  TRAVEL_COVERAGE_DICT,
  TREATMENT_SURGERIES_DICT,
  TSC_AUTO_LIABILITY_INDICATORS_DICT,
  TWILIO_ADJUSTER_STATUS_DICT,
  TWILIO_CONFIGURATION,
  UK_COUNTRIES_DICT,
  UK_DRIVER_LICENSES_TYPES,
  UK_VEHICLE_DAMAGED_CAR_PARTS_DICT,
  VEHICLE_DAMAGED_CAR_PARTS_DICT,
  WATER_DAMAGES_SOURCES,
  WC_COVERAGE_DICT,
};
