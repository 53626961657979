import { makeStyles } from '@material-ui/core/styles';

import { DRAWER_WIDTH } from './rootStyles';

import colors from './colors.module.scss';

export const colorPalette = {
  success: colors.success,
  error: colors.error,
  text: {
    primary: colors.textPrimary,
    secondary: colors.textSecondary,
    link: colors.textLink,
    disabled: colors.textDisabled,
  },
  status: {
    done: colors.statusDone,
    failed: colors.statusFailed,
    pending: colors.statusPending,
    canceled: colors.statusCanceled,
    warning: colors.statusWarning,
    not_active: colors.statusCanceled,
    in_progress: colors.statusInProgress,
  },
  borders: {
    bottom: colors.grey_05,
  },
  appBackgroundColor: colors.appBackgroundColor,
  shadowColor: colors.shadowColor,
};

export const widthsPalette = {
  wideFullScreenWidth: 1162,
};

const styles = (theme) => {
  const textIconSize = theme.typography.fontSize * 1.25;
  const actionColor = '#32C7CA';
  const linkColor = '#305A7F';
  const warningColor = '#FFBB00';
  const disabledIconColor = '#909090';

  const drawer = {
    overflowX: 'hidden',
    width: DRAWER_WIDTH,
    backgroundColor: colors.drawerBackground,
    boxShadow: '0 5px 30px 0 rgba(240,244,247,0.63)',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      border: '1px solid transparent',
    },
  };

  return {
    drawer: {
      ...drawer,
    },
    OutOfOfficeDrawer: {
      ...drawer,
      backgroundColor: '#444b52',
    },
    drawerIconsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      paddingTop: 16,
    },
    drawerIconButton: {
      marginTop: 10,
      marginBottom: 10,
      width: 40,
      height: 40,
      borderRadius: 6,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 8,
      paddingLeft: 8,
      '&:active': {
        borderRadius: 6,
        backgroundColor: actionColor,
      },
      '&:hover': {
        borderRadius: 6,
        backgroundColor: colors.buttonHoverGrey,
      },
    },
    drawerIconButtonSelected: {
      backgroundColor: actionColor,
    },
    drawerIcon: {
      color: 'white',
      opacity: 0.8,
    },
    drawerAvatar: {
      border: '1px solid rgba(255,255,255,0.3)',
      marginTop: 4,
      marginBottom: 10,
      '&:hover': {
        border: '1px solid rgba(255,255,255, 0.7)',
      },
    },
    drawerButtonIcons: {
      display: 'flex',
      margin: 'auto auto 30px auto',
      paddingTop: '60px',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    actionColor: {
      color: actionColor,
    },
    actionBackgroundColor: {
      backgroundColor: actionColor,
    },
    actionButton: {
      backgroundColor: actionColor,
      '&:hover': {
        backgroundColor: '#23aebb',
        borderColor: '#0062cc',
      },
      '&:active': {
        backgroundColor: '#23aebb',
      },
    },
    cancelButton: {
      color: '#305A7F',
      backgroundColor: '#FFFFFF',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgba(48,90,127,0.1),0px 4px 5px 0px rgba(48,90,127,0.1),0px 1px 4px 0px rgba(48,90,127,0.1)',
      },
      '&:active': {
        backgroundColor: 'rgba(48,90,127,0.2)',
      },
      '&.withMarginRight': {
        marginRight: theme.spacing(3.5),
      },
      '&:not(.withoutBorder)': {
        border: '1px solid #305a7f',
      },
    },
    googleButton: {
      color: '#305A7F',
      border: '1px solid #305a7f',
      height: '42px',
    },
    loginButton: {
      width: '200px',
      height: '42px',
    },
    collapsibleWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      cursor: 'pointer',
      padding: '16x',
      borderRadius: '8px',
    },
    claimPageBarTableRow: {
      height: '45px',
    },
    fnolPageBarTableRow: {
      height: '40px',
    },
    tabs: {
      zIndex: 3,
      maxHeight: '40px',
      width: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    claimPageBodyNoTabs: {
      flexGrow: 1,
      overflow: 'auto',
    },
    pageBody: {
      flexGrow: 1,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(1),
    },
    cancelButtonWithRoundMargin: {
      margin: theme.spacing(1),
      color: '#305A7F',
      backgroundColor: '#FFFFFF',
      border: '1px solid #305a7f',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgba(48,90,127,0.1),0px 4px 5px 0px rgba(48,90,127,0.1),0px 1px 4px 0px rgba(48,90,127,0.1)',
      },
      '&:active': {
        backgroundColor: 'rgba(48,90,127,0.2)',
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      marginTop: theme.spacing(4),
    },
    leftButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: theme.spacing(2),
    },
    alignedContainerForGrid: {
      display: 'flex',
      width: '100%',
      marginTop: theme.spacing(2),
    },
    inLineButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    spaceBetweenButtonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: theme.spacing(4),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: '5px',
      '&.MuiChip-root': {
        fontFamily: 'Poppins',
        fontSize: 12,
      },
    },
    chipsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '5px',
      justifyContent: 'flex-start',
    },
    container: {
      display: 'flex',
    },
    containerCentered: {
      display: 'flex',
      alignItems: 'center',
    },
    containerJustifyContentCentered: {
      display: 'flex',
      justifyContent: 'center',
    },
    inputContainer: {
      marginTop: 3,
      marginBottom: 3,
    },
    spacedTextFieldWrapper: {
      '& .MuiGrid-item': {
        marginTop: 6,
        marginBottom: 6,
      },
    },
    textField: {
      marginTop: 3,
      marginBottom: 3,
    },
    formTextField: {
      marginTop: 3,
      marginBottom: 3,
      minHeight: 65,
    },
    formTextFieldNoErrorSpacing: {
      marginTop: 3,
      marginBottom: 3,
    },
    formTextFieldRow: {
      minHeight: 65,
      width: '200px',
    },
    formUnderInputErrorText: {
      ontFamily: 'Poppins',
      fontSize: 12,
      color: colorPalette.error,
    },
    textFieldRow: {
      marginLeft: 2,
      marginRight: 2,
      width: '200px',
    },
    showField: {
      marginBottom: '2px',
      marginTop: '2px',
    },
    showValueField: {
      whiteSpace: 'pre-wrap',
    },
    showValueEditableField: {
      whiteSpace: 'pre-wrap',
    },
    inlineEditIcon: {
      marginLeft: 4,
    },
    card: {
      height: '100%',
    },
    cardDiv: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    cardDivRow: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
    },
    cardDivNoTopMarginRow: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    cardDivRowInternal: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    leftButtonDialog: {
      marginRight: theme.spacing(2),
    },
    leftButtonIcon: {
      marginRight: theme.spacing(1),
    },
    rightButtonIcon: {
      marginLeft: theme.spacing(1),
    },
    rightButtonInverseIcon: {
      marginLeft: theme.spacing(1),
      color: '#305A7F',
      backgroundColor: '#FFFFFF',
      border: '1px solid #305a7f',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgba(48,90,127,0.1),0px 4px 5px 0px rgba(48,90,127,0.1),0px 1px 4px 0px rgba(48,90,127,0.1)',
      },
      '&:active': {
        backgroundColor: 'rgba(48,90,127,0.2)',
      },
    },
    ButtonInverseIcon: {
      color: '#305A7F',
      backgroundColor: '#FFFFFF',
      border: '1px solid #305a7f',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgba(48,90,127,0.1),0px 4px 5px 0px rgba(48,90,127,0.1),0px 1px 4px 0px rgba(48,90,127,0.1)',
      },
      '&:active': {
        backgroundColor: 'rgba(48,90,127,0.2)',
      },
    },
    loading: {
      color: '#192835',
      margin: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    spacer: {
      flex: 1,
    },
    table: {
      minWidth: 50,
      background: 'none',
      border: 'none',
    },
    fixedTable: {
      tableLayout: 'fixed',
    },
    headerTableCell: {
      color: 'rgba(0,0,0,0.5)',
      fontSize: '12px',
      letterSpacing: '-0.26px',
      padding: '5px 0 0 5px',
      fontWeight: 'bold',
      backgroundColor: '#fbfbfb',
      borderBottom: 'none',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    tableCell: {
      '&:first-child': {
        paddingLeft: theme.spacing(2),
      },
      verticalAlign: 'middle',
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    summaryFooterTableCell: {
      '&:first-child': {
        paddingLeft: theme.spacing(3),
      },
      verticalAlign: 'middle',
      paddingTop: '6px',
      paddingBottom: '6px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
    textIcon: {
      fontSize: textIconSize,
    },
    marginedIcon: {
      margin: '5px',
    },
    tableCellIconContainer: {
      position: 'relative',
      '& $cellIcon': {
        visibility: 'hidden',
      },
      '&:hover': {
        '& $cellIcon': {
          visibility: 'visible',
        },
      },
    },
    cellIcon: {
      position: 'absolute',
      right: -0.5 * theme.spacing(1),
      top: `calc(50% - ${textIconSize / 2}px)`,
    },
    textIconButton: {
      height: theme.typography.fontSize * 2,
      width: theme.typography.fontSize * 2,
    },
    warningBackground: {
      backgroundColor: `${warningColor} !important`,
    },
    warningColor: {
      color: `${warningColor} !important`,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-12px',
      marginLeft: '-12px',
    },
    overflowText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    overflowTextWithWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '@keyframes smsMessageSelectedEffect': {
      '0%': { backgroundColor: undefined },
      '50%': { backgroundColor: 'lightgray' },
      '100%': { backgroundColor: undefined },
    },
    smsMessageSelected: {
      animationName: '$smsMessageSelectedEffect',
      animationDuration: '1s',
    },
    outlinedCardFieldset: {
      border: '1px solid rgba(0,0,0,0.09)',
      borderRadius: '10px',
    },
    outlinedCardLegend: {
      padding: '5px 9px 5px 9px',
    },
    containerTitle: {
      padding: '0',
    },
    containerContent: {
      paddingBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogFullHeight: {
      '& .MuiDialog-paperScrollPaper': {
        height: 'calc(100% - 64px)',
        maxHeight: '760px',
      },
    },
    iconButton: {
      padding: '8px',
    },
    tooltipIcon: {
      display: 'flex',
    },
    iconSize: {
      fontSize: '16px',
    },
    dialogTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    centeredDialogTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogTitleContent: {
      padding: '24px 24px 16px 24px',
    },
    cardHeaderAction: {
      alignSelf: 'start',
    },
    dialogTitleAction: {
      display: 'flex',
      alignItems: 'center',
    },
    indicationIcon: {
      opacity: 0.6,
    },
    smallIcon: {
      width: 20,
      height: 20,
    },
    claimMenu: {
      width: 220,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        width: 70,
      },
    },
    claimMenuButtonContainer: {
      padding: '10px 13px 0px 13px',
      width: '100%',
    },
    claimMenuButton: {
      // TODO ADD on hover background
      padding: 0,
      height: '50px',
      minWidth: 55,
      width: '100%',
      justifyContent: 'flex-start',
      paddingLeft: '20px',
    },
    displayDownLg: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    displayUpMd: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    claimMenuButtonLabel: {
      opacity: 0.7,
      color: '#192835',
      fontSize: '15px',
      letterSpacing: '-0.3px',
      lineHeight: '23px',
      textTransform: 'none',
    },
    claimMenuButtonLabelSelected: {
      opacity: 1,
    },
    claimMenuButtonSelected: {
      opacity: 1,
      backgroundColor: 'rgba(50,199,202,0.07)',
    },
    claimMenuIconSelected: {
      color: actionColor,
    },
    actionsMenuButton: {
      padding: 0,
      height: '40px',
      width: '100%',
      justifyContent: 'flex-start',
      paddingLeft: '20px',
    },
    actionsMenuButtonLabelEnabled: {
      color: 'rgba(0, 0, 0, 0.7)',
      fontSize: '15px',
      letterSpacing: '-0.3px',
      lineHeight: '22px',
      textTransform: 'none',
      fontWeight: 400,
    },
    actionsMenuButtonLabelDisabled: {
      fontSize: '15px',
      letterSpacing: '-0.3px',
      lineHeight: '22px',
      textTransform: 'none',
      fontWeight: 400,
    },
    actionMenuDivider: {
      marginLeft: 20,
      marginRight: 20,
      backgroundColor: 'rgba(0,0,0,0.09)',
    },
    summary: {
      borderRadius: '5px',
      backgroundColor: '#E6F3F4',
      padding: '14px',
      boxSizing: 'border-box',
      minHeight: '100%',
    },
    summarySectionTitle: {
      borderBottom: '1px solid #D0D0D0',
      fontWeight: 550,
      marginBottom: '3px',
      letterSpacing: '0px',
      color: '#606060',
      textTransform: 'uppercase',
      fontSize: '10px',
    },
    summarySubTitle: {
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 550,
      color: '#606060',
    },
    h4: {
      fontSize: '14px',
      letterSpacing: '0.15px',
      fontWeight: 600,
      color: '#202020',
    },
    summaryNumber: {
      fontSize: '12px',
      letterSpacing: '0.4px',
      fontWeight: 600,
      marginLeft: '3px',
      color: '#202020',
    },
    elevatedCard: {
      boxShadow: `0px 4px 8px ${colors.shadowColor}`,
      backgroundColor: '#FFFFFF',
      borderRadius: '6px',
      padding: '40px',
    },
    innerCard: {
      backgroundColor: '#F7F7F7',
      padding: '10px 14px',
      borderRadius: '5px',
      marginBottom: '14px',
    },
    innerCardTitle: {
      borderBottom: '1px solid #D0D0D0',
      marginBottom: '10px',
      paddingBottom: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    tableActionsIcon: {
      padding: '3px 7px',
    },
    multilineWithoutScroll: {
      '& .MuiInputBase-inputMultiline': {
        overflow: 'hidden',
      },
    },
    formsSwitch: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.darker,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.darker,
      },
    },
    marginedFormsSwitch: {
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.darker,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.darker,
      },
      marginTop: '-8px',
    },
    primaryLink: {
      color: theme.palette.primary.darker,
      fontWeight: 'bold',
      fontSize: '15px',
      letterSpacing: '-0.3px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    secondaryLink: {
      color: theme.palette.primary.darker,
      fontSize: '12px',
      letterSpacing: '-0.3px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    fixedSizeConfigurationBox: {
      minHeight: '560px',
      maxHeight: '560px',
      overflow: 'scroll',
    },
    customClaimsSwitch: {
      marginTop: '10px',
    },
    link: {
      color: linkColor,
      fontWeight: 500,
    },
    lightLink: {
      color: actionColor,
      fontWeight: 500,
    },
    fontWeightNormal: {
      fontWeight: 'normal',
    },
    radioOrCheckboxWithIcon: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #D0D0D0',
      borderRadius: '4px',
      padding: '3px 8px',
      width: 'max-content',
      marginRight: '14px',
      minWidth: '120px',
      backgroundColor: 'inherit',
      cursor: 'pointer',
      '&:not(.disabled):hover': {
        backgroundColor: 'rgba(227, 243, 243, 0.6)',
        border: '2px solid rgba(26, 156, 158, 0.6)',
        padding: '2px 7px',
      },
      '& .Mui-checked': {
        color: theme.palette.primary.darker,
      },
      '&.disabled': {
        borderColor: '#E0E0E0',
        '& .Mui-checked': {
          color: '#00000061',
        },
        cursor: 'auto',
      },
    },
    radioOrCheckboxWithIconOverride: {
      display: 'block',
      margin: 0,
      width: '100%',
      backgroundColor: '#fafafa',
    },
    hoverableIcon: {
      cursor: 'pointer',
      '&:hover': {
        stroke: theme.palette.primary.darker,
        fill: theme.palette.primary.darker,
      },
    },
    hoverableIconNew: {
      cursor: 'pointer',
      '&:hover': {
        color: '#1A9C9E',
      },
    },
    hoverableNonFilledIcon: {
      cursor: 'pointer',
      marginLeft: 4,
      '&:hover': {
        stroke: theme.palette.primary.darker,
      },
    },
    hoverableIconDisabled: {
      cursor: 'auto',
      pointerEvents: 'auto', // to enable tooltip behavior on disabled icons
      marginLeft: 4,
    },
    filledIcon: {
      cursor: 'pointer',
      stroke: theme.palette.primary.darker,
    },
    dotIconContainer: {
      right: theme.spacing(1),
      display: 'flex',
      position: 'relative',
    },
    antiMarginForMarginIcon: {
      marginLeft: '-5px',
      marginTop: '5px',
    },
    reportsDashboardHeaderTab: {
      display: 'flex',
      justifyContent: 'space-between',
      background: 'initial',
      marginRight: 24,
      borderStyle: 'solid',
      borderWidth: 0,
      borderBottomWidth: 1,
      borderRadius: 0,
      borderColor: '#D0D0D0',
    },
    greyedText: {
      color: '#00000061',
    },
    hoverableNonStrokedIcon: {
      cursor: 'pointer',
      '&:hover': {
        fill: theme.palette.primary.darker,
        fillOpacity: 1,
      },
    },
    italicGrey: {
      fontStyle: 'italic',
      color: '#606060',
    },
    disabledFilledIcon: {
      fill: disabledIconColor,
    },
    spaceAroundContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    moiBackToTableTitle: {
      textDecoration: 'underline',
      textTransform: 'capitalize',
      color: colorPalette.text.disabled,
      '&:hover': {
        color: colorPalette.text.link,
      },
    },
    moiDialogInstructions: {
      color: colorPalette.text.secondary,
      lineHeight: '150%',
      marginTop: '20px',
    },
    moiDialogSubtitle: {
      padding: '24px 0',
      paddingTop: '24px',
      paddingBottom: '24px',
      fontWeight: '600',
      '&.withoutPaddingBottom': {
        paddingBottom: '0',
      },
    },
    moiCardHeader: {
      fontSize: '20px',
      paddingTop: '0',
    },
    centeredFullScreenCardWrapper: {
      margin: '40px auto',
      backgroundColor: colors.appBackgroundColor,
      width: widthsPalette.wideFullScreenWidth,
      alignSelf: 'center',
      boxShadow: `0px 6px 12px ${colors.shadowColor}`,
      borderRadius: '6px',
    },
    cardBordered: {
      border: `1px solid ${colors.dividerColor}`,
      borderRadius: '8px',
    },
    buttonRightAligned: {
      textAlign: 'right',
      marginTop: '15px',
      marginBottom: '15px',
    },
    policySearchButton: {
      display: 'flex',
      margin: '38px 0 6px auto',
      padding: '8px 22px',
      lineHeight: '26px',
      fontSize: '14px',
      height: 'auto',
    },
    noPadding: {
      padding: 0,
    },
    sharedLimits: {
      paddingTop: '20px',
    },
    disabledPointerEventsOverride: {
      pointerEvents: 'auto',
      paddingBottom: '20px',
      fontWeight: 600,
    },
    shadowedCardDivRow: {
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      marginRight: 10,
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
    },
    alertBanner: {
      marginBottom: 20,
    },
  };
};

export const useStyles = makeStyles(styles, { index: 1 });

export default styles;
