const baseColors = {
  transparent: 'transparent',
  current: 'currentColor',
  white: '#ffffff',
  slate: {
    100: '#FAFAFA',
    200: '#F5F5F5',
    300: '#F4F4F4',
    400: '#EDEDED',
    500: '#E0E0E0',
    600: '#D0D0D0',
    700: '#909090',
    800: '#606060',
    900: '#202020',
  },
  grey: {
    100: '#EAEBED',
    200: '#CCCFD3',
    300: '#A3A9AF',
    400: '#79818A',
    500: '#505B67',
    600: '#2A3746',
    700: '#242F3C',
    800: '#1E2732',
    900: '#181F28',
    950: '#13191F',
  },
  teal: {
    100: '#EBFAFA',
    200: '#D6F4F4',
    300: '#ADE9EA',
    400: '#84DDDF',
    500: '#5BD2D5',
    600: '#32C7CA',
    700: '#1A9C9E',
    800: '#196465',
    900: '#0D3233',
    950: '#051414',
  },
  pink: {
    100: '#FFF0F5',
    200: '#FFDBE7',
    300: '#FFBFD3',
    400: '#FFA2BF',
    500: '#FF86AB',
    600: '#FF6B99',
    700: '#D95B82',
    800: '#B54C6D',
    900: '#913D57',
    950: '#733045',
  },
  blue: {
    100: '#EAEFF2',
    200: '#CDD7E0',
    300: '#A6B8C8',
    400: '#7D97AE',
    500: '#557896',
    600: '#305A7F',
    700: '#294D6C',
    800: '#22405A',
    900: '#1B3348',
    950: '#162839',
    1000: '#5D5FEF',
  },
  red: {
    100: '#FEECEB',
    700: '#F44336',
  },
  green: {
    100: '#E7F8F3',
    600: '#32CA8B',
    700: '#0CBC8B',
  },
  orange: {
    100: '#FFF3EB',
    700: '#FF8833',
  },
  yellow: {
    100: '#FFF8E6',
    700: '#FFBB00',
    800: '#ACA909',
  },
  blueSky: {
    100: '#EFFAFC',
    700: '#339CFD',
  },
  purple: {
    100: '#F3E8FC',
    200: '#E0C6F5',
    600: '#8C3BC7',
    700: '#3D16AE',
  },
  berry: {
    400: '#D9A0B3',
    500: '#C86E98', // Light berry-600
    600: '#A53472',
    700: '#5D2141',
  },
  azure: {
    200: '#D6EBFF',
    500: '#5CB0FD',
    700: '#297DCA',
  },
};

const colors = {
  ...baseColors,
  status: {
    done: baseColors.green[700],
    failed: baseColors.red[700],
    pending: baseColors.yellow[700],
    warning: baseColors.orange[700],
    calledOff: baseColors.slate[700],
    action: baseColors.blueSky[700],
  },
  ai: {
    bgAiDark: '#0C375D',
    bgAiLight: '#47CCCF',
    bgHumanDark: '#D95B82',
    bgHumanLight: '#FF6B99',
    bgAiMessage: '#F5FDFD',
    bgHumanMessage: baseColors.slate[100],
  },
  aiV2: {
    fadedWhite: '#F8F8FE',
    grey: {
      100: '#E8EAED',
      200: '#80858A',
      300: '#737376',
      400: '#606060',
    },
    lightGrey: '#E8EAED',
    darkGrey: '#80858A',
    darkerGrey: '#737376',

    darkBlue: '#23376C',
    lightpink: '#FFF2F6',
    pink: '#FF6B99',
  },
};

export default colors;
