import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getIn, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import useFetchAdjusters from '~/Adjuster/useFetchAdjusters';
import colors from '~/assets/colors.module.scss';
import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import { useCms } from '~/components/hooks/useCms';
import { useSubOrganization } from '~/components/hooks/useSubOrganization';
import { useLicenses } from '~/components/SystemConfiguration/Tabs/AdjusterManagement';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';
import { reportErrorInProductionOrThrow, stringCmp } from '~/Utils';

export const LICENSED_OPTIONS = {
  licensed: 'Licensed',
  unlicensed: 'Unlicensed',
  undefined: '',
};

export const NOT_LICENSED_TEXT = 'Not Licensed';

const AdjusterSelect = ({
  includeDaSpecialist,
  excludedAdjustersIds,
  allowedAdjustersIds,
  label,
  textFieldProps,
  onChange,
  value,
  showOnly,
  disabled,
  width,
  extraValues,
  allUsersIncludingRemoved,
  currentAdjusterMark,
  sortAlphabetic = false,
  allUsers,
  checkLicenses = false,
}) => {
  const classes = useStyles();
  const { user } = useCms();
  const { subOrganization } = useSubOrganization();

  const { isLicensesFeatureEnabled, isLicenseCorrelationEnabled, isAdjusterLicensedForLossLocation } = useLicenses();
  const { claim } = useClaim();

  const loss_location_country = claim?.incident?.loss_location?.country;
  const shouldCheckLicenses =
    isLicensesFeatureEnabled && isLicenseCorrelationEnabled && checkLicenses && loss_location_country === 'US';
  const {
    isLoadingPossibleAdjusters,
    isErrorPossibleAdjusters,
    possibleAdjustersWithRemoved = [],
    possibleAdjustersWithDaSpecialist = [],
    possibleAdjustersWithoutDaSpecialist = [],
  } = useFetchAdjusters(user.organization_id);

  let possibleAdjusters = includeDaSpecialist
    ? possibleAdjustersWithDaSpecialist
    : possibleAdjustersWithoutDaSpecialist;

  possibleAdjusters = allUsersIncludingRemoved ? possibleAdjustersWithRemoved : possibleAdjusters;
  possibleAdjusters = !isEmpty(allUsers) ? allUsers : possibleAdjusters;

  if (allowedAdjustersIds) {
    possibleAdjusters = possibleAdjusters.filter((adjuster) => allowedAdjustersIds.includes(adjuster.id));
  }

  if (excludedAdjustersIds) {
    possibleAdjusters = possibleAdjusters.filter((adjuster) => !excludedAdjustersIds.includes(adjuster.id));
  }

  if (extraValues) {
    possibleAdjusters = extraValues.concat(possibleAdjusters);
  }

  if (sortAlphabetic) {
    possibleAdjusters = possibleAdjusters.sort((a, b) => stringCmp(a?.username || '', b?.username || ''));
  }

  if (currentAdjusterMark) {
    const currentAdjuster = possibleAdjusters?.find((adjuster) => adjuster.id === user.id);
    if (currentAdjuster?.username && !currentAdjuster?.username?.endsWith(currentAdjusterMark)) {
      currentAdjuster.username += currentAdjusterMark;
    }

    possibleAdjusters = possibleAdjusters.sort((a, b) =>
      a?.id === currentAdjuster?.id ? -1 : b?.id === currentAdjuster?.id ? 1 : 0
    );
  }

  if (subOrganization) {
    possibleAdjusters = possibleAdjusters.filter(
      (adjuster) =>
        !adjuster.sub_organization_ids ||
        adjuster.sub_organization_ids.length === 0 ||
        adjuster.sub_organization_ids.includes(subOrganization.id)
    );
  }

  if (shouldCheckLicenses) {
    possibleAdjusters = possibleAdjusters.map((adjuster) => {
      const isLicensed = isAdjusterLicensedForLossLocation(adjuster, claim?.incident?.loss_location?.state);
      if (isLicensed === undefined) {
        return {
          ...adjuster,
          is_licensed: LICENSED_OPTIONS.undefined,
        };
      }
      return {
        ...adjuster,
        is_licensed: isLicensed ? LICENSED_OPTIONS.licensed : LICENSED_OPTIONS.unlicensed,
      };
    });
    possibleAdjusters = [
      ...possibleAdjusters.filter((adjuster) => adjuster.is_licensed === LICENSED_OPTIONS.undefined),
      ...possibleAdjusters.filter((adjuster) => adjuster.is_licensed === LICENSED_OPTIONS.licensed),
      ...possibleAdjusters.filter((adjuster) => adjuster.is_licensed === LICENSED_OPTIONS.unlicensed),
    ];
  }

  const getOptionLabel = (option) => option.username;

  const renderOptionForCheckLicenses = (option) => {
    let isLicensed = option.is_licensed;
    if (option.username.includes('File Owner') && claim) {
      const claimOwner = possibleAdjusters.find((adjuster) => adjuster.id === claim.handling_adjuster_id);
      isLicensed = claimOwner?.is_licensed;
    }

    return (
      <>
        <div style={{ width: '100%' }}>{option.username}</div>
        {isLicensed === LICENSED_OPTIONS.unlicensed && (
          <Chip
            size="small"
            label={NOT_LICENSED_TEXT}
            className={classes.chip}
            style={{ backgroundColor: colors.statusWarning }}
          />
        )}
      </>
    );
  };

  const valueToPass = typeof value === 'function' ? value(possibleAdjusters) : value;

  if (showOnly) {
    return (
      <ShowOnlyTextField
        classes={classes}
        showOnlyValueComponent={valueToPass ? getOptionLabel(valueToPass) : ''}
        label={label}
      />
    );
  }

  if (!claim && checkLicenses) {
    reportErrorInProductionOrThrow('Licenses check can only be done in the context of a claim');
  }

  const AutocompleteComponent = shouldCheckLicenses
    ? withStyles({
        groupLabel: {
          color: colors.textSecondary,
          fontWeight: 600,
          fontSize: '12px',
        },
      })(Autocomplete)
    : Autocomplete;

  return (
    <AutocompleteComponent
      id="adjuster"
      options={possibleAdjusters}
      renderOption={shouldCheckLicenses ? renderOptionForCheckLicenses : undefined}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => option.id === value.id}
      groupBy={shouldCheckLicenses ? (option) => option.is_licensed : undefined}
      className={`w-[${width}px]`}
      loading={isLoadingPossibleAdjusters || isErrorPossibleAdjusters}
      onChange={(e, newVal) => onChange(e, newVal || '')}
      value={valueToPass || null}
      disabled={disabled || isLoadingPossibleAdjusters || isErrorPossibleAdjusters}
      label={label}
      textFieldProps={textFieldProps}
    />
  );
};

AdjusterSelect.propTypes = {
  includeDaSpecialist: PropTypes.bool,
  excludedAdjustersIds: PropTypes.array,
  allowedAdjustersIds: PropTypes.array, // If this is given, possible adjusters is filtered to contains only adjusters from this list (values in extraValues will be shown anyway)
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  textFieldProps: PropTypes.object,
  currentAdjusterMark: PropTypes.string,
  sortAlphabetic: PropTypes.bool,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  allUsers: PropTypes.array,
  allUsersIncludingRemoved: PropTypes.bool,
  extraValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
  checkLicenses: PropTypes.bool,
};

const AdjusterSelectTextFieldFormik = ({
  id,
  includeDaSpecialist,
  excludedAdjustersIds,
  allowedAdjustersIds,
  label,
  showOnly,
  disabled,
  extraValues,
  currentAdjusterMark,
  sortAlphabetic = false,
  allUsers,
  onChange,
  checkLicenses,
  ...restProps
}) => {
  const { setFieldValue, setFieldTouched, values, touched, errors } = useFormikContext();
  const defaultOnChange = (_, adjuster) => setFieldValue(id, adjuster ? adjuster.id : '');
  return (
    <AdjusterSelect
      showOnly={showOnly}
      disabled={disabled}
      label={label}
      extraValues={extraValues}
      includeDaSpecialist={includeDaSpecialist}
      allowedAdjustersIds={allowedAdjustersIds}
      excludedAdjustersIds={excludedAdjustersIds}
      currentAdjusterMark={currentAdjusterMark}
      sortAlphabetic={sortAlphabetic}
      allUsers={allUsers}
      onChange={onChange || defaultOnChange}
      value={(possibleAdjusters) => possibleAdjusters.find((adjuster) => adjuster.id === getIn(values, id)) || null}
      textFieldProps={{
        error: getIn(errors, id) && getIn(touched, id),
        helperText: getIn(errors, id) && getIn(touched, id) && getIn(errors, id),
        onBlur: () => setFieldTouched(id, true),
        ...restProps,
      }}
      checkLicenses={checkLicenses}
    />
  );
};

AdjusterSelectTextFieldFormik.propTypes = {
  includeDaSpecialist: PropTypes.bool,
  excludedAdjustersIds: PropTypes.array,
  allowedAdjustersIds: PropTypes.array, // If this is given, possible adjusters is filtered to contains only adjusters from this list (values in extraValues will be shown anyway)
  label: PropTypes.string.isRequired,
  allUsers: PropTypes.array,
  textFieldProps: PropTypes.object,
  currentAdjusterMark: PropTypes.string,
  sortAlphabetic: PropTypes.bool,
  id: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  extraValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      username: PropTypes.string.isRequired,
    })
  ),
  checkLicenses: PropTypes.bool,
};

export { AdjusterSelect };
export default AdjusterSelectTextFieldFormik;
