import type { CSSProperties } from 'react';
import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import axios from 'axios';

import { useClaim } from '~/components/ClaimContainer';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import PermissionsButtonWrapper from '~/components/core/Permissions/PermissionsButtonWrapper';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';
import { RestrictedPermissions } from '~/components/core/Permissions/RestrictedPermissions';
import type { DocumentFile } from '~/components/Documents/UploadDocumentsCard/UploadAIDocumentsCard';
import MultiUploadDocuments from '~/components/Documents/UploadDocumentsCard/UploadAIDocumentsCard';
import AttachmentIcon from '~/components/icons/AttachmentIcon';
import SendMessageIcon from '~/components/icons/SendMessageIcon';
import { reportAxiosError } from '~/Utils';

export interface ChatInputFieldProps {
  onUserInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUserSubmit: () => Promise<void>;
  disabled: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  value: string;
  type: 'elevated' | 'inline';
}

const typeToStyleProps: Record<ChatInputFieldProps['type'], { className: string; style: CSSProperties }> = {
  elevated: {
    className: 'flex w-full items-center rounded-2xl border-2 border-solid border-[#1B2559] py-12 pl-20 pr-12',
    style: {
      boxShadow: 'rgb(78 97 236 / 16%) 0px 12px 20px 0px',
    },
  },
  inline: {
    className: 'flex w-full items-center py-12 pl-20 pr-12 border-0 border-t-1 border-solid border-[#23376c38] ',
    style: {},
  },
};

export const ChatInputField: React.FC<ChatInputFieldProps> = ({
  onUserInput,
  onUserSubmit,
  disabled,
  inputRef,
  value,
  type = 'inline',
}) => {
  const { claim } = useClaim();
  const [newDocumentOpen, setNewDocumentOpen] = useState(false);
  return (
    <div {...typeToStyleProps[type]}>
      <InputBase
        inputRef={inputRef}
        autoFocus
        fullWidth
        value={value}
        placeholder="Ask me anything"
        onChange={onUserInput}
        disabled={disabled}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            await onUserSubmit();
          }
        }}
      />
      <RestrictedPermissions action={PERMISSION_ACTIONS.CLAIM_DOCUMENT} verb={PERMISSION_VERBS.WRITE}>
        <PermissionsButtonWrapper>
          <IconButton onClick={() => setNewDocumentOpen(true)} disabled={disabled}>
            <AttachmentIcon className="rotate-[-45deg]" />
          </IconButton>
        </PermissionsButtonWrapper>
      </RestrictedPermissions>
      <IconButton className="bg-pink-600 p-12 hover:bg-pink-300" onClick={onUserSubmit} disabled={disabled}>
        <SendMessageIcon iconColor="white" size={20} />
      </IconButton>
      {newDocumentOpen && (
        <MultiUploadDocuments
          claim={claim}
          onSubmitDocument={async (documents: DocumentFile[]) => {
            try {
              await axios.post(`/api/v1/claims/${claim.id}/ai/auto_doc_upload`, {
                stored_file_ids: documents.map((doc) => doc.stored_file_id),
              });
              setNewDocumentOpen(false);
            } catch (error) {
              await reportAxiosError(error);
            }
          }}
          onCancel={() => setNewDocumentOpen(false)}
        />
      )}
    </div>
  );
};
