import React, { useEffect } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { localeDetails } from '~/components/CmsMain/localeGlobals';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { isHospitalityUser, isMgmUser, isRwUser, stringCmp } from '~/Utils';

import { isoDateToUs, serverDateToLocal } from '../DateTimeUtils';
import { getConfigLOBs, getLobIcon } from '../Utils/lobUtils';

import SkeletonTable from './core/Skeletons/SkeletonTable';
import { paginationPropsPropTypesShape } from './core/Tables/SortableTable/propTypes';
import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import ClaimLink from './ClaimLink';
import { ContactEntity } from './Contact';
import { SortableTable } from './core';
import useOrganization from './OrganizationContext';
import useDataFetcher from './useDataFetcher';

function adjustColumnByUser(user, columns) {
  if (isMgmUser(user) || isRwUser(user) || isHospitalityUser(user)) {
    columns = columns.filter((col) => !['peril_or_claim_type', 'cause_of_loss_or_claim_sub_type'].includes(col.id));
  }

  return columns;
}

function ExposureRecoveryTable(props) {
  const { exposures, recovery_types, maxHeight, paginationProps, onSortByColumn } = props;

  const { user } = useCms();
  const { lobConfigurationsDict } = useLobConfiguration();

  function currencyFormatter(currency, amount) {
    return Intl.NumberFormat(localeDetails.locale, {
      style: 'currency',
      currency: currency || localeDetails.currency,
    }).format(amount);
  }

  const commonColumns = [
    // eslint-disable-next-line react/display-name
    {
      id: 'type',
      width: '10px',
      specialCell: (exposure) => getLobIcon({ lob: exposure.claim_lob, lobConfigurationsDict }),
    },
    // stopPropagation from Link, otherwise, Link will be followed but Table onClick will also occur
    {
      id: 'id',
      numeric: false,
      width: '160px',
      disablePadding: false,
      label: 'Claim',
      // eslint-disable-next-line react/display-name
      specialCell: (exposure) => (
        <ClaimLink claimId={exposure.claim_id} linkText={exposure.claim_id_display} openInNewTab />
      ),
      specialCmpFunc: (row1, row2) => row1.claim_id - row2.claim_id,
    },
    { id: 'exposure_label', numeric: false, disablePadding: false, label: 'Exposure' },
    {
      id: 'peril_or_claim_type',
      numeric: false,
      disablePadding: false,
      label: (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>Type /</span>
          &nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>Peril</span>
        </>
      ),
    },
    {
      id: 'cause_of_loss_or_claim_sub_type',
      numeric: false,
      disablePadding: false,
      label: (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>Sub-type /</span>
          &nbsp;
          <span style={{ whiteSpace: 'nowrap' }}>Cause of Loss</span>
        </>
      ),
    },
    {
      id: 'date_of_loss',
      width: '130px',
      numeric: false,
      disablePadding: false,
      label: 'Date of Loss',
      specialCell: (row) => isoDateToUs(row.date_of_loss),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_of_loss, row2.date_of_loss),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'is_closed',
      width: '70px',
      numeric: false,
      disablePadding: false,
      label: 'Exposure Status',
      specialCell: (exposure) =>
        exposure.is_closed ? <font color="red">Closed</font> : <font color="green">Open</font>,
    },
  ];

  const subroSpecificColumns = [
    { id: 'subro_handling_adjuster', numeric: false, disablePadding: false, label: 'Handling Adjuster' },
    {
      id: 'date_recovery_started',
      numeric: false,
      disablePadding: false,
      label: 'Date Started',
      specialCell: (row) => serverDateToLocal(row.date_recovery_started),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_recovery_started, row2.date_recovery_started),
    },
    {
      id: 'anticipated_subro_amount',
      disablePadding: false,
      label: 'Anticipated Subro Amount',
      specialCell: (exposure) => currencyFormatter(exposure.policy_currency, exposure.anticipated_subro_amount),
      specialCmpFunc: (row1, row2) =>
        parseFloat(row1.anticipated_subro_amount) - parseFloat(row2.anticipated_subro_amount),
    },
    {
      id: 'subro_recoveries_sum',
      disablePadding: false,
      label: 'Recovered Subro Amount',
      specialCell: (exposure) => currencyFormatter(exposure.policy_currency, exposure.subro_recoveries_sum),
      specialCmpFunc: (row1, row2) => parseFloat(row1.subro_recoveries_sum) - parseFloat(row2.subro_recoveries_sum),
    },
    {
      id: 'adverse_carrier_id',
      numeric: false,
      disablePadding: false,
      label: 'Adverse Carrier',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.adverse_carrier_id ? (
          <ContactEntity contactId={row.adverse_carrier_id} contactDisplayName={row.adverse_carrier_full_name} />
        ) : null,
    },
  ];

  const salvageSpecificColumns = [
    {
      id: 'salvage_vendor_id',
      numeric: false,
      disablePadding: false,
      label: 'Vendor',
      // eslint-disable-next-line react/display-name
      specialCell: (row) =>
        row.salvage_vendor_id ? (
          <ContactEntity contactId={row.salvage_vendor_id} contactDisplayName={row.salvage_vendor_full_name} />
        ) : null,
    },
    {
      id: 'date_recovery_started',
      numeric: false,
      disablePadding: false,
      label: 'Date Started',
      specialCell: (row) => serverDateToLocal(row.date_recovery_started),
      specialCmpFunc: (row1, row2) => stringCmp(row1.date_recovery_started, row2.date_recovery_started),
    },
    {
      id: 'anticipated_salvage_amount',
      disablePadding: false,
      label: 'Anticipated Salvage Amount',
      specialCell: (exposure) => currencyFormatter(exposure.policy_currency, exposure.anticipated_salvage_amount),
      specialCmpFunc: (row1, row2) =>
        parseFloat(row1.anticipated_salvage_amount) - parseFloat(row2.anticipated_salvage_amount),
    },
    {
      id: 'salvage_recoveries_sum',
      disablePadding: false,
      label: 'Recovered Salvage Amount',
      specialCell: (exposure) => currencyFormatter(exposure.policy_currency, exposure.salvage_recoveries_sum),
      specialCmpFunc: (row1, row2) => parseFloat(row1.salvage_recoveries_sum) - parseFloat(row2.salvage_recoveries_sum),
    },
  ];

  let columnData = commonColumns;
  if (recovery_types.includes('subro')) {
    columnData = columnData.concat(subroSpecificColumns);
  }

  if (recovery_types.includes('salvage')) {
    columnData = columnData.concat(salvageSpecificColumns);
  }

  columnData = adjustColumnByUser(user, columnData);

  return (
    <SortableTable
      rows={exposures}
      columns={columnData}
      defaultOrderColumn={columnData.findIndex((column) => column.id === 'date_of_loss')}
      maxHeight={maxHeight}
      order="desc"
      stickyHeader
      paginationProps={paginationProps}
      onSortByColumn={onSortByColumn}
      autoPaginateRowsPerPage={25}
    />
  );
}

ExposureRecoveryTable.propTypes = {
  exposures: PropTypes.array.isRequired,
  recovery_types: PropTypes.array.isRequired,
  maxHeight: PropTypes.string,
  paginationProps: paginationPropsPropTypesShape,
  onSortByColumn: requiredIf(PropTypes.func, (props) => props.paginationProps),
};

function RecoveriesPage() {
  const {
    isLoading: isLoadingSubro,
    isError: isErrorSubro,
    data: openSubroExposures,
  } = useDataFetcher('/api/v1/claims/recoveries_exposures', { params: { recovery_types: ['subro'] } });
  const {
    isLoading: isLoadingSalvage,
    isError: isErrorSalvage,
    data: openSalvageExposures,
  } = useDataFetcher('/api/v1/claims/recoveries_exposures', { params: { recovery_types: ['salvage'] } });

  const classes = useStyles();
  const { setPageTitle } = useCms();
  const org = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const noSalvageGCs = [];
  const supportedClaimTypes = org.supportedClaimTypes;
  const supportedGCTypes = getConfigLOBs(lobConfigurationsDict, supportedClaimTypes);
  supportedGCTypes?.forEach((supportedGCType) => {
    const involvedParties = lobConfigurationsDict[supportedGCType]?.involved_parties;
    const noSalvage = involvedParties?.every(
      (involvedParty) => !involvedParty?.is_allowed_property_damage || !involvedParty?.active
    );
    if (noSalvage) {
      noSalvageGCs.push(supportedGCType);
    }
  });

  const noSalvageClaimTypes = ['wc_claim', 'gl_claim', ...noSalvageGCs];
  const includeSalvage = supportedClaimTypes.some((claim_type) => !noSalvageClaimTypes.includes(claim_type));

  useEffect(() => setPageTitle('Recoveries', 'Recoveries - Five Sigma CMS'), [setPageTitle]);

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Active Subro Exposures">
        {isLoadingSubro ? (
          <SkeletonTable rowsCount={8} columnsCount={10} maxHeight="55vh" isError={isErrorSubro} />
        ) : (
          <ExposureRecoveryTable exposures={openSubroExposures} recovery_types={['subro']} maxHeight="55vh" />
        )}
      </CardDialog>
      {includeSalvage && (
        <CardDialog title="Active Salvage Exposures">
          {isLoadingSalvage ? (
            <SkeletonTable rowsCount={8} columnsCount={10} maxHeight="45vh" isError={isErrorSalvage} />
          ) : (
            <ExposureRecoveryTable exposures={openSalvageExposures} recovery_types={['salvage']} maxHeight="45vh" />
          )}
        </CardDialog>
      )}
    </div>
  );
}

export default RecoveriesPage;
