import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { localeDetails } from '~/components/CmsMain/localeGlobals';

const CurrencyFormatterContext = React.createContext({});

function CurrencyFormatterContextProvider(props) {
  const { currency, children } = props;
  const currencyFormatter = new Intl.NumberFormat(localeDetails.locale, {
    style: 'currency',
    currency: currency || localeDetails.currency,
  });

  function getLocaleSeparator(separatorType) {
    const numberWithGroupAndDecimalSeparator = 1000.1;
    return currencyFormatter
      .formatToParts(numberWithGroupAndDecimalSeparator)
      .find((part) => part.type === separatorType)?.value;
  }

  return (
    <CurrencyFormatterContext.Provider
      value={{
        currencyFormatter,
        groupSeparator: getLocaleSeparator('group'),
        decimalSeparator: getLocaleSeparator('decimal'),
        symbol: getLocaleSeparator('currency'),
      }}
    >
      {children}
    </CurrencyFormatterContext.Provider>
  );
}

CurrencyFormatterContextProvider.propTypes = {
  currency: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const withCurrencyFormatter = (WrappedComponent) => {
  class withCurrencyFormatter extends React.Component {
    render() {
      return (
        <CurrencyFormatterContext.Consumer>
          {(CurrencyFormatterContext) => <WrappedComponent {...CurrencyFormatterContext} {...this.props} />}
        </CurrencyFormatterContext.Consumer>
      );
    }
  }

  withCurrencyFormatter.displayName = `withCurrencyFormatter(${getDisplayName(WrappedComponent)})`;
  return withCurrencyFormatter;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function useCurrencyFormatter() {
  return useContext(CurrencyFormatterContext);
}

export default useCurrencyFormatter;
export { CurrencyFormatterContextProvider, useCurrencyFormatter, withCurrencyFormatter };
