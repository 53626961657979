import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isFeatureEnabled, isPolicyManuallyFilled, isPolicyNotFound, reportErrorInProductionOrThrow } from '../Utils';

import AutoPolicyPage from './Auto/AutoPolicyDetails';
import { MgmPolicyDetails } from './ClientSpecific/Mgm/MgmPolicyDetails';
import HomePolicyPage from './Home/HomePolicyDetails';
import { useCms } from './hooks/useCms';
import { PetPolicyPage } from './Pet/PetPolicy';
import GenericPolicyPage from './Policy/GenericPolicy/GenericPolicyPage';
import PolicyNotFound from './Policy/GenericPolicy/PolicyNotFound/PolicyNotFound';
import PolicyNotFoundContainer from './Policy/PolicyNotFoundContainer';
import { TravelPolicyPage } from './Travel/TravelPolicy';
import CardDialog from './CardDialog';
import { withClaim } from './ClaimContainer';
import WithConfirm from './ConfirmModal';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import HoverActionField from './HoverActionField';
import { usePolicy } from './PolicyContainer';
import { MonetaryValueTextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function EditCoverageHover(props) {
  const { coverage, displayIcon, onUpdate, hideUpdateConfirm } = props;
  const [showEditCoverage, setShowEditCoverage] = useState(false);
  const classes = useStyles();
  const { user } = useCms();
  const coverage_limit_key = `${coverage.id}_limit`;

  return (
    <>
      {!user.role.is_view_only && (
        <HoverActionField onAction={() => setShowEditCoverage(true)} permanent={displayIcon} />
      )}
      <Formik
        initialValues={{ [coverage_limit_key]: coverage.limit }}
        validationSchema={Yup.object().shape({
          [coverage_limit_key]: Yup.number().required(),
        })}
        onSubmit={async (values, formikProps) => {
          try {
            await onUpdate(values);
            setShowEditCoverage(false);
            formikProps.resetForm();
          } catch (error) {
            formikProps.setSubmitting(false);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;
          return (
            <CardDialog
              isDialog={true}
              open={showEditCoverage}
              title={`Edit ${coverage.coverage_name} limit`}
              maxWidth="xs"
              onClose={() => setShowEditCoverage(false)}
              preventClose={isSubmitting}
            >
              <MonetaryValueTextFieldFormik
                id={coverage_limit_key}
                label={`${coverage.coverage_name} limit`}
                allowNegative={false}
                className={classes.textField}
                fullWidth
              />

              <div className={classes.buttonsContainer}>
                {hideUpdateConfirm ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Update
                  </Button>
                ) : (
                  <WithConfirm
                    title="Update Policy?"
                    contentText="This change will effect the policy and any claim related to it"
                    primaryButtonName="Yes"
                    shouldCloseOnPrimary={false}
                  >
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Update
                    </Button>
                  </WithConfirm>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

EditCoverageHover.propTypes = {
  displayIcon: PropTypes.bool.isRequired,
  coverage: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  hideUpdateConfirm: PropTypes.bool,
};

const PolicyScreen = ({ classes, claim, enableOpeningFnol }) => {
  const { policy } = usePolicy();
  const { userOrganization } = useCms();
  const isManualPolicy = isPolicyManuallyFilled(policy);

  if (!claim && policy.type !== 'travel_policy') {
    throw new Error('Policy screen in a non claim context is enabled only for travel');
  }

  const displayNewPolicyPage =
    policy.type === 'general_policy' ||
    (claim && isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL));

  if (isPolicyNotFound(claim)) {
    if (claim.is_closed) {
      return (
        <div className={classes.cardDivRow}>
          <CardDialog noCardTitle>
            <Typography display="block" variant="h4" color="secondary">
              Policy Not found
            </Typography>
          </CardDialog>
        </div>
      );
    } else {
      return displayNewPolicyPage ? <PolicyNotFound /> : <PolicyNotFoundContainer />;
    }
  }

  const getPolicyComponent = () => {
    if (
      policy.type === 'general_policy' ||
      (claim && isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL))
    ) {
      return <GenericPolicyPage />;
    } else if (policy.type === 'auto_policy') {
      return <AutoPolicyPage />;
    } else if (policy.type === 'home_policy') {
      return <HomePolicyPage EditCoverageHover={EditCoverageHover} />;
    } else if (['wc_policy', 'gl_policy'].includes(policy.type)) {
      return <MgmPolicyDetails claim={claim} />;
    } else if ('pet_policy' === policy.type) {
      return <PetPolicyPage />;
    } else if (policy.type === 'travel_policy') {
      return <TravelPolicyPage policy={policy} withReportNewLoss enableOpeningFnol={enableOpeningFnol} />;
    } else {
      reportErrorInProductionOrThrow(`Policy screen is not supported for claim type ${claim.type}`);
      return null;
    }
  };

  return (
    <RestrictedPermissions
      action={isManualPolicy ? PERMISSION_ACTIONS.MANUAL_POLICY : PERMISSION_ACTIONS.API_POLICY}
      verb={PERMISSION_VERBS.WRITE}
    >
      {getPolicyComponent()}
    </RestrictedPermissions>
  );
};

PolicyScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object,
  enableOpeningFnol: PropTypes.bool,
};

export default withClaim(PolicyScreen);
