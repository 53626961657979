import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import { Formik } from 'formik';
import { BadgeAccountHorizontalOutline, TextBoxCheckOutline } from 'mdi-material-ui';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import { localeDetails } from '~/components/CmsMain/localeGlobals';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import { MonetaryValueTextField } from '~/components/core/Molecules/Fields/MonetaryValueTextField';
import TextField from '~/components/core/Molecules/Fields/TextField';
import SortableTable from '~/components/core/Tables/SortableTable';
import Text from '~/components/core/TextComponents/Text';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import DocumentTextFieldFormik from '~/components/Documents/DocumentTextFieldFormik';
import TrashIcon from '~/components/icons/TrashIcon';
import { reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import CardDialog from '../CardDialog';
import { ClaimContextProvider, useClaim } from '../ClaimContainer';
import Heading from '../core/TextComponents/Heading';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import PencilIcon from '../icons/PencilIcon';
import { DatePickerTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { isExposureWriteDisabled } from './ExposureUtils';

const generateId = uuidv4;

function DamageAssessmentContainerFastcoverDemo(props) {
  const { exposure, viewOnly, iconOnly, onUpdate } = props;

  const { claim: claimInContext, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { damage_assessment } = exposure;
  const newDamageAssessmentInitialState =
    !damage_assessment || damage_assessment.amount === null || damage_assessment.amount === undefined;
  const [newDamageAssessment, setNewDamageAssessment] = useState(newDamageAssessmentInitialState);

  const viewOnlyDamageAssessment = viewOnly || isExposureWriteDisabled(exposure, user);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewDamageAssessment(newDamageAssessmentInitialState);
  };

  function onUpdateIfExists() {
    if (onUpdate) {
      return onUpdate();
    } else {
      return Promise.resolve();
    }
  }

  const onSubmitDamageAssessment = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claimInContext.id}/exposures/${exposure.id}/damage_assessments`, values);

      if (claimInContext) {
        await onAsyncClaimUpdate();
      } else {
        await Promise.resolve();
      }
      onUpdateIfExists();
      setNewDamageAssessment(values.amount === null || values.amount === undefined);
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    } finally {
      setIsDialogOpen(false);
    }
  };

  if (!damage_assessment && viewOnlyDamageAssessment) {
    return <></>;
  }

  function getIconComponentForDa(damage_assessment) {
    if (!damage_assessment.is_estimation_report_exists) {
      return <BadgeAccountHorizontalOutline />;
    } else {
      return <TextBoxCheckOutline />;
    }
  }

  return (
    <>
      <HoverActionField
        icon={viewOnlyDamageAssessment ? InfoIcon : PencilIcon}
        onAction={() => setIsDialogOpen(true)}
        permanent={!damage_assessment}
        actionAriaLabel="Set damage assessment"
      >
        {damage_assessment ? (
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            {getIconComponentForDa(damage_assessment)}
            {!iconOnly && damage_assessment.amount !== null && (
              <>&nbsp;{currencyFormatter.format(damage_assessment.amount)}</>
            )}
          </span>
        ) : (
          <em>Set</em>
        )}
      </HoverActionField>
      {isDialogOpen && (
        <DamageAssessmentCard
          claim={claimInContext}
          reloadClaim={onAsyncClaimUpdate}
          exposure={exposure}
          isNewDamageAssessment={newDamageAssessment}
          damageAssessment={damage_assessment}
          cardDialogProps={{
            isDialog: true,
            open: isDialogOpen,
            closeOnBackdropClick: damage_assessment && !newDamageAssessment,
          }}
          viewOnly={viewOnlyDamageAssessment}
          onSubmitDamageAssessment={onSubmitDamageAssessment}
          onSelectNewDamageAssessment={() => setNewDamageAssessment(true)}
          onCancel={handleCloseDialog}
        />
      )}
    </>
  );
}

DamageAssessmentContainerFastcoverDemo.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposure: PropTypes.object.isRequired,
  viewOnly: PropTypes.bool,
  iconOnly: PropTypes.bool,
  onUpdate: PropTypes.func,
};

const damageAssessmentFields = {
  estimation_reports_ids: [],
  amount: '',
  inspection_date: '',
  note: '',
};

function DamageAssessmentCard(props) {
  const {
    claim,
    reloadClaim,
    exposure,
    isNewDamageAssessment,
    damageAssessment,
    viewOnly,
    onSubmitDamageAssessment,
    onSelectNewDamageAssessment,
    cardDialogProps,
    onCancel,
  } = props;
  const classes = useStyles();
  const [currentLineItems, setCurrentLineItems] = useState(
    damageAssessment?.damage_assessment_extra?.line_items || [
      {
        id: generateId(),
        order: 1,
        invoice_date: null,
        item_claimed: null,
        amount_submitted: null,
        amount_considered: null,
      },
    ]
  );

  let initialValues = damageAssessment ? { ...damageAssessment } : { ...damageAssessmentFields };
  initialValues['estimation_document_id'] = damageAssessment?.estimation_reports_ids?.[0];
  const updateRow = (row, field, value) => {
    setCurrentLineItems((prevState) =>
      prevState.map((currentRow) => {
        if (currentRow.id !== row.id) {
          return currentRow;
        }
        return { ...row, [field]: value };
      })
    );
  };

  const handleDeleteRow = (rowId) => {
    setCurrentLineItems((prevLineItems) => prevLineItems.filter((item) => item.id !== rowId));
  };

  const lineItemsColumns = [
    { id: 'order', label: 'Order', isHidden: true },
    {
      id: 'item_claimed',
      disableSort: true,
      label: 'Item Claimed',
      specialCell: (row) => (
        <TextField
          id="item_claimed"
          inlineEditableField
          onChange={(value) => {
            console.log('onChange item_claimed', value);
            updateRow(row, 'item_claimed', value);
          }}
          value={row.item_claimed}
          className={classes.textFieldRow}
        />
      ),
    },
    {
      id: 'amount_submitted',
      disableSort: true,
      label: 'Amount Submitted',
      specialCell: (row) => (
        <MonetaryValueTextField
          id="amount_submitted"
          inlineEditableField
          onChange={(value) => {
            updateRow(row, 'amount_submitted', value);
          }}
          value={row.amount_submitted}
          className="w-[100px]"
          fullWidth
        />
      ),
    },
    {
      id: 'invoice_date',
      disableSort: true,
      label: 'Invoice Date',
      specialCell: (row) => (
        <DatePickerTextFieldFormik
          id="invoice_date"
          label="Invoice Date"
          className="min-w-[130px]"
          disableFuture
          value={row.invoice_date ? new Date(row.invoice_date) : null}
          onChange={(value) => {
            updateRow(
              row,
              'invoice_date',
              new Date(value).toLocaleDateString(localeDetails.locale?.baseName, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })
            );
          }}
          fullWidth
        />
      ),
    },
    {
      id: 'amount_considered',
      disableSort: true,
      label: 'Amount Considered',
      specialCell: (row) => (
        <MonetaryValueTextField
          id="amount_considered"
          inlineEditableField
          onChange={(value) => {
            updateRow(row, 'amount_considered', value);
          }}
          value={row.amount_considered}
          className="w-[100px]"
          fullWidth
        />
      ),
    },
    {
      id: 'delete',
      disableSort: true,
      label: 'Delete',
      specialCell: (row) => (
        <IconButton
          onClick={() => {
            handleDeleteRow(row.id);
          }}
          disabled={currentLineItems.length === 1}
        >
          <TrashIcon iconColor="currentColor" />
        </IconButton>
      ),
    },
  ];

  const totalAmountConsidered = currentLineItems.reduce((total, item) => {
    return total + (item.amount_considered || 0);
  }, 0);
  const deductibleAmount = exposure?.deductible_amount || 0;
  const settlementAmount = Math.max(totalAmountConsidered - deductibleAmount, 0);

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          estimation_document_id: Yup.string().required(),
        })}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          try {
            const body = {
              damage_assessment_extra: {
                line_items: currentLineItems,
                total_amount_considered: totalAmountConsidered,
                deductible_amount: deductibleAmount,
                settlement_amount: settlementAmount,
              },
              estimation_reports_ids: [values['estimation_document_id']],
              amount: totalAmountConsidered,
              note: values.note,
            };
            await onSubmitDamageAssessment(body);
            formikProps.resetForm();
          } catch (error) {
            reportAxiosError(error);
          } finally {
            formikProps.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, values } = formikProps;
          return (
            <CardDialog
              title="Damage Assessment"
              maxWidth="xl"
              fullWidth
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
              preventClose={isSubmitting}
              {...cardDialogProps}
            >
              <div>
                <div className="grid grid-cols-2 gap-16">
                  <div className="mb-32 rounded-lg border-1 border-solid border-slate-400 p-32 pt-20">
                    <Heading variant={Heading.TYPES.H1} className="mb-20">
                      Line Items
                    </Heading>
                    <div className="mb-8 inline-flex w-full justify-end">
                      <Button
                        className=""
                        color="primary"
                        onClick={() =>
                          setCurrentLineItems((previousRows) =>
                            previousRows.concat({
                              id: generateId(),
                              order: Math.max(...previousRows.map(({ order }) => order)),
                              invoice_date: undefined,
                              item_claimed: undefined,
                              amount_submitted: 0,
                              amount_considered: 0,
                            })
                          )
                        }
                      >
                        <AddIcon />
                        Add Item
                      </Button>
                    </div>
                    <SortableTable
                      columns={lineItemsColumns}
                      rows={currentLineItems}
                      stickyHeader
                      defaultOrderColumn={0}
                    />
                    <div className="flex flex-col items-end justify-end">
                      <div className="flex items-center">
                        <Text variant={Text.VARIANTS.SM}>Subtotal:</Text>
                        <MonetaryValueTextField
                          showOnly
                          id="subtotal"
                          inlineEditableField
                          value={totalAmountConsidered}
                          className="w-[100px]"
                          fullWidth
                        />
                      </div>
                      <div className="flex items-center">
                        <Text variant={Text.VARIANTS.SM}>Excess:</Text>
                        <MonetaryValueTextField
                          showOnly
                          id="excess"
                          inlineEditableField
                          value={deductibleAmount}
                          className="w-[100px]"
                          fullWidth
                        />
                      </div>
                      <div className="flex items-center">
                        <Text variant={Text.VARIANTS.SM}>Settlement:</Text>
                        <MonetaryValueTextField
                          showOnly
                          id="settlment"
                          inlineEditableField
                          value={settlementAmount}
                          className="w-[100px]"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <DocumentTextFieldFormik id="estimation_document_id" label="Estimation Document" />
                    {claim && values?.estimation_document_id ? (
                      <object
                        className="w-full grow"
                        // style={{ height: '100%', width: '100%', zIndex: '1' }}
                        alt=""
                        data={`/api/v1/claims/${claim.id}/documents/${values?.estimation_document_id}`}
                        type="application/pdf"
                      />
                    ) : null}
                  </div>
                </div>

                <TextFieldFormik
                  id="note"
                  label="Note"
                  className={cn(classes.textField, 'mt-8')}
                  disabled={!isNewDamageAssessment}
                  fullWidth
                  multiline
                />
              </div>
              <div className={classes.buttonsContainer}>
                {!isNewDamageAssessment ? (
                  <>
                    {!viewOnly && (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={onSelectNewDamageAssessment}
                      >
                        Update Damage Assessment
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {!viewOnly && (
                      <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                        Set Damage Assessment
                      </Button>
                    )}
                  </>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </ClaimContextProvider>
  );
}

DamageAssessmentCard.propTypes = {
  claim: PropTypes.object.isRequired,
  reloadClaim: PropTypes.func.isRequired,
  exposure: PropTypes.object.isRequired,
  damageAssessment: PropTypes.object,
  isNewDamageAssessment: PropTypes.bool.isRequired,
  cardDialogProps: PropTypes.object.isRequired,
  onSubmitDamageAssessment: PropTypes.func.isRequired,
  onSelectNewDamageAssessment: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReinspect: PropTypes.func,
  onViewReinspectionReport: PropTypes.func,
  viewOnly: PropTypes.bool,
  onSendEmailClick: PropTypes.func,
};

export default DamageAssessmentContainerFastcoverDemo;
