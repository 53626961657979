import React from 'react';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';

export const FnolAiInboxContainer: React.FC = () => {
  return (
    <CollapsibleWrapper
      title="FNOL Inbox"
      actionCard
      noBorder
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
    >
      <div>FNOL Inbox Placeholder</div>
    </CollapsibleWrapper>
  );
};
