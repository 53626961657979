import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Divider, Drawer, Link as ExternalLink } from '@material-ui/core';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Tooltip from '~/components/core/Atomic/Tooltip';
import SidebarActionCreateButton from '~/components/core/Buttons/SidebarActionCreateButton';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import SupportMenu from '~/components/SupportMenu/SupportMenu';
import cn from '~/Utils/cn';

import { MIXPANEL_EVENTS } from '../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isSysConfigEditor, isUserFiveSigma, isUserOshaRep, isUserSuperUser } from '../UserUtils';
import {
  isDashboardViewer,
  isExtraDashboardViewer,
  isFeatureEnabled,
  isHospitalityUser,
  isMgmUser,
  isRwUser,
} from '../Utils';

import mixpanel from './CmsMain/mixpanel';
import { isPermissionsEnabled } from './core/Permissions/PermissionUtils';
import { useCms } from './hooks/useCms';
import { useIsReportViewer } from './hooks/useIsReportViewer';
import UserMenu from './UserMenu/index';
import { OrganizationLogo, PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper } from './core';
import {
  AddIcon,
  AdvancedSearchIcon,
  ApprovalPaymentIcon,
  BuildingsIcon,
  CalendarIcon,
  ChecksIcon,
  ClaimsIcon,
  CommunicationsIcon,
  ContactNewIcon,
  DaReinspectionIcon,
  DashboardReportsIcon,
  FieldInspectionIcon,
  HomeIcon,
  LossReportIcon,
  MailIcon,
  MedicalBillsIcon,
  OshaReportsIcon,
  PoliciesIcon,
  QaReviewIcon,
  QuestionCircleIcon,
  RecoveriesIcon,
  SystemConfigurationIcon,
} from './icons';
import useOrganization from './OrganizationContext';

import { useStyles } from '../assets/styles';
import colors from '../assets/colors.module.scss';

// TODO - Phone Rep view
function CmsDrawer({ onUpdatePassword }) {
  const { user, userOrganization } = useCms();
  const { orgAdditionalConfigs, isPerformanceQaEnabled, isGeneralEmailQueueEnabled } = useOrganization();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isApprovalPaymentsScreenEnabled, isPaymentsSubmissionScreenEnabled } = usePaymentsConfiguration();
  const glChecksPageUsers = orgAdditionalConfigs?.gl_checks_page_users || [];

  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [supportAnchorEl, setSupportAnchorEl] = useState(null);

  const isUserReportsViewer = useIsReportViewer();

  const getDrawerOptions = () => {
    if (isUserFiveSigma(user)) {
      return [
        {
          tooltip: 'Home',
          Icon: HomeIcon,
          isActive: location.pathname === '/home',
          linkTo: '/home',
          shouldAppear: true,
        },
        {
          tooltip: 'System Configuration',
          Icon: SystemConfigurationIcon,
          isActive: location.pathname.startsWith('/admin'),
          linkTo: '/admin',
          shouldAppear: isUserSuperUser(user),
        },
      ];
    }

    if (isUserOshaRep(user)) {
      return [];
    }

    const displayQaDashboard =
      isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.QA_DASHBOARD) || isPerformanceQaEnabled;
    return [
      { tooltip: 'Home', Icon: HomeIcon, isActive: location.pathname === '/home', linkTo: '/home', shouldAppear: true },
      {
        tooltip: 'Claims',
        Icon: ClaimsIcon,
        isActive: location.pathname.startsWith('/claims'),
        linkTo: '/claims',
        shouldAppear: true,
        permissions: { action: PERMISSION_ACTIONS.CLAIM, verb: PERMISSION_VERBS.READ },
      },
      {
        tooltip: 'Calendar',
        Icon: CalendarIcon,
        isActive: location.pathname === '/calendar',
        linkTo: '/calendar',
        shouldAppear: true,
      },
      {
        tooltip: 'Contacts',
        Icon: ContactNewIcon,
        isActive: location.pathname === '/contacts',
        linkTo: '/contacts',
        shouldAppear: true,
        permissions: { action: PERMISSION_ACTIONS.CONTACT, verb: PERMISSION_VERBS.READ },
      },
      {
        tooltip: 'Recoveries',
        Icon: RecoveriesIcon,
        isActive: location.pathname === '/recoveries',
        linkTo: '/recoveries',
        shouldAppear: true,
      },
      {
        tooltip: 'Performance QA Center',
        Icon: QaReviewIcon,
        isActive: location.pathname === '/qa-dashboard',
        linkTo: '/qa-dashboard',
        shouldAppear:
          isPermissionsEnabled(userOrganization) ||
          ((user.is_org_level_supervisor || user.is_manager) && displayQaDashboard),
        permissions: { action: PERMISSION_ACTIONS.QA, verb: PERMISSION_VERBS.READ },
      },
      {
        tooltip: 'DA Reinspection',
        Icon: DaReinspectionIcon,
        isActive: location.pathname === '/da-reinspection',
        linkTo: '/da-reinspection',
        shouldAppear:
          user.is_org_level_supervisor &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DA_REINSPECTION),
      },
      {
        tooltip: 'Classification',
        Icon: MailIcon,
        isActive: location.pathname === '/classification',
        linkTo: '/classification',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CLASSIFICATION),
      },
      {
        tooltip: 'Loss Report',
        Icon: LossReportIcon,
        isActive: location.pathname === '/loss_report',
        linkTo: '/loss_report',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.LOSS_REPORT_TEMP),
      },
      {
        tooltip: 'Approvals',
        Icon: ApprovalPaymentIcon,
        isActive: location.pathname.startsWith('/finance_approvals'),
        linkTo: '/finance_approvals',
        shouldAppear: isApprovalPaymentsScreenEnabled || isPaymentsSubmissionScreenEnabled,
      },
      {
        tooltip: 'Communication',
        Icon: CommunicationsIcon,
        isActive: location.pathname === '/communications',
        linkTo: '/communications',
        shouldAppear: true,
      },
      {
        tooltip: 'Medical Bills',
        Icon: MedicalBillsIcon,
        isActive: location.pathname === '/medical_bills',
        linkTo: '/medical_bills',
        shouldAppear: isMgmUser(user),
      },
      {
        tooltip: 'Checks',
        Icon: ChecksIcon,
        isActive: location.pathname === '/organization_checks',
        linkTo: '/organization_checks',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PAYMENT_CHECKS),
      },
      {
        tooltip: 'GL Checks',
        Icon: ChecksIcon,
        isActive: location.pathname === '/organization_gl_checks',
        linkTo: '/organization_gl_checks',
        shouldAppear:
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PAYMENT_CHECKS) &&
          glChecksPageUsers.includes(user.id),
      },
      {
        tooltip: 'Osha Reports',
        Icon: OshaReportsIcon,
        isActive: location.pathname === '/osha_reports',
        linkTo: '/osha_reports',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.OSHA_REPORTS),
      },
      {
        tooltip: 'System Configuration',
        Icon: SystemConfigurationIcon,
        isActive: location.pathname.startsWith('/sysconfig'),
        linkTo: '/sysconfig',
        shouldAppear:
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PERMISSIONS_ENFORCEMENT) ||
          isSysConfigEditor(user),
        permissions: { action: PERMISSION_ACTIONS.SYSTEM_CONFIGURATION, verb: PERMISSION_VERBS.FULL },
      },
      {
        tooltip: 'Field inspection queues',
        Icon: FieldInspectionIcon,
        isActive: location.pathname === '/field_inspection_queue',
        linkTo: '/field_inspection_queue',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FIELD_INSPECTION_QUEUE),
      },
      {
        tooltip: 'Advanced Search',
        Icon: AdvancedSearchIcon,
        isActive: location.pathname === '/advanced_search',
        linkTo: '/advanced_search',
        shouldAppear:
          (isMgmUser(user) || isRwUser(user) || isHospitalityUser(user)) &&
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.T__MGM__ADVANCED_SEARCH),
      },
      {
        tooltip: 'Policies',
        Icon: PoliciesIcon,
        isActive: location.pathname === '/policies',
        linkTo: '/policies',
        shouldAppear: isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.POLICIES_SEARCH_PAGE),
      },
      {
        tooltip: 'General Email Classification',
        Icon: MailIcon,
        isActive: location.pathname === '/general_email_classification',
        linkTo: '/general_email_classification',
        shouldAppear: isGeneralEmailQueueEnabled,
        permissions: {
          action: PERMISSION_ACTIONS.GENERAL_EMAIL_CLASSIFICATION,
          verb: PERMISSION_VERBS.READ,
        },
      },
      {
        tooltip: 'Reports',
        Icon: DashboardReportsIcon,
        isActive: location.pathname.startsWith('/reports'),
        linkTo: '/reports',
        shouldAppear:
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_REPORTS) &&
          (isUserReportsViewer ||
            isDashboardViewer(orgAdditionalConfigs, user) ||
            isExtraDashboardViewer(orgAdditionalConfigs, user)),
        permissions: { action: PERMISSION_ACTIONS.REPORT, verb: PERMISSION_VERBS.READ },
      },
      {
        tooltip: 'Reports',
        Icon: DashboardReportsIcon,
        isActive: location.pathname.startsWith('/reports_v2'),
        linkTo: '/reports_v2',
        shouldAppear:
          isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.FF_TABLEAU_SELF_SERVICE) &&
          isUserReportsViewer,
        permissions: { action: PERMISSION_ACTIONS.REPORT, verb: PERMISSION_VERBS.READ },
      },
    ].map((item) => {
      if (!item.permissions?.verb) {
        return {
          ...item,
          permissions: { action: item.permissions?.action, verb: PERMISSION_VERBS.READ },
        };
      }
      return item;
    });
  };

  const drawerOptions = getDrawerOptions();

  const isUserOutOfOffice = user.is_out_of_office;
  return (
    <>
      <Drawer
        variant="permanent"
        className={isUserOutOfOffice ? classes.OutOfOfficeDrawer : classes.drawer}
        classes={{ paper: isUserOutOfOffice ? classes.OutOfOfficeDrawer : classes.drawer }}
      >
        <div className={classes.drawerIconsContainer}>
          <Avatar
            onClick={(e) => setUserAnchorEl(e.currentTarget)}
            className={classes.drawerAvatar}
            src={`/api/v1/organizations/${user.organization_id}/users/${user.id}/avatar`}
            style={{
              backgroundColor: 'rgb(49 199 202 / 80%)',
              border: isUserOutOfOffice ? '2.5px solid rgba(0,0,0,0.7)' : undefined,
            }}
          >
            {' '}
            {/*TODO: Understand how to use it with classes */}
            {user.first_name[0]}
            {user.last_name[0]}
          </Avatar>
          <DrawerDivider />
          {isUserSuperUser(user) && (
            <>
              <SidebarActionCreateButton
                icon={BuildingsIcon}
                title="Add Org"
                onClick={() => history.push('/admin/organizations/new_organization_wizard')}
              />
              <DrawerDivider />
            </>
          )}
          {userOrganization.fnol_enabled &&
            isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) && (
              <PermissionsRenderWrapper action={PERMISSION_ACTIONS.CREATE_FNOL} verb={PERMISSION_VERBS.WRITE}>
                <SidebarActionCreateButton
                  icon={AddIcon}
                  title="FNOL"
                  onClick={() => history.push('/fnol')}
                  disabled={user.role.is_view_only}
                />
                <DrawerDivider />
              </PermissionsRenderWrapper>
            )}
          {drawerOptions
            .filter(({ shouldAppear }) => shouldAppear)
            .map(({ tooltip, Icon, isActive, linkTo, permissions, href }, idx) => (
              <CmsDrawerIconButtonWithPermissions
                tooltip={tooltip}
                Icon={Icon}
                isActive={isActive}
                linkTo={linkTo}
                permissions={permissions}
                href={href}
                key={idx}
              />
            ))}
        </div>
        <div className={classes.drawerButtonIcons}>
          <DrawerDivider />
          <CmsDrawerIconButton
            tooltip="Support"
            Icon={QuestionCircleIcon}
            onClick={(e) => {
              setSupportAnchorEl(e.currentTarget);
            }}
          />

          <OrganizationLogo />
        </div>
      </Drawer>

      {userAnchorEl ? (
        <UserMenu
          anchorEl={userAnchorEl}
          onClose={() => setUserAnchorEl(undefined)}
          onUpdatePassword={onUpdatePassword}
        />
      ) : null}
      {supportAnchorEl ? (
        <SupportMenu
          anchorEl={supportAnchorEl}
          onClose={() => {
            setSupportAnchorEl(undefined);
          }}
        />
      ) : null}
    </>
  );
}

const CmsDrawerIconButtonWithPermissions = ({ permissions, ...rest }) => {
  return (
    <PermissionsRenderWrapper {...permissions} useDefaults>
      <CmsDrawerIconButton {...rest} />
    </PermissionsRenderWrapper>
  );
};

CmsDrawerIconButtonWithPermissions.propTypes = {
  permissions: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
  Icon: PropTypes.func,
  isActive: PropTypes.bool,
  linkTo: PropTypes.string,
  href: PropTypes.string,
};

const CmsDrawerIconButton = ({ tooltip, Icon, isActive, linkTo, href, onClick }) => {
  const classes = useStyles();

  let CustomComponent;
  if (href) {
    CustomComponent = ExternalLink;
  } else if (linkTo) {
    CustomComponent = Link;
  }

  return (
    <Tooltip title={tooltip} placement="right" arrow>
      <IconButton
        component={CustomComponent}
        target={href ? '_blank' : ''}
        to={linkTo}
        href={href}
        className={cn(classes.drawerIconButton, { [classes.drawerIconButtonSelected]: isActive })}
        onClick={(e) => {
          mixpanel?.track(MIXPANEL_EVENTS.SIDE_PANEL_CLICK, { element: tooltip });
          if (onClick) {
            onClick(e);
          }
        }}
      >
        <Icon size={24} iconColor={isActive ? 'white' : colors.drawerIcon} />
      </IconButton>
    </Tooltip>
  );
};

CmsDrawerIconButton.propTypes = {
  tooltip: PropTypes.string,
  Icon: PropTypes.func,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  linkTo: PropTypes.string,
  href: PropTypes.string,
};

const DrawerDivider = () => (
  <Divider
    style={{
      marginTop: 10,
      marginBottom: 10,
      width: '100%',
      border: '1px solid rgba(255,255,255,0.08)',
    }}
  />
);

CmsDrawer.propTypes = {
  onUpdatePassword: PropTypes.func,
};

export default CmsDrawer;
