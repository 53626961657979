import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn, useFormikContext } from 'formik';
import iban from 'iban';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { COLUMN_KEYS, EXTENDABLE_COLUMN_KEYS } from '~/components/Finances/FinancesTable/constants';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import AdjusterSelectTextFieldFormik from '../../../Adjuster/AdjusterSelectTextFieldFormik';
import { CONSTANTS, FINANCIAL_STATUS_DICT } from '../../../Types';
import { formatSortOrder } from '../../../Utils';
import cn from '../../../Utils/cn';
import CardDialog from '../../CardDialog';
import CheckboxFormik from '../../CheckboxFormik';
import { Heading, Text } from '../../core';
import { useCms } from '../../hooks/useCms';
import LoadingIndicator from '../../LoadingIndicator';
import useOrganization from '../../OrganizationContext';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
} from '../../TextFieldFormik';
import { CoveragesSingleSelectFormik } from '../../TPA/Coverages/CoveragesSingleSelectFormik';
import { LobSelectFormik } from '../../TPA/LOB/LobSelectFormik';
import SubOrganizationSelectFormik from '../../TPA/SubOrganizations/SubOrganizationSelectFormik';
import useDataFetcher from '../../useDataFetcher';
import FinancesTable from '../FinancesTable';

import { useStyles } from '../../../assets/styles';
import colors from '../../../assets/colors.module.scss';

const DEFAULT_SORT_BY_COLUMN = { id: 'request_date', order: 'desc' };
const DEFAULT_FINANCES_PER_PAGE = 15;
const RESULTS_PER_PAGE_MIN = 10;
const RESULTS_PER_PAGE_MAX = 30;

const PAYEE_PERSON_INITIAL_VALUES = {
  payee_first_name: '',
  payee_last_name: '',
  payee_ssn: '',
};

const PAYEE_COMPANY_INITIAL_VALUES = {
  payee_company_name: '',
  payee_tin: '',
};

const FILTER_PARAMS_INITIAL_VALUES = {
  page: 0,
  results_per_page: DEFAULT_FINANCES_PER_PAGE,
  sort_by_column: DEFAULT_SORT_BY_COLUMN,
  claim_number: '',
  sub_organization_id: '',
  lob: '',
  coverage_type: '',
  requester_user_id: '',
  decision_amount_from: '',
  decision_amount_to: '',
  payable_type: '',
  approved_date_from: '',
  approved_date_to: '',
  is_only_deductible_applied: false,
  payment_method: '',
  check_number: '',
  check_date_from: '',
  check_date_to: '',
  reference: '',
  iban: '',
  uk_sort_code: '',
  uk_bank_account_number: '',
  is_company: '',
  financial_status_filters: [],
  invoice_number: '',
  ...PAYEE_PERSON_INITIAL_VALUES,
  ...PAYEE_COMPANY_INITIAL_VALUES,
};

const DISPLAY_COLUMN_IDS = [
  COLUMN_KEYS.claim_id_display,
  COLUMN_KEYS.request_date,
  COLUMN_KEYS.financial_status_last_update,
  COLUMN_KEYS.exposure_label,
  COLUMN_KEYS.payable_with_reserve_type,
  COLUMN_KEYS.request_status,
  COLUMN_KEYS.financial_status,
  COLUMN_KEYS.amount,
  COLUMN_KEYS.actions,

  EXTENDABLE_COLUMN_KEYS.issued_by,
  EXTENDABLE_COLUMN_KEYS.payees,
  EXTENDABLE_COLUMN_KEYS.sent_to,
  EXTENDABLE_COLUMN_KEYS.reference,
  EXTENDABLE_COLUMN_KEYS.note,
  EXTENDABLE_COLUMN_KEYS.deductible_applied,
  EXTENDABLE_COLUMN_KEYS.payment_method,
];

const PAYABLE_TYPES = {
  indemnity: 'Indemnity',
  expenses: 'Expenses',
};

const PaymentSearchPage = () => {
  const { user } = useCms();
  const [showResults, setShowResults] = useState(false);
  const [filterParams, setFilterParams] = useState(FILTER_PARAMS_INITIAL_VALUES);
  const { page, sort_by_column } = filterParams;
  const config = { params: { ...filterParams, page: page + 1, sort_by_column: formatSortOrder(sort_by_column) } }; // page in the flask-SQLAlchemy is 1-based
  const { isLoading, data: { count, finances } = {} } = useDataFetcher(
    showResults ? `/api/v1/organizations/${user.organization_id}/finances` : undefined,
    config
  );
  const [isSearchOpen, setIsSearchOpen] = useState(true);

  const onSubmit = (values) => {
    const { coverage_type, approved_date_from, approved_date_to, payable_type, ...restValues } = values;
    let valuesToSend = { ...restValues };
    if (coverage_type) {
      valuesToSend.coverage_filters = [coverage_type];
    }

    if (approved_date_from) {
      valuesToSend.start_date = approved_date_from;
      valuesToSend.date_field = 'decision_date';
    }

    if (approved_date_to) {
      valuesToSend.end_date = approved_date_to;
      valuesToSend.date_field = 'decision_date';
    }

    if (payable_type) {
      valuesToSend.payable_types_filter = [payable_type];
    }

    setIsSearchOpen(false);
    setShowResults(true);
    setFilterParams(valuesToSend);
  };

  const onReset = (values) => {
    setShowResults(false);
    setFilterParams(values);
  };

  const onChangePage = (_, newPage) => {
    setFilterParams({
      ...filterParams,
      page: newPage,
    });
  };

  const onChangeRowsPerPage = (event) => {
    setFilterParams({
      ...filterParams,
      results_per_page: parseInt(event.target.value),
      page: 0,
    });
  };

  const onSortByColumn = (_, sort_by_column) => {
    setFilterParams({
      ...filterParams,
      sort_by_column,
      page: 0, // reset the page when sorting
    });
  };

  return (
    <>
      <div className="mb-32">
        <CollapsibleWrapper
          title="Search Payments"
          actionCard
          noBorder
          open={isEmpty(finances) || isSearchOpen} //we add the isEmpty(finances) to insure that it collapses after the results are shown
          onCollapse={() => setIsSearchOpen((prevOpen) => !prevOpen)}
          unmountOnExit={false}
          isActionCardDrawer={false}
        >
          <PaymentSearchDialog onSubmit={onSubmit} onReset={onReset} />
        </CollapsibleWrapper>
      </div>
      {isLoading && showResults && <LoadingIndicator />}
      {!isLoading && showResults && (
        <>
          {!isEmpty(finances) ? (
            <>
              <Text className="pt-16" weight={Text.WEIGHTS.MEDIUM} variant={Text.VARIANTS.LG}>
                Search Results
              </Text>
              <FinancesTable
                finances={finances}
                paginationProps={{
                  page,
                  rowsPerPage: filterParams?.results_per_page,
                  onChangePage,
                  count,
                  onChangeRowsPerPage,
                  rowsPerPageOptions: [
                    RESULTS_PER_PAGE_MIN,
                    DEFAULT_FINANCES_PER_PAGE,
                    CONSTANTS.DEFAULT_CLAIMS_PER_PAGE,
                    RESULTS_PER_PAGE_MAX,
                  ],
                }}
                onSortByColumn={onSortByColumn}
                sortByColumn={filterParams.sort_by_column}
                displayColumnIds={DISPLAY_COLUMN_IDS}
              />
            </>
          ) : (
            <Text weight={Text.WEIGHTS.MEDIUM} variant={Text.VARIANTS.LG}>
              We couldn’t find any payments which match your current search, try refining your search
            </Text>
          )}
        </>
      )}
    </>
  );
};

const PaymentSearchDialog = ({ onSubmit, onReset }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ ...FILTER_PARAMS_INITIAL_VALUES }}
      validationSchema={Yup.object().shape({
        sub_organization_id: Yup.number().nullable(),
        claim_number: Yup.string().nullable(),
        decision_amount_from: Yup.number().nullable(),
        decision_amount_to: Yup.number().nullable(),
        requester_user_id: Yup.string().nullable(),
        lob: Yup.string().nullable(),
        coverage_type: Yup.string().nullable(),
        payable_type: Yup.string().nullable(),
        approved_date_from: Yup.date().nullable(),
        approved_date_to: Yup.date().nullable(),
        is_only_deductible_applied: Yup.boolean(),
        payee_first_name: Yup.string().nullable(),
        payee_last_name: Yup.string().nullable(),
        payee_ssn: Yup.number().nullable(),
        payee_company_name: Yup.string().nullable(),
        payee_tin: Yup.string().nullable(),
        check_number: Yup.string().nullable(),
        check_date_from: Yup.date().nullable(),
        check_date_to: Yup.date().nullable(),
        reference: Yup.string().nullable(),
        is_company: Yup.boolean().nullable(),
        financial_status_filters: Yup.array(),
        invoice_number: Yup.string(),
        iban: Yup.string()
          .nullable()
          .test('iban-validation', 'Invalid IBAN', (value) => !value || iban.isValid(value)),
        uk_sort_code: Yup.string()
          .nullable()
          .test(
            'uk-sort-code-validation',
            'Sort code must be of the form 20-00-00 or 200000',
            (value) => !value || /^\d{2}-\d{2}-\d{2}$|^\d{6}$/.test(value)
          ),
        uk_bank_account_number: Yup.string()
          .nullable()
          .test(
            'uk-bank-account-number-validation',
            'Account number must be 8 digits',
            (value) => !value || /^\d{8}$/.test(value)
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
      onReset={(values, { setSubmitting }) => {
        onReset(values);
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm }) => (
        <CardDialog noCardTitle containerStyle={{ background: colors.grey_02 }}>
          <div className="grid">
            <PaymentSearchFilters />
            <div className="flex gap-12">
              <div className="mt-4">
                <Button onClick={resetForm} variant="contained" className={classes.cancelButton}>
                  Clear
                </Button>
              </div>
              <div>
                <Button
                  className={cn(classes.button, 'm-4, ml-20')}
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </CardDialog>
      )}
    </Formik>
  );
};

PaymentSearchDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

const PaymentSearchFilters = () => {
  const classes = useStyles();
  const { isSubmitting, values } = useFormikContext();
  const { uniquePaymentMethodDisplayNamesMap } = usePaymentsConfiguration();
  const { subOrganizationEnabled } = useOrganization();

  const subOrganizationIds = useMemo(() => {
    const subOrganization = getIn(values, 'sub_organization_id');
    return subOrganization ? [subOrganization] : [];
  }, [values]);

  const lobs = useMemo(() => {
    return values['lob'] ? [values['lob']] : [];
  }, [values]);

  const shouldShowSsn = localeDetails.locale.region === 'US';

  return (
    <div>
      <Heading variant={Heading.TYPES.H3}>General Details</Heading>

      <div className="mb-32 grid grid-cols-5 gap-32">
        {subOrganizationEnabled && (
          <div>
            <SubOrganizationSelectFormik fieldId="sub_organization_id" disabled={isSubmitting} />
          </div>
        )}

        <div>
          <LobSelectFormik fieldId="lob" subOrganizationIds={subOrganizationIds} disabled={isSubmitting} />
        </div>

        <div>
          <CoveragesSingleSelectFormik
            coverageFieldId="coverage_type"
            disabled={isSubmitting}
            subOrganizationIds={subOrganizationIds}
            lobs={lobs}
          />
        </div>
      </div>

      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <TextFieldFormik
            id="payable_type"
            label="Payable Type"
            className={classes.textField}
            fullWidth
            select
            disabled={isSubmitting}
          >
            {Object.keys(PAYABLE_TYPES).map((payableTypeKey) => (
              <MenuItem key={payableTypeKey} value={payableTypeKey}>
                {PAYABLE_TYPES[payableTypeKey]}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
        <div>
          <AdjusterSelectTextFieldFormik
            id="requester_user_id"
            label="Issued By"
            disabled={isSubmitting}
            className={classes.textField}
          />
        </div>
        <div>
          <MultiSelectTextFieldFormik
            id="financial_status_filters"
            label="Financial Status"
            disabled={isSubmitting}
            options={Object.keys(FINANCIAL_STATUS_DICT)}
            renderValue={(selectedFinancialStatus) =>
              selectedFinancialStatus.map((selectedId) => FINANCIAL_STATUS_DICT[selectedId]?.desc).join(', ')
            }
            renderOption={(val) => FINANCIAL_STATUS_DICT[val].desc}
            className={classes.formTextFieldNoErrorSpacing}
            fullWidth
            sortAlphabetic
          />
        </div>
        <div>
          <TextFieldFormik
            id="invoice_number"
            label="Invoice Number"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
      </div>

      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <MonetaryValueTextFieldFormik
            id="decision_amount_from"
            label="Amount From"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>

        <div>
          <MonetaryValueTextFieldFormik
            id="decision_amount_to"
            label="Amount To"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
        <div>
          <DatePickerTextFieldFormik
            id="approved_date_from"
            label="Transaction Date From"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>
        <div>
          <DatePickerTextFieldFormik
            id="approved_date_to"
            label="Transaction Date To"
            className={classes.textField}
            disableFuture
            fullWidth
            disabled={isSubmitting}
            clearable
          />
        </div>
      </div>

      <div className="mb-32 grid gap-32">
        <div className="mt-12">
          <CheckboxFormik id="is_only_deductible_applied" label="Show only payments with deductible applied" />
        </div>
      </div>

      <Heading variant={Heading.TYPES.H3}>Method Specific</Heading>
      <div className="mb-32 grid grid-cols-5 gap-32">
        <div>
          <TextFieldFormik
            id="payment_method"
            label="Payment Method"
            className={classes.textField}
            fullWidth
            select
            disabled={isSubmitting}
          >
            {[...uniquePaymentMethodDisplayNamesMap].map(([paymentMethodKey, displayName]) => (
              <MenuItem key={paymentMethodKey} value={paymentMethodKey}>
                {displayName}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>

        <MethodSpecificFilters paymentMethod={values['payment_method']} />
      </div>

      <Heading variant={Heading.TYPES.H3}>Payee Details</Heading>

      <div className="mb-32 grid grid-cols-5 gap-32">
        <div className="mt-12">
          <CheckboxFormik id="is_company" label="Payee is a Company" />
        </div>
        {values['is_company'] ? (
          <>
            <div>
              <TextFieldFormik
                id="payee_company_name"
                label="Company Name"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>
            <div>
              <TextFieldFormik
                id="payee_tin"
                label="Tin"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>
          </>
        ) : (
          <>
            <div>
              <TextFieldFormik
                id="payee_first_name"
                label="First Name"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>

            <div>
              <TextFieldFormik
                id="payee_last_name"
                label="Last Name"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting}
              />
            </div>

            {shouldShowSsn && (
              <div>
                <TextFieldFormik
                  id="payee_ssn"
                  label="Social Security Number"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PaymentSearchFilters.propTypes = {};

function MethodSpecificFilters({ paymentMethod }) {
  const { shouldUseReference, isCheckPayment, shouldUseUKBankTransfer, shouldUseIbanBankTransfer } =
    usePaymentsConfiguration(paymentMethod);
  const { isSubmitting } = useFormikContext();
  const classes = useStyles();

  if (!paymentMethod) {
    return null;
  }

  return (
    <>
      {isCheckPayment && (
        <>
          <div>
            <TextFieldFormik
              id="check_number"
              label="Check Number"
              className={classes.textField}
              fullWidth
              disabled={isSubmitting}
            />
          </div>
          <div>
            <DatePickerTextFieldFormik
              id="check_date_from"
              label="Check Date From"
              className={classes.textField}
              disableFuture
              fullWidth
              disabled={isSubmitting}
              clearable
            />
          </div>
          <div>
            <DatePickerTextFieldFormik
              id="check_date_to"
              label="Check Date To"
              className={classes.textField}
              disableFuture
              fullWidth
              disabled={isSubmitting}
              clearable
            />
          </div>
        </>
      )}
      {shouldUseUKBankTransfer && (
        <>
          <div>
            <TextFieldFormik id="uk_sort_code" label="Sort Code" fullWidth />
          </div>
          <div>
            <TextFieldFormik id="uk_bank_account_number" label="Account Number" fullWidth />
          </div>
        </>
      )}
      {shouldUseIbanBankTransfer && (
        <div>
          <TextFieldFormik id="iban" label="IBAN" fullWidth />
        </div>
      )}
      {shouldUseReference && (
        <div>
          <TextFieldFormik
            id="reference"
            label="Reference"
            className={classes.textField}
            fullWidth
            disabled={isSubmitting}
          />
        </div>
      )}
    </>
  );
}

MethodSpecificFilters.propTypes = {
  paymentMethod: PropTypes.string,
};

export default PaymentSearchPage;
