import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import Heading from '~/components/core/TextComponents/Heading';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import { useContact } from '../../useContact';

import { FORMIK_COMMUNICATION_FIELD_IDS } from './constants';
import { getFullCommunicationPath } from './utils';

// If you update this function, make sure you update should_check_for_communication_permissions as well (BE) and Contact.jsx if still exists
const shouldCheckForCommunicationPermissions = (organization, isContactCompany, contactRole) => {
  return (
    isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.ALWAYS_CHECK_COMMUNICATION_PERMISSION) ||
    (!isContactCompany && contactRole !== 'vendor')
  );
};

const Permissions = ({ disabled }) => {
  const { userOrganization } = useCms();
  const { isCompany, currentRole } = useContact();
  const { values } = useFormikContext();

  const checkForCommunicationPermissions = shouldCheckForCommunicationPermissions(
    userOrganization,
    isCompany,
    currentRole
  );

  if (!checkForCommunicationPermissions) {
    return null;
  }

  return (
    <div>
      <Heading variant="h4" className="mb-12">
        Permissions
      </Heading>
      {!isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.QOVER_CONTACTS_UI_UNIQUE_BEHAVIOR) ? (
        <div className="grid grid-cols-2 items-center gap-20">
          <div>Send email messages</div>
          <RadioButtonGroupFormik
            className="justify-self-end"
            id={getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.IS_EMAILING_ALLOWED)}
            direction="row"
            options={[
              {
                text: 'No',
                optionValue: false,
              },
              {
                text: 'Yes',
                optionValue: true,
              },
            ]}
            showOnly={
              disabled || !getIn(values, getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.EMAILS))?.length
            }
            buttonVariant="default"
          />
        </div>
      ) : null}
      <div className="grid grid-cols-2 items-center">
        <div>Send text messages</div>
        <RadioButtonGroupFormik
          className="justify-self-end"
          id={getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.IS_SMSING_ALLOWED)}
          direction="row"
          options={[
            {
              text: 'No',
              optionValue: false,
            },
            {
              text: 'Yes',
              optionValue: true,
            },
          ]}
          showOnly={disabled || !getIn(values, getFullCommunicationPath(FORMIK_COMMUNICATION_FIELD_IDS.PHONES))?.length}
          buttonVariant="default"
        />
      </div>
    </div>
  );
};

Permissions.propTypes = {
  disabled: PropTypes.bool,
};

export default Permissions;
