import React from 'react';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper } from '~/components/core';
import Grid from '~/components/core/Atomic/Grid/Grid';
import NotesSummary from '~/components/NotesSummary';

interface NotesScreenProps {
  isTabActive?: boolean;
}

const NotesScreen: React.FC<NotesScreenProps> = ({ isTabActive }) => {
  const classes = useStyles();
  const { claim } = useClaim();
  return (
    <PermissionsRenderWrapper action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.READ}>
      <Grid item md={12}>
        <div className={classes.cardDivRow}>
          {claim && <NotesSummary isActive={isTabActive} claim={claim} onNoteClick={noop} />}
        </div>
      </Grid>
    </PermissionsRenderWrapper>
  );
};

export default NotesScreen;
