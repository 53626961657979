import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const NoteTabIcon: React.FC<IconProps> = ({ iconColor = '#202020', ...props }) => (
  <SvgIcon stroke="none" viewBox="0 0 16 16" {...props}>
    <path
      d="M5.11562 6.09844H10.1156C10.4156 6.09844 10.7156 5.79844 10.7156 5.49844C10.7156 5.19844 10.4156 4.89844 10.1156 4.89844H5.11562C4.81562 4.89844 4.51562 5.19844 4.51562 5.49844C4.51562 5.79844 4.81562 6.09844 5.11562 6.09844Z"
      fill={iconColor}
    />
    <path
      d="M5.11562 8.59844H10.1156C10.4156 8.59844 10.7156 8.29844 10.7156 7.99844C10.7156 7.69844 10.4156 7.39844 10.1156 7.39844H5.11562C4.81562 7.39844 4.51562 7.69844 4.51562 7.99844C4.51562 8.29844 4.81562 8.59844 5.11562 8.59844Z"
      fill={iconColor}
    />
    <path
      d="M7.61562 9.89844H5.11562C4.81562 9.89844 4.51562 10.1984 4.51562 10.4984C4.51562 10.7984 4.81562 11.0984 5.11562 11.0984H7.61562C7.91562 11.0984 8.21563 10.7984 8.21563 10.4984C8.21563 10.1984 7.91562 9.89844 7.61562 9.89844Z"
      fill={iconColor}
    />
    <path
      d="M14.7152 0.9C14.5152 0.7 14.1152 0.5 13.8152 0.5H1.41523C1.11523 0.5 0.715234 0.6 0.515234 0.9C0.215234 1.1 0.115234 1.4 0.115234 1.7V14.2C0.115234 14.5 0.215234 14.9 0.515234 15.1C0.715234 15.3 1.11523 15.5 1.41523 15.5H9.91523C10.1152 15.5 10.2152 15.5 10.4152 15.4C10.5152 15.4 10.6152 15.3 10.6152 15.3C10.7152 15.3 10.7152 15.2 10.8152 15.2L14.8152 11.2C14.8152 11.2 14.9152 11.1 14.9152 11C15.0152 10.9 15.0152 10.9 15.0152 10.8C15.1152 10.6 15.1152 10.5 15.1152 10.3V1.7C15.1152 1.4 15.0152 1.1 14.7152 0.9ZM1.41523 1.7H13.9152V9.8H10.1152C9.81523 9.8 9.51523 10.1 9.51523 10.4V14.2H1.41523V1.7ZM13.0152 11.1L10.8152 13.3V11.1H13.0152Z"
      fill={iconColor}
    />
  </SvgIcon>
);
export default NoteTabIcon;
