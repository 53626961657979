import React from 'react';

import { PropertyDetails } from '../../core';
import useCurrencyFormatter from '../../CurrencyFormatterContext';

export const CurrencyPropertyDetails: React.FC<{ title: string; value?: number | null }> = ({ value, title }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return <PropertyDetails title={title} text={typeof value === 'number' ? currencyFormatter.format(value) : ''} />;
};
