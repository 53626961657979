import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import CheckboxFormik from '~/components/CheckboxFormik';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import PermissionsButtonWrapper from '~/components/core/Permissions/PermissionsButtonWrapper';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';
import { GovernmentIdFieldFormik } from '~/components/GovernmentId';
import { DatePickerTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';

import { FORMIK_DETAILS_FIELD_IDS, SEX_OPTIONS } from '../../constants';
import { useContact } from '../../useContact';
import { getFullDetailsTabFieldPath } from '../../utils';

const Identity = ({ disabled }) => {
  const { isCompany, isPolicyContact, setIsCompany, currentCountry } = useContact();
  const { values, setFieldValue } = useFormikContext();

  const onIsCompanyChanged = (isCompany) => {
    if (getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_COMPANY)) === isCompany) {
      return;
    }

    if (isCompany) {
      const firstName = getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.FIRST_NAME));
      const middleName = getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.MIDDLE_NAME));
      const lastName = getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.LAST_NAME));
      const companyName = [firstName, middleName, lastName].filter((name) => !!name).join(' ');
      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.COMPANY_NAME), companyName);
    } else {
      const companyName = getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.COMPANY_NAME));
      let companyNameSplit = companyName.split(' ');
      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.LAST_NAME), companyNameSplit.pop());
      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.MIDDLE_NAME), '');
      setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.FIRST_NAME), companyNameSplit.join(' '));
    }

    setFieldValue(getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_COMPANY), isCompany);
    setIsCompany(isCompany);
  };

  return (
    <>
      <CheckboxFormik
        id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_COMPANY)}
        label="Is Company"
        fullWidth
        onChange={(event) => onIsCompanyChanged(event.target.checked)}
        disabled={disabled}
      />
      {isCompany ? (
        <>
          <TextFieldFormik
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.COMPANY_NAME)}
            label="Company Name"
            fullWidth
            showOnly={isPolicyContact}
            disabled={disabled}
          />
        </>
      ) : (
        <>
          <TextFieldFormik
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.FIRST_NAME)}
            label="First Name"
            fullWidth
            showOnly={isPolicyContact}
            disabled={disabled}
          />
          <TextFieldFormik
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.MIDDLE_NAME)}
            label="Middle Name"
            fullWidth
            showOnly={isPolicyContact}
            disabled={disabled}
          />
          <TextFieldFormik
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.LAST_NAME)}
            label="Last Name"
            fullWidth
            showOnly={isPolicyContact}
            disabled={disabled}
          />
          <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT_PII} verb={PERMISSION_VERBS.WRITE}>
            <DatePickerTextFieldFormik
              id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.DATE_OF_BIRTH)}
              label="Date of Birth"
              fullWidth
              disabled={disabled}
            />
          </PermissionsButtonWrapper>
          <CheckboxFormik
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.IS_DECEASED)}
            label="Contact is deceased"
            fullWidth
            disabled={disabled}
          />
          <PermissionsButtonWrapper action={PERMISSION_ACTIONS.CONTACT_PII} verb={PERMISSION_VERBS.WRITE}>
            <GovernmentIdFieldFormik
              id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.GOVERNMENT_ID)}
              value={getIn(values, getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.GOVERNMENT_ID), '')}
              disabled={disabled}
              country={currentCountry}
            />
          </PermissionsButtonWrapper>
          <RadioButtonGroupFormik
            label="Sex"
            id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.SEX)}
            direction="row"
            options={SEX_OPTIONS.map((option) => ({
              text: option,
              optionValue: option,
            }))}
            showOnly={disabled}
            sizeButtonsEvenly
          />
        </>
      )}
    </>
  );
};

Identity.propTypes = {
  disabled: PropTypes.bool,
};

export default Identity;
