import React from 'react';
import PropTypes from 'prop-types';
import NotificationsIconOutlined from '@material-ui/icons/NotificationsOutlined';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import FailedTokensWarningBanner from '~/components/GenericTemplates/FailedTokensWarningBanner';
import GenericTemplateSelectionContainerFormik from '~/components/GenericTemplates/FromTemplate/GenericTemplateSelectionContainerFormik';
import OrgUnitSelectTextFieldFormik from '~/OrganizationUnit/OrgUnitSelectTextFieldFormik';
import useFetchOrgUnits from '~/OrganizationUnit/useFetchOrgUnits';

import AdjusterSelectTextFieldFormik from '../../Adjuster/AdjusterSelectTextFieldFormik';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import CommunicationLink from '../communications/CommunicationLink';
import DocumentLink from '../Documents/DocumentLink';
import TextFieldFormik, { DatePickerTextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const ReminderDialogCard = (props) => {
  const { cardDialogProps, buttonsComponent, claim, showOnly, onEdit, disabled, targetUser, onUpdate } = props;
  const classes = useStyles();

  const { claim: claimInContext } = useClaim();
  const { values } = useFormikContext();
  const [isFromTemplateDialogOpen, setIsFromTemplateDialogOpen] = React.useState(false);
  const [failedTokens, setFailedTokens] = React.useState(null);

  const handleOpenFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(true);
  };

  const handleCloseFromTemplateDialog = () => {
    setIsFromTemplateDialogOpen(false);
  };
  let reminderTargetOptions = [];
  if (claim) {
    reminderTargetOptions = claim.exposures.map((exposure) => {
      return {
        id: exposure.id,
        label: exposure.label_text,
        secondary: exposure.handling_adjuster ? exposure.handling_adjuster : claim.handling_adjuster,
      };
    });
    reminderTargetOptions = _.sortBy(reminderTargetOptions, (label) => label.label);
    // don't allow adding claim notifications if no file owner, but on showOnly present this option in case some already exist
    if (claim.handling_adjuster_id || showOnly) {
      reminderTargetOptions = [{ id: 0, label: 'Claim', secondary: claim.handling_adjuster }].concat(
        reminderTargetOptions
      );
    }
  }

  reminderTargetOptions = [{ id: -1, label: (showOnly && targetUser) || 'Myself' }].concat(reminderTargetOptions);

  reminderTargetOptions = reminderTargetOptions.concat([
    { id: -2, label: 'Other User' },
    { id: -3, label: 'Unit Leader' },
  ]);

  function getAdjustersToExcludeFromOthers() {
    let adjustersIdsToExclude = [];

    if (claim.handling_adjuster_id) {
      adjustersIdsToExclude.push(claim.handling_adjuster_id);
    }

    claim.exposures.forEach(
      (exposure) => exposure.handling_adjuster_id && adjustersIdsToExclude.push(exposure.handling_adjuster_id)
    );

    return adjustersIdsToExclude;
  }

  let orgUnits = useFetchOrgUnits({
    disabled: !(values.target_id === -3 && !showOnly),
  });

  return (
    <CardDialog {...cardDialogProps}>
      <div className="space-y-4">
        <div className="flex w-full items-center justify-between">
          <Typography display="block" variant="subtitle1">
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <NotificationsIconOutlined /> &nbsp; Notification{' '}
              {claim &&
                (claimInContext ? (
                  claim.claim_id_display
                ) : (
                  <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />
                ))}
              {values.communication && (
                <span>
                  &nbsp; regarding{' '}
                  <CommunicationLink text="communication" communication={values.communication} onUpdate={onUpdate} />
                </span>
              )}
            </span>
          </Typography>
          {values.document && (
            <Typography display="block" variant="subtitle1">
              Regarding document:{' '}
              <DocumentLink text={values.document.document_name} document={values.document} indicateLinkIfRemoved />
            </Typography>
          )}
          {!showOnly && claimInContext ? (
            <div className="flex items-center justify-end">
              <Button color="primary" onClick={handleOpenFromTemplateDialog}>
                <AddIcon />
                From template
              </Button>
            </div>
          ) : null}
        </div>

        <div>
          <TextFieldFormik
            id="title"
            label="Title"
            className={classes.textField}
            fullWidth
            showOnly={showOnly}
            onEdit={onEdit}
            disabled={disabled || showOnly}
          />
        </div>
        <div>
          <TextFieldFormik
            id="details"
            label="Details"
            rows="6"
            className={classes.textField}
            fullWidth
            multiline
            showOnly={showOnly}
            onEdit={onEdit}
            disabled={disabled || showOnly}
          />
        </div>
        <FailedTokensWarningBanner failedTokens={failedTokens} />

        <div>
          <DatePickerTextFieldFormik
            id="due_date"
            label="Date"
            disablePast
            className={classes.textField}
            showOnly={showOnly}
            onEdit={onEdit}
            disabled={disabled || showOnly}
          />
        </div>
        <div>
          <TextFieldFormik
            id="target_id"
            select
            label="Notification For"
            className={classes.textField}
            fullWidth
            showOnly={showOnly}
            onEdit={onEdit}
            disabled={disabled || showOnly}
          >
            {reminderTargetOptions.map((target) => (
              <MenuItem key={target.id} value={target.id}>
                <div>
                  <Typography display="block" variant="body1">
                    {target.label}
                  </Typography>
                  {target.secondary && (
                    <Typography display="block" variant="body2" color="textSecondary">
                      {target.secondary}
                    </Typography>
                  )}
                </div>
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
        {values.target_id === -2 && (
          <div>
            <AdjusterSelectTextFieldFormik
              id="other_adjuster_id"
              label="User"
              className={classes.textField}
              doNotIncludeNoneOption
              excludedAdjustersIds={getAdjustersToExcludeFromOthers()}
              fullWidth
            />
          </div>
        )}
        {values.target_id === -3 && (
          <div>
            <OrgUnitSelectTextFieldFormik
              id="organization_unit_id"
              label="Organization Unit"
              units={orgUnits.units}
              isLoading={orgUnits.isLoading}
              isError={orgUnits.isError}
              showOnly={showOnly}
              disabled={disabled || showOnly}
            />
          </div>
        )}
      </div>
      {buttonsComponent}
      {isFromTemplateDialogOpen && claimInContext ? (
        <GenericTemplateSelectionContainerFormik
          handleClose={handleCloseFromTemplateDialog}
          templateType="notification"
          titleFieldId="title"
          bodyFieldId="details"
          shouldConvertBodyHtmlToText
          setFailedTokens={setFailedTokens}
        />
      ) : null}
    </CardDialog>
  );
};

ReminderDialogCard.propTypes = {
  buttonsComponent: PropTypes.node,
  cardDialogProps: PropTypes.object.isRequired,
  claim: PropTypes.object, // Could be reminder not related to claim (For now only possible for reminder about failure to send reply email out of claim)
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  targetUser: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default ReminderDialogCard;
