import { isNil } from 'lodash';

import type { LineItemProps, LineItemRow } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';

export const UNDECIDED = '__';
export const NO_COVERAGE = '__';

interface AggregateCoverage {
  id: string;
  coverage: string;
  amounts: Record<string, number>;
}

export const getAggregateCoverages = (lineItems: LineItemProps[]): AggregateCoverage[] => {
  const coverageMap: Record<string, Record<string, number>> = {};

  lineItems.forEach((item) => {
    const coverage = item.coverage ?? NO_COVERAGE;
    const coverageDecision = item.coverage_decision ?? UNDECIDED;
    const amount = item.amount ?? 0;
    const coveredAmount = item.covered_amount ?? 0;

    if (!coverageMap[coverage]) {
      coverageMap[coverage] = {};
    }

    if (!coverageMap[coverage][coverageDecision]) {
      coverageMap[coverage][coverageDecision] = 0;
    }

    if (coverageDecision === 'covered') {
      if (!coverageMap[coverage].excluded) {
        coverageMap[coverage].excluded = 0;
      }
      coverageMap[coverage].excluded += amount - coveredAmount;
      coverageMap[coverage][coverageDecision] += coveredAmount;
    } else {
      coverageMap[coverage][coverageDecision] += amount;
    }
  });

  // Convert the map to an array of objects
  return Object.entries(coverageMap).map(([coverage, amounts]) => ({
    id: coverage,
    coverage,
    amounts,
  }));
};

export const calcCoveredAmount = (row: LineItemRow, value = row.covered_amount): number => {
  if (row.coverage_decision === 'covered' && !isNil(row.amount) && !isNil(value)) {
    return Math.min(row.amount, value);
  }
  return 0;
};
