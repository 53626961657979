import React from 'react';
import axios from 'axios';

import { ACTION_TYPE_DESCRIPTOR } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { INSIGHTS_ICONS } from '~/components/AiChat/SideBarDialog/utils';
import type { AiAction, AiChatExchangesAndReferencesApiResponse } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { MainCard } from '~/components/core';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';
import cn from '~/Utils/cn';

import '../../MarkdownTemplate.css';

interface ActionsPanelProps {
  action: AiAction;
  onActionUpdate: () => Promise<void>;
}

export const ActionPanel: React.FC<ActionsPanelProps> = ({ action, onActionUpdate }) => {
  const { claim, onAsyncClaimUpdate } = useClaim();
  if (!action?.task_data) reportErrorInProductionOrThrow(new Error('Action task_data is missing'));
  const taskType = action.task_data?.task_type;
  const [title, setTitle] = React.useState<string>('');
  const [InnerComponent, setInnerComponent] = React.useState<React.ElementType | null>(null);
  const [ActionFooterComponent, setActionFooterComponent] = React.useState<React.ElementType | null | undefined>();
  const related_exposure = React.useMemo(
    () =>
      claim?.exposures?.find(
        (exposure: { id: string | undefined }) => exposure?.id?.toString() === action?.related_exposure_id
      ),
    [action, claim]
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDone, setIsDone] = React.useState(action.is_executed);

  const executeAction = React.useCallback(async () => {
    try {
      setIsDone(true);
      await axios.post<AiChatExchangesAndReferencesApiResponse>(
        `/api/v1/claims/${claim.id}/actions/${action.action_id}/execute`
      );
    } catch (error) {
      await reportAxiosError(error);
      setIsDone(false);
      throw error;
    } finally {
      await onActionUpdate();
      await onAsyncClaimUpdate();
    }
  }, [onActionUpdate, onAsyncClaimUpdate, claim, action]);

  React.useEffect(() => {
    if (taskType) {
      const descriptor = ACTION_TYPE_DESCRIPTOR[taskType];
      if (!descriptor) {
        reportErrorInProductionOrThrow(new Error(`Invalid action task data type ${action.task_data?.task_type}`));
        return;
      }

      setTitle(() => descriptor.title);
      setInnerComponent(() => descriptor.InnerActionComponent);
      setActionFooterComponent(() => descriptor.ActionFooterComponent);
    }
  }, [taskType, action]);

  return (
    <div
      className={cn('animate-fadeIn gap-10 relative flex w-full items-center bg-white', {
        ['bg-slate-100']: isOpen,
      })}
    >
      <MainCard
        key="contained"
        collapsible
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        title={
          <div className="flex flex-col gap-2">
            <span className="text-lg font-bold">{title}</span>
            <span className="text-sm italic text-slate-800">{related_exposure?.label_text}</span>
          </div>
        }
        type="contained"
        isOpen={isOpen}
        onCollapseClick={(cardState) => setIsOpen(cardState)}
        titleAction={
          <div
            className={cn('ml-auto flex shrink-0 cursor-pointer items-center justify-center rounded-full p-2 ', {
              ['bg-pink-200/70']: !isDone,
              ['bg-green-100']: isDone,
            })}
          >
            {INSIGHTS_ICONS[isDone ? 'checked' : 'warning']}
          </div>
        }
      >
        <div className="relative flex w-full flex-col gap-12">
          <div className="flex flex-col gap-12 ">
            {InnerComponent && <InnerComponent action={action} />}
            {ActionFooterComponent && (
              <ActionFooterComponent action={action} onExecute={executeAction} isDone={isDone} />
            )}
          </div>
        </div>
      </MainCard>
    </div>
  );
};
