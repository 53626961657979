import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import useFetchExposure from '~/components/exposures/useFetchExposure';
import { useHasPermission } from '~/components/hooks/useHasPermission';

import { CONFIGURATION_FEATURES_NAMES, SUPERVISOR_APPROVAL_STATUS_DICT } from '../../Types';
import { capitalize, isFeatureEnabled, reportAxiosError } from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { ClaimContextProvider } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { LoadingSwitch, PERMISSION_ACTIONS, PERMISSION_VERBS, Text } from '../core';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import { usePaymentsLimits } from '../hooks/usePaymentLimits';
import { useExposureSubReserves } from '../hooks/useSubReserves';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';
import ToggleButtonGroupFormik from '../ToggleButtonGroupFormik';

import { getPayableWithReserveType, getPolicyExposureLimit, isLimitExists } from './ExposureUtils';
import RequestAmountFormik from './RequestAmountFormik';

import { useStyles } from '../../assets/styles';

const getTitle = (payableType) => {
  const titlePayableType = payableType === 'expenses' ? 'Expenses' : 'Indemnity';
  return `Update ${titlePayableType} Reserve`;
};

function ReserveChangeRequestContainer(props) {
  const { user, userOrganization } = useCms();
  const { subReserves } = useOrganization();

  const { claim, payableWithReserve, exposure, reserveChangeRequest, cardDialogProps, onClose, onUpdate } = props;
  const { reserve, was_payment_request_issued } = payableWithReserve;

  const policyExposureLimit = getPolicyExposureLimit(claim, exposure);
  const payableType = getPayableWithReserveType(exposure, payableWithReserve);

  const shouldDisplaySubReserves =
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.SUB_RESERVERS_IN_PD_AND_BI) &&
    subReserves[exposure.coverage_type];
  const subReservesForExposure = shouldDisplaySubReserves ? subReserves[exposure.coverage_type] : undefined;

  function _handleSubmit(path, values) {
    return axios
      .post(path, values)
      .then(onUpdate)
      .catch((error) => {
        reportAxiosError(error);
        throw error;
      })
      .then(onClose);
  }

  const payableUrl = exposure.isGeneralExpenses
    ? `/api/v1/claims/${claim.id}/general_expenses/reserve_change_requests`
    : `/api/v1/claims/${claim.id}/exposures/${exposure.id}/${payableType}/reserve_change_requests`;

  function handleSubmitReserve(values) {
    return _handleSubmit(payableUrl, values);
  }

  function handleCancelReserveChangeRequest(values) {
    return _handleSubmit(`${payableUrl}/${reserveChangeRequest.id}/decide`, { ...values, decision: 'cancel' });
  }

  function handleApproveReserveChangeRequest(values) {
    return _handleSubmit(`${payableUrl}/${reserveChangeRequest.id}/decide`, values);
  }

  function getCardDialog() {
    if (!reserveChangeRequest) {
      return (
        <ReserveChangeRequestCard
          cardDialogProps={cardDialogProps}
          onSubmitReserve={handleSubmitReserve}
          payableWithReserve={payableWithReserve}
          payableType={payableType}
          onCancel={onClose}
          policyExposureLimit={policyExposureLimit}
          exposure={exposure}
          claim={claim}
          legacySubReserves={subReservesForExposure}
        />
      );
    }

    // reserve_change_request
    if (reserveChangeRequest.request_status !== 'pending') {
      return (
        <ReserveChangeRequestDecisionCard
          cardDialogProps={cardDialogProps}
          reserveChangeRequest={reserveChangeRequest}
          claim={claim}
          exposure={exposure}
          payableType={payableType}
          onClose={onClose}
          legacySubReserves={subReservesForExposure}
          wasPaymentIssued={was_payment_request_issued}
        />
      );
    }

    // reserve_change_request is pending
    if ((user.is_org_level_supervisor || user.is_manager) && user.id !== reserveChangeRequest.requester_user?.id) {
      return (
        <ReserveChangeApprovalCard
          cardDialogProps={cardDialogProps}
          reserveChangeRequest={reserveChangeRequest}
          currentReserve={reserve}
          onDecideReserveChangeRequest={handleApproveReserveChangeRequest}
          onCancel={onClose}
          payableWithReserve={payableWithReserve}
          policyExposureLimit={policyExposureLimit}
          claim={claim}
          exposure={exposure}
          payableType={payableType}
          legacySubReserves={subReservesForExposure}
        />
      );
    } else {
      return (
        <ReserveChangeCancelCard
          cardDialogProps={cardDialogProps}
          reserveChangeRequest={reserveChangeRequest}
          currentReserve={reserve}
          onCancelReserveChangeRequest={handleCancelReserveChangeRequest}
          onCancel={onClose}
          legacySubReserves={subReservesForExposure}
          wasPaymentIssued={was_payment_request_issued}
          payableType={payableType}
          exposure={exposure}
        />
      );
    }
  }

  return <>{getCardDialog()}</>;
}

ReserveChangeRequestContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  reserveChangeRequest: PropTypes.object,
  cardDialogProps: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ReserveChangeRequestDynamicContainer({
  claimId,
  exposureId,
  payableWithReserveId,
  reserveChangeRequest,
  cardDialogProps,
  onClose,
  onUpdate,
}) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);
  const {
    isLoadingExposure,
    isErrorExposure,
    exposure: exposureFetchedOrUndefined,
  } = useFetchExposure(claimId, exposureId); // exposureId might be undefined

  const { userOrganization } = useCms();

  const isEfficientExposures = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);

  const isLoading = isLoadingClaim || isLoadingExposure;
  const isError = isErrorClaim || isErrorExposure;

  if (isLoading) {
    return (
      <CardDialog title="Update Reserve" {...cardDialogProps}>
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  let payableWithReserve = null;
  let exposure = null;
  if (claim.general_expenses.id === payableWithReserveId) {
    payableWithReserve = claim.general_expenses;
    // TODO: Move this to somewhere else
    exposure = {
      isGeneralExpenses: true,
      is_closed: false,
      expenses: claim.general_expenses,
    };
  } else {
    exposure = isEfficientExposures
      ? exposureFetchedOrUndefined
      : claim.exposures.find((exposure) => exposure.id === exposureId);
    if (!exposure) {
      throw Error(`Couldn't find exposure ${exposureId} in claim ${claimId}`);
    }

    if (exposure.indemnity.id === payableWithReserveId) {
      payableWithReserve = exposure.indemnity;
    } else if (exposure.expenses.id === payableWithReserveId) {
      payableWithReserve = exposure.expenses;
    } else {
      throw Error(`Couldn't find payableWithReserve ${payableWithReserveId} in exposure ${exposureId}`);
    }
  }

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <ReserveChangeRequestContainer
        claim={claim}
        exposure={exposure}
        payableWithReserve={payableWithReserve}
        reserveChangeRequest={reserveChangeRequest}
        cardDialogProps={cardDialogProps}
        onClose={onClose}
        onUpdate={onUpdate}
      />
    </ClaimContextProvider>
  );
}

ReserveChangeRequestDynamicContainer.propTypes = {
  claimId: PropTypes.number.isRequired,
  exposureId: PropTypes.number.isRequired,
  payableWithReserveId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  reserveChangeRequest: PropTypes.object,
  cardDialogProps: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ReserveChangeRequestCard(props) {
  const {
    claim,
    onSubmitReserve,
    cardDialogProps,
    onCancel,
    payableWithReserve,
    policyExposureLimit,
    payableType,
    legacySubReserves,
    exposure,
  } = props;
  const classes = useStyles();

  const [showPolicyExposureLimitDialog, setShowPolicyExposureLimitDialog] = React.useState(false);
  const { isSubReservesConfigEnabled, subReservesConfigMap } = useExposureSubReserves(payableType, exposure);
  const { userExposureLimit, isUserExposureLimitLoading } = usePaymentsLimits(
    claim,
    exposure,
    payableType,
    'reserve',
    cardDialogProps.open ?? true
  );

  const getReserveChangeRequestFields = (payableWithReserve) => {
    const fields = {
      reserve: payableWithReserve?.reserve ?? '',
      note: '',
      financial_request_extra: {
        sub_reserves: {},
      },
    };

    if (payableWithReserve.legacy_last_reserve_change_request_sub_reserves) {
      fields.financial_request_extra.sub_reserves = payableWithReserve.legacy_last_reserve_change_request_sub_reserves;
    }

    if (isSubReservesConfigEnabled) {
      const subReserveKeys = [...subReservesConfigMap.keys()];
      const initialSubReserves = _.chain(subReserveKeys)
        .keyBy((key) => key)
        .mapValues(() => 0)
        .value();
      fields.sub_reserves = { ...initialSubReserves, ...(payableWithReserve?.sub_reserves ?? {}) };
    }

    return fields;
  };

  const getReserveChangeRequestValidationSchema = () => {
    const reserveChangeRequestValidationFields = {
      reserve: Yup.number().required('Required'),
    };

    if (isSubReservesConfigEnabled) {
      const subReserveKeys = [...subReservesConfigMap.keys()];
      const subReservesValidationSchema = _.chain(subReserveKeys)
        .keyBy((key) => key)
        .mapValues(() => Yup.number().required('Required'))
        .value();
      reserveChangeRequestValidationFields.sub_reserves = Yup.object().shape(subReservesValidationSchema);
    }

    return Yup.object().shape(reserveChangeRequestValidationFields);
  };

  return (
    <Formik
      initialValues={getReserveChangeRequestFields(payableWithReserve)}
      validationSchema={getReserveChangeRequestValidationSchema()}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        if (
          payableType !== 'expenses' &&
          isLimitExists(policyExposureLimit) &&
          values['reserve'] + payableWithReserve.paid_sum > policyExposureLimit
        ) {
          setShowPolicyExposureLimitDialog(true);
          formikProps.setSubmitting(false);
          return;
        }

        onSubmitReserve(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        const checkIsExceedsUserAuthority = () =>
          isLimitExists(userExposureLimit) && userExposureLimit < values['reserve'];

        return (
          <>
            <CardDialog
              title={getTitle(payableType)}
              width="850px"
              {...cardDialogProps}
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
            >
              <LoadingSwitch isLoading={isUserExposureLimitLoading}>
                <RequestAmountFormik
                  id="reserve"
                  label="Reserve"
                  labelWithSubReserves="Reserve total"
                  isSubmitting={isSubmitting}
                  isExceedingLimit={checkIsExceedsUserAuthority()}
                  payableType={payableType}
                  legacySubReserves={legacySubReserves}
                  wasPaymentIssued={payableWithReserve.was_payment_request_issued}
                  exposure={exposure}
                />
                <div className={classes.inputContainer}>
                  <TextFieldFormik
                    id="note"
                    label="Reason"
                    rows="2"
                    className={classes.textField}
                    fullWidth
                    multiline
                  />
                </div>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    {checkIsExceedsUserAuthority() ? 'Request Supervisor Approval' : 'Update'}
                  </Button>
                </div>
              </LoadingSwitch>
            </CardDialog>
            {showPolicyExposureLimitDialog && (
              <ReserveExceedsPolicyLimitsCard
                open={showPolicyExposureLimitDialog}
                claim={claim}
                onClose={() => setShowPolicyExposureLimitDialog(false)}
                onOverride={async (overrideValues) => {
                  await onSubmitReserve({ ...values, ...overrideValues });
                  formikProps.resetForm();
                  setShowPolicyExposureLimitDialog(false);
                }}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

ReserveChangeRequestCard.propTypes = {
  claim: PropTypes.object.isRequired,
  cardDialogProps: PropTypes.object.isRequired,
  onSubmitReserve: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  policyExposureLimit: PropTypes.number.isRequired,
  exposure: PropTypes.object,
  legacySubReserves: PropTypes.arrayOf(PropTypes.string),
};

function ReserveExceedsPolicyLimitsCard(props) {
  const { open, onClose, onOverride } = props;
  const classes = useStyles();

  const userHasClaimPermissions = useHasPermission({
    action: PERMISSION_ACTIONS.APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT,
    verb: PERMISSION_VERBS.WRITE,
  });

  return (
    <CardDialog title="Reserve exceeds policy limits" isDialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Typography display="block">Reserve exceeds policy limits</Typography>
      {userHasClaimPermissions && onOverride && (
        <Formik
          initialValues={{
            is_override_limit: false,
            override_limit_reason: '',
          }}
          validationSchema={Yup.object({
            is_override_limit: Yup.bool(),
            override_limit_reason: Yup.string().required('Required'),
          })}
          enableReinitialize
          onSubmit={(values, formikProps) => {
            try {
              onOverride(values);
            } catch (exception) {
              formikProps.setSubmitting(false);
            }
          }}
        >
          {(formikProps) => {
            const { values, handleSubmit, isSubmitting } = formikProps;
            return (
              <div style={{ marginTop: '16px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <CheckboxFormik
                      id="is_override_limit"
                      label="Set reserve over policy limit"
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="override_limit_reason"
                      label="Reason"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting || !values.is_override_limit}
                    />
                  </Grid>
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting || !values.is_override_limit}
                    >
                      Confirm
                    </Button>
                  </div>
                </Grid>
              </div>
            );
          }}
        </Formik>
      )}
    </CardDialog>
  );
}

ReserveExceedsPolicyLimitsCard.propTypes = {
  claim: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOverride: PropTypes.func,
};

function ReserveChangeCancelCard(props) {
  const {
    reserveChangeRequest,
    currentReserve,
    onCancelReserveChangeRequest,
    cardDialogProps,
    onCancel,
    legacySubReserves,
    wasPaymentIssued,
    payableType,
    exposure,
  } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        ...reserveChangeRequest,
        note: '',
      }}
      validationSchema={Yup.object().shape({ note: Yup.string().required('Required') })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onCancelReserveChangeRequest(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            title={getTitle(payableType)}
            width="450px"
            {...cardDialogProps}
            onClose={() => {
              formikProps.handleReset();
              onCancel();
            }}
          >
            <Grid container>
              <Grid item>
                <RequestAmountFormik
                  id="request_amount"
                  label="Reserve"
                  labelWithSubReserves="Reserve total"
                  isSubmitting={isSubmitting}
                  payableType={payableType}
                  legacySubReserves={legacySubReserves}
                  showOnly
                  wasPaymentIssued={wasPaymentIssued}
                  pendingMessage="Pending approval"
                  exposure={exposure}
                >
                  <MonetaryValueTextFieldFormik
                    id="current_reserve"
                    label="Current reserve"
                    className={classes.textField}
                    value={currentReserve}
                    showOnly
                  />
                </RequestAmountFormik>
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <div>
              <br />
            </div>
            <div className={classes.inputContainer}>
              <TextFieldFormik
                id="request_note"
                label="Adjuster's original note"
                rows="2"
                className={classes.textField}
                fullWidth
                multiline
                showOnly
              />
            </div>
            <div>
              <br />
            </div>
            <div className={classes.inputContainer}>
              <TextFieldFormik id="note" label="Note" rows="2" className={classes.textField} fullWidth multiline />
            </div>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Cancel Request
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ReserveChangeCancelCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  reserveChangeRequest: PropTypes.object.isRequired,
  currentReserve: PropTypes.number.isRequired,
  onCancelReserveChangeRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  legacySubReserves: PropTypes.arrayOf(PropTypes.string),
  wasPaymentIssued: PropTypes.bool,
  payableType: PropTypes.string.isRequired,
  exposure: PropTypes.object,
};

function ReserveChangeApprovalCard(props) {
  const {
    reserveChangeRequest,
    currentReserve,
    onDecideReserveChangeRequest,
    cardDialogProps,
    onCancel,
    payableWithReserve,
    policyExposureLimit,
    claim,
    exposure,
    payableType,
    legacySubReserves,
  } = props;
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();
  const { isSubReservesConfigEnabled } = useExposureSubReserves(payableType, exposure);

  const [showPolicyExposureLimitDialog, setShowPolicyExposureLimitDialog] = React.useState(false);
  const { userExposureLimit, isUserExposureLimitLoading } = usePaymentsLimits(
    claim,
    exposure,
    payableType,
    'reserve',
    cardDialogProps.open ?? true
  );

  return (
    <Formik
      initialValues={{
        ...reserveChangeRequest,
        note: '',
        decision: '',
        decision_amount: '',
      }}
      validationSchema={Yup.object().shape({
        note: Yup.string().required('Required'),
        decision: Yup.string().required('Required').oneOf(['approve', 'approve_different', 'decline']),
        decision_amount: Yup.number().when('decision', {
          is: 'approve_different',
          then: Yup.number().required('Required'),
        }),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        if (
          payableType !== 'expenses' &&
          isLimitExists(policyExposureLimit) &&
          values['decision_amount'] + payableWithReserve.paid_sum > policyExposureLimit
        ) {
          setShowPolicyExposureLimitDialog(true);
          formikProps.setSubmitting(false);
          return;
        }

        onDecideReserveChangeRequest(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;
        const decision = values.decision;
        let approvingAmount = 0;
        if (decision === 'approve') {
          approvingAmount = reserveChangeRequest.request_amount;
        } else if (decision === 'approve_different') {
          approvingAmount = values.decision_amount;
        }

        const isExceedsUserAuthority = () => isLimitExists(userExposureLimit) && userExposureLimit < approvingAmount;

        function switchAmountField() {
          switch (values['decision']) {
            case 'approve':
              return (
                <RequestAmountFormik
                  id="request_amount"
                  label="Approved reserve"
                  labelWithSubReserves="Approved reserve total"
                  isSubmitting={isSubmitting}
                  payableType={payableType}
                  legacySubReserves={legacySubReserves}
                  isExceedingLimit={isExceedsUserAuthority()}
                  showOnly
                  wasPaymentIssued={payableWithReserve.was_payment_request_issued}
                  exposure={exposure}
                />
              );
            case 'approve_different':
              return (
                <RequestAmountFormik
                  id="decision_amount"
                  label="New reserve"
                  labelWithSubReserves="New reserve total"
                  isSubmitting={isSubmitting}
                  payableType={payableType}
                  legacySubReserves={legacySubReserves}
                  isExceedingLimit={isExceedsUserAuthority()}
                  wasPaymentIssued={payableWithReserve.was_payment_request_issued}
                  exposure={exposure}
                />
              );
            case 'decline':
              return (
                <MonetaryValueTextFieldFormik
                  key="decline"
                  id="request_amount"
                  label="New reserve"
                  value={currentReserve}
                  showOnly
                />
              );
            default:
              return (
                <div style={{ visibility: 'hidden' }}>
                  <MonetaryValueTextFieldFormik
                    key="placeholder"
                    id="request_amount"
                    label="New reserve"
                    value={currentReserve}
                    showOnly
                  />
                </div>
              );
          }
        }

        function switchSubmitButton() {
          let buttonName = '';

          if (!values['decision']) {
            return (
              <Button disabled variant="contained" color="primary">
                Approve Request
              </Button>
            );
          }

          switch (values['decision']) {
            case 'approve':
              buttonName = isExceedsUserAuthority() ? 'Request Supervisor Approval' : 'Approve Request';
              break;
            case 'approve_different':
              buttonName = isExceedsUserAuthority() ? 'Request Supervisor Approval' : 'Approve Different Request';
              break;
            case 'decline':
              buttonName = 'Decline Request';
              break;
            default:
              throw Error(`Unknown value for decision: ${values['decision']}`);
          }

          return (
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              {buttonName}
            </Button>
          );
        }

        const requestActionsOptions = [
          {
            value: 'approve',
            key: 'approve',
            label: (
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Approve
              </Text>
            ),
          },
          {
            value: 'decline',
            key: 'decline',
            label: (
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Decline
              </Text>
            ),
          },
        ];

        if (!isSubReservesConfigEnabled) {
          requestActionsOptions.splice(1, 0, {
            value: 'approve_different',
            key: 'approve_different',
            label: (
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Approve different sum
              </Text>
            ),
          });
        }

        return (
          <>
            <CardDialog
              title={`${capitalize(payableType)} Reserve Change Approval`}
              width="450px"
              {...cardDialogProps}
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
            >
              <LoadingSwitch isLoading={isUserExposureLimitLoading}>
                <Typography>{`Requested by: ${reserveChangeRequest.requester_user.username}`}</Typography>
                <div className={classes.inputContainer}>
                  <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />
                  {claim.policy.insured_contact && (
                    // In self insured organization there is no insured
                    <ContactShowOnlyTextField
                      contactId={claim.policy.insured_contact.id}
                      contactDisplayName={claim.policy.insured_contact.full_name}
                      label="Insured"
                    />
                  )}
                </div>
                <div className={classes.inputContainer}>
                  {exposure.isGeneralExpenses ? 'General Expenses' : <ExposureDetailedTitle exposure={exposure} />}
                </div>
                <Grid container>
                  <Grid item>
                    <MonetaryValueTextFieldFormik
                      id="request_amount"
                      label="Reserve"
                      className={classes.textField}
                      showOnly
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item>
                    <MonetaryValueTextFieldFormik
                      id="current_reserve"
                      label="Current reserve"
                      className={classes.textField}
                      value={currentReserve}
                      showOnly
                    />
                  </Grid>
                </Grid>
                <div className={classes.inputContainer}>
                  <TextFieldFormik
                    id="request_note"
                    label="Adjuster's original note"
                    rows="2"
                    className={classes.textField}
                    fullWidth
                    multiline
                    showOnly
                  />
                </div>
                <div>
                  <br />
                </div>
                <ToggleButtonGroupFormik id="decision" options={requestActionsOptions} />

                {switchAmountField()}
                <div className={classes.inputContainer}>
                  <TextFieldFormik id="note" label="Note" rows="2" className={classes.textField} fullWidth multiline />
                </div>
                <div className={classes.buttonsContainer}>{switchSubmitButton()}</div>
              </LoadingSwitch>
            </CardDialog>
            <CardDialog
              title="Reserve exceeds policy limits"
              isDialog
              open={showPolicyExposureLimitDialog}
              onClose={() => setShowPolicyExposureLimitDialog(false)}
              maxWidth="xs"
              fullWidth
            >
              <Typography display="block">{`New incurred of ${currencyFormatter.format(
                payableWithReserve.paid_sum + values['decision_amount']
              )} will exceed limit of ${currencyFormatter.format(policyExposureLimit)}`}</Typography>
            </CardDialog>
          </>
        );
      }}
    </Formik>
  );
}

ReserveChangeApprovalCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  reserveChangeRequest: PropTypes.object.isRequired,
  currentReserve: PropTypes.number.isRequired,
  onDecideReserveChangeRequest: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  policyExposureLimit: PropTypes.number.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  legacySubReserves: PropTypes.arrayOf(PropTypes.string),
};

function ReserveChangeRequestDecisionCard(props) {
  const {
    reserveChangeRequest,
    claim,
    exposure,
    payableType,
    cardDialogProps,
    onClose,
    legacySubReserves,
    wasPaymentIssued,
  } = props;
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        ...reserveChangeRequest,
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const { values, isSubmitting } = formikProps;

        return (
          <CardDialog
            title={`${capitalize(payableType)} Reserve Change Decision`}
            width="450px"
            {...cardDialogProps}
            onClose={onClose}
          >
            <Typography>{`Decision made by: ${reserveChangeRequest.decider_user.username}`}</Typography>
            <div className={classes.inputContainer}>
              <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />
              {claim.policy.insured_contact && (
                <ContactShowOnlyTextField
                  contactId={claim.policy.insured_contact.id}
                  contactDisplayName={claim.policy.insured_contact.full_name}
                  label="Insured"
                />
              )}
            </div>
            <div className={classes.inputContainer}>
              {exposure.isGeneralExpenses ? 'General Expenses' : <ExposureDetailedTitle exposure={exposure} />}
            </div>
            <RequestAmountFormik
              id="request_amount"
              label="Reserve Requested"
              labelWithSubReserves="Reserve total requested"
              isSubmitting={isSubmitting}
              payableType={payableType}
              legacySubReserves={legacySubReserves}
              wasPaymentIssued={wasPaymentIssued}
              showOnly
              exposure={exposure}
            />
            <div className={classes.inputContainer}>
              <TextFieldFormik
                id="request_note"
                label="Adjuster's original note"
                rows="2"
                className={classes.textField}
                fullWidth
                multiline
                showOnly
              />
            </div>
            <div>
              <br />
            </div>
            <div>
              <Typography display="block">{`Decision: ${
                SUPERVISOR_APPROVAL_STATUS_DICT[values['request_status']].desc
              }`}</Typography>
            </div>
            <div>
              <MonetaryValueTextFieldFormik
                id="decision_amount"
                label="Reserve Approved"
                className={classes.textField}
                showOnly
              />
            </div>
            <div className={classes.inputContainer}>
              <TextFieldFormik
                id="decision_note"
                label="Decision Note"
                rows="2"
                className={classes.textField}
                fullWidth
                multiline
                showOnly
              />
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ReserveChangeRequestDecisionCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  reserveChangeRequest: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  legacySubReserves: PropTypes.arrayOf(PropTypes.string),
  wasPaymentIssued: PropTypes.bool,
};

export default ReserveChangeRequestContainer;
export { ReserveChangeRequestDynamicContainer };
