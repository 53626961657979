import React, { useMemo } from 'react';
import axios from 'axios';

import { useCmsEmbedded } from '~/components/AiChat/hooks/useCmsEmbedded';
import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { InvoiceExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/InvoiceAnalysis/InvoiceExecuteButton';
import { InvoiceRecommendationAnalysis } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis';
import type {
  CoverageDecisionType,
  InvoiceAnalysisResponse,
  LineItemProps,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import type { InvoiceAnalysisRecommendationTaskData } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import { reportAxiosError } from '~/Utils';

export const InvoiceAnalysisFooter: React.FC<ActionFooterProps> = ({ action, onExecute, isDone }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { applyInvoiceItem } = useCmsEmbedded();

  const { claim } = useClaim();

  const action_data = action.task_data as InvoiceAnalysisRecommendationTaskData;

  const coverages = (action_data.coverages || []).map(({ coverage_display_name, coverage_key }) => ({
    description: coverage_display_name,
    value: coverage_key,
  }));

  const coverageDecisionMap = useMemo(() => {
    return COVERAGE_DECISIONS.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, []);

  const coverageMap = useMemo(() => {
    return coverages.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, [coverages]);

  const handleAction = async (data: InvoiceAnalysisResponse, executeCallback = false) => {
    try {
      setIsSubmitting(true);
      await axios.post(
        `/api/v1/claims/${claim.id}/ai_recommendations/invoice_analysis/${action_data.analysis_results_id}/update_results`,
        { invoice_analysis: { ...data, task_id: action_data.task_id } }
      );
      setIsOpen(false);
      if (executeCallback) {
        onExecute();
      }
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex">
      <InvoiceExecuteButton onExecute={() => setIsOpen(true)} isDone={isDone} />
      <InvoiceRecommendationAnalysis
        disabled={isDone || isSubmitting}
        open={isOpen}
        dialogTitle={`Claim ${claim.claim_id_display}`}
        invoiceData={{
          date: action_data.invoice_date,
          number: action_data.invoice_number,
          vendor: action_data.vendor_name,
          total_amount: action_data.total_amount,
        }}
        lineItems={action_data.line_items.map(
          (item): LineItemProps => ({
            id: item.id,
            description: item.description,
            coverage_decision: coverageDecisionMap[item.coverage_decision || ''] ? item.coverage_decision : '',
            coverage: coverageMap[item.coverage_key || ''] ? item.coverage_key : '',
            amount: item.total_price,
            explanation: item.explanation,
          })
        )}
        coverages={coverages}
        coverageDecisions={COVERAGE_DECISIONS}
        filePath={action_data.file_path}
        onClose={() => {
          setIsOpen(false);
        }}
        onSubmit={async (data) => await handleAction(data, true)}
        onSave={handleAction}
        onApplyCoveredItem={async (item) => {
          await applyInvoiceItem({
            item,
            claimId: claim.id,
            analysisResultsId: action_data.analysis_results_id,
          });
        }}
      />
    </div>
  );
};

const COVERAGE_DECISIONS: { value: CoverageDecisionType; description: string }[] = [
  {
    value: 'covered',
    description: 'Covered',
  },
  {
    value: 'excluded',
    description: 'Excluded',
  },
  {
    value: 'pre_existing_condition',
    description: 'Pre-existing Condition',
  },
  {
    value: 'not_covered',
    description: 'Not Covered',
  },
];
