import { flattenDeep, union } from 'lodash';
import moment from 'moment';

import type { ContactFullModel } from '~/components/Contacts/types';
import type { ExposureModel, UserModel } from '~/components/exposures/types';
import { getDefaultReminderTarget } from '~/components/ReminderNotificationContainer';
import { isoDateToUs } from '~/DateTimeUtils';
import { capitalize } from '~/Utils';

import type { Communication, DocumentMetadata } from '../types';

import type { ChannelType, CommunicationIconSettings } from './constants';
import { channelTypeToErrorIconSettings, channelTypeToIconSettings } from './constants';
import type { PartialReminder } from './types';

export const calcExposureIds = (
  claim: { exposures: ExposureModel[] },
  user: { id: string },
  contact: ContactFullModel | undefined,
  attachedDocuments: DocumentMetadata[] = []
): number[] => {
  if (!claim) {
    return [];
  }
  const userRelatedExposureIds = claim.exposures
    .filter((exposure: { handling_adjuster_id: string }) => exposure.handling_adjuster_id === user.id)
    .map((exposure: { id: number }) => exposure.id);
  const contactExposureIds = contact ? contact.exposure_ids : [];
  const attachedDocumentsExposureIds = attachedDocuments
    ? flattenDeep(attachedDocuments.map((document: { exposure_ids: number[] }) => document.exposure_ids))
    : [];
  const exposureIdsCombined = union(userRelatedExposureIds, contactExposureIds, attachedDocumentsExposureIds);
  exposureIdsCombined.sort();
  return exposureIdsCombined;
};

export const getCommunicationReminderDefaultValues = (
  channel: ChannelType,
  contact: ContactFullModel | undefined,
  user: UserModel,
  claim: { exposures: ExposureModel[] }
): PartialReminder => {
  const channelDescription = capitalize(channel.replace('_', ' '));
  return {
    title: 'Follow up on Communication',
    details: `${channelDescription} was sent on ${isoDateToUs(new Date())}${contact ? ' to ' + contact.full_name : ''}`,
    due_date: moment().add(30, 'd'),
    target_id: claim ? getDefaultReminderTarget(user, claim) : -1, // -1 = Self
    was_manually_edited: false,
  };
};

export const getCommunicationIconSettings = (
  communication: Communication,
  channel: ChannelType
): CommunicationIconSettings => {
  const isDeliveryError = ['email', 'sms'].includes(channel) && communication?.is_delivery_issue_error_exists;
  const settings = isDeliveryError ? channelTypeToErrorIconSettings[channel] : channelTypeToIconSettings[channel];

  return settings;
};
