import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import useFormikChangeListener from '~/components/core/Formik/FormikChangeListener';

import { COUNTRIES_DICT } from '../Types';

import useGlobalAddresses from './hooks/useGlobalAddresses';
import AutocompleteFormik from './AutocompleteFormik';

function CountryAutocompleteFormik(props) {
  const {
    id,
    label = 'Country',
    onChange,
    countriesConfigurationKey,
    disabled = false,
    options,
    statesFieldIdToResetOnChange,
  } = props;
  const { countriesOptions } = useGlobalAddresses({ countriesConfigurationKey });
  const { setFieldValue } = useFormikContext();

  useFormikChangeListener({
    listenForKeys: id,
    onChange: () => {
      if (statesFieldIdToResetOnChange) {
        setFieldValue(statesFieldIdToResetOnChange, []);
      }
    },
    runOnFirstRender: false,
  });

  return (
    <AutocompleteFormik
      id={id}
      label={label}
      options={options || countriesOptions}
      getOptionLabel={(option) => COUNTRIES_DICT[option]}
      sortAlphabetic
      onChange={onChange}
      disabled={disabled}
    />
  );
}

CountryAutocompleteFormik.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  countriesConfigurationKey: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  statesFieldIdToResetOnChange: PropTypes.string,
};

export default CountryAutocompleteFormik;
