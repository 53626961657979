import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { AccountHardHat, Autorenew, CashUsd, Domain, Ticket, WrenchOutline } from 'mdi-material-ui';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import { MOR_OPTIONS_DICT } from '../../Types';
import { isInshurClaim, isQoverClaim, isTscClaim } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import { getAllSearchableContactRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import RadioButtonFormik from '../RadioButtonFormik';

import { useStyles } from '../../assets/styles';

const morFields = {
  method: '',
  contractor_id: '',
  body_shop_id: '',
  manual_vendor_id: '',
  manual_vendor: '',
  contact_id: '',
  contact: '',
};

const morValidationFields = {
  method: Yup.string().required('Method required'),
  contractor_id: Yup.number().when('method', {
    is: 'contractor_directed_repair',
    then: Yup.number().required('Required'),
  }),
  body_shop_id: Yup.number().when('method', {
    is: 'body_shop_directed_repair',
    then: Yup.number().required('Required'),
  }),
  manual_vendor_id: Yup.number().when('method', {
    is: 'managed_repair_manual',
    then: Yup.number().required('Required'),
  }),
};

function getCustomMorValidationFields(claimMorConfiguration) {
  return {
    method: Yup.string().required('Method required'),
    contact_id: Yup.number().when('method', (method, currSchema) =>
      currSchema.test('is-contact-required', 'Required', (contact_id) =>
        claimMorConfiguration.mor_options[method].contact_option ? !!contact_id : true
      )
    ),
  };
}

function getMorIcon(morMethod) {
  switch (morMethod) {
    case 'insured_directed_repair':
    case 'claimant_directed_repair':
      return CashUsd;
    case 'contractor_directed_repair':
    case 'body_shop_directed_repair':
      return AccountHardHat;
    case 'managed_repair_manual':
      return WrenchOutline;
    case 'rebuild':
    case 'replace':
      return Autorenew;
    case 'adverse_carrier_directed_repair':
      return Domain;
    case 'cash':
    case 'compensation_cash':
    case 'fb_credit':
    case 'room_comp':
    case 'gl_other':
    case 'pay_to_claimant':
    case 'pay_to_attorney':
      return CashUsd;
    case 'voucher':
      return Ticket;
    default:
      return CashUsd;
  }
}

function getMorIconComponent(morMethod) {
  const IconComponent = getMorIcon(morMethod);
  return <IconComponent />;
}

function MorCard(props) {
  const { cardDialogProps, exposure, mor, onSelectMor, onSelectNewMor, onCancel } = props;

  const classes = useStyles();
  const { claim } = useClaim();

  const { isLoading, isError, managedRepairStatus, morConfiguration, organizationContactRolesDict } = useOrganization();
  const isManagedRepairEnabled = !isLoading && !isError && managedRepairStatus.is_managed_repair_enabled;

  const claimMorConfiguration = morConfiguration[claim.type];

  function existingMorToFormikFields(mor) {
    let fields = { method: mor.method };

    if (claimMorConfiguration) {
      fields = { ...fields, contact_id: mor.contact?.id, contact_full_name: mor.contact?.full_name };
    }

    if (mor.method === 'contractor_directed_repair') {
      fields = { ...fields, contractor_id: mor.contact.id, contractor_full_name: mor.contact.full_name };
    }

    if (mor.method === 'body_shop_directed_repair') {
      fields = { ...fields, body_shop_id: mor.contact.id, body_shop_full_name: mor.contact.full_name };
    }

    if (mor.method === 'managed_repair_manual') {
      fields = { ...fields, manual_vendor_id: mor.contact.id, manual_vendor_full_name: mor.contact.full_name };
    }

    return fields;
  }

  const enableRebuildAndReplace = claim.type === 'home_claim';

  let initialValues = {};
  if (mor) {
    initialValues = existingMorToFormikFields(mor);
  } else {
    initialValues = { ...morFields };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(
        claimMorConfiguration ? getCustomMorValidationFields(claimMorConfiguration) : morValidationFields
      )}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSelectMor(values)
          .then(() => formikProps.resetForm())
          .catch(() => formikProps.setSubmitting(false));
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values } = formikProps;

        function getMorMethodRadioButton(morMethod) {
          const IconComponent = getMorIcon(morMethod);
          return (
            <RadioButtonFormik
              id="method"
              label={
                <span style={{ whiteSpace: 'nowrap' }} className={classes.containerCentered}>
                  <IconComponent className={classes.leftButtonIcon} />
                  {MOR_OPTIONS_DICT[morMethod].desc}
                </span>
              }
              optionValue={morMethod}
              disabled={!!mor}
            />
          );
        }

        function getCustomMorMethodRadioButton(morOption) {
          return (
            <RadioButtonFormik
              id="method"
              label={
                <span style={{ whiteSpace: 'nowrap' }} className={classes.containerCentered}>
                  {morOption.desc}
                </span>
              }
              optionValue={morOption.method}
              disabled={!!mor}
            />
          );
        }

        function getCustomMorMethodLine(morOption, isSelected) {
          return (
            <>
              <Grid item xs={7}>
                {getCustomMorMethodRadioButton(morOption)}
              </Grid>
              <Grid item xs={5}>
                {isSelected && morOption.contact_option && (
                  <ContactTextFieldFormik
                    id="contact"
                    label={morOption.contact_option.label}
                    disabled={!!mor}
                    acceptedRoles={
                      morOption.contact_option.accepted_roles ||
                      getAllSearchableContactRoles(organizationContactRolesDict)
                    }
                    fixedSearchResults
                    fullWidth
                  />
                )}
              </Grid>
            </>
          );
        }

        const glMorOptionsComponent = (
          <>
            {claim.type === 'gl_claim' && claim.subtype === 'event' && (
              <>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('compensation_cash')}
                </Grid>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('fb_credit')}
                </Grid>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('room_comp')}
                </Grid>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('gl_other')}
                </Grid>
              </>
            )}
            {claim.type === 'gl_claim' && claim.subtype === 'claim' && (
              <>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('pay_to_claimant')}
                </Grid>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('pay_to_attorney')}
                </Grid>
              </>
            )}
          </>
        );

        const homeAutoMorOptionsComponent = (
          <>
            <Grid item xs={12}>
              {(isTscClaim(claim) || isInshurClaim(claim)) && exposure.coverage_type === 'coverage_physical_damage'
                ? getMorMethodRadioButton('claimant_directed_repair')
                : getMorMethodRadioButton('insured_directed_repair')}
            </Grid>
            {(isTscClaim(claim) || isInshurClaim(claim)) &&
              exposure.coverage_type === 'coverage_physical_damage_subro' && (
                <Grid item xs={12}>
                  {getMorMethodRadioButton('adverse_carrier_directed_repair')}
                </Grid>
              )}
            {claim.type === 'home_claim' ? (
              <>
                <Grid item xs={7}>
                  {getMorMethodRadioButton('contractor_directed_repair')}
                </Grid>
                <Grid item xs={5}>
                  {values['method'] === 'contractor_directed_repair' && (
                    <ContactTextFieldFormik
                      id="contractor"
                      label="Contractor"
                      disabled={!!mor}
                      acceptedRoles={['contractor']}
                      fixedSearchResults
                      fullWidth
                    />
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={7}>
                  {getMorMethodRadioButton('body_shop_directed_repair')}
                </Grid>
                <Grid item xs={5}>
                  <ContactTextFieldFormik
                    id="body_shop"
                    label="Body Shop"
                    disabled={!!mor}
                    acceptedRoles={['body_shop']}
                    style={{ visibility: values['method'] === 'body_shop_directed_repair' ? 'visible' : 'hidden' }}
                    fixedSearchResults
                    fullWidth
                  />
                </Grid>
              </>
            )}
            {isManagedRepairEnabled && (
              <>
                <Grid item xs={7}>
                  {getMorMethodRadioButton('managed_repair_manual')}
                </Grid>
                <Grid item xs={5}>
                  <ContactTextFieldFormik
                    id="manual_vendor"
                    label="Select Vendor"
                    style={{ visibility: values['method'] === 'managed_repair_manual' ? 'visible' : 'hidden' }}
                    acceptedRoles={isQoverClaim(claim) ? ['expert', 'repairer', 'other'] : ['vendor']}
                    disabled={!!mor}
                    fixedSearchResults
                    fullWidth
                  />
                </Grid>
              </>
            )}
            {enableRebuildAndReplace && (
              <>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('rebuild')}
                </Grid>
                <Grid item xs={12}>
                  {getMorMethodRadioButton('replace')}
                </Grid>
                <div>
                  <ErrorHelperTextFormik id="method" />
                </div>
              </>
            )}
          </>
        );

        let morOptionsComponent;
        if (claimMorConfiguration) {
          morOptionsComponent = claimMorConfiguration.mor_options_order
            .filter(
              (k) =>
                !claimMorConfiguration.mor_options[k].coverage_specific ||
                claimMorConfiguration.mor_options[k].coverage_specific.includes(exposure.coverage_type)
            )
            .map((morOptionMethod) => (
              <React.Fragment key={morOptionMethod}>
                {getCustomMorMethodLine(
                  { method: morOptionMethod, ...claimMorConfiguration.mor_options[morOptionMethod] },
                  values['method'] === morOptionMethod
                )}
              </React.Fragment>
            ));
        } else {
          switch (claim.type) {
            case 'gl_claim':
              morOptionsComponent = glMorOptionsComponent;
              break;
            default:
              morOptionsComponent = homeAutoMorOptionsComponent;
          }
        }

        return (
          <CardDialog
            title="MOR"
            width="700px"
            fullWidth
            {...cardDialogProps}
            onClose={() => {
              formikProps.handleReset();
              onCancel();
            }}
          >
            {isLoading || isError ? (
              <LoadingIndicator isError={isError} />
            ) : (
              <>
                <Grid container alignItems="center" spacing={1}>
                  {morOptionsComponent}
                </Grid>
                {!mor ? (
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                      Select MOR
                    </Button>
                  </div>
                ) : (
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onSelectNewMor}>
                      Select another MOR
                    </Button>
                  </div>
                )}
              </>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

MorCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  mor: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSelectMor: requiredIf(PropTypes.func, (props) => !props.mor),
  onSelectNewMor: PropTypes.func,
};

export { getMorIcon, getMorIconComponent };
export default MorCard;
