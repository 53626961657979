import { getParamByISO } from 'iso-country-currency';
import moment from 'moment-timezone';

import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/nl-be';
import 'moment/dist/locale/en-au';

export let localeDetails = null;

export const updateOrganizationLocaleDetails = (organization) => {
  updateLocaleDetails(organization.configuration.locale?.toLowerCase());
};

export const updateLocaleDetails = (locale = 'en-us') => {
  let locale_obj = new Intl.Locale(locale);
  localeDetails = { locale: locale_obj, currency: getParamByISO(locale_obj.region, 'currency') };
  moment.locale(locale);
};
