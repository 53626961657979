import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';

import { AUTO_TICKETS_VIOLATION } from '../Types';

import { ErrorHelperTextFormik } from './core/Formik/ErrorHelperTextFormik';
import CardDialog from './CardDialog';
import { FsRadio } from './core';
import RadioButtonFormik from './RadioButtonFormik';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function getPoliceInvolvementFields(values) {
  return {
    were_police_involved: values.were_police_involved,
    police_department: values.police_department || '',
    police_report: values.police_report || '',
    did_speak_with_officer: values.did_speak_with_officer,
    was_ticket_received: values.was_ticket_received,
    violation_title: values.violation_title || '',
    police_crime_reference_number: values.police_crime_reference_number || '',
  };
}

function PoliceReportField(props) {
  const { policeReportDetails, onUpdate, disabled } = props;
  const [policeInvolvedDialogOpen, setPoliceInvolvedDialogOpen] = useState(false);

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={7}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            Were the Police Involved?
          </span>
        </Grid>
        <Grid item xs={5}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <FormControl>
              <FormControlLabel
                value=""
                control={
                  <FsRadio
                    color="primary"
                    checked={policeReportDetails && policeReportDetails.were_police_involved === false}
                    onChange={async () =>
                      await onUpdate({
                        ...policeInvolvedInitialValues,
                        were_police_involved: false,
                      })
                    }
                    disabled={disabled}
                    size="small"
                  />
                }
                label="No"
                labelPlacement="end"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                value=""
                control={
                  <FsRadio
                    color="primary"
                    checked={policeReportDetails && policeReportDetails.were_police_involved === true}
                    onChange={() => setPoliceInvolvedDialogOpen(true)}
                    onClick={() => setPoliceInvolvedDialogOpen(true)}
                    disabled={disabled}
                    size="small"
                  />
                }
                label="Yes >"
                labelPlacement="end"
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <PoliceReportInvolvedDialog
        open={policeInvolvedDialogOpen}
        onUpdate={async (values) => {
          await onUpdate({ ...values, were_police_involved: true });
          setPoliceInvolvedDialogOpen(false);
        }}
        onClose={() => setPoliceInvolvedDialogOpen(false)}
        policeInvolvedDetails={policeReportDetails}
      />
    </>
  );
}

PoliceReportField.propTypes = {
  policeReportDetails: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const policeInvolvedInitialValues = {
  police_department: '',
  police_report: '',
  did_speak_with_officer: '',
  was_ticket_received: '',
  violation_title: '',
};

const initialValuesByRegion = {
  GB: {
    police_crime_reference_number: '',
  },
};

function PoliceReportInvolvedDialog(props) {
  const { policeInvolvedDetails, open, onClose, onUpdate } = props;
  const classes = useStyles();

  const region = localeDetails.locale.region;
  const shouldShowPoliceCrimeReferenceNumber = region === 'GB';

  return (
    <Formik
      initialValues={{
        ...(initialValuesByRegion[region] || {}),
        ...policeInvolvedInitialValues,
        ...policeInvolvedDetails,
      }}
      validationSchema={Yup.object().shape({
        police_department: Yup.string().nullable().required('Required'),
        did_speak_with_officer: Yup.boolean().nullable().required('Required'),
        was_ticket_received: Yup.boolean()
          .nullable()
          .when('did_speak_with_officer', {
            is: true,
            then: Yup.boolean().required('Required'),
          }),
        violation_title: Yup.string()
          .nullable()
          .when('was_ticket_received', {
            is: true,
            then: Yup.string().required('Required'),
          }),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        await onUpdate(values);
        return formikProps.resetForm();
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;

        return (
          <CardDialog
            isDialog
            open={open}
            title="Police Involvement Details"
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="police_department"
                  label="Police Department"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="police_report"
                  label="Police Report"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting || !values['police_department']}
                />
              </Grid>
              {shouldShowPoliceCrimeReferenceNumber && (
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="police_crime_reference_number"
                    label="Crime Reference Number"
                    className={classes.textField}
                    fullWidth
                    disabled={isSubmitting}
                  />
                </Grid>
              )}
              <Grid item xs={8}>
                <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                  Did you speak to the officer?
                </span>
                <ErrorHelperTextFormik style={{ marginTop: 0 }} id="did_speak_with_officer" />
              </Grid>
              <Grid item xs={4}>
                <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                  <RadioButtonFormik
                    id="did_speak_with_officer"
                    label="No"
                    optionValue={false}
                    onChange={() => {
                      setFieldValue('did_speak_with_officer', false);
                      setFieldValue('was_ticket_received', '');
                      setFieldValue('violation_title', '');
                    }}
                  />
                  <RadioButtonFormik id="did_speak_with_officer" label="Yes" optionValue={true} />
                </div>
              </Grid>
              {values['did_speak_with_officer'] && (
                <>
                  <Grid item xs={8}>
                    <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                      Did you receive a ticket?
                    </span>
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="was_ticket_received" />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                      <RadioButtonFormik
                        id="was_ticket_received"
                        label="No"
                        optionValue={false}
                        onChange={() => {
                          setFieldValue('was_ticket_received', false);
                          setFieldValue('violation_title', '');
                        }}
                      />
                      <RadioButtonFormik id="was_ticket_received" label="Yes" optionValue={true} />
                    </div>
                  </Grid>
                </>
              )}
              {values['was_ticket_received'] && values['did_speak_with_officer'] && (
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="violation_title"
                    select
                    label="Violation Title"
                    className={classes.textField}
                    fullWidth
                  >
                    {AUTO_TICKETS_VIOLATION.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                </Grid>
              )}
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

PoliceReportInvolvedDialog.propTypes = {
  open: PropTypes.bool,
  policeInvolvedDetails: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { getPoliceInvolvementFields };
export default PoliceReportField;
