import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import { localeDetails } from '~/components/CmsMain/localeGlobals';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import {
  CONFIGURATION_FEATURES_NAMES,
  UK_VEHICLE_DAMAGED_CAR_PARTS_DICT,
  VEHICLE_DAMAGED_CAR_PARTS_DICT,
} from '~/Types';
import {
  isFeatureEnabled,
  isInshurClaim,
  isQoverClaim,
  isTscClaim,
  isXceedanceClaim,
  reportAxiosError,
  reportErrorInProductionOrThrow,
} from '~/Utils';
import { findInvolvedProperty, getThirdPartyVehiclesFromClaim } from '~/Utils/ClaimUtils';

import AdjusterSelectTextFieldFormik from '../../Adjuster/AdjusterSelectTextFieldFormik';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import SendEmailCommunicationCardContainer from '../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import ContactTextFieldFormik, { ContactShowOnlyTextField } from '../ContactTextFieldFormik';
import { FsMenuItem } from '../core';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { useCurrencyFormatter } from '../CurrencyFormatterContext';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import HoverChangeField from '../HoverChangeField';
import LoadingDialog from '../LoadingDialog';
import RadioButtonFormik from '../RadioButtonFormik';
import {
  MonetaryValueTextFieldFormik,
  MultiSelectTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
} from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

function RecoveryMenuItemContainer(props) {
  const { exposure, recoveryType, onItemClick, disabled } = props;

  const { claim } = useClaim();
  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();

  let disabledToolTipReason;
  if (
    recoveryType === 'salvage' &&
    claim.type === 'auto_claim' &&
    exposure.involved_property &&
    exposure.involved_property.type === 'involved_vehicle'
  ) {
    const involvedVehicle = findInvolvedProperty(claim, exposure.involved_property);
    if (!(involvedVehicle.is_total_loss === 'yes' || involvedVehicle.is_total_loss === true)) {
      disabledToolTipReason = 'Vehicle is not marked as total loss';
    }
  }

  return exposure[`is_${recoveryType}_exist`] ? (
    <FsMenuItem onClick={() => onItemClick('update')} disabled={disabled}>
      {capitalizedType} Status
    </FsMenuItem>
  ) : (
    <FsMenuItem
      disabled={!!disabledToolTipReason || disabled}
      onClick={() => onItemClick('assign')}
      tooltipText={disabledToolTipReason}
    >
      Assign {capitalizedType}
    </FsMenuItem>
  );
}

RecoveryMenuItemContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']).isRequired,
  disabled: PropTypes.bool,
};

function AssignNewRecoveryDialog(props) {
  const { exposure, recoveryType, onClose, onAssignRecovery } = props;

  const getEmptyEmailPreviewState = () => {
    return { sendEmailOpen: false, emailTextAndTitle: null, emailContact: null };
  };
  const [emailPreviewState, setEmailPreviewState] = useState(getEmptyEmailPreviewState());
  const { sendEmailOpen, emailTextAndTitle, emailContact } = emailPreviewState;
  const [recoveryId, setRecoveryId] = useState(undefined);

  const { claim, onAsyncClaimUpdate } = useClaim();

  const handleAssign = async (values) => {
    if (values.should_pursue === false) {
      try {
        await axios.post(
          `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}/mark_should_not_pursue`
        );
        await onAsyncClaimUpdate();
        onClose();
        return;
      } catch (error) {
        reportAxiosError(error);
        throw error;
      }
    }

    try {
      const shouldSendEmail =
        values.assignment_type === 'vendor' &&
        !(recoveryType === 'salvage' && (isTscClaim(claim) || isInshurClaim(claim))) &&
        values.vendor.emails.length > 0;
      let emailRes;

      if (shouldSendEmail) {
        emailRes = await axios.get(
          `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}/assignment_email_text`
        );
      }

      const res = await axios.post(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}`,
        values
      );
      setRecoveryId(res.data.recovery_id);

      if (shouldSendEmail) {
        setEmailPreviewState({ emailTextAndTitle: emailRes.data, emailContact: values.vendor, sendEmailOpen: true });
      } else {
        await onAsyncClaimUpdate();
        onAssignRecovery();
      }
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const connectRecoveryCommunication = async (recoveryType, communicationId) => {
    try {
      await axios.post(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}/${recoveryId}/connect_communication`,
        { communication_id: communicationId }
      );
    } catch (error) {
      reportAxiosError(error);
    }
  };

  return (
    <>
      <AssignRecoveryDialog
        claim={claim}
        onSubmit={handleAssign}
        onClose={onClose}
        recoveryType={recoveryType}
        title={`${recoveryType === 'subro' ? 'Subrogation' : 'Salvage'} Assignment`}
        disableSpecialist={recoveryType === 'salvage'}
        markNotPursue={exposure[`should_not_pursue_${recoveryType}`]}
      />
      {sendEmailOpen && (
        <SendEmailCommunicationCardContainer
          contact={emailContact}
          onClose={async () => {
            await onAsyncClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
            onClose();
          }}
          onSendEmail={async (communication) => {
            await connectRecoveryCommunication(recoveryType, communication.id);
            await onAsyncClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
            onAssignRecovery();
          }}
          emailTitle={emailTextAndTitle.email_title}
          emailText={emailTextAndTitle.email_body}
          summary={`Vendor ${emailContact.full_name} was assigned to perform recovery`}
          exposureIds={[exposure.id]}
        />
      )}
    </>
  );
}
AssignNewRecoveryDialog.propTypes = {
  onAssignRecovery: PropTypes.func,
  exposure: PropTypes.object,
  onClose: PropTypes.func,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']),
};

function RecoveryDialogContainer(props) {
  const { exposure, recoveryType, dialogType, onClose } = props;

  const [showAssignNewDialog, setShowAssignNewDialog] = useState(dialogType === 'assign');
  const [showUpdateNewDialog, setShowUpdateNewDialog] = useState(dialogType === 'update');
  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();

  const { claim, onAsyncClaimUpdate } = useClaim();

  const {
    isLoading,
    isError,
    data: recoveries,
    reloadData: reloadRecoveries,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}`);

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track={`Update ${capitalizedType} Status`} />;
  }

  const handleAddAmount = async (recoveryId, values) => {
    try {
      await axios.post(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}/${recoveryId}/recovery_amounts`,
        values
      );
      await onAsyncClaimUpdate();
      await reloadRecoveries();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdateDetails = async (recoveryId, values) => {
    try {
      await axios.patch(
        `/api/v1/claims/${claim.id}/exposures/${exposure.id}/recoveries/${recoveryType}/${recoveryId}`,
        values
      );
      await onAsyncClaimUpdate();
      await reloadRecoveries();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <>
      {showUpdateNewDialog && (
        <UpdateRecoveryDialog
          exposure={exposure}
          recoveries={recoveries}
          onClose={onClose}
          onAddAmount={handleAddAmount}
          onUpdateDetails={handleUpdateDetails}
          recoveryType={recoveryType}
          onAddRecoveryRequested={() => setShowAssignNewDialog(true)}
        />
      )}
      {showAssignNewDialog && (
        <AssignNewRecoveryDialog
          exposure={exposure}
          recoveryType={recoveryType}
          onClose={onClose}
          onAssignRecovery={async () => {
            await onAsyncClaimUpdate();
            await reloadRecoveries();
            setShowAssignNewDialog(false);
            setShowUpdateNewDialog(true);
          }}
        />
      )}
    </>
  );
}

RecoveryDialogContainer.propTypes = {
  exposure: requiredIf(PropTypes.object, (props) => props.open),
  onClose: requiredIf(PropTypes.func, (props) => props.open),
  recoveryType: requiredIf(PropTypes.oneOf(['subro', 'salvage']), (props) => props.open),
  dialogType: requiredIf(PropTypes.oneOf(['assign', 'update']), (props) => props.open),
};

const subroSpecificFields = {
  adverse_carrier_id: '',
};

const subroSpecificValidationFields = {};

const salvageSpecificFields = {
  salvage_parts: [],
  vendor_referral_number: '',
};

const salvageSpecificValidationFields = {};

const subroWithInvolvedSelectSpecificFields = {
  third_party_involved_id: '',
  ownerContactId: '',
  adverseCarrierId: '',
  adverseCarrierReferenceNumber: '',
};
const subroWithInvolvedSelectSpecificValidationFields = {
  third_party_involved_id: Yup.number().when(
    'should_pursue',
    (val, currSchema) => val && currSchema.required('Third party is required')
  ),
  ownerContactId: Yup.number().when(
    'third_party_involved_id',
    (id, currSchema) => id && currSchema.required('Third party is missing owner contact')
  ),
  adverseCarrierId: Yup.number().when(
    'third_party_involved_id',
    (id, currSchema) => id && currSchema.required('Third party is missing adverse carrier')
  ),
  adverseCarrierReferenceNumber: Yup.string().when(
    'third_party_involved_id',
    (id, currSchema) => id && currSchema.required('Third party is missing adverse carrier reference number')
  ),
};
function AssignRecoveryDialog(props) {
  const { claim, title, onSubmit, onClose, recoveryType, disableSpecialist, markNotPursue } = props;
  const classes = useStyles();
  const { userOrganization } = useCms();

  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();

  const subroAutoAssignmentFeatureEnabled =
    claim.lob === 'auto_claim' &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.SUBRO_ASSIGNMENT_INVOLVED_AUTO_FILL);

  const additionalFields =
    recoveryType === 'salvage'
      ? salvageSpecificFields
      : subroAutoAssignmentFeatureEnabled
      ? subroWithInvolvedSelectSpecificFields
      : subroSpecificFields;
  const additionalValidationFields =
    recoveryType === 'salvage'
      ? salvageSpecificValidationFields
      : subroAutoAssignmentFeatureEnabled
      ? subroWithInvolvedSelectSpecificValidationFields
      : subroSpecificValidationFields;

  return (
    <Formik
      initialValues={{
        should_pursue: markNotPursue ? false : '',
        anticipated_amount: '',
        assignment_type: '',
        vendor_id: '',
        handling_specialist_id: '',
        note: '',
        ...additionalFields,
      }}
      validationSchema={Yup.object().shape({
        should_pursue: Yup.bool().required('Required'),
        anticipated_amount: Yup.number()
          .nullable()
          .when('should_pursue', {
            is: (val) => val === true,
            then: Yup.number(), // .required('Required')
          }),
        assignment_type: Yup.string().when('should_pursue', {
          is: (val) => val === true,
          then: Yup.string().required('Required'),
        }),
        vendor_id: Yup.number().when('assignment_type', {
          is: (val) => val === 'vendor',
          then: Yup.number().required('Required'),
        }),
        handling_specialist_id: Yup.string().when('assignment_type', {
          is: (val) => val === 'internal_specialist',
          then: Yup.string().required('Required'),
        }),
        note: Yup.string(),
        ...additionalValidationFields,
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSubmit(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleReset, handleSubmit, setFieldValue } = formikProps;
        let shouldEmailBeSent = false;
        let wouldEmailBeSent = false;
        if (
          values.assignment_type === 'vendor' &&
          !(recoveryType === 'salvage' && (isTscClaim(claim) || isInshurClaim(claim)))
        ) {
          shouldEmailBeSent = values.vendor;
          wouldEmailBeSent = shouldEmailBeSent && values.vendor.emails && values.vendor.emails.length > 0;
        }

        const shouldPursue = values.should_pursue === true;

        const vehicle_damaged_car_parts_dict =
          localeDetails.locale.region === 'GB' ? UK_VEHICLE_DAMAGED_CAR_PARTS_DICT : VEHICLE_DAMAGED_CAR_PARTS_DICT;

        let vendorAcceptedRoles = ['vendor']; // default
        if (isQoverClaim(claim)) {
          vendorAcceptedRoles = ['reseller', 'expert', 'repairer', 'other'];
        } else if (isXceedanceClaim(claim)) {
          vendorAcceptedRoles = ['subrogation_service'];
        }

        return (
          <CardDialog
            isDialog={true}
            title={title}
            fullWidth
            maxWidth="sm"
            onClose={() => {
              handleReset();
              onClose();
            }}
            preventClose={isSubmitting}
          >
            <>
              <Grid container alignItems="center" spacing={1}>
                <Grid style={{ padding: 0 }} item xs={6}>
                  <span style={{ height: '80%', display: 'inline-flex', alignItems: 'center' }}>
                    {`Pursue ${capitalizedType}?`}
                  </span>
                </Grid>
                <Grid style={{ padding: 0 }} item xs={6}>
                  <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
                    <RadioButtonFormik
                      id="should_pursue"
                      label="No"
                      optionValue={false}
                      disabled={isSubmitting}
                      onChange={() => {
                        setFieldValue('assignment_type', '');
                        setFieldValue('should_pursue', false);
                        setFieldValue('third_party_involved_id', '');
                      }}
                    />
                    <RadioButtonFormik id="should_pursue" label="Yes" optionValue={true} disabled={isSubmitting} />
                    <ErrorHelperTextFormik style={{ marginTop: 0 }} id="should_pursue" />
                  </div>
                </Grid>
                {recoveryType === 'salvage' && claim.type === 'auto_claim' && (
                  <Grid item xs={12}>
                    <MultiSelectTextFieldFormik
                      id="salvage_parts"
                      label="Salvage Parts"
                      options={Object.keys(vehicle_damaged_car_parts_dict)}
                      renderValue={(selected) => selected.map((val) => vehicle_damaged_car_parts_dict[val]).join(', ')}
                      renderOption={(val) => vehicle_damaged_car_parts_dict[val]}
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting || !shouldPursue}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MonetaryValueTextFieldFormik
                    id="anticipated_amount"
                    label="Anticipated Amount"
                    className={classes.textField}
                    fullWidth
                    disabled={isSubmitting || !shouldPursue}
                  />
                </Grid>
                {!disableSpecialist && (
                  <>
                    <Grid item xs={5}>
                      <RadioButtonFormik
                        id="assignment_type"
                        label={`Internal ${capitalizedType} Specialist`}
                        onChange={() => {
                          setFieldValue('assignment_type', 'internal_specialist');
                          setFieldValue('vendor_id', '');
                        }}
                        optionValue="internal_specialist"
                        disabled={isSubmitting || !shouldPursue}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <AdjusterSelectTextFieldFormik
                        id="handling_specialist_id"
                        label="Handling Specialist"
                        className={classes.textField}
                        style={{
                          visibility: values['assignment_type'] === 'internal_specialist' ? 'visible' : 'hidden',
                        }}
                        fullWidth
                        disabled={isSubmitting || !shouldPursue}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={5}>
                  <RadioButtonFormik
                    id="assignment_type"
                    label={`Assign ${recoveryType === 'subro' ? 'subrogation' : 'salvage'} vendor`}
                    onChange={() => {
                      setFieldValue('assignment_type', 'vendor');
                      setFieldValue('vendor_id', '');
                    }}
                    optionValue="vendor"
                    disabled={isSubmitting || !shouldPursue}
                  />
                </Grid>
                <Grid item xs={7}>
                  <ContactTextFieldFormik
                    id="vendor"
                    label="Vendor"
                    acceptedRoles={vendorAcceptedRoles}
                    style={{ visibility: values['assignment_type'] === 'vendor' ? 'visible' : 'hidden' }}
                    fixedSearchResults
                    fullWidth
                    disabled={isSubmitting || !shouldPursue}
                  />
                  {isInshurClaim(claim) && recoveryType === 'salvage' && values['assignment_type'] === 'vendor' && (
                    <TextFieldFormik
                      id="vendor_referral_number"
                      label="Vendor's Referral Number"
                      className={classes.textField}
                      fullWidth
                      disabled={isSubmitting || !shouldPursue}
                    />
                  )}
                </Grid>
              </Grid>
              <ErrorHelperTextFormik style={{ marginTop: 0 }} id="assignment_type" />
              <Typography
                display="block"
                variant="caption"
                align="right"
                style={{ color: '#e65100', visibility: shouldEmailBeSent ? 'visible' : 'hidden' }}
              >
                {wouldEmailBeSent
                  ? 'Assignment email will be presented for review'
                  : 'To generate an Assignment email - add an email address to this contact'}
              </Typography>
              {recoveryType === 'subro' && !subroAutoAssignmentFeatureEnabled && (
                <ContactTextFieldFormik
                  id="adverse_carrier"
                  label="Adverse Carrier"
                  acceptedRoles={['insurer']}
                  fixedSearchResults
                  fullWidth
                  disabled={isSubmitting || !shouldPursue}
                />
              )}
              {recoveryType === 'subro' && subroAutoAssignmentFeatureEnabled && <SubroSelectFromInvolvedProperties />}
              <TextFieldFormik
                id="note"
                label="Recovery Note"
                className={classes.textField}
                fullWidth
                disabled={isSubmitting || !shouldPursue}
              />
              <div className={classes.buttonsContainer}>
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                  Assign {recoveryType}
                </Button>
              </div>
            </>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AssignRecoveryDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']).isRequired,
  disableSpecialist: PropTypes.bool,
  title: PropTypes.string.isRequired,
  markNotPursue: PropTypes.bool,
};

const getPropertyInvolvedPropertiesRequiredFields = (involvedProperty) => ({
  id: involvedProperty.id,
  displayName: involvedProperty.display_name,
  ownerContactId: involvedProperty.owner_contact_id || '',
  adverseCarrierId: involvedProperty.insurer_contact_id || '',
  adverseCarrierReferenceNumber: involvedProperty.insurer_reference_number || '',
});

const getInvolvedPropertiesForDropdown = (claim) => {
  const involvedProperties = {};
  getThirdPartyVehiclesFromClaim(claim).forEach(({ involved_vehicle: vehicle }) => {
    if (vehicle) {
      involvedProperties[vehicle.id] = getPropertyInvolvedPropertiesRequiredFields(vehicle);
    }
  });

  claim.incident.other_properties_parties.forEach(({ generic_property_involved: property }) => {
    if (property) {
      involvedProperties[property.id] = getPropertyInvolvedPropertiesRequiredFields(property);
    }
  });

  return involvedProperties;
};

const involvedPropertiesRequiredFields = ['ownerContactId', 'adverseCarrierId', 'adverseCarrierReferenceNumber'];

function SubroSelectFromInvolvedProperties() {
  const { values, isSubmitting, setFieldValue, setFieldTouched } = useFormikContext();
  const [thirdParties, setThirdParties] = useState();
  const classes = useStyles();
  const { claim } = useClaim();

  const shouldPursue = values.should_pursue === true;

  useEffect(() => {
    claim && setThirdParties(getInvolvedPropertiesForDropdown(claim));
  }, [claim]);

  useEffect(() => {
    if (values.third_party_involved_id) {
      involvedPropertiesRequiredFields.forEach((key) => {
        setFieldValue(key, thirdParties[values.third_party_involved_id][key]);
        setFieldTouched(key, true);
      });
    } else {
      involvedPropertiesRequiredFields.forEach((key) => {
        setFieldValue(key, '');
        setFieldTouched(key, false);
      });
    }
  }, [values.third_party_involved_id, thirdParties, setFieldValue, setFieldTouched]);

  return thirdParties ? (
    <>
      <TextFieldFormik
        id="third_party_involved_id"
        select
        label="Third Party"
        fullWidth
        className={classes.textField}
        disabled={isSubmitting || !shouldPursue}
      >
        {Object.keys(thirdParties).map((tpId) => (
          <MenuItem key={tpId} value={tpId}>
            {thirdParties[tpId]?.displayName}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {involvedPropertiesRequiredFields.map((key) => (
        <ErrorHelperTextFormik key={`${key}_error`} style={{ marginTop: 0 }} id={key} />
      ))}
    </>
  ) : null;
}

function AddRecoveryAmountDialog(props) {
  const { onAddAmount, recoveryType, onClose } = props;
  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <Formik
      initialValues={{
        amount: '',
        check_number: '',
        check_note: '',
      }}
      validationSchema={Yup.object({
        amount: Yup.number()
          .required('Required if recovery not complete')
          .min(0.01, `Must be at least ${currencyFormatter.format(0.01)}`), // add use currency
        check_number: Yup.string(),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onAddAmount(values);
        } catch (error) {
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        return (
          <CardDialog
            title={`Add ${capitalizedType} Amount`}
            isDialog
            onClose={onClose}
            preventClose={formikProps.isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik id="amount" label="Amount" className={classes.textField} fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik id="check_number" label="Check Number" disabled={formikProps.isSubmitting} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik id="check_note" label="Check note" className={classes.textField} fullWidth />
              </Grid>
              <div className={classes.buttonsContainer}>
                <Button
                  disabled={formikProps.isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={formikProps.handleSubmit}
                >
                  Add
                </Button>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

AddRecoveryAmountDialog.propTypes = {
  onAddAmount: PropTypes.func.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']).isRequired,
  onClose: PropTypes.func.isRequired,
};

function RecoverySummary(props) {
  const { recovery, onAddAmount, onUpdateDetails, recoveryType } = props;
  const classes = useStyles();
  const { claim } = useClaim();
  const { userOrganization } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();

  const [showAddAmount, setShowAddAmount] = React.useState(false);

  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();

  const subroAutoAssignmentFeatureEnabled =
    claim.lob === 'auto_claim' &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.SUBRO_ASSIGNMENT_INVOLVED_AUTO_FILL);

  const handleAddAmount = async (values) => {
    await onAddAmount(recovery.id, values);
    setShowAddAmount(false);
  };

  return (
    <div className={classes.cardDivRow}>
      <CardDialog
        title={
          <>
            {recovery.is_complete ? 'Completed' : 'Active'} {capitalizedType} Recovery
            <span style={{ paddingLeft: 16 }}>
              <Button
                size="small"
                variant="outlined"
                color={recovery.is_complete ? 'secondary' : 'primary'}
                className={classes.inputContainer}
                onClick={() => onUpdateDetails(recovery.id, { is_complete: !recovery.is_complete })}
              >
                {`${recovery.is_complete ? 'Re-Open' : 'Complete'} Recovery`}
              </Button>
            </span>
          </>
        }
        titleVariant="subtitle1"
        contentStyle={{ padding: '0px 16px 16px 16px' }}
        outlinedCard
      >
        <Grid container>
          {recoveryType === 'subro' && !subroAutoAssignmentFeatureEnabled && (
            <Grid item xs={12}>
              <HoverChangeField
                name="adverse_carrier_id"
                customFieldIdName="adverse_carrier"
                value={recovery.adverse_carrier_id || ''}
                label="Adverse Carrier"
                onUpdate={async (values) => await onUpdateDetails(recovery.id, values)}
                specialFieldType="contact"
                specialFieldAdditionalProps={{ acceptedRoles: ['insurer'] }}
                overrideOnEdit
                showOnly
              >
                <ContactShowOnlyTextField
                  contactId={recovery.adverse_carrier_id}
                  contactDisplayName={recovery.adverse_carrier_full_name}
                  label="Adverse Carrier"
                  showOnly
                />
              </HoverChangeField>
            </Grid>
          )}
          {recoveryType === 'subro' && subroAutoAssignmentFeatureEnabled && (
            <>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={recovery.third_party_involved_display_name}
                  label="Third party"
                />
              </Grid>
              <Grid item xs={6}>
                <ContactShowOnlyTextField
                  contactId={recovery.third_party_involved_adverse_carrier_id}
                  contactDisplayName={recovery.third_party_involved_adverse_carrier_full_name}
                  label="Adverse Carrier"
                  showOnly
                />
              </Grid>
              <Grid item xs={6}>
                <ContactShowOnlyTextField
                  contactId={recovery.third_party_involved_adverse_carrier_owner_id}
                  contactDisplayName={recovery.third_party_involved_adverse_carrier_owner_full_name}
                  label="Third party owner"
                  showOnly
                />
              </Grid>
              <Grid item xs={6}>
                <ShowOnlyTextField
                  classes={classes}
                  showOnlyValueComponent={recovery.third_party_involved_adverse_carrier_reference_number}
                  label="Adverse carrier reference number"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            {recovery.vendor_id && ( // Vendor Recovery
              <Grid container>
                <Grid item xs={6}>
                  <ShowOnlyTextField
                    classes={classes}
                    showOnlyValueComponent={recovery.vendor_full_name}
                    label="Vendor"
                  />
                </Grid>
                <Grid item xs={6}>
                  <HoverChangeField
                    name="vendor_referral_number"
                    value={recovery.vendor_referral_number || ''}
                    label="Vendor's Referral Number"
                    onUpdate={async (values) => await onUpdateDetails(recovery.id, values)}
                  >
                    <ShowOnlyTextField
                      classes={classes}
                      label="Vendor's Referral Number"
                      showOnlyValueComponent={recovery.vendor_referral_number || ''}
                    />
                  </HoverChangeField>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <AnticipatedAmountContainer recovery={recovery} onUpdateAnticipatedAmount={onUpdateDetails} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              gutterBottom
              display="block"
              variant="body2"
            >{`${capitalizedType} Recovered: ${currencyFormatter.format(recovery.received_sum)}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <HoverChangeField
              name="note"
              value={recovery.note}
              label="Recovery Note"
              onUpdate={async (updatedValues) => await onUpdateDetails(recovery.id, updatedValues)}
              fullWidth
              multiline
              rows={5}
              width="450px"
              showOnly
            >
              <ShowOnlyTextField
                classes={classes}
                showOnlyValueComponent={recovery.note}
                label="Recovery Note"
                maxHeight="5.3em" // ~ 3 line
                width="100%"
              />
            </HoverChangeField>
          </Grid>
          {!recovery.is_complete && (
            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={recovery.is_complete}
                  onClick={() => setShowAddAmount(true)}
                >
                  Add recovered amount
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
        {showAddAmount && (
          <AddRecoveryAmountDialog
            onAddAmount={handleAddAmount}
            recoveryType={recoveryType}
            onClose={() => setShowAddAmount(false)}
          />
        )}
      </CardDialog>
    </div>
  );
}
RecoverySummary.propTypes = {
  recovery: PropTypes.object.isRequired,
  onAddAmount: PropTypes.func.isRequired,
  onUpdateDetails: PropTypes.func.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']).isRequired,
};

function UpdateRecoveryDialog(props) {
  const { recoveries, exposure, onAddAmount, onClose, onUpdateDetails, recoveryType, onAddRecoveryRequested } = props;
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();
  const capitalizedType = recoveryType[0].toUpperCase() + recoveryType.slice(1).toLowerCase();

  return (
    <CardDialog
      isDialog={true}
      open={open}
      title={`${capitalizedType} Status`}
      fullWidth
      maxWidth="md"
      onClose={() => {
        onClose();
      }}
    >
      <div>
        <Typography display="block">{exposure.label}</Typography>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" display="block">{`Indemnity: ${currencyFormatter.format(
            exposure.indemnity.paid_sum
          )}`}</Typography>
          <Typography variant="body1" display="block">{`Total Salvage Recovered: ${currencyFormatter.format(
            exposure.salvage_recoveries_sum
          )}`}</Typography>
          <Typography variant="body1" display="block">{`Total Subro Recovered: ${currencyFormatter.format(
            exposure.subro_recoveries_sum
          )}`}</Typography>
          <Typography variant="body1" display="block">{`Total Anticipated Amount: ${currencyFormatter.format(
            recoveries.reduce((sum, currRecovery) => sum + currRecovery.anticipated_amount, 0)
          )}`}</Typography>
        </Grid>
        {recoveries.map((recovery) => (
          <Grid item xs={12} key={recovery.id}>
            <RecoverySummary
              recovery={recovery}
              exposure={exposure}
              onAddAmount={onAddAmount}
              onUpdateDetails={onUpdateDetails}
              recoveryType={recoveryType}
            />
          </Grid>
        ))}
      </Grid>
      {recoveryType === 'subro' && (
        <div className={classes.buttonsContainer}>
          <Button onClick={onAddRecoveryRequested}>
            <AddIcon />
            {`Add new ${recoveryType} recovery`}
          </Button>
        </div>
      )}
    </CardDialog>
  );
}

UpdateRecoveryDialog.propTypes = {
  recoveries: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onAddAmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateDetails: PropTypes.func.isRequired,
  recoveryType: PropTypes.oneOf(['subro', 'salvage']).isRequired,
  onAddRecoveryRequested: PropTypes.func,
};

function AnticipatedAmountContainer(props) {
  const { recovery, onUpdateAnticipatedAmount } = props;
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const { currencyFormatter } = useCurrencyFormatter();

  const classes = useStyles();

  return (
    <>
      <HoverActionField onAction={(e) => setPopoverAnchorEl(e.currentTarget)}>
        <Typography display="block" variant="body2">{`Anticipated Amount: ${
          recovery.anticipated_amount ? currencyFormatter.format(recovery.anticipated_amount) : ''
        }`}</Typography>
      </HoverActionField>
      {popoverAnchorEl && (
        <Formik
          initialValues={{
            anticipated_amount: '',
            anticipated_amount_note: '',
          }}
          validationSchema={Yup.object({
            anticipated_amount: Yup.number().required('Required'),
          })}
          onSubmit={async (values, formikProps) => {
            try {
              await onUpdateAnticipatedAmount(recovery.id, values);
              setPopoverAnchorEl(undefined);
            } catch {
              formikProps.setSubmitting(false);
            }
          }}
        >
          {(formikProps) => {
            return (
              <CardDialog
                title="Update Anticipated Amount"
                isPopover
                open
                anchorEl={popoverAnchorEl}
                onClose={() => setPopoverAnchorEl(undefined)}
                preventClose={formikProps.isSubmitting}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik
                      id="anticipated_amount"
                      label="Anticipated Amount"
                      fullWidth
                      disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="anticipated_amount_note"
                      label="Reason"
                      fullWidth
                      disabled={formikProps.isSubmitting}
                    />
                  </Grid>
                  <div className={classes.buttonsContainer}>
                    <Button
                      disabled={formikProps.isSubmitting}
                      variant="contained"
                      color="primary"
                      onClick={formikProps.handleSubmit}
                    >
                      Update
                    </Button>
                  </div>
                </Grid>
              </CardDialog>
            );
          }}
        </Formik>
      )}
    </>
  );
}

AnticipatedAmountContainer.propTypes = {
  recovery: PropTypes.object.isRequired,
  onUpdateAnticipatedAmount: PropTypes.func.isRequired,
};

function RecoveryStatus(props) {
  const { isRecoveryExist, shouldNotPursue, isRecoveryPending, recoverySum, recoveryTypeShorthand } = props;
  const { currencyFormatter } = useCurrencyFormatter();

  const attentionColor = 'text-status-failed';
  const completeColor = 'text-status-done';
  const colorClassName = isRecoveryPending ? attentionColor : completeColor;

  if (shouldNotPursue) {
    return (
      <Typography variant="body2" color="textSecondary">
        {recoveryTypeShorthand}: mark not pursue
      </Typography>
    );
  } else if (!isRecoveryExist) {
    // No recovery
    return <></>;
  } else if (!isRecoveryPending && recoverySum === 0) {
    //Recovery complete with no recovered sum
    return (
      <Typography variant="body2" className={colorClassName}>
        {recoveryTypeShorthand}: Complete
      </Typography>
    );
  } else if (isRecoveryPending && recoverySum === 0) {
    // Recovery Pending with no recovered sum
    return (
      <Typography variant="body2" className={colorClassName}>
        {recoveryTypeShorthand}: Pending
      </Typography>
    );
  } else if (recoverySum > 0) {
    // Recovery complete/pending with recovered syn
    return (
      <Typography variant="body2" className={colorClassName}>
        {recoveryTypeShorthand}: {currencyFormatter.format(recoverySum)}
      </Typography>
    );
  } else {
    reportErrorInProductionOrThrow('Should not happen unknown recovery status');
    return <></>;
  }
}

RecoveryStatus.propTypes = {
  isRecoveryPending: PropTypes.bool.isRequired,
  isRecoveryExist: PropTypes.bool.isRequired,
  shouldNotPursue: PropTypes.bool.isRequired,
  recoverySum: PropTypes.number.isRequired,
  recoveryTypeShorthand: PropTypes.string.isRequired,
};

function RecoveryStatusContainer(props) {
  const { exposure } = props;

  return (
    <>
      <RecoveryStatus
        recoveryTypeShorthand="Subro"
        isRecoveryExist={exposure.is_subro_exist}
        shouldNotPursue={exposure.should_not_pursue_subro}
        isRecoveryPending={exposure.is_subro_active}
        recoverySum={exposure.subro_recoveries_sum}
      />
      <RecoveryStatus
        recoveryTypeShorthand="Slvg"
        isRecoveryExist={exposure.is_salvage_exist}
        shouldNotPursue={exposure.should_not_pursue_salvage}
        isRecoveryPending={!!exposure.is_salvage_active}
        recoverySum={exposure.salvage_recoveries_sum}
      />
    </>
  );
}

RecoveryStatusContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export { RecoveryDialogContainer, RecoveryStatusContainer };
export default RecoveryMenuItemContainer;
