import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const ChevronDownIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 25 25" fill="none" iconColor={iconColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70209 8.46967C4.99498 8.17678 5.46986 8.17678 5.76275 8.46967L12.7324 15.4393L19.7021 8.46967C19.995 8.17678 20.4699 8.17678 20.7628 8.46967C21.0556 8.76256 21.0556 9.23744 20.7628 9.53033L13.2628 17.0303C12.9699 17.3232 12.495 17.3232 12.2021 17.0303L4.70209 9.53033C4.4092 9.23744 4.4092 8.76256 4.70209 8.46967Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default ChevronDownIcon;
