import React from 'react';

import cn from '~/Utils/cn';

export const ChatDialogHeaderContainer: React.FC<{ isCollapsed: boolean; className?: string }> = ({
  children,
  className,
  isCollapsed,
}) => (
  <div
    className={cn('__HEADER__ max-h-1/2 bg-aiChat bg-cover bg-no-repeat', {
      'h-min': isCollapsed,
      'mih-1/3 f-min': !isCollapsed,
      className,
    })}
  >
    <div className="h-full w-full bg-aiV2-darkBlue bg-opacity-30">
      <div
        className={cn('__HEADER_OVERLAY_ flex h-full flex-col gap-20 bg-cliveGradiant bg-blend-lighten', {
          'gap-32': isCollapsed,
        })}
      >
        {children}
      </div>
    </div>
  </div>
);
