import React from 'react';
import PropTypes from 'prop-types';

import { FORMIK_DETAILS_FIELD_IDS } from '~/components/Contacts/UpsertContact/constants';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { TextFieldFormik } from '~/components/TextFieldFormik';
import UsStatesSelectTextFieldFormik from '~/components/UsStatesSelectTextFieldFormik';

import { getFullDetailsTabFieldPath } from '../../utils';

const Attorney = ({ disabled }) => {
  const { currentRole, currentCountry } = useContact();

  if (currentRole !== 'attorney') {
    return null;
  }
  const isUsContact = currentCountry === 'US';
  const isUkContact = currentCountry === 'GB';

  return (
    <div className="grid grid-cols-2 gap-20">
      {isUsContact ? (
        <UsStatesSelectTextFieldFormik
          id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_STATE)}
          label="State Bar"
          fullWidth
          disabled={disabled}
        />
      ) : null}
      <TextFieldFormik
        id={getFullDetailsTabFieldPath(FORMIK_DETAILS_FIELD_IDS.ATTORNEY_BAR_NUMBER)}
        label={`${isUkContact ? 'SRA' : 'Bar'} Number`}
        fullWidth
        disabled={disabled}
      />
    </div>
  );
};

Attorney.propTypes = {
  disabled: PropTypes.bool,
};

export default Attorney;
