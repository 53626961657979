import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment-timezone';

import Typography from '~/components/core/Atomic/Typography';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';

import { isoDateToUs, serverDateToLocal } from '../../DateTimeUtils';
import { isHospitalityUser, isMgmUser, isRwUser, reportAxiosError, stringCmp } from '../../Utils';
import { getLobIcon } from '../../Utils/lobUtils';
import { useClaim } from '../ClaimContainer';
import ClaimLink from '../ClaimLink';
import { ContactEntity } from '../Contact';
import { FsIconButton, SortableTable, TooltipIcon } from '../core';
import { PAGINATION_LOCATION } from '../core/Tables/SortableTable/utils';
import { useCms } from '../hooks/useCms';
import { DismissIcon, ThreeDotsHorizontalIcon } from '../icons';

import { getClaimNotificationIcon } from './ClaimNotificationCardUtils';
import { ViewClaimNotificationCard } from './ViewClaimNotificationCard';

import { colorPalette, useStyles } from '../../assets/styles';

function compareNotificationType(notification1, notification2) {
  // lower number will appear first. For now all communication types are group together without inner order
  const orderByType = {
    internal_communication_claim_notification: 1,
    financial_request_decision_claim_notification: 2,
    claim_owner_change_claim_notification: 3,
    communication_claim_notification: 4,
    reminder_claim_notification: 5,
  };
  return orderByType[notification2.type] - orderByType[notification1.type];
}

function adjustColumnForMgm(columnData) {
  let mgmColumnData = columnData.filter((column) => column.id !== 'label_text');
  mgmColumnData = mgmColumnData.map((column) => {
    switch (column.id) {
      case 'insured_contact_full_name':
        return {
          id: 'claimant_contact_full_name',
          numeric: false,
          disablePadding: false,
          label: 'Claimant',
          disableSort: true,
          specialCell: (claimNotification) =>
            claimNotification.claimant_contact_id ? (
              <ContactEntity
                inline
                contactId={claimNotification.claimant_contact_id}
                contactDisplayName={claimNotification.claimant_contact_full_name}
              />
            ) : null,
          specialCmpFunc: (claimNotification1, claimNotification2) =>
            stringCmp(
              claimNotification1.claimant_contact_full_name || '',
              claimNotification2.claimant_contact_full_name || ''
            ),
        };
      default:
        return column;
    }
  });

  return mgmColumnData;
}

function adjustColumnByUser(user, columnData) {
  let newColumnData = columnData;
  if (isMgmUser(user) || isRwUser(user) || isHospitalityUser(user)) {
    newColumnData = adjustColumnForMgm(columnData);
  }
  return newColumnData;
}

export function ClaimNotificationsTable({
  claimNotifications,
  exposuresDict,
  onNotificationUpdate,
  userContext,
  showRecipientUser,
  showDateDismissed,
  showDueDate,
  showExposure,
  showInsuredName,
  showNotificationDate,
  maxHeight,
  paginationProps,
  onSortByColumn,
  disableSort,
  openClaimInNewTab,
  defaultOrderColumnName,
  defaultSortOrder,
  paginationLocation = PAGINATION_LOCATION.TOP,
  showSubOrg,
  showDismissedBy,
}) {
  const classes = useStyles();
  const [claimNotificationToShowId, setClaimNotificationToShowId] = React.useState(undefined);
  const [notificationIdsSubmitting, setNotificationIdsSubmitting] = React.useState([]);
  const { isLoading: isLoadingLobConfiguration, lobConfigurationsDict } = useLobConfiguration();

  const { claim: claimInContext, onClaimUpdate } = useClaim(); // might not be in claim context => claim === onClaimUpdate === undefined
  const { user } = useCms();

  function updateClaimIfInContext() {
    if (claimInContext) {
      return onClaimUpdate();
    } else {
      return Promise.resolve();
    }
  }

  const handleUpdate = () => {
    updateClaimIfInContext().then(() => onNotificationUpdate(paginationProps?.page));
  };

  async function changeClaimNotificationState(claimNotification) {
    const action = claimNotification.datetime_dismissed ? 'undismiss' : 'dismiss';
    setNotificationIdsSubmitting((prev) => [...prev, claimNotification.id]);

    const notificationsResourceLocation = claimNotification.claim_id
      ? `${claimNotification.claim_id}/notifications`
      : 'claims_notifications';

    try {
      await axios.post(`/api/v1/claims/${notificationsResourceLocation}/${claimNotification.id}/${action}`);
      await onNotificationUpdate(paginationProps?.page);
    } catch (error) {
      await reportAxiosError(error);
    }
  }

  React.useEffect(() => {
    setNotificationIdsSubmitting([]);
  }, [claimNotifications]);

  function claimNotificationClaimTypeIcon(claimNotification) {
    if (!claimNotification.claim_id || !claimNotification.claim_lob || isLoadingLobConfiguration) {
      return null;
    }

    return getLobIcon({ lob: claimNotification.claim_lob, lobConfigurationsDict });
  }

  const extractNotificationRecipient = (claimNotification) => {
    let primary = '';
    let secondary = '';
    switch (claimNotification.recipient_reason) {
      case 'claim':
        primary = 'Claim';
        secondary = claimNotification.recipient_user;
        break;
      case 'exposure':
        primary = exposuresDict[claimNotification.exposure_id]?.label_text;
        secondary = claimNotification.recipient_user;
        break;
      case 'organization_unit_leader':
        primary = 'Unit Leader';
        secondary = claimNotification.organization_unit_name;
        break;
      case 'user':
        primary = claimNotification.recipient_user;
        break;
      default:
        throw Error(`Unknown recipient_reason: ${claimNotification.recipient_reason}`);
    }
    return { primary, secondary };
  };

  const getClaimNotificationDateStr = (claimNotification) => {
    const date = getClaimNotificationDate(claimNotification);
    return claimNotification.type === 'reminder_claim_notification' ? isoDateToUs(date) : serverDateToLocal(date);
  };

  const getClaimNotificationDate = (claimNotification) =>
    claimNotification.type === 'reminder_claim_notification' ? claimNotification.due_date : claimNotification.datetime;

  function compareNotificationDate(notification1, notification2) {
    const date1 = getClaimNotificationDate(notification1);
    const date2 = getClaimNotificationDate(notification2);

    return moment.utc(date2) > moment.utc(date1);
  }

  const isRestrictedPredicate = (row) => row.is_restricted;

  let columnData = [
    {
      id: 'dismiss_action',
      label: '',
      width: 25,
      disableSort: true,
      specialCell: (claimNotification) => (
        <FsIconButton
          tooltipText={claimNotification.datetime_dismissed ? 'Undismiss' : 'Dismiss'}
          size="small"
          onClick={() => changeClaimNotificationState(claimNotification)}
          disabled={notificationIdsSubmitting.includes(claimNotification.id)}
        >
          {claimNotification.datetime_dismissed ? (
            <DismissIcon iconColor={colorPalette.success} className={classes.hoverableIcon} />
          ) : (
            <DismissIcon className={classes.hoverableIcon} />
          )}
        </FsIconButton>
      ),
    },
    {
      id: 'notification_date',
      label: 'Date',
      specialCmpFunc: compareNotificationDate,
      isHidden: !showNotificationDate,
    },
    {
      id: 'type',
      label: 'Type',
      width: '15px',
      specialCell: (claimNotification) => (
        <div style={{ display: 'flex' }}>{getClaimNotificationIcon(claimNotification)}</div>
      ),
      specialCmpFunc: compareNotificationType,
    },
    {
      id: 'sub_organization',
      label: 'Sub-Organization',
      width: '15px',
      specialCell: (claimNotification) => (
        <div style={{ display: 'flex' }}>{claimNotification.sub_organization_name}</div>
      ),
      specialCmpFunc: compareNotificationType,
      isHidden: !showSubOrg,
    },
    {
      id: 'claim_id',
      width: '200px',
      label: 'Claim',
      specialCell: (claimNotification) =>
        claimNotification.claim_id ? (
          <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            {claimNotificationClaimTypeIcon(claimNotification)}
            <span style={{ paddingLeft: '6px' }}>
              <ClaimLink
                claimId={claimNotification.claim_id}
                linkText={claimNotification.claim_id_display}
                openInNewTab={openClaimInNewTab}
              />
            </span>
          </div>
        ) : null,
      isHidden: !userContext,
    },
    {
      id: 'label_text',
      label: 'Exposure',
      disableSort: true,
      specialCell: (claimNotification) =>
        claimNotification.exposure_id ? (
          <ExposureDetailedTitle exposure={exposuresDict[claimNotification.exposure_id]} />
        ) : null,
      isHidden: !showExposure,
      hideRestrictedContent: true,
    },
    {
      id: 'insured_contact_full_name',
      label: 'Insured Name',
      disableSort: true,
      specialCell: (claimNotification) =>
        claimNotification.insured_contact_id ? (
          <ContactEntity
            inline
            contactId={claimNotification.insured_contact_id}
            contactDisplayName={claimNotification.insured_contact_full_name}
          />
        ) : null,
      specialCmpFunc: (claimNotification1, claimNotification2) =>
        stringCmp(
          claimNotification1.insured_contact_full_name || '',
          claimNotification2.insured_contact_full_name || ''
        ),
      hideRestrictedContent: true,
    },
    {
      id: 'datetime_dismissed',
      label: 'Date Dismissed',
      specialCell: (claimNotification) => serverDateToLocal(claimNotification.datetime_dismissed),
      isHidden: !showDateDismissed,
      hideRestrictedContent: true,
    },
    {
      id: 'due_date',
      label: 'Due Date',
      specialCell: (claimNotification) => isoDateToUs(claimNotification.due_date),
      isHidden: !showDueDate,
      hideRestrictedContent: true,
    },
    // eslint-disable-next-line react/prop-types
    {
      id: 'title',
      label: 'Title',
      disableSort: false,
      specialCell: (claimNotifications) => (
        <span style={{ textDecoration: !!claimNotifications.datetime_dismissed && 'line-through' }}>
          {claimNotifications.title}
        </span>
      ),
      hideRestrictedContent: true,
    },
    {
      id: 'recipient_user',
      label: 'Notification For',
      specialCell: (claimNotification) => {
        const { primary: label, secondary } = extractNotificationRecipient(claimNotification);
        return (
          <div>
            <Typography display="block" variant="body2">
              {label}
            </Typography>
            {secondary && (
              <Typography display="block" variant="body2" color="textSecondary">
                {secondary}
              </Typography>
            )}
          </div>
        );
      },
      specialCmpFunc: (row1, row2) => {
        const { primary: primary1, secondary: secondary1 } = extractNotificationRecipient(row1);
        const { primary: primary2, secondary: secondary2 } = extractNotificationRecipient(row2);
        return stringCmp(`${primary1}-${secondary1}`, `${primary2}-${secondary2}`);
      },
      isHidden: !showRecipientUser,
    },
    {
      id: 'dismissing_user',
      label: 'Dismissed By',
      isHidden: !showDismissedBy,
    },
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell: (claimNotification) =>
        claimNotification.type !== 'claim_owner_change_claim_notification' ? (
          <TooltipIcon title="View more details">
            <ThreeDotsHorizontalIcon
              style={{ cursor: 'pointer' }}
              className={classes.hoverableIcon}
              onClick={() => setClaimNotificationToShowId(claimNotification.id)}
            />
          </TooltipIcon>
        ) : null,
      hideRestrictedContent: true,
    },
  ];

  columnData = columnData.filter((column) => !column.isHidden);

  columnData = adjustColumnByUser(user, columnData);

  // leaving these here in case it's needed for some reason, even though the columns don't exist
  if (!userContext) {
    columnData = columnData.filter((column) => column.id !== 'claim_type');
  }

  if (!showInsuredName) {
    columnData = columnData.filter((column) => column.id !== 'insured_full_name');
  }

  const claimNotificationToShow = claimNotifications.find((c) => c.id === claimNotificationToShowId);

  let orderByColumnIdx = columnData.findIndex((column) => column.id === (defaultOrderColumnName || 'type'));

  // to avoid re-calculating the date and needing a special sort, we do it here
  const claimNotificationsWithDate = claimNotifications.map((cN) => ({
    ...cN,
    notification_date: getClaimNotificationDateStr(cN),
  }));

  const restrictedContentMessage = `This is attached to a sub-org you don't have permissions for.
  Please contact your system administrator`;

  return (
    <>
      <SortableTable
        columns={columnData}
        rows={claimNotificationsWithDate}
        maxHeight={maxHeight}
        stickyHeader
        defaultOrderColumn={orderByColumnIdx}
        order={defaultSortOrder}
        paginationProps={paginationProps}
        onSortByColumn={onSortByColumn}
        disableSortByUser={disableSort}
        keepRowsOrder={disableSort}
        autoPaginateRowsPerPage={15}
        paginationLocation={paginationLocation}
        isRestrictedPredicate={isRestrictedPredicate}
        restrictedContentTooltipText={restrictedContentMessage}
      />
      {claimNotificationToShow && (
        <ViewClaimNotificationCard
          claimNotification={claimNotificationToShow}
          onUpdate={handleUpdate}
          userContext={userContext}
          onClose={() => setClaimNotificationToShowId(undefined)}
        />
      )}
    </>
  );
}

ClaimNotificationsTable.propTypes = {
  claimNotifications: PropTypes.array.isRequired,
  exposuresDict: PropTypes.object.isRequired,
  onNotificationUpdate: PropTypes.func.isRequired,
  userContext: PropTypes.bool,
  showRecipientUser: PropTypes.bool,
  showDateDismissed: PropTypes.bool,
  showDueDate: PropTypes.bool,
  showExposure: PropTypes.bool,
  showInsuredName: PropTypes.bool,
  showNotificationDate: PropTypes.bool,
  maxHeight: PropTypes.string,
  disableSort: PropTypes.bool,
  paginationProps: SortableTable.paginationPropsPropTypesShape,
  onSortByColumn: requiredIf(PropTypes.func, (props) => props.paginationProps && !props.disableSort), // If paginationProps is given, either onSortByColumn or disableSort should be given as well
  openClaimInNewTab: PropTypes.bool,
  defaultOrderColumnName: PropTypes.string,
  defaultSortOrder: PropTypes.string,
  paginationLocation: PropTypes.string,
  showSubOrg: PropTypes.bool,
  showDismissedBy: PropTypes.bool,
};
