import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { CONFIGURATION_FEATURES_NAMES, MOR_OPTIONS_DICT } from '~/Types';
import { isFeatureEnabled, isMarshmallowClaim, isQoverClaim, reportAxiosError } from '~/Utils';

import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import SendEmailCommunicationCardContainer from '../communications/EmailCommunicationCard/SendEmailCommunicationCardContainer';
import { EditDocumentDialog } from '../Documents/DocumentCard';
import DocumentsContainer from '../Documents/DocumentsContainer';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';
import useOrganization from '../OrganizationContext';

import AobDashboardButton from './AobDashboard';
import ExposureTooltipedLock from './ExposureTooltipedLock';
import { isExposureWriteDisabled } from './ExposureUtils';
import MorCard, { getMorIconComponent } from './MorCard';

function MorContainer(props) {
  const { exposure } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const { morConfiguration } = useOrganization();

  const claimMorConfiguration = morConfiguration[claim.type];

  const getEmptyEmailPreviewState = () => {
    return { sendEmailOpen: false, emailTextAndTitle: null, emailContact: null };
  };

  const [openMorCard, setOpenMorCard] = useState(false);
  const [newMor, setNewMor] = useState(false);
  const [emailPreviewState, setEmailPreviewState] = useState(getEmptyEmailPreviewState());
  const { sendEmailOpen, emailTextAndTitle, emailContact } = emailPreviewState;

  const { damage_assessment } = exposure;
  const mor = exposure.method_of_resolution;

  const shouldShowAobDashboard = mor && (claimMorConfiguration ? claimMorConfiguration.aob_dashboard || false : false);
  const shouldShowAobDtp =
    !shouldShowAobDashboard &&
    mor &&
    (claimMorConfiguration
      ? claimMorConfiguration.mor_options[mor.method].aob_dtp_option
      : ['body_shop_directed_repair', 'contractor_directed_repair'].includes(mor.method));

  const isConfigurableCoveargesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );

  // We currently support MOR only for HOME and AUTO property and pecuniary
  if (
    ['wc_claim', 'pet_claim', 'travel_claim'].includes(claim.type) ||
    ((claim.type === 'home_claim' || claim.type === 'auto_claim') &&
      exposure.involved_person &&
      !(
        (isMarshmallowClaim(claim) || isQoverClaim(claim)) &&
        ['coverage_tp_injury_expenses', 'coverage_tp_miscellaneous_expenses', 'accDeathFuneral'].includes(
          exposure.coverage_type
        )
      ))
  ) {
    return <></>;
  }

  const onUpdateAobDtp = async (documentId) => {
    try {
      if (documentId) {
        await axios.post(
          `/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_resolution/aob_dtp_confirmation`,
          { aob_dtp_document_id: documentId }
        );
      } else {
        await axios.delete(
          `/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_resolution/aob_dtp_confirmation`
        );
      }
      await onAsyncClaimUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const handleCloseDialog = () => {
    setOpenMorCard(false);
    setNewMor(false);
  };

  const onSubmitMor = async (morValues) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/method_of_resolution`, morValues);

      await onAsyncClaimUpdate();

      handleCloseDialog();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const readOnly = isExposureWriteDisabled(exposure, user);
  // We are assuming that if damage_assessment exists, all other required columns before it exist as well
  if (['gl_claim'].includes(claim.type)) {
    ({}); // if it's gl_claim skip the next if - allow MOR anyway (even if no MOR, Damage assessment etc)
  } else if (
    !isConfigurableCoveargesEnabled &&
    (!damage_assessment ||
      !(damage_assessment.is_estimation_report_exists || damage_assessment.amount !== null) ||
      (!mor && readOnly))
  ) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  return (
    <>
      {mor ? (
        <>
          <HoverActionField
            onAction={() => setOpenMorCard(true)}
            permanent={!mor}
            disabled={readOnly}
            actionAriaLabel="Set method of resolution"
          >
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {claimMorConfiguration ? (
                claimMorConfiguration.mor_options[mor.method].desc
              ) : (
                <Tooltip title={MOR_OPTIONS_DICT[mor.method].desc}>{getMorIconComponent(mor.method)}</Tooltip>
              )}
            </span>
          </HoverActionField>
          {shouldShowAobDashboard && <AobDashboardButton />}
          {shouldShowAobDtp && (
            <AobDtpConfirmationContainer
              claim={claim}
              onAsyncClaimUpdate={onAsyncClaimUpdate}
              onUpdate={onUpdateAobDtp}
              aobDtpDocument={mor.aob_dtp_document}
            />
          )}
        </>
      ) : (
        <HoverActionField onAction={() => setOpenMorCard(true)} permanent={!mor}>
          <em>Select</em>
        </HoverActionField>
      )}

      <MorCard
        key={newMor} // The size of the popover is different, depends if its viewing or choosing. we want render again if it changes
        mor={newMor ? null : mor}
        exposure={exposure}
        cardDialogProps={{
          isDialog: true,
          open: openMorCard,
          closeOnBackdropClick: mor && !newMor,
        }}
        onSelectMor={onSubmitMor}
        onSelectNewMor={() => setNewMor(true)}
        onCancel={handleCloseDialog}
      />
      {sendEmailOpen && (
        <SendEmailCommunicationCardContainer
          contact={emailContact}
          onClose={async () => {
            await onAsyncClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
          }}
          onSendEmail={async () => {
            await onAsyncClaimUpdate();
            setEmailPreviewState(getEmptyEmailPreviewState());
          }}
          emailTitle={emailTextAndTitle.email_title}
          emailText={emailTextAndTitle.email_body}
          summary={`Vendor ${emailContact.full_name} was assigned to perform Mor`}
          exposureIds={[exposure.id]}
        />
      )}
    </>
  );
}

MorContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

function AobDtpConfirmationContainer(props) {
  const { claim, onAsyncClaimUpdate, aobDtpDocument, onUpdate } = props;
  const [openChooseDocumentDialog, setOpenChooseDocumentDialog] = useState(false);
  const [openDocumentDetailsDialog, setOpenDocumentDetailsDialog] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const [isFetching, setIsFetching] = useState(false);

  const classes = useStyles();

  const handleUpdateAobDtp = async (values) => {
    setIsFetching(true);
    await onUpdate(values);
    setIsFetching(false);
  };

  return (
    <>
      <HoverActionField
        onAction={() => (aobDtpDocument ? setOpenDocumentDetailsDialog(true) : setOpenChooseDocumentDialog(true))}
      >
        <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          {aobDtpDocument ? 'AoB / DtP confirmed' : 'No AoB / DtP'}
        </span>
      </HoverActionField>
      <CardDialog
        title="Select signed AoB/DtP document"
        fullWidth
        isDialog
        open={openChooseDocumentDialog}
        maxWidth="lg"
        onClose={() => {
          setSelectedDocumentId(null);
          setOpenChooseDocumentDialog(false);
        }}
      >
        <DocumentsContainer
          classes={classes}
          claim={claim}
          selectedDocumentIds={selectedDocumentId ? [selectedDocumentId] : []}
          disableChooseMultiple
          onDocumentsClick={(documentIds) => setSelectedDocumentId(documentIds[0])} // because of disableChooseMultiple, can only choose 1 document at a time
          onNewDocumentCreated={async (documentId) => {
            await onAsyncClaimUpdate();
            setSelectedDocumentId(documentId);
          }}
          hideFromTemplate={true}
          autoPaginateRowsPerPage={25}
        />
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedDocumentId || isFetching}
            onClick={async () => {
              await handleUpdateAobDtp(selectedDocumentId);
              setOpenChooseDocumentDialog(false);
            }}
          >
            Select
          </Button>
        </div>
      </CardDialog>
      <EditDocumentDialog
        open={!!(aobDtpDocument && openDocumentDetailsDialog)} // When we finish updating is still true but aobDtpDocument already null
        claim={claim}
        onCancel={() => setOpenDocumentDetailsDialog(false)}
        document={aobDtpDocument}
        customButtonsComponent={
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              disabled={isFetching}
              onClick={async () => {
                await handleUpdateAobDtp(null);
                setOpenDocumentDetailsDialog(false);
              }}
            >
              Cancel AoB / DtP
            </Button>
          </div>
        }
        showOnly
      />
    </>
  );
}

AobDtpConfirmationContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  onAsyncClaimUpdate: PropTypes.func.isRequired,
  aobDtpDocument: PropTypes.object,
  onUpdate: PropTypes.func.isRequired,
};

export default MorContainer;
