import React from 'react';

import type { ActionsPanelProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import type { InvoiceAnalysisRecommendationTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { reportErrorInProductionOrThrow } from '~/Utils';

import '../../../MarkdownTemplate.css';

export const InvoiceAnalysisActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  if (!action?.task_data || action?.task_data?.task_type !== AI_ACTION_TYPES.INVOICE_ANALYSIS_RECOMMENDATION) {
    reportErrorInProductionOrThrow(new Error('Invalid action task data for Invoice Analysis Recommendation'));
  }

  const taskData = action.task_data as InvoiceAnalysisRecommendationTaskData;

  return taskData.invoice_number || taskData.invoice_date ? (
    <>
      {[
        taskData.invoice_number ? (
          <InvoiceData key="invoice_number" title="Invoice Number" value={taskData.invoice_number} />
        ) : null,
        taskData.invoice_date ? (
          <InvoiceData key="invoice_date" title="Invoice Date" value={taskData.invoice_date} />
        ) : null,
      ]}
    </>
  ) : null;
};

const InvoiceData: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <div className="flex w-fit rounded-full bg-slate-400 px-12 py-8">
    <span className="min-w-fit pr-8 font-bold">{title} :</span>
    <span className="text-slate-900">{value}</span>
  </div>
);
