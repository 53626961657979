import React, { useState } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import Button from '~/components/core/Atomic/Buttons/Button';

import { serverDateToLocal } from '../DateTimeUtils';
import { reportAxiosError, stringCmp } from '../Utils';

import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import WithConfirm from './ConfirmModal';
import { SortableTable } from './core';
import LoadingIndicator from './LoadingIndicator';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function ChecksPage() {
  const classes = useStyles();
  const { user } = useCms();
  const [isGeneratingNewChecks, setIsGeneratingNewChecks] = useState();
  const [isMarkingCheckAsVoid, setIsMarkingCheckAsVoid] = useState();
  const location = useLocation();
  const filterData =
    location.pathname === '/organization_gl_checks' ? { claim_type: 'gl_claim', property_name: 'aria' } : {};
  const { isLoading, isError, data, reloadData } = useDataFetcher(
    `/api/v1/organizations/${user.organization_id}/checks/issued`,
    { params: filterData }
  );

  function handleBatchIdClicked(batch_id) {
    window.open(`/api/v1/organizations/${user.organization_id}/checks/${batch_id}/batch_file`, '_blank');
    window.open(`/api/v1/organizations/${user.organization_id}/checks/${batch_id}/ledger_file`, '_blank');
  }

  async function handleGenerateChecks() {
    try {
      setIsGeneratingNewChecks(true);
      const res = await axios.post(`/api/v1/organizations/${user.organization_id}/checks/generate`, filterData);
      await reloadData();
      if (res.data) {
        handleBatchIdClicked(res.data.batch_id);
      } // TODO: add indication if no new checks where created
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsGeneratingNewChecks(false);
    }
  }

  async function handleMarkAsVoid(check_id) {
    try {
      setIsMarkingCheckAsVoid(check_id);
      await axios.post(`/api/v1/organizations/${user.organization_id}/checks/mark_as_void`, { check_id });
      await reloadData();
    } catch (error) {
      reportAxiosError(error);
    } finally {
      setIsMarkingCheckAsVoid(undefined);
    }
  }

  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  const columnData = [
    { id: 'pay_to_line', numeric: false, label: 'Payee' },
    {
      id: 'paid_amount',
      numeric: true,
      label: 'Amount',
      width: '50px',
      specialCell: (row) =>
        Intl.NumberFormat(localeDetails.locale, {
          style: 'currency',
          currency: row.paid_currency || localeDetails.currency,
        }).format(row.paid_amount),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'batch_id',
      numeric: true,
      label: 'Batch Id',
      width: '50px',
      specialCell: (row) => (
        <Button onClick={() => handleBatchIdClicked(row.batch_id)} color="primary">
          {row.batch_id}
        </Button>
      ),
    },
    { id: 'check_number', numeric: true, label: 'Check Number', width: '50px' },
    {
      id: 'check_date',
      numeric: false,
      label: 'Issued At',
      width: '70px',
      specialCell: (row) => serverDateToLocal(row.check_date),
      specialCmpFunc: (row1, row2) => stringCmp(row1.check_date, row2.check_date),
    },
    { id: 'requester_user', numeric: false, label: 'Issued By', width: '90px' },
    // eslint-disable-next-line react/display-name
    {
      id: 'mark_as_void',
      numeric: false,
      label: 'Mark As Void',
      specialCell: (row) => (
        <WithConfirm
          key={row.id}
          title="Are you sure?"
          contentText="This will void the check and unmark the payment as paid"
          primaryButtonName="Mark As Void"
          shouldCloseOnPrimary={true}
        >
          <Button
            onClick={() => handleMarkAsVoid(row.id)}
            color="primary"
            disabled={!!row.void_date || !!isMarkingCheckAsVoid}
          >
            {row.void_date ? 'Void' : 'Mark As Void'}
          </Button>
        </WithConfirm>
      ),
    },
  ];

  return (
    <div className={classes.pageBody}>
      <CardDialog title="Organization Checks">
        <div className={classes.inLineButtonsContainer}>
          <div style={{ position: 'relative' }}>
            <Button variant="contained" color="primary" disabled={isGeneratingNewChecks} onClick={handleGenerateChecks}>
              Generate new checks
            </Button>
            {isGeneratingNewChecks && <LoadingIndicator size={24} className={classes.buttonProgress} />}
          </div>
        </div>
        <SortableTable
          rows={data}
          columns={columnData}
          defaultOrderColumn={columnData.findIndex((column) => column.id === 'batch_id')}
          order="desc"
        />
      </CardDialog>
    </div>
  );
}

export default ChecksPage;
