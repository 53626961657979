import React from 'react';

interface PaymentTestResult {
  testDescription: string;
}

export const PaymentTestResult: React.FC<PaymentTestResult> = ({ testDescription }) => {
  const colorClass = 'bg-green-700 text-white';

  return (
    <div className="gap-1 flex flex-col text-sm">
      <div className="gap-3 flex items-baseline gap-12">
        <span className="test-description w-fit max-w-[80%] text-base font-bold">{testDescription}</span>
        <span className={`badge rounded-full text-sm ${colorClass} h-fit w-fit px-12 py-2 font-bold`}>PASSED</span>
      </div>
      <div className="gap-1 mt-24 flex flex-col px-4">
        <span className="b mt-12 flex w-fit gap-12 rounded-full bg-pink-100 px-12 py-8">
          <span className="min-w-fit font-bold ">Recommended Action: </span>
          <span className="font-bold text-slate-900">Settle Invoice</span>
        </span>
      </div>
    </div>
  );
  //
};
