import { localeDetails } from '~/components/CmsMain/localeGlobals';

export function isLocaleRegionIsUs() {
  return localeDetails.locale.region === 'US';
}

export function isLocaleRegionIsUK() {
  return localeDetails.locale.region === 'GB' || localeDetails.locale.region === 'IE';
}

// Share logic with the backend intl_utils.py get_region_code() to keep consistent behavior
export const getRegionCode = () => {
  if (!localeDetails?.locale?.region) {
    return null;
  }

  switch (localeDetails?.locale.region) {
    case 'US':
      return 'US';
    case 'GB':
      return 'UK';
    case 'AU':
      return 'AU';
    default:
      return 'EU';
  }
};
