import { ParseError, parsePhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';

const PHONE_NUMBER_ERRORS_COPIES = {
  NOT_A_NUMBER: 'Phone number must be numbers only',
  INVALID_COUNTRY: 'Invalid country number entered',
  TOO_SHORT: 'Phone Number too short',
  TOO_LONG: 'Phone Number too long',
  INVALID_LENGTH: 'Invalid phone Number length',
};

export function getPhoneValidationMessageError(phone) {
  // Validate phone number. return '' if phone is valid

  let parsedPhoneNumber = undefined;

  if (phone.match(/[a-z]/i)) {
    return "Invalid phone number, can't contain alphabet letters";
  }

  try {
    parsedPhoneNumber = parsePhoneNumber(phone, localeDetails.locale.region);
  } catch (error) {
    if (error instanceof ParseError) {
      if (PHONE_NUMBER_ERRORS_COPIES[error.message]) {
        return PHONE_NUMBER_ERRORS_COPIES[error.message];
      }
      // Not a phone number, non-existent country, etc.
      return `Invalid phone number: ${error.message}`;
    } else {
      throw error;
    }
  }

  // Checks number format and length, but not checking the strict requirement per country and prefix
  return parsedPhoneNumber.isPossible() ? '' : 'Invalid phone number';
}

export const yupPhoneValidation = Yup.string()
  .max(32)
  .test('is-possible-phone', 'Invalid Phone', function test(phone) {
    if (!phone) {
      return true;
    }

    const errorMsg = getPhoneValidationMessageError(phone);
    if (errorMsg) {
      return this.createError({ message: errorMsg });
    }
    return true;
  });
