import axios from 'axios';

import type { CoveredItemRow } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import { useClaim } from '~/components/ClaimContainer';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import { MinimizedPaymentRequestContainer } from '~/components/exposures/PaymentRequestContainer/PaymentRequestContainer';
import useFetchExposures from '~/components/exposures/useFetchExposures';
import { reportAxiosError, reportGenericException } from '~/Utils';

interface ApplyInvoiceItemProps {
  item: CoveredItemRow;
  claimId: number;
  analysisResultsId: string;
}

interface UseCmsEmbeddedReturn {
  applyInvoiceItem: (props: ApplyInvoiceItemProps) => Promise<void>;
}

interface ExposureCreateResponse {
  exposure_id: string;
  reserve: number;
}

const createExposure = async ({
  item,
  claimId,
  analysisResultsId,
}: ApplyInvoiceItemProps): Promise<ExposureCreateResponse> => {
  const { data } = await axios.post(
    `/api/v1/claims/${claimId}/ai_embedded_recommendations/invoice_analysis/${analysisResultsId}/apply_covered_item`,
    { coverage_item: item }
  );
  return data;
};

const openPaymentDialog = ({
  add,
  claim,
  exposure,
  payableType = 'indemnity',
  onUpdate,
}: {
  // eslint-disable-next-line
  add: (props: any) => { handleCloseDialog: () => void };
  claim: { id: number };
  // eslint-disable-next-line
  exposure: Record<string, any>;
  payableType?: string;
  onUpdate: unknown;
}) => {
  const payableWithReserve = exposure[payableType];

  const paymentRequestDialogProps = {
    claim,
    exposure,
    payableWithReserve,
    payableType,
    onUpdate,
    cardDialogProps: {
      isDialog: true,
      maxWidth: 'md',
      fullWidth: true,
    },
    defaultAmount: payableWithReserve.reserve,
  };

  const openPaymentRequestDialog = () => {
    const { handleCloseDialog } = add({
      barHeader: 'New Payment Request',
      type: 'PAYMENT',
      dialogComponent: MinimizedPaymentRequestContainer,
      dialogProps: {
        ...paymentRequestDialogProps,
        disableMinimized: true,
        onClose: () => handleCloseDialog(),
      },
    });
  };
  openPaymentRequestDialog();
  return;
};

export const useCmsEmbedded = (): UseCmsEmbeddedReturn => {
  const { claim, onClaimUpdate } = useClaim();
  const { reloadData: exposuresReloadData } = useFetchExposures();
  const { add } = useMinimizedDialogs();

  const applyInvoiceItem = async ({ item, claimId, analysisResultsId }: ApplyInvoiceItemProps) => {
    try {
      const { exposure_id } = await createExposure({ item, claimId, analysisResultsId });

      const { exposures } = await exposuresReloadData();

      const exposure = exposures.find(({ id }: { id: number | string }) => id === exposure_id);

      if (!exposure) {
        reportGenericException('Exposure not found');
        return;
      }

      openPaymentDialog({ add, claim, exposure, onUpdate: onClaimUpdate });

      onClaimUpdate();
    } catch (error) {
      await reportAxiosError(error);
      return;
    }
  };
  return {
    applyInvoiceItem,
  };
};
