import React from 'react';

import { ActionPanel } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionPanel';
import EmptyStateIcon from '~/components/AiChat/SideBarDialog/icons/EmptyStateIcon';
import type { AiAction } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import EmptyState from '~/components/core/EmptyState';
import { useCms } from '~/components/hooks/useCms';
import LoadingIndicator from '~/components/LoadingIndicator';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

interface ActionsProps {
  actions: AiAction[];
  isLoading: boolean;
  reloadActions: () => Promise<void>;
}

const sortActionsById = (a: AiAction, b: AiAction) => ((a.action_id || '') > (b.action_id || '') ? 1 : -1);

export const Actions: React.FC<ActionsProps> = ({ actions, isLoading, reloadActions }) => {
  const { userOrganization } = useCms() as { userOrganization: unknown };
  const isPaymentEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_PAYMENT_DISPLAY
  );
  const isReserveEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_RESERVE_DISPLAY
  );
  const isCoverageDecisionEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_COVERAGE_DECISION_DISPLAY
  );
  const isInvoiceAnalysisEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_INVOICE_ANALYSIS_DISPLAY
  );

  const filteredActions = React.useMemo(
    () =>
      actions
        ?.filter((action: AiAction) => {
          return (
            (isCoverageDecisionEnabled && action.task_data?.task_type === AI_ACTION_TYPES.COVERAGE_DECISION) ||
            (isPaymentEnabled && action.task_data?.task_type === AI_ACTION_TYPES.PAYMENT) ||
            (isReserveEnabled && action.task_data?.task_type === AI_ACTION_TYPES.RESERVE_UPDATE) ||
            (isInvoiceAnalysisEnabled &&
              action.task_data?.task_type === AI_ACTION_TYPES.INVOICE_ANALYSIS_RECOMMENDATION)
          );
        })
        .sort(sortActionsById),
    [actions, isCoverageDecisionEnabled, isInvoiceAnalysisEnabled, isPaymentEnabled, isReserveEnabled]
  );

  return (
    <div className="__ACTIONS_ROOT__ mt-16 flex h-[calc(100vh-230px)] flex-col gap-20 overflow-scroll">
      <div className="__ACTIONS_HEADER__ h-min-[100px] gap-100 flex flex-col">
        <h2 className="mx-20" hidden={!filteredActions?.length && !isLoading}>
          Claim Actions
        </h2>
        {!filteredActions?.length && !isLoading && (
          <div className="flex h-[calc(100vh-650px)] w-full flex-col items-center justify-center px-[100px]">
            <EmptyState
              className="text-center"
              title="No recommended actions yet"
              illustration={<EmptyStateIcon height={200} width={200} />}
            />
          </div>
        )}
        {filteredActions?.length > 0 && (
          <ul className="my-0 ml-20 flex flex-col gap-20 p-0">
            {filteredActions?.map((action: AiAction) => (
              <ActionPanel action={action} key={action.action_id} onActionUpdate={reloadActions} />
            ))}
          </ul>
        )}
        {isLoading && (
          <div className="flex h-[calc(100vh-600px)] items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
      </div>
    </div>
  );
};
