import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import { localeDetails } from '~/components/CmsMain/localeGlobals';
import mixpanel from '~/components/CmsMain/mixpanel';
import DocumentPhysicalMailCommunicationCardContainer from '~/components/communications/PhysicalMailCommunicationCard';
import { useCms } from '~/components/hooks/useCms';
import { MailBoxIcon } from '~/components/icons/notifications';
import InlineIconButton from '~/components/InlineIconButton';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import cn from '~/Utils/cn';

const PhysicalMailCommunicationPopover = ({ contact, onUpdate, ignorePermissions = false }) => {
  const classes = useStyles();
  const { user } = useCms();
  const { claim } = useClaim();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const handleOpenDialog = () => {
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.CONTACT_MINI_CARD_NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED,
    });
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const isContactAddressValid = () => {
    return (
      contact && contact.street_address1 && contact.city && (contact.state || localeDetails.locale.region !== 'US')
    );
  };

  const isDisabled = !isContactAddressValid() || user.role.is_view_only;

  return (
    <>
      <InlineIconButton
        icon={MailBoxIcon}
        onClick={handleOpenDialog}
        disabled={isDisabled}
        className={cn({ [classes.hoverableNonFilledIcon]: !isDisabled })}
        ignorePermissions={ignorePermissions}
      />
      {isDialogOpen && (
        <DocumentPhysicalMailCommunicationCardContainer
          claim={claim}
          contact={contact}
          onCancel={handleCloseDialog}
          onDocumentPhysicalMail={() => {
            onUpdate();
            handleCloseDialog();
          }}
        />
      )}
    </>
  );
};

PhysicalMailCommunicationPopover.propTypes = {
  contact: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  ignorePermissions: PropTypes.bool,
};

export default PhysicalMailCommunicationPopover;
