import type { ReactNode } from 'react';
import React from 'react';
import { Collapse } from '@material-ui/core';

import type { CollapsibleCardProps } from '~/components/core/Molecules/Collapsible/types';
import { useCollapsible } from '~/components/core/Molecules/Collapsible/useCollapsible';

import cn from '../../../../Utils/cn';
import { Text } from '../../TextComponents';

import type { CardBaseExternalProps, CardBaseStyleProps, TitleProps } from './types';
import { getCardContentContainerSpacingClass, getCardPaddingClasses } from './types';

const BORDER_CLASS = 'border-1 border-slate-600 border-solid';

interface CardBaseProps extends CollapsibleCardProps, CardBaseExternalProps, CardBaseStyleProps {
  titleProps: TitleProps;
  titleAction?: ReactNode;
}

export const CardBase: React.FC<CardBaseProps> = ({
  paddingX,
  paddingY,
  shadow,
  border,
  backgroundColor,
  contentGap,
  titleProps,
  collapsible,
  onCollapseClick,
  isOpen,
  openByDefault,
  title,
  action,
  children,
  titleAction,
}) => {
  const { onClick, getChevron, isOpenCombined } = useCollapsible({
    collapsible,
    isOpen,
    openByDefault,
    onCollapseClick,
  });

  const shouldShowAction = action && (!collapsible || isOpenCombined);
  const shouldShowTitleRow = collapsible || title || action;

  const getTextProps = () => ({
    variant: titleProps.variant ? Text.VARIANTS[titleProps.variant] : undefined,
    weight: titleProps.weight ? Text.WEIGHTS[titleProps.weight] : undefined,
    colorVariant: titleProps.colorVariant ? Text.COLOR_VARIANTS[titleProps.colorVariant] : undefined,
  });

  const spacingClasses = getCardContentContainerSpacingClass(contentGap, Boolean(title));

  return (
    <CardFrame
      paddingX={paddingX}
      paddingY={paddingY}
      shadow={shadow}
      border={border}
      backgroundColor={backgroundColor}
    >
      {shouldShowTitleRow ? (
        <div className="row-auto flex items-center justify-between">
          <div className="row-auto flex w-full gap-12" onClick={onClick}>
            {collapsible ? (
              <div className="flex h-32 w-32 shrink-0 cursor-pointer items-center justify-center rounded-full hover:bg-slate-900/5">
                {getChevron({})}
              </div>
            ) : null}
            {title ? (
              <div className="flex w-full items-center">
                <Text {...getTextProps()}>{title}</Text>
              </div>
            ) : null}
            {titleAction ? <div className="flex items-center">{titleAction}</div> : null}
          </div>
          {shouldShowAction ? <div className="flex w-full items-center justify-end">{action}</div> : null}
        </div>
      ) : null}
      {collapsible ? (
        <Collapse in={isOpenCombined} timeout="auto" unmountOnExit>
          <div className={`flex flex-col ${spacingClasses}`}>{children}</div>
        </Collapse>
      ) : (
        <div className={`flex flex-col ${spacingClasses}`}>{children}</div>
      )}
    </CardFrame>
  );
};

export const CardFrame: React.FC<React.PropsWithChildren<Omit<CardBaseStyleProps, 'contentGap'>>> = ({
  paddingX,
  paddingY,
  shadow,
  border,
  backgroundColor,
  children,
}) => (
  <div
    className={cn(backgroundColor, getCardPaddingClasses(paddingX, paddingY), 'flex w-full flex-col rounded-md', {
      [BORDER_CLASS]: border,
      'shadow-sm': shadow,
    })}
  >
    {children}
  </div>
);
