import { localeDetails } from '~/components/CmsMain/localeGlobals';

import { COUNTRY_TO_STATE_MAP } from '../../Types';

export function isTinRequired(organizationContactRolesDict, contactRole, contactCountry) {
  const effectiveCountry = contactCountry || localeDetails.locale.region;

  return effectiveCountry === 'US' && contactRole && !!organizationContactRolesDict[contactRole]?.is_tin_required;
}

export function isVatEnabled(contactCountry) {
  return contactCountry && contactCountry !== 'US';
}

export function displayRoleName(organizationContactRolesDict, role) {
  if (!role) {
    return '';
  }
  return organizationContactRolesDict[role].desc;
}

export function getAllClaimRelatedRoles(organizationContactRolesDict) {
  return Object.keys(organizationContactRolesDict).filter((role) => !organizationContactRolesDict[role].is_multi_claim);
}

export function getAllOrganizationWideRoles(organizationContactRolesDict) {
  return Object.keys(organizationContactRolesDict).filter(
    (role) => organizationContactRolesDict[role].is_multi_claim && !organizationContactRolesDict[role].is_system
  );
}

export function getAllOrganizationRolesExcludingSystem(organizationContactRolesDict) {
  return Object.keys(organizationContactRolesDict).filter((role) => !organizationContactRolesDict[role].is_system);
}

export function getAllSearchableContactRoles(organizationContactRolesDict) {
  return Object.keys(organizationContactRolesDict).filter((role) => role !== 'user');
}

export function getRoleDescription(contact, organizationContactRolesDict) {
  const attorney_registration_name = localeDetails.locale.region === 'GB' ? 'SRA' : 'Bar';

  const contactRole = organizationContactRolesDict[contact.role];
  let contactRoleDesc = contactRole.desc;
  if (contact.role === 'other' && contact.role_other_desc) {
    contactRoleDesc += ` - ${contact.role_other_desc}`;
  }

  if (contact.attorney_bar_state) {
    contactRoleDesc += ` - ${COUNTRY_TO_STATE_MAP['US'][contact.attorney_bar_state]} Bar`;
  }

  if (contact.attorney_bar_number) {
    contactRoleDesc += `${contact.attorney_bar_state ? ',' : ` ${attorney_registration_name}`} #${
      contact.attorney_bar_number
    }`;
  }

  return contactRoleDesc;
}
