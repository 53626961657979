import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

import { localeDetails } from '~/components/CmsMain/localeGlobals';

export function isPostalCodeValid(postalCode, region = undefined) {
  if (!region) {
    region = localeDetails.locale.region;
  }

  if (!postcodeValidatorExistsForCountry(region)) {
    return true;
  }

  return postcodeValidator(postalCode, region);
}

export function getPostalCodeError(region = undefined) {
  if (!region) {
    region = localeDetails.locale.region;
  }

  if (region === 'US') {
    return 'Wrong zipcode format (e.g 12345 or 12345-1234)';
  }

  return 'Wrong zipcode format';
}

export function getPostalCodeYupTestParams(isRequired, region = undefined) {
  if (!region) {
    region = localeDetails.locale.region;
  }

  return [
    'is-postal-code-valid',
    getPostalCodeError(region),
    (postalCode) => {
      if (!postalCode) {
        if (isRequired) return false;
        else return true;
      }

      return isPostalCodeValid(postalCode, region);
    },
  ];
}
