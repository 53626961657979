import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import IconWrapperWithDotIndication from '~/components/core/IconWrapperWithDotIndication';
import RadioWithButtonWrapper from '~/components/core/RadioWithButtonWrapper';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { ConfirmModal } from '../ConfirmModal';
import { FsTooltip, TooltipIcon } from '../core';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { InfoIcon, ReassignIcon } from '../icons';
import { SuccessIllustration } from '../illustrations';
import LoadingIndicator from '../LoadingIndicator';
import { MultiSelectTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

const STEPS = {
  PREP: 0,
  FORM: 1,
  CONFIRMATION: 2,
  SUCCESS: 3,
};

const INITIAL_FORMIK_VALUES = {
  id: '',
  deactivate_user: '',
  reassign_open_claims_and_exposures: false,
  open_claims_and_exposures_user_ids: [],
  reassign_closed_claims_and_exposures: false,
  closed_claims_and_exposures_user_ids: [],
  user_notifications_user_id: '',
};

const ReassignContainer = ({
  adjuster,
  onReassign,
  visibleAdjusters,
  allAdjusters,
  reassignmentJob,
  reloadReassignmentJobs,
  orgUnits,
}) => {
  const visibleAdjustersDict = _.keyBy(visibleAdjusters, 'id');
  const allAdjustersDict = _.keyBy(allAdjusters, 'id');

  const classes = useStyles();

  const [step, setStep] = useState(STEPS.PREP);
  const [showReassignmentDialog, setShowReassignmentDialog] = useState(false);
  const [counts, setCounts] = useState(null);
  const [isLoadingCounts, setIsLoadingCounts] = useState(false);

  useEffect(() => {
    if (showReassignmentDialog && !counts && !isLoadingCounts) {
      (async () => {
        try {
          setIsLoadingCounts(true);
          const response = await axios.get(
            `/sysconfig/api/v1/organizations/${adjuster.organization_id}/users/${adjuster.id}/assignments_and_notifications`,
            { params: { allow_removed_user: true } }
          );
          setCounts(response.data);
        } catch (error) {
          await reportAxiosError(error);
        } finally {
          setIsLoadingCounts(false);
        }
      })();
    }
  }, [adjuster.id, adjuster.organization_id, counts, isLoadingCounts, showReassignmentDialog]);

  const isDisabled = !!reassignmentJob?.is_in_progress;
  const handleOpenDialog = () => {
    if (isDisabled) return;
    setStep(STEPS.PREP);
    setShowReassignmentDialog(true);
  };

  const initialValues = reassignmentJob ? { ...reassignmentJob } : { ...INITIAL_FORMIK_VALUES };

  return (
    <>
      <IconWrapperWithDotIndication
        icon={
          <ReassignIcon
            className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
            onClick={handleOpenDialog}
            disabled={isDisabled}
            style={{ pointerEvents: 'auto' }}
          />
        }
        title={
          reassignmentJob
            ? reassignmentJob.is_in_progress
              ? 'Reassignment job in progress'
              : 'Reassignment job exists'
            : 'Reassign claims and notifications'
        }
        shouldBeMarked={reassignmentJob ? true : false}
        className={classes.antiMarginForMarginIcon}
      />

      {showReassignmentDialog && (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            deactivate_user: Yup.boolean().required('Required'),
            reassign_open_claims_and_exposures: Yup.boolean().required('Required'),
            open_claims_and_exposures_user_ids: Yup.array().when('reassign_open_claims_and_exposures', {
              is: true,
              then: Yup.array().of(Yup.string()).min(1, 'Required'),
              otherwise: Yup.array().strip(),
            }),
            reassign_closed_claims_and_exposures: Yup.boolean().required('Required'),
            closed_claims_and_exposures_user_ids: Yup.array().when('reassign_closed_claims_and_exposures', {
              is: true,
              then: Yup.array().of(Yup.string()).min(1, 'Required'),
              otherwise: Yup.array().strip(),
            }),
            user_notifications_user_id: Yup.string().when('deactivate_user', {
              is: false,
              then: Yup.string().strip(),
              otherwise: Yup.string().required('Required'),
            }),
          })}
          onSubmit={async (values, formikProps) => {
            try {
              if (values.id) {
                await axios.put(
                  `/sysconfig/api/v1/organizations/${adjuster.organization_id}/users/${adjuster.id}/reassign_claims_and_notifications/${values.id}`,
                  values
                );
              } else {
                await axios.post(
                  `/sysconfig/api/v1/organizations/${adjuster.organization_id}/users/${adjuster.id}/reassign_claims_and_notifications`,
                  values
                );
              }
              await onReassign();
              await reloadReassignmentJobs();
              setStep(STEPS.SUCCESS);
            } catch (error) {
              await reportAxiosError(error);
              formikProps.setSubmitting(false);
            }
          }}
        >
          {(formikProps) => {
            const { handleSubmit, handleReset, setFieldTouched, validateForm } = formikProps;

            const handleDialogClose = async () => {
              setShowReassignmentDialog(false);
              setCounts(null);
              setStep(STEPS.PREP);
              handleReset();
              await reloadReassignmentJobs();
            };

            const handleFormSave = async () => {
              const errors = await validateForm();
              if (Object.keys(errors).length !== 0) {
                Object.keys(errors).map((fieldName) => setFieldTouched(fieldName, true));
              } else {
                setStep(STEPS.CONFIRMATION);
              }
            };

            const getDialogSize = () => (step === STEPS.FORM ? 'md' : 'sm');
            const getDialogTitle = () => (step === STEPS.FORM ? 'Reassign Claims and Notifications' : '');

            return (
              <>
                {showReassignmentDialog && (
                  <CardDialog
                    isDialog
                    open
                    title={getDialogTitle()}
                    onClose={handleDialogClose}
                    maxWidth={getDialogSize()}
                  >
                    {step === STEPS.PREP && (
                      <PreparationContents
                        disabled={isLoadingCounts}
                        onConfirm={() => {
                          setStep(STEPS.FORM);
                        }}
                      />
                    )}
                    {step === STEPS.FORM && (
                      <FormContents
                        adjuster={adjuster}
                        visibleAdjustersDict={visibleAdjustersDict}
                        allAdjustersDict={allAdjustersDict}
                        counts={counts}
                        onClose={handleDialogClose}
                        onConfirm={handleFormSave}
                        reassignmentJob={reassignmentJob}
                        isAdjusterUnitLeader={orgUnits.isUnitLeader(adjuster.id)}
                      />
                    )}
                    {step === STEPS.CONFIRMATION && (
                      <ConfirmationContents
                        onConfirm={handleSubmit}
                        onBack={() => {
                          setStep(STEPS.FORM);
                        }}
                      />
                    )}
                    {step === STEPS.SUCCESS && <SuccessContents onClose={handleDialogClose} />}
                  </CardDialog>
                )}
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

ReassignContainer.propTypes = {
  adjuster: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onReassign: PropTypes.func.isRequired,
  visibleAdjusters: PropTypes.array.isRequired,
  allAdjusters: PropTypes.array.isRequired,
  reassignmentJob: PropTypes.object,
  reloadReassignmentJobs: PropTypes.func,
  allowRemovedUser: PropTypes.bool,
  orgUnits: PropTypes.object,
};

const FormContents = ({
  adjuster,
  visibleAdjustersDict,
  allAdjustersDict,
  counts,
  onClose,
  onConfirm,
  reassignmentJob,
  isAdjusterUnitLeader,
}) => {
  const classes = useStyles();
  const { isSubmitting, values, setFieldValue, setFieldTouched, resetForm } = useFormikContext();
  const [updateReassignmentJob, setUpdateReassignmentJob] = useState(!!reassignmentJob);
  const [supervisorDeactivationErrorPopupOpen, setSupervisorDeactivationErrorPopupOpen] = useState(false);

  const isDeactivationAllowed = !updateReassignmentJob && !isAdjusterUnitLeader;
  const reasonDeactivationNotAllowed = isAdjusterUnitLeader ? 'Cannot deactivate a user who is a unit leader' : null;

  const handleDeactivateUserChanged = (deactivate_user) => {
    setFieldValue('deactivate_user', deactivate_user);

    if (deactivate_user && adjuster.is_org_level_supervisor && adjuster.is_manager) {
      setSupervisorDeactivationErrorPopupOpen(true);
    } else if (deactivate_user) {
      setFieldValue('reassign_open_claims_and_exposures', true);
      setFieldValue('reassign_closed_claims_and_exposures', true);
    } else {
      setFieldValue('user_notifications_user_id', '');
      setFieldTouched('user_notifications_user_id', false);
    }
  };

  const handleOpenClaimsChecked = (e) => {
    setFieldValue('reassign_open_claims_and_exposures', e.target.checked);

    if (!e.target.checked) {
      setFieldValue('open_claims_and_exposures_user_ids', []);
      setFieldTouched('open_claims_and_exposures_user_ids', false);
    }
  };

  const handleClosedClaimsChecked = (e) => {
    setFieldValue('reassign_closed_claims_and_exposures', e.target.checked);

    if (!e.target.checked) {
      setFieldValue('closed_claims_and_exposures_user_ids', []);
      setFieldTouched('closed_claims_and_exposures_user_ids', false);
    }
  };

  const handleResetForm = () => {
    resetForm({ values: { ...INITIAL_FORMIK_VALUES } });
    setUpdateReassignmentJob(false);
  };

  const SupervisorDeactivationErrorPopup = () => {
    return (
      <ConfirmModal
        isOpen={true}
        title="Reassignment cannot be completed"
        contentText={
          'The user you are trying to deactivate is a supervisor whose in charge of active users. To complete the reassignment go back to the "Adjuster management" tab and remove the user from any supervisor`s responsibility'
        }
        primaryButtonName="Ok"
        onPrimaryBtnClick={onClose}
        onClose={onClose}
        removeSecondaryButton={true}
      />
    );
  };

  return (
    <>
      <Grid container spacing={5} style={{ flex: 1, overflow: 'auto' }}>
        {adjuster.is_org_level_supervisor && (
          <>
            <Grid item xs={8}>
              <Typography display="block" variant="subtitle2" style={{ display: 'flex', fontWeight: 'bold' }}>
                <InfoIcon size={20} iconColor="#909090" style={{ marginRight: 5 }} /> Note
              </Typography>
              <Typography variant="body1" style={{ color: '#606060' }}>
                Since the user is a supervisor, user notifications will not be reassigned with the claims even if they
                can be attributed to specific claims.
                <br />
                These notifications can still be reassigned as non attributed user notifications.
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </>
        )}
        <Grid item xs={12}>
          <Typography
            display="block"
            variant="subtitle2"
            style={{ marginBottom: 10, display: 'flex', fontWeight: 'bold' }}
            className={updateReassignmentJob ? classes.greyedText : ''}
          >
            Deactivate User?
            <TooltipIcon title="Deactivated users will be removed from the system, and blocked from accessing the system">
              <InfoIcon size={20} iconColor="#909090" style={{ marginLeft: 5 }} />
            </TooltipIcon>
          </Typography>
          <FsTooltip title={reasonDeactivationNotAllowed} disabled={isDeactivationAllowed}>
            <RadioWithButtonWrapper
              id="deactivate_user"
              value={true}
              text="Yes"
              checked={values.deactivate_user}
              onChange={handleDeactivateUserChanged}
              disabled={!isDeactivationAllowed}
            />
          </FsTooltip>
          <RadioWithButtonWrapper
            id="deactivate_user"
            value={false}
            text="No"
            checked={values.deactivate_user === false}
            onChange={handleDeactivateUserChanged}
            disabled={updateReassignmentJob}
          />
          <ErrorHelperTextFormik id="deactivate_user" />
        </Grid>
        {values.deactivate_user !== false && (
          <Grid
            item
            xs={12}
            className={updateReassignmentJob && !!reassignmentJob.user_notifications_user_id ? classes.greyedText : ''}
          >
            <Typography display="block" variant="subtitle2" style={{ fontWeight: 'bold' }}>
              User Notifications
            </Typography>
            <Typography style={{ display: 'inline-flex' }} color="inherit">
              Reassign {counts?.user_notifications ?? ''} user notifications and unassigned notifications
              <TooltipIcon title="Unassigned notifications are any notification that were not explicitly assigned">
                <InfoIcon size={20} iconColor="#909090" style={{ marginLeft: 5 }} />
              </TooltipIcon>
            </Typography>

            <Typography style={{ marginTop: 20, display: 'flex', alignItems: 'center' }} color="inherit">
              Reassign to
              <TextFieldFormik
                id="user_notifications_user_id"
                label=""
                className={classes.textFieldRow}
                select
                style={{ marginLeft: 10 }}
                disabled={updateReassignmentJob && !!reassignmentJob.user_notifications_user_id}
              >
                {Object.keys(visibleAdjustersDict).map((userId) => (
                  <MenuItem key={userId} value={userId}>
                    {visibleAdjustersDict[userId].username}
                  </MenuItem>
                ))}
              </TextFieldFormik>
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          className={
            updateReassignmentJob && reassignmentJob.reassign_open_claims_and_exposures ? classes.greyedText : ''
          }
        >
          <Typography display="block" variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Open Claims
          </Typography>
          <CheckboxFormik
            id="reassign_open_claims_and_exposures"
            label={`Reassign ${
              counts?.open_claims + counts?.open_exposures ?? 'all'
            } open claims and exposures including:`}
            onChange={handleOpenClaimsChecked}
            disabled={updateReassignmentJob && reassignmentJob.reassign_open_claims_and_exposures}
          />
          <Typography color="inherit">
            <ul style={{ paddingLeft: 45 }}>
              <li>
                All open claims and exposures that are assigned to{' '}
                <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span>.
              </li>
              <li>
                All notifications that are assigned to <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span>{' '}
                as a claim/exposure owner.
              </li>
              {!adjuster.is_org_level_supervisor && (
                <li>
                  All user notifications that are attributed to{' '}
                  <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span> under these claims/exposures.
                </li>
              )}
            </ul>
          </Typography>
          <Typography style={{ paddingLeft: 30, display: 'inline-flex' }} color="inherit">
            Reassign to{' '}
            <TooltipIcon title="If you select more than one adjuster, the claims will be reassigned evenly">
              <InfoIcon size={20} iconColor="#909090" style={{ marginLeft: 5 }} />
            </TooltipIcon>
            <MultiSelectTextFieldFormik
              id="open_claims_and_exposures_user_ids"
              label=""
              options={Object.keys(visibleAdjustersDict)}
              renderValue={() => ''}
              renderOption={(user_id) => allAdjustersDict[user_id].username}
              className={`${classes.formTextFieldNoErrorSpacing} ${classes.textFieldRow}`}
              withOptionChips
              disabled={
                !values.reassign_open_claims_and_exposures ||
                (updateReassignmentJob && reassignmentJob.reassign_open_claims_and_exposures)
              }
              style={{ marginTop: 0, marginLeft: 10 }}
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={
            updateReassignmentJob && reassignmentJob.reassign_closed_claims_and_exposures ? classes.greyedText : ''
          }
        >
          <Typography display="block" variant="subtitle2" style={{ fontWeight: 'bold' }}>
            Closed Claims
          </Typography>
          <CheckboxFormik
            id="reassign_closed_claims_and_exposures"
            label={`Reassign ${
              counts?.closed_claims + counts?.closed_exposures ?? 'all'
            } closed claims and exposures including:`}
            onChange={handleClosedClaimsChecked}
            disabled={updateReassignmentJob && reassignmentJob.reassign_closed_claims_and_exposures}
          />
          <Typography color="inherit">
            <ul style={{ paddingLeft: 45 }}>
              <li>
                All closed claims and exposures that are assigned to{' '}
                <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span>.
              </li>
              <li>
                All notifications that are assigned to <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span>{' '}
                as a claim/exposure owner.
              </li>
              {!adjuster.is_org_level_supervisor && (
                <li>
                  All user notifications that are attributed to{' '}
                  <span style={{ fontWeight: 'bold' }}>{adjuster.username}</span> under these claims/exposures.
                </li>
              )}
            </ul>
          </Typography>

          <Typography style={{ paddingLeft: 30, display: 'inline-flex' }} color="inherit">
            Reassign to{' '}
            <TooltipIcon title="If you select more than one adjuster, the claims will be reassigned evenly">
              <InfoIcon size={20} iconColor="#909090" style={{ marginLeft: 5 }} />
            </TooltipIcon>
            <MultiSelectTextFieldFormik
              id="closed_claims_and_exposures_user_ids"
              label=""
              options={Object.keys(visibleAdjustersDict)}
              renderValue={() => ''}
              renderOption={(user_id) => allAdjustersDict[user_id].username}
              className={`${classes.formTextFieldNoErrorSpacing} ${classes.textFieldRow}`}
              withOptionChips
              disabled={
                !values.reassign_closed_claims_and_exposures ||
                (updateReassignmentJob && reassignmentJob.reassign_closed_claims_and_exposures)
              }
              style={{ marginTop: 0, marginLeft: 10 }}
            />
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        {updateReassignmentJob && !reassignmentJob.deactivate_user && (
          <Button color="primary" disabled={isSubmitting} onClick={handleResetForm} className={classes.cancelButton}>
            Reset
          </Button>
        )}
        <CancelButton disabled={isSubmitting} onClick={onClose} />
        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onConfirm}>
          Save
        </Button>
      </div>
      {supervisorDeactivationErrorPopupOpen && <SupervisorDeactivationErrorPopup />}
    </>
  );
};

FormContents.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  adjuster: PropTypes.object.isRequired,
  visibleAdjustersDict: PropTypes.object.isRequired,
  allAdjustersDict: PropTypes.object.isRequired,
  counts: PropTypes.object.isRequired,
  reassignmentJob: PropTypes.object,
  isAdjusterUnitLeader: PropTypes.bool,
};

const PreparationContents = ({ onConfirm, disabled = false }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: 20 }}>
            Before you continue...
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Make sure you remove or replace this user from the following:
            <ul>
              <li>Assignment queue</li>
              <li>Special and Supervisor Roles</li>
              <li>Tableau Accounts</li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" color="primary" disabled={disabled} onClick={onConfirm}>
          PROCEED
        </Button>
      </div>
    </>
  );
};

PreparationContents.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const ConfirmationContents = ({ onBack, onConfirm }) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);

  const handleConfirm = async () => {
    setShowLoader(true);
    await onConfirm();
  };

  return (
    <>
      <Grid container spacing={1} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: 20 }}>
            Are you sure you want
            <br />
            to complete the reassignment?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>This action is irreversible.</Typography>
        </Grid>
        {showLoader && (
          <Grid item xs={12}>
            <LoadingIndicator />
            <Typography>Updating reassignment...</Typography>
          </Grid>
        )}
      </Grid>
      <div className={classes.buttonsContainer}>
        <CancelButton onClick={onBack} content="NO" />
        <Button variant="contained" color="primary" disabled={showLoader} onClick={handleConfirm}>
          YES, REASSIGN
        </Button>
      </div>
    </>
  );
};

ConfirmationContents.propTypes = {
  onBack: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const SuccessContents = ({ onClose }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1} style={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <SuccessIllustration />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Reassignment job initiated successfully - notification will be sent when finished
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" color="primary" onClick={onClose}>
          OK
        </Button>
      </div>
    </>
  );
};

SuccessContents.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { ReassignContainer };
