import { isEmpty } from 'lodash';

import {
  CONFIGURATION_FEATURES_NAMES,
  PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3,
  PAYMENT_METHODS_DETAILS_DICT,
} from '../../Types';
import { isFeatureEnabled, isMgmOrganization, isRwOrganization, stringCmp } from '../../Utils';
import { getPaymentMethodDisplayName } from '../exposures/PaymentRequestContainer/PaymentMethodUtils';
import { useCms } from '../hooks/useCms';
import useOrganization from '../OrganizationContext';
import { useSysconfig } from '../SystemConfiguration/SystemConfigurationScreen';

import { useSubOrganization } from './useSubOrganization';

export const usePaymentsConfiguration = (selectedPaymentMethod = undefined) => {
  const { legacyExpensePaymentTypes, legacyIndemnityPaymentTypes, paymentsConfiguration } = useOrganization();
  const { organization: sysconfigOrganization } = useSysconfig();
  const { userOrganization } = useCms();
  const { subOrganization } = useSubOrganization();
  const { user } = useCms();

  let paymentsMethodConfigurations;
  let enabledPaymentMethodConfigurations;
  let paymentMethods;
  let defaultPaymentMethod;
  let isUkBankTransferEnabledForAny;
  let isIbanBankTransferEnabledForAny;
  let isCommerceBankEnabledForAny;
  let isPaymentLabelsEnabledForAny;
  let uniquePaymentMethodDisplayNamesMap = new Map();
  const isSubReservesEnabled = paymentsConfiguration.is_sub_reserves_enabled;

  const organization = sysconfigOrganization || userOrganization;

  const isMultipleSubReservePaymentsEnabled = paymentsConfiguration.is_multiple_sub_reserve_payments_enabled;
  const isPrefillReserveAmountsCapabilityEnabled = paymentsConfiguration.is_prefill_reserve_amounts_capability_enabled;

  const checkPaymentMethodEnabled = (paymentMethodConfigurations, paymentMethod) => {
    return isEmpty(paymentMethodConfigurations)
      ? false
      : paymentMethodConfigurations.some(
          (paymentMethodConfig) =>
            paymentMethodConfig.payment_method === paymentMethod && !paymentMethodConfig.is_disabled
        );
  };

  const setPaymentMethodsEnabledForAny = (paymentMethodConfigurations) => {
    isUkBankTransferEnabledForAny = checkPaymentMethodEnabled(paymentMethodConfigurations, 'uk_bank_transfer');
    isIbanBankTransferEnabledForAny = checkPaymentMethodEnabled(paymentMethodConfigurations, 'iban_bank_transfer');
    isCommerceBankEnabledForAny = checkPaymentMethodEnabled(paymentMethodConfigurations, 'commerce_bank');
  };

  const setIsPaymentLabelsEnabledForAny = (paymentMethodConfigurations) => {
    isPaymentLabelsEnabledForAny = isEmpty(paymentMethodConfigurations)
      ? true
      : paymentMethodConfigurations.some(
          (paymentMethodConfig) =>
            paymentMethodConfig.method_configuration.use_expenses_labels ||
            paymentMethodConfig.method_configuration.use_indemnity_labels
        );
  };

  function filterAndSortPaymentMethodConfigs(paymentMethodConfigurations) {
    return paymentMethodConfigurations
      ?.filter((methodConfig) => !methodConfig.is_disabled)
      ?.sort((a, b) => {
        const displayNameA = a.is_custom_method
          ? a.payment_method_display_name
          : PAYMENT_METHODS_DETAILS_DICT[a.payment_method].display_name;

        const displayNameB = b.is_custom_method
          ? b.payment_method_display_name
          : PAYMENT_METHODS_DETAILS_DICT[b.payment_method].display_name;
        return stringCmp(displayNameA, displayNameB);
      });
  }

  function getDecoratedPaymentMethodConfig(methodConfig) {
    const decoratedMethodConfig = { ...methodConfig };

    decoratedMethodConfig.is_custom_method =
      PAYMENT_METHODS_DETAILS_DICT[methodConfig.payment_method]?.is_custom_method || false;
    decoratedMethodConfig.original_payment_method = decoratedMethodConfig.payment_method;

    if (decoratedMethodConfig.is_custom_method) {
      decoratedMethodConfig.payment_method = methodConfig.payment_method_external_id;
    }

    return decoratedMethodConfig;
  }

  let allUniqueEnabledPaymentMethodConfigs;

  if (paymentsConfiguration.payment_method_configurations.length > 0) {
    const decoratedConfigs = paymentsConfiguration.payment_method_configurations.map(getDecoratedPaymentMethodConfig);
    const filteredConfigs = filterAndSortPaymentMethodConfigs(decoratedConfigs);
    // There could be multiple configs of the same method but only one will be used.
    allUniqueEnabledPaymentMethodConfigs = [
      ...new Map(filteredConfigs.map((config) => [config.payment_method, config])).values(),
    ];
  } else {
    allUniqueEnabledPaymentMethodConfigs = [getDecoratedPaymentMethodConfig(PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3)];
  }

  allUniqueEnabledPaymentMethodConfigs.forEach((paymentConfig) => {
    const paymentMethodName = paymentConfig.payment_method;
    const displayName = getPaymentMethodDisplayName(paymentConfig);
    uniquePaymentMethodDisplayNamesMap.set(paymentMethodName, displayName);
  });

  if (paymentsConfiguration.is_org_level_configuration) {
    paymentsMethodConfigurations =
      paymentsConfiguration.payment_method_configurations.length > 0
        ? paymentsConfiguration.payment_method_configurations
        : [PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3];

    setPaymentMethodsEnabledForAny(paymentsConfiguration.payment_method_configurations);
    setIsPaymentLabelsEnabledForAny(paymentsConfiguration.payment_method_configurations);
  } else {
    paymentsMethodConfigurations =
      subOrganization?.payment_method_configurations.length > 0
        ? subOrganization.payment_method_configurations
        : [PAYMENT_METHOD_CONFIGURATION_DEFAULT_V3];

    const subOrgsPaymentMethods = organization.sub_organizations
      .map((subOrg) => subOrg.payment_method_configurations)
      .flat();

    setPaymentMethodsEnabledForAny(subOrgsPaymentMethods);
    setIsPaymentLabelsEnabledForAny(paymentsMethodConfigurations);
  }
  paymentsMethodConfigurations = paymentsMethodConfigurations.map(getDecoratedPaymentMethodConfig);

  paymentMethods = paymentsMethodConfigurations
    .filter((paymentMethod) => !paymentMethod.is_disabled)
    ?.sort((a, b) => (b.payment_method > a.payment_method ? -1 : 1))
    ?.map((paymentMethod) => paymentMethod.payment_method);

  defaultPaymentMethod = paymentMethods[0];
  enabledPaymentMethodConfigurations = filterAndSortPaymentMethodConfigs(paymentsMethodConfigurations);

  const currentPaymentMethod = selectedPaymentMethod ?? enabledPaymentMethodConfigurations[0]?.payment_method;
  const currentPaymentMethodConfiguration = paymentsMethodConfigurations?.find(
    (paymentMethodConfiguration) => paymentMethodConfiguration.payment_method === currentPaymentMethod
  );

  const currentPaymentMethodDetails =
    PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethodConfiguration?.original_payment_method || currentPaymentMethod];

  const getPaymentMethodProperty = (property) => {
    return currentPaymentMethodConfiguration?.method_configuration[property];
  };

  const isCustomMethod = currentPaymentMethodConfiguration?.is_custom_method || false;
  const paymentMethodDisplayName = currentPaymentMethodConfiguration?.payment_method_display_name || '';
  const isPaymentTypeRequireMailToContactId = currentPaymentMethodDetails?.mail_to_contact?.should_include || false;
  const isLobPayment = paymentsConfiguration?.method === 'lob';
  const isOneIncPayment = currentPaymentMethod === 'one_inc';
  const isCheckPayment = currentPaymentMethod === 'check';
  const shouldUseUKBankTransfer = ['uk_bank_transfer'].includes(currentPaymentMethod);
  const shouldUseIbanBankTransfer = 'iban_bank_transfer' === currentPaymentMethod;
  const shouldUseMail = isPaymentTypeRequireMailToContactId;
  const shouldUsePayToLine = currentPaymentMethodDetails?.pay_to_line?.should_include || false;
  const shouldUseReference = currentPaymentMethodDetails?.reference?.should_include || false;
  const referenceMaxLength = currentPaymentMethodDetails?.reference?.max_length || null;
  const shouldUseCheckDescription =
    (currentPaymentMethodDetails?.check_description?.should_include || false) && !isMgmOrganization(organization);
  const checkDescriptionLimit = shouldUseCheckDescription && currentPaymentMethodDetails?.check_description?.max_length;
  const defaultPayToLineLimit =
    (shouldUsePayToLine && currentPaymentMethodDetails?.pay_to_line?.default_max_length) || 0;

  const isRequireApprovalForEveryPayment = Boolean(paymentsConfiguration?.require_approval_for_every_payment);
  const additionalUsersToApprove = paymentsConfiguration?.additional_approving_user_ids;
  const allowMultiplePendingPaymentRequests = Boolean(paymentsConfiguration?.allow_multiple_pending_payment_requests);
  const enableApprovalScreen = paymentsConfiguration?.enable_approval_screen;
  const isAllowedToApprove =
    user.is_org_level_supervisor ||
    user.is_manager ||
    (enableApprovalScreen && additionalUsersToApprove.includes(user.id));
  const isApprovalPaymentsScreenEnabled = enableApprovalScreen && isAllowedToApprove;
  const isPayeeEmailRequired = currentPaymentMethodConfiguration?.method_configuration?.required_fields?.email || false;
  const isPayeePhoneNumberRequired =
    currentPaymentMethodConfiguration?.method_configuration?.required_fields?.phone_number || false;
  const isDateOfBirthRequired =
    currentPaymentMethodConfiguration?.method_configuration?.required_fields?.date_of_birth || false;
  const duplicatePaymentsLevelOfVerification = paymentsConfiguration?.duplicate_payments_level_of_verification;
  const duplicatePaymentsAction = paymentsConfiguration?.duplicate_payments_action;
  const adjustReserveIncludesDeductible = paymentsConfiguration?.adjust_reserve_includes_deductible;
  const adjustReserveAfterPaymentCancellation = paymentsConfiguration?.adjust_reserve_after_payment_cancellation;

  const isConfigurableFnolEnabled = isFeatureEnabled(organization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const allowIndemnityPaymentsOnlyForExistingPropertyOwner =
    (paymentsConfiguration?.allow_indemnity_payments_only_for_existing_property_owner && !isConfigurableFnolEnabled) ||
    false;

  const includeVatInPaymentScreen = paymentsConfiguration?.include_vat_in_payment_screen;
  const show1099Fields = paymentsConfiguration?.enable_1099_fields;

  const isExpensesSubReservesConfigEnabled = paymentsConfiguration?.is_expenses_sub_reserves_config_enabled || false;
  const expensesSubReservesConfig = paymentsConfiguration?.expenses_sub_reserves_config || {};

  const additionalUserIdsToSubmit = paymentsConfiguration?.additional_submitting_user_ids || [];
  const enableSubmissionScreen = paymentsConfiguration?.enable_submission_screen || false;
  const isAllowedToSubmit = additionalUserIdsToSubmit.includes(user.id);
  const isPaymentsSubmissionScreenEnabled = isCommerceBankEnabledForAny && enableSubmissionScreen && isAllowedToSubmit;

  const shouldDisablePaymentExceedingReserve = paymentsConfiguration?.should_disable_payment_exceeding_reserve || false;
  const shouldDisableAdjusterModifyingSelfPaymentLimits =
    paymentsConfiguration?.disable_adjuster_modifying_self_payment_limits || false;

  const canCurrentUserApprovePayment = (
    issuedByUserId,
    wasOfacHitFound,
    wasOfacHitApproved,
    userHasPermissionsOfacOverride
  ) => {
    if (user.id === issuedByUserId || (wasOfacHitFound && !wasOfacHitApproved && !userHasPermissionsOfacOverride)) {
      return false;
    }

    if (!isRequireApprovalForEveryPayment) {
      return user.is_org_level_supervisor || user.is_manager;
    }

    return isAllowedToApprove;
  };
  const shouldEnableDocuments =
    getPaymentMethodProperty('enable_documents') ||
    PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod]?.['always_enable_documents'];
  const shouldEnableReissued = getPaymentMethodProperty('enable_reissued', true);
  const shouldMovePendingCancelUponCancellation =
    PAYMENT_METHODS_DETAILS_DICT[currentPaymentMethod]?.['should_move_pending_cancel_upon_cancellation'];
  const mailToLimits = getPaymentMethodProperty('mail_to_limits');
  const shouldEnableRecordOnly = getPaymentMethodProperty('enable_record_only') || isMgmOrganization(organization);
  const prefillIndemnityReferenceWithClaimNumber =
    shouldUseReference && getPaymentMethodProperty('prefill_indemnity_reference_with_claim_number');
  const prefillExpensesReferenceWithInvoiceNumber =
    shouldUseReference && getPaymentMethodProperty('prefill_expenses_reference_with_invoice_number');

  const shouldUseLegacyLabel = isMgmOrganization(organization) || isRwOrganization(organization);

  const expensePaymentTypes = shouldUseLegacyLabel
    ? legacyExpensePaymentTypes
    : getPaymentMethodProperty('expenses_labels');

  const indemnityPaymentTypes = shouldUseLegacyLabel
    ? legacyIndemnityPaymentTypes
    : getPaymentMethodProperty('indemnity_labels');

  const shouldUseIndemnityLabels = isMgmOrganization(organization)
    ? true
    : getPaymentMethodProperty('use_indemnity_labels');

  const shouldUseExpensesLabels =
    isMgmOrganization(organization) || isRwOrganization(organization)
      ? true
      : getPaymentMethodProperty('use_expenses_labels');

  const isPaymentAllowedOnClosedExposureForPayableType = (payableType) => {
    return payableType === 'expenses'
      ? paymentsConfiguration?.enable_paying_expenses_if_exposure_is_closed
      : paymentsConfiguration?.enable_paying_indemnity_if_exposure_is_closed;
  };

  const isPaymentLimitExistsForPayableType = (payableType) => {
    return payableType === 'expenses'
      ? paymentsConfiguration?.is_expenses_limit_for_closed_exposure_enabled
      : paymentsConfiguration?.is_indemnity_limit_for_closed_exposure_enabled;
  };

  const getPaymentLimitForPayableType = (payableType) => {
    return payableType === 'expenses'
      ? paymentsConfiguration?.expenses_limit_for_closed_exposure
      : paymentsConfiguration?.indemnity_limit_for_closed_exposure;
  };

  return {
    isLobPayment,
    isOneIncPayment,
    isCheckPayment,
    isRequireApprovalForEveryPayment,
    isApprovalPaymentsScreenEnabled,
    canCurrentUserApprovePayment,
    additionalUsersToApprove,
    shouldUseUKBankTransfer,
    shouldUseIbanBankTransfer,
    shouldUseMail,
    shouldUseReference,
    shouldUsePayToLine,
    shouldUseCheckDescription,
    checkDescriptionLimit,
    defaultPayToLineLimit,
    defaultPaymentMethod,
    isPaymentAllowedOnClosedExposureForPayableType,
    isPaymentLimitExistsForPayableType,
    getPaymentLimitForPayableType,
    shouldEnableReissued,
    mailToLimits,
    shouldEnableRecordOnly,
    prefillIndemnityReferenceWithClaimNumber,
    prefillExpensesReferenceWithInvoiceNumber,
    enabledPaymentMethodConfigurations,
    uniquePaymentMethodDisplayNamesMap,
    expensePaymentTypes,
    indemnityPaymentTypes,
    shouldUseIndemnityLabels,
    shouldUseExpensesLabels,
    allowMultiplePendingPaymentRequests,
    isPayeeEmailRequired,
    isPayeePhoneNumberRequired,
    isDateOfBirthRequired,
    isCustomMethod,
    paymentMethodDisplayName,
    referenceMaxLength,
    duplicatePaymentsLevelOfVerification,
    duplicatePaymentsAction,
    allowIndemnityPaymentsOnlyForExistingPropertyOwner,
    isUkBankTransferEnabledForAny,
    isIbanBankTransferEnabledForAny,
    includeVatInPaymentScreen,
    show1099Fields,
    shouldEnableDocuments,
    shouldMovePendingCancelUponCancellation,
    paymentMethodConfiguration: currentPaymentMethodConfiguration,
    isExpensesSubReservesConfigEnabled,
    expensesSubReservesConfig,
    shouldDisablePaymentExceedingReserve,
    isPaymentsSubmissionScreenEnabled,
    isPaymentLabelsEnabledForAny,
    isSubReservesEnabled,
    isMultipleSubReservePaymentsEnabled,
    adjustReserveIncludesDeductible,
    adjustReserveAfterPaymentCancellation,
    shouldDisableAdjusterModifyingSelfPaymentLimits,
    isPrefillReserveAmountsCapabilityEnabled,
  };
};
