import React from 'react';
import type { InputLabelProps } from '@material-ui/core';

import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { NumericTextFieldFormik } from '~/components/TextFieldFormik';

interface MonetaryValueTextFieldFormikProps {
  id: string;
  label?: string;
  className?: string;
  showOnly?: boolean;
  allowNegative?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (value: number | null) => void;
  onEdit?: (value: number | null) => void;
  InputLabelProps?: Partial<InputLabelProps>;
  value?: number | string;
}

const MonetaryValueTextFieldFormik: React.FC<MonetaryValueTextFieldFormikProps> = (props) => {
  const { currencyFormatter, symbol, groupSeparator, decimalSeparator } = useCurrencyFormatter();

  return (
    <NumericTextFieldFormik
      thousandSeparator={groupSeparator ? groupSeparator : true}
      decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
      decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
      fixedDecimalScale
      allowNegative={false}
      prefix={symbol}
      placeholder={currencyFormatter.format(0)}
      label={props.label || ''}
      {...props}
    />
  );
};

export default MonetaryValueTextFieldFormik;
