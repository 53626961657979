import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import { FileDocument, MailboxOpenUp } from 'mdi-material-ui';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import CommunicationActionSendNew from '~/components/communications/CommunicationCard/CommunicationActionSendNew';
import CommunicationReminderContainer from '~/components/communications/CommunicationCard/CommunicationReminderContainer';
import useCreateCommunication from '~/components/communications/CommunicationCard/useCreateCommunication';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import { getExposuresArrayFromClaimByIds, isFeatureEnabled, isXceedanceOrganization, reportAxiosError } from '~/Utils';
import cn from '~/Utils/cn';

import { serverDateTimeToLocal, serverDateTimeToLocalDate } from '../../DateTimeUtils';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '../../pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES, COUNTRY_TO_STATE_MAP, LOCATION_TYPES_DICT } from '../../Types';
import { isClaimWriteDisabled, useFetchClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import mixpanel from '../CmsMain/mixpanel';
import { ConfirmModal } from '../ConfirmModal';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { DOCUMENT_NAME_MAX_SIZE, DocumentCoreFields } from '../Documents/DocumentCard';
import FormikDocumentsAttachment from '../Documents/FormikDocumentsAttachment';
import { useCms } from '../hooks/useCms';
import useGlobalAddresses from '../hooks/useGlobalAddresses';
import HoverActionField from '../HoverActionField';
import InlineIconButton from '../InlineIconButton';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import PlainTable from '../PlainTable';
import { ShowOnlyTextField, TextFieldFormik } from '../TextFieldFormik';

import CommunicationCard from './CommunicationCard';
import CommunicationRecipientContainer from './CommunicationRecipientContainer';
import { getAllSearchableContactRoles } from './ContactUtils';

import { useStyles } from '../../assets/styles';

const isStateCountry = (country) => country in COUNTRY_TO_STATE_MAP;

const mailTypeOptionsDict = {
  usps_standard: 'USPS Standard',
  usps_first_class: 'USPS First Class',
};

const extraServiceOptionsDict = {
  none: 'None',
  certified: 'Certified Mail',
  certified_return_receipt: 'Certified Mail With Electronic Return Receipt',
};

const lobAdditionalFields = {
  mail_type: '',
  extra_service: '',
};

const lobAdditionalValidation = {
  mail_type: Yup.string().required('Required'),
  extra_service: Yup.string().when('mail_type', { is: 'usps_first_class', then: Yup.string().required('Required') }),
};

export const getPhysicalMailValidation = (outgoingPhysicalMailMethod, userOrganization, countries) => {
  let additionalValidation = {};
  if (outgoingPhysicalMailMethod === 'lob') {
    additionalValidation = lobAdditionalValidation;
  } else if (isXceedanceOrganization(userOrganization)) {
    additionalValidation = { mail_type: Yup.string().required('Required') };
  }

  return Yup.object().shape({
    contact_id: Yup.number().required('Required'),
    contact: Yup.object()
      .test(
        'is-valid',
        'Contact must contain address1, city, postal code and state or country',
        (value) =>
          !value ||
          (value.street_address1 &&
            value.city &&
            (isStateCountry(value.country) ? value.state : value.country) &&
            value.zipcode)
      )
      .test(
        'is-supported-country',
        `Country is not supported for ${LOCATION_TYPES_DICT.physical_mail_address}`,
        (value) => !value || countries.includes(value.country)
      ),
    cc_contacts: Yup.array().of(getPhysicalMailCcValidationScheme()),
    attached_documents: Yup.array().required('Required').min(1, 'At least one document is required'),
    exposure_ids: Yup.array().required('Required').min(1, 'Required'),
    summary: Yup.string(),
    add_follow_up_reminder: Yup.boolean(),
    ...additionalValidation,
  });
};

export const physicalMailCommunicationToFormikValues = (communication) => ({
  summary: communication.summary,
});

export function getPhysicalCommunicationSpecificIdentifier(communication) {
  return communication.full_address;
}

function MailMethodAdditionalFields(props) {
  const { outgoingPhysicalMailMethod } = useOrganization();
  const { userOrganization } = useCms();

  if (outgoingPhysicalMailMethod === 'lob') {
    return <LobMailMethodAdditionalFields {...props} />;
  }

  if (isXceedanceOrganization(userOrganization)) {
    return <XceedanceMailMethodAdditionalFields {...props} />;
  }

  return null;
}

function LobMailMethodAdditionalFields(props) {
  const classes = useStyles();
  const { values } = useFormikContext();

  const { showOnly } = props;

  if (showOnly) {
    if (!values.mail_type) {
      return null;
    }

    let mailTypeText = mailTypeOptionsDict[values.mail_type];
    if (values.mail_type === 'usps_first_class' && values.extra_service !== 'none') {
      mailTypeText += ` - ${extraServiceOptionsDict[values.extra_service]}`;
    }

    return (
      <div>
        <Typography display="block" variant="caption">
          Mail Type
        </Typography>
        <Typography display="block" variant="body1">
          {mailTypeText}
        </Typography>
        {values.tracking && values.tracking.tracking_number && (
          <Typography
            display="block"
            variant="subtitle1"
          >{`Tracking Number: ${values.tracking.tracking_number}`}</Typography>
        )}
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldFormik id="mail_type" label="Mail Type" className={classes.textField} fullWidth select>
          {Object.keys(mailTypeOptionsDict).map((mailTypeOption) => (
            <MenuItem key={mailTypeOption} value={mailTypeOption}>
              {mailTypeOptionsDict[mailTypeOption]}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
      {values['mail_type'] === 'usps_first_class' && (
        <Grid item xs={6}>
          <TextFieldFormik id="extra_service" label="Extra Service" className={classes.textField} fullWidth select>
            {Object.keys(extraServiceOptionsDict).map((extraServiceOption) => (
              <MenuItem key={extraServiceOption} value={extraServiceOption}>
                {extraServiceOptionsDict[extraServiceOption]}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </Grid>
      )}
    </Grid>
  );
}

LobMailMethodAdditionalFields.propTypes = {
  showOnly: PropTypes.bool,
};

function XceedanceMailMethodAdditionalFields(props) {
  const classes = useStyles();
  const { values } = useFormikContext();

  const { showOnly } = props;

  const xceedanceMailTypeOptionsDict = ['Regular Mail', 'Overnight', 'Priority', 'Certified Mail'];

  if (showOnly) {
    if (!values.mail_type) {
      return null;
    }

    return (
      <div>
        <Typography display="block" variant="caption">
          Mail Type
        </Typography>
        <Typography display="block" variant="body1">
          {values.mail_type}
        </Typography>
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextFieldFormik id="mail_type" label="Mail Type" className={classes.textField} fullWidth select>
          {xceedanceMailTypeOptionsDict.map((mailTypeOption) => (
            <MenuItem key={mailTypeOption} value={mailTypeOption}>
              {mailTypeOption}
            </MenuItem>
          ))}
        </TextFieldFormik>
      </Grid>
    </Grid>
  );
}

XceedanceMailMethodAdditionalFields.propTypes = {
  showOnly: PropTypes.bool,
};

function LobTrackingEventsContainer(props) {
  const { communication } = props;

  const classes = useStyles();
  const [openTrackingEventsDialog, setOpenTrackingEventsDialog] = React.useState(false);

  // copied from lob's site - https://support.lob.com/hc/en-us/articles/115000097404-Can-I-track-my-mail-
  const trackingNameExplanation = {
    Mailed: 'The mailpiece has been handed off to and accepted by USPS and is en route.',
    'In Transit': 'The mailpiece is being processed at the entry/origin facility.',
    'In Local Area': 'The mailpiece is being processed at the destination facility.',
    'Processed for Delivery':
      "The mailpiece has been greenlit for delivery at the recipient's nearest postal facility. The mailpiece should reach the mailbox within 1 business day of this tracking event.",
    'Re-Routed':
      'The mailpiece is re-routed due to recipient change of address, address errors, or USPS relabeling of barcode/ID tag area.',
    'Returned to Sender': 'The mailpiece is being returned to sender due to barcode, ID tag area, or address errors.',
  };

  const errorEvents = ['Returned to Sender'];

  let lobTrackingEvents = [...communication.tracking.tracking_events]; // already sorted
  lobTrackingEvents.reverse();
  const lastEvent = lobTrackingEvents[0];

  function TrackingEventLine({ trackingEvent }) {
    return (
      <Typography>
        {serverDateTimeToLocal(trackingEvent.time)} -{' '}
        <strong style={{ color: errorEvents.includes(trackingEvent.name) ? 'red' : undefined }}>
          {trackingEvent.name}
        </strong>
      </Typography>
    );
  }
  TrackingEventLine.propTypes = {
    trackingEvent: PropTypes.object.isRequired,
  };

  return (
    <>
      <div className={classes.container} style={{ justifyContent: 'center', alignItems: 'center', margin: '16px 0' }}>
        <HoverActionField icon={VisibilityIcon} onAction={() => setOpenTrackingEventsDialog(true)}>
          <TrackingEventLine trackingEvent={lastEvent} />
        </HoverActionField>
      </div>
      {openTrackingEventsDialog && (
        <CardDialog isDialog title="Tracking" onClose={() => setOpenTrackingEventsDialog(false)} closeOnBackdropClick>
          {lobTrackingEvents.map((evt) => (
            <div key={evt.id} style={{ margin: '16px 0' }}>
              <TrackingEventLine trackingEvent={evt} />
              <Typography variant="caption">{trackingNameExplanation[evt.name]}</Typography>
            </div>
          ))}
        </CardDialog>
      )}
    </>
  );
}

LobTrackingEventsContainer.propTypes = {
  communication: PropTypes.object.isRequired,
};

export function PhysicalCommunicationSpecificBody(props) {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  const { communication, communicationInitialFields, isDocument, isNew, onUpdate } = props;

  const isView = !isNew && !isDocument;

  let documentsAttachmentComponent = undefined;
  if (isView) {
    documentsAttachmentComponent = communication.attachments.length > 0 && (
      <PhysicalMailsAttachmentTable physicalMailCommunication={communication} onUpdate={onUpdate} />
    );
  } else {
    documentsAttachmentComponent = <FormikDocumentsAttachment id="attached_documents" />;
  }

  return (
    <>
      {!isView && (
        <div className={cn('flex items-center', { ['justify-center']: !isNewUIEnabled })}>
          <Typography display="block" variant="subtitle2">
            {communicationInitialFields.communicationContact &&
              communicationInitialFields.communicationContact.full_address}
          </Typography>
        </div>
      )}
      {isView &&
        communication.tracking &&
        communication.tracking.tracking_events_provider === 'lob' &&
        communication.tracking.tracking_events &&
        communication.tracking.tracking_events.length > 0 && (
          <LobTrackingEventsContainer communication={communication} />
        )}
      {/* For now the CC is implemented as sending the mail multiple times. So the BE does not return the CC list */}
      {!isView && (
        <div className={classes.inputContainer}>
          <CommunicationRecipientContainer
            isView={isView}
            SpecificRecipientDialog={PhysicalMailRecipient}
            recipientContactValidationScheme={getPhysicalMailCcValidationScheme()}
            recipientType="cc"
          />
        </div>
      )}
      {isView && communication.is_stored_file_exists && (
        <div style={{ margin: '8px 0px' }}>
          <a
            href={`/api/v1/communications/physical_mails/${communication.id}/stored_file`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Letter
          </a>
        </div>
      )}
      <div className={classes.inputContainer}>{documentsAttachmentComponent}</div>
      <MailMethodAdditionalFields showOnly={isView} />
      {isNew && <CommunicationReminderContainer />}
    </>
  );
}

PhysicalCommunicationSpecificBody.propTypes = {
  communication: requiredIf(PropTypes.object, (props) => !props.isNew && !props.isDocument),
  isNew: PropTypes.bool,
  isDocument: PropTypes.bool,
  communicationInitialFields: PropTypes.shape({
    communicationContact: PropTypes.object,
  }),
  onUpdate: requiredIf(PropTypes.func, (props) => !props.isNew && !props.isDocument),
};

const getPhysicalMailCcValidationScheme = () => {
  const isStateRequired = localeDetails.locale.region === 'US';

  return Yup.object().shape({
    contact_id: Yup.number().required('Required').typeError('Required'),
    contact: Yup.object().test(
      'is-valid',
      `Contact must contain address1, city${isStateRequired ? ', state' : ''} and postal code`,
      (value) => !value || (value.street_address1 && value.city && (!isStateRequired || value.state) && value.zipcode)
    ),
  });
};

function PhysicalMailRecipient(props) {
  const { recipientEntry, recipientEntryId, disabled, showOnly } = props;
  const classes = useStyles();
  const { organizationContactRolesDict } = useOrganization();

  return (
    <>
      <div>
        <ContactTextFieldFormik
          id={`${recipientEntryId}.contact`}
          fullWidth
          disabled={disabled}
          fixedSearchResults
          acceptedRoles={getAllSearchableContactRoles(organizationContactRolesDict)}
          showOnly={showOnly}
        />
      </div>
      <div>
        {recipientEntry.contact && (
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={recipientEntry.contact.full_address} label="" />
        )}
      </div>
    </>
  );
}

PhysicalMailRecipient.propTypes = {
  recipientEntry: PropTypes.object.isRequired,
  recipientEntryId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showOnly: PropTypes.bool,
};

function PhysicalMailsAttachmentTable(props) {
  const { physicalMailCommunication, onUpdate } = props;
  const classes = useStyles();
  const { attachments } = physicalMailCommunication;
  const [attachmentToUploadId, setAttachmentToUploadId] = React.useState(undefined);

  const isAttachedToClaim = !!physicalMailCommunication.claim_id;
  const shouldAllowUploadAttachments = isAttachedToClaim;
  const { documentTypesDict } = useOrganization();

  const handleUploadDocument = async (values) => {
    try {
      await axios.post(
        `/api/v1/claims/${physicalMailCommunication.claim_id}/communications/physical_mails/${physicalMailCommunication.id}/attachments/${attachmentToUploadId}/upload_attachment`,
        values
      );
      await onUpdate();
      return setAttachmentToUploadId(undefined);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  const getAttachmentFileUrl = (attachment) =>
    !attachment.document
      ? `/api/v1/communications/physical_mails/${physicalMailCommunication.id}/attachments/${attachment.id}/stored_file`
      : `/api/v1/claims/${attachment.document.claim_id}/documents/${attachment.document.id}`;

  const isAttachmentUploaded = (attachment) => {
    return attachment.document && !attachment.document.is_removed;
  };

  let attachmentsColumnsData = [
    // eslint-disable-next-line react/display-name
    {
      id: 'view_action',
      specialCell: (attachment) => (
        <InlineIconButton
          icon={FileDocument}
          onClick={() => window.open(getAttachmentFileUrl(attachment), '_target')}
        />
      ),
    },
    {
      id: 'file_name',
      numeric: false,
      disablePadding: false,
      label: 'File Name',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) ? (
          <span>{attachment.file_name}</span>
        ) : (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.file_name}
          </a>
        ),
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'upload',
      numeric: false,
      label: '',
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) ? (
          'uploaded'
        ) : (
          <Button color="primary" onClick={() => setAttachmentToUploadId(attachment.id)}>
            <CloudUploadIcon />
          </Button>
        ),
    },
    {
      id: 'document_id',
      numeric: true,
      disablePadding: false,
      label: '#',
      // eslint-disable-next-line react/display-name
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) && (
          <a href={getAttachmentFileUrl(attachment)} target="_blank" rel="noopener noreferrer">
            {attachment.document.claim_internal_id}
          </a>
        ),
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
      specialCell: (attachment) =>
        isAttachmentUploaded(attachment) &&
        (documentTypesDict?.[attachment.document.type]?.desc || attachment.document.type),
    },
    {
      id: 'document_name',
      numeric: false,
      disablePadding: false,
      label: 'Document Name',
      specialCell: (attachment) => isAttachmentUploaded(attachment) && attachment.document.document_name,
    },
  ];

  if (!shouldAllowUploadAttachments) {
    attachmentsColumnsData = attachmentsColumnsData.filter((attachmentsColumn) => attachmentsColumn.id !== 'upload');
  }

  const attachmentToUpload =
    attachmentToUploadId && attachments.find((attachment) => attachment.id === attachmentToUploadId);

  return (
    <>
      <PlainTable classes={classes} columns={attachmentsColumnsData} rows={attachments} />

      {shouldAllowUploadAttachments && (
        <UploadDocumentFromPhysicalMailDialog
          open={!!attachmentToUploadId}
          attachment={attachmentToUpload}
          claimId={physicalMailCommunication.claim_id}
          onUploadAttachment={handleUploadDocument}
          onCancel={() => setAttachmentToUploadId(null)}
        />
      )}
    </>
  );
}

PhysicalMailsAttachmentTable.propTypes = {
  physicalMailCommunication: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

function UploadDocumentFromPhysicalMailDialog(props) {
  const { attachment, claimId, open, onCancel, onUploadAttachment } = props;
  const classes = useStyles();
  const [claim, isLoading, isError] = useFetchClaim(claimId);

  // We can't render the formik if we don't have the attachment
  if (!open) {
    return <></>;
  }

  return (
    <Formik
      initialValues={{
        type: 'physical_mail',
        document_name: '',
        document_date: serverDateTimeToLocalDate(attachment.stored_file.datetime_uploaded),
        exposure_ids: [0],
        summary: '',
      }}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Required'),
        document_name: Yup.string().required('Required').max(DOCUMENT_NAME_MAX_SIZE),
        document_date: Yup.date().required('Required'),
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
      })}
      onSubmit={(values, formikProps) => {
        onUploadAttachment(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog
            open={open}
            title="Upload Document"
            maxWidth="xs"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            {isLoading || isError ? (
              <LoadingIndicator isError={isError} />
            ) : (
              <Grid container spacing={1}>
                <DocumentCoreFields classes={classes} claim={claim} />
                <Grid item xs={12}>
                  <div className={classes.buttonsContainer}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Upload
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

UploadDocumentFromPhysicalMailDialog.propTypes = {
  attachment: requiredIf(PropTypes.object, (props) => props.open),
  claimId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
};

function DocumentPhysicalMailCommunicationCardContainer(props) {
  const { claim, contact, onCancel, onDocumentPhysicalMail, cc_contacts, overrideInitialValues, exposureIds } = props;
  const { communicationCommonInitialValues } = useCreateCommunication('physical_mail', {
    contact,
    exposure_ids: exposureIds,
  });
  const [deliveryError, setDeliveryError] = React.useState(undefined);
  const { outgoingPhysicalMailMethod } = useOrganization();
  const { userOrganization } = useCms();
  const { countriesOptions } = useGlobalAddresses({ countriesConfigurationKey: 'physical_mail_address' });
  const isNewUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMUNICATION_UI_2);

  let initialValues = {
    ...communicationCommonInitialValues,
    attached_documents: [],
    add_follow_up_reminder: false,
    cc_contacts: cc_contacts ? cc_contacts.map((contact) => ({ contact_id: contact.id, contact, id: uuidv4() })) : [],
  };

  if (outgoingPhysicalMailMethod === 'lob') {
    initialValues = {
      ...initialValues,
      ...lobAdditionalFields,
    };
  } else if (isXceedanceOrganization(userOrganization)) {
    initialValues = {
      ...initialValues,
      ...{ mail_type: '' },
    };
  }

  return (
    <Formik
      initialValues={{ ...initialValues, ...overrideInitialValues }}
      validationSchema={getPhysicalMailValidation(outgoingPhysicalMailMethod, userOrganization, countriesOptions)}
      onSubmit={async (values, formikProps) => {
        try {
          const res = await axios.post(`/api/v1/claims/${claim.id}/contacts/${values.contact.id}/physical_mail`, {
            ...values,
            should_send_anyway_if_undeliverable: !!deliveryError,
          });
          formikProps.setSubmitting(false);
          onDocumentPhysicalMail(res.data);

          const exposures = getExposuresArrayFromClaimByIds(claim, values.exposure_ids);
          mixpanel.track(MIXPANEL_EVENTS.PHYSICAL_COMMUNICATION_MAIL_SENT, {
            ...mixpanel.getMixpanelAttributes(claim, exposures, {
              source: MIXPANEL_EVENT_SOURCES.SEND_PHYSICAL_MAIL_CLICK,
              communication_ids: res.data.map((data) => data.id),
              mail_type: values.mail_type,
              status: res.status === 200 ? 'Success' : 'Failed',
              http_response_code: res.status,
            }),
          });
        } catch (error) {
          if (
            error.response &&
            error.response.data.message &&
            error.response.data.message.startsWith('Notify User: Address is undeliverable')
          ) {
            setDeliveryError(error.response.data.message.split(':')[2]);
            formikProps.setSubmitting(false);
            return;
          }
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
    >
      {(formikProps) => {
        const { values } = formikProps;
        return (
          <>
            <CommunicationCard
              title={isNewUIEnabled ? 'Physical Mail' : undefined}
              communicationInitialFields={{
                communicationChannel: 'physical_mail',
                communicationContact: values.contact,
                communicationDirection: 'Outgoing',
              }}
              CommunicationTypeSpecificBody={PhysicalCommunicationSpecificBody}
              communicationHeaderAdditionalProps={{
                isDynamicContact: !contact,
                dynamicContactLabel: 'Send to',
              }}
              isDialog
              isNew
              maxWidth="md"
              onClose={onCancel}
              CommunicationAction={CommunicationActionSendNew}
              communicationActionProps={{
                onSubmit: () => {
                  formikProps.handleSubmit();
                },
                isSubmitting: formikProps.isSubmitting,
              }}
            />
            <ConfirmModal
              isOpen={!!deliveryError}
              title="Send to Undeliverable Address?"
              isSubmitting={formikProps.isSubmitting}
              contentText={`The address ${deliveryError} was not recognized by USPS and might be undeliverable. If you are sure the address is correct, we can try to send anyway.`}
              primaryButtonName="Send Anyway"
              onClose={() => setDeliveryError(undefined)}
              onPrimaryBtnClick={async () => {
                await formikProps.handleSubmit();
              }}
            />
          </>
        );
      }}
    </Formik>
  );
}

DocumentPhysicalMailCommunicationCardContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  contact: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onDocumentPhysicalMail: PropTypes.func.isRequired,
  overrideInitialValues: PropTypes.object,
  cc_contacts: PropTypes.array,
  exposureIds: PropTypes.array,
};

function NewPhysicalMailCommunicationContainer() {
  const [showNewPhysicalCommunicationDialog, setShowNewPhysicalCommunicationDialog] = useState(false);
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { outgoingPhysicalMailMethod } = useOrganization();
  const { user } = useCms();

  if (!claim) {
    throw Error('Document physical mail should be within claim context');
  }

  const handleNewPhysicalEmailClick = () => {
    setShowNewPhysicalCommunicationDialog(true);
    mixpanel.track(MIXPANEL_EVENTS.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED, {
      source: MIXPANEL_EVENT_SOURCES.NEW_PHYSICAL_MAIL_COMMUNICATION_CLICKED,
    });
  };

  return (
    <>
      <InlineIconButton
        defaultColor="inherit"
        onClick={handleNewPhysicalEmailClick}
        icon={MailboxOpenUp}
        tooltipTitle={`${outgoingPhysicalMailMethod ? 'Send' : 'Document'} physical mail`}
        disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
      />

      {showNewPhysicalCommunicationDialog && (
        <DocumentPhysicalMailCommunicationCardContainer
          claim={claim}
          onCancel={() => setShowNewPhysicalCommunicationDialog(false)}
          onDocumentPhysicalMail={() => {
            onAsyncClaimUpdate();
            setShowNewPhysicalCommunicationDialog(false);
          }}
        />
      )}
    </>
  );
}

export default DocumentPhysicalMailCommunicationCardContainer;
export { NewPhysicalMailCommunicationContainer };
