import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import filenamify from 'filenamify';
import { Formik, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import { Line } from 'rc-progress';
import * as Yup from 'yup';

import AssistanceAiBanner from '~/components/core/AssistanceAiBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import ToggleButtonGroup from '~/components/core/Molecules/Fields/ToggleButtonGroup';
import PermissionsButtonWrapper from '~/components/core/Permissions/PermissionsButtonWrapper';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '~/components/core/Permissions/PermissionUtils';

import { getLocalDateToday, serverDateTimeToLocal } from '../../DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES, PROPRIETARY_FORMAT_VIDEO_FILE } from '../../Types';
import {
  getFilenameExtensionWithDot,
  isFeatureEnabled,
  reportAxiosError,
  reportErrorInProductionOrThrow,
} from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import AutocompleteFormik from '../AutocompleteFormik';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ExposureMultiSelectTextFieldFormik from '../ExposureMultiSelectTextFieldFormik';
import FileDropZone from '../FileDropZone';
import { GalleryContainerInner } from '../Gallery/GalleryScreen';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import TextFieldFormik, { DatePickerTextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

const DocumentCoreFieldsInitialValues = {
  type: '',
  document_name: '',
  document_date: '',
  document_date_received: '',
  exposure_ids: [],
  summary: '',
};

const DOCUMENT_NAME_MAX_SIZE = 256;

const documentFieldsValidation = (isClaimIdRequired = true) => ({
  claim_id: isClaimIdRequired ? Yup.number().required() : Yup.number(),
  document_name: Yup.string().required('Required').max(DOCUMENT_NAME_MAX_SIZE),
  document_date: Yup.date().required('Required'),
  document_date_received: Yup.date()
    .nullable()
    .when('document_date', {
      is: (val) => !!val,
      then: Yup.date().nullable().min(Yup.ref('document_date'), "Can't be before document creation date"),
    }),
  exposure_ids: Yup.array().required('Required').min(1, 'Required'),
  file_size: Yup.number().required('Required').min(1, 'Required'), // we prefer validating file_size to file since we can vouch for its value
  type: Yup.string().required('Required'),
});

const inputLabelProps = {
  shrink: true,
};

const baseStyle = {
  width: 200,
  height: 200,
  borderWidth: 2,
  borderColor: '#666',
  borderStyle: 'dashed',
  borderRadius: 5,
};

const activeStyle = {
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
};

const spacing = 1;

export function isDocumentAPdf(document) {
  return document.mime_type === 'application/pdf';
}

function fileNameToDocumentName(fileName) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export function getAllowedDocumentTypes(documentTypesDict, claimType) {
  return Object.keys(documentTypesDict).reduce((acc, doc_type) => {
    if (!documentTypesDict[doc_type].is_public_option) {
      return acc;
    } else if (
      documentTypesDict[doc_type].excluded_claim_types &&
      documentTypesDict[doc_type].excluded_claim_types.includes(claimType)
    ) {
      return acc;
    } else if (
      documentTypesDict[doc_type].included_claim_types &&
      !documentTypesDict[doc_type].included_claim_types.includes(claimType)
    ) {
      return acc;
    }

    return acc.concat([doc_type]);
  }, []);
}

const onGenAiDoc = async ({ file, fileId, setFieldValue, organizationId, claimId }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/api/v1/generative_ai/documents_ai/${organizationId}/${claimId}${fileId ? `/file/${fileId}` : ''}`,
      ...(file
        ? {
            data: file,
            headers: {
              'Content-Type': file.type,
            },
          }
        : {}),
    });
    const {
      data: { name, document_type, created_at, exposure_ids, summary },
    } = response;

    setFieldValue('document_name', name);
    setFieldValue('type', document_type);
    setFieldValue('summary', summary);
    setFieldValue('exposure_ids', exposure_ids);
    setFieldValue('document_date', created_at ? new Date(created_at).toISOString().substring(0, 10) : null);
  } catch (error) {
    await reportAxiosError(error);
  }
};

function UploadDocumentInner({
  claim,
  showFileBoxOnly,
  uploadOutsideOfClaim,
  onCancel,
  uploadPercent,
  allowedDocumentTypes,
  urlPrefix,
  onUpdatePercent,
}) {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { handleSubmit, isSubmitting, handleReset, touched, values, errors, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [displayAIBanner, setDisplayAIBanner] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const onFileSelect = async (file) => {
    setFieldValue('file', file);
    setFieldTouched('file', true);
    setFieldValue('file_last_modified', new Date(file.lastModified).toISOString());
    setFieldValue('file_size', file.size);
    setFieldTouched('file_size', true);

    if (!values['document_name']) {
      setFieldValue('document_name', fileNameToDocumentName(file.name));
      setFieldTouched('document_name', true);
    }

    if (isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DOCUMENT_AI)) {
      try {
        setDisplayAIBanner(true);
        await onGenAiDoc({ file, organizationId: userOrganization.id, claimId: claim.id, setFieldValue });
      } catch (error) {
        await reportAxiosError(error);
      }
      setDisplayAIBanner(false);
    }

    try {
      setIsUploading(true);

      const storageFileName = await uploadStoredFile({ urlPrefix, file, onUpdatePercent });
      setFieldValue('storage_file_name', storageFileName);

      const { data } = await axios.post(`${urlPrefix}/upload_stored_file_finished_callback`, {
        storage_filename: storageFileName,
        file_type: file.type,
        file_name: file.name,
        exposure_ids: values['exposure_ids'],
        file_size: file.size,
        last_modified: new Date(file.lastModified).toISOString(),
        type: values['type'] || '',
        summary: values['summary'],
        document_name: values['document_name'],
        document_date: values['document_date'],
        document_date_received: values['document_date_received'],
      });
      setFieldValue('stored_file_id', data);
    } catch (error) {
      // It will cause a break in the file, and a duplicated stored file
      await reportAxiosError(error);
      onCancelFileSelect();
    }
    setIsUploading(false);
  };

  const onCancelFileSelect = () => {
    // if default document_name is set, remove it
    if (
      values['document_name'] &&
      values['file'] &&
      values['document_name'] === fileNameToDocumentName(values['file'].name)
    )
      setFieldValue('document_name', '');

    setFieldValue('file', undefined);
    setFieldValue('file_size', undefined);
    setFieldValue('file_last_modified', undefined);
    setFieldValue('storage_filename', undefined);
    setFieldValue('stored_file_id', undefined);
  };

  const cancelFunc = () => {
    handleReset();
    onCancel();
  };

  const calculatedPercent = isUploading ? Math.min(Math.max(1, uploadPercent), 90) : uploadPercent;

  return (
    <CardDialog isDialog title="Upload Document" maxWidth="sm" onClose={cancelFunc} preventClose={isSubmitting}>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          {displayAIBanner ? (
            <AssistanceAiBanner
              title="AI Assistant"
              subTitle="is working on filling up these forms for you."
              size={AssistanceAiBanner.SIZE_VARIANTS.LG}
              iconSize={32}
              weight={AssistanceAiBanner.WEIGHTS.REGULAR}
              hasBackground
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FileDropZone
            onFileSelect={onFileSelect}
            onCancelFileSelect={onCancelFileSelect}
            uploadPercent={calculatedPercent}
            file={values['file']}
            error={getIn(errors, 'file_size') && getIn(touched, 'file_size')}
            errorMessage={getIn(errors, 'file_size')}
            disabled={displayAIBanner}
          />
        </Grid>

        {!showFileBoxOnly && (
          <DocumentCoreFields
            classes={classes}
            claim={claim}
            hideExposuresLabels={uploadOutsideOfClaim}
            allowedDocumentTypes={allowedDocumentTypes}
            disabled={displayAIBanner}
          />
        )}

        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>
            <CancelButton disabled={isSubmitting} onClick={cancelFunc} />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isUploading || isSubmitting}>
              Upload
            </Button>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

UploadDocumentInner.propTypes = {
  claim: requiredIf(PropTypes.object, (props) => !props.uploadOutsideOfClaim),
  allowedDocumentTypes: requiredIf(PropTypes.object, (props) => !props.claim),
  onCancel: PropTypes.func.isRequired,
  showFileBoxOnly: PropTypes.bool,
  uploadOutsideOfClaim: PropTypes.bool,
  uploadPercent: PropTypes.number.isRequired,
  onUpdatePercent: PropTypes.func.isRequired,
  urlPrefix: PropTypes.string,
};

const getUploadDocumentInitialValues = (claim, exposure_ids, allowNullClaimId = false) => ({
  claim_id: allowNullClaimId && !claim ? undefined : claim.id,
  exposure_ids,
  type: '',
  document_name: '',
  document_date: getLocalDateToday(),
  document_date_received: getLocalDateToday(),
  summary: '',
  file: undefined,
  file_size: 0,
  file_last_modified: 0,
});

const uploadStoredFile = async ({ urlPrefix, file, onUpdatePercent }) => {
  const { data } = await axios.post(`${urlPrefix}/upload_url`, {
    file_size: file.size,
    file_type: file.type,
    file_name: file.name,
  });

  const uploadUrl = data.upload_url;
  const storageFileName = data.storage_filename;

  const config = {
    onUploadProgress: onUpdatePercent,
  };

  await axios.put(uploadUrl, file, config);
  return storageFileName;
};

function UploadDocument(props) {
  const { onSubmitDocument, claim, alternativeUploadUrl, initialValues, showFileBoxOnly, uploadOutsideOfClaim } = props;
  // claim can be empty if uploadOutsideOfClaim is true
  const [uploadPercent, setUploadPercent] = useState(0);
  const urlPrefix = alternativeUploadUrl ? alternativeUploadUrl : `/api/v1/claims/${claim.id}/documents`;

  const { user } = useCms();

  const onUpdatePercent = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadPercent(percentCompleted);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const file = values['file'];
    const storedFileId = values['stored_file_id'];

    try {
      const storageFileName =
        values['storage_file_name'] || (await uploadStoredFile({ urlPrefix, file, onUpdatePercent }));

      const { data } = storedFileId
        ? await axios.post(`${urlPrefix}/update_document_stored_file`, {
            stored_file_id: storedFileId,
            exposure_ids: values['exposure_ids'],
            last_modified: values['file_last_modified'],
            type: values['type'],
            summary: values['summary'],
            file_size: file.size,
            file_type: file.type,
            file_name: file.name,
            document_name: values['document_name'],
            document_date: values['document_date'],
            document_date_received: values['document_date_received'],
          })
        : await axios.post(`${urlPrefix}/upload_finished_callback`, {
            exposure_ids: values['exposure_ids'],
            storage_filename: storageFileName,
            file_size: file.size,
            file_type: file.type,
            file_name: file.name,
            last_modified: values['file_last_modified'],
            type: values['type'],
            summary: values['summary'],
            document_name: values['document_name'],
            document_date: values['document_date'],
            document_date_received: values['document_date_received'],
          });

      await onSubmitDocument({ id: data, stored_file_extra: { document_name: values['document_name'] } });
    } catch (error) {
      await reportAxiosError(error);
    }
    setSubmitting(false);
  };

  let relatedExposure = null;
  if (!uploadOutsideOfClaim) {
    // Normal flow...
    relatedExposure = claim.exposures.find((exposure) => exposure.handling_adjuster_id === user.id);
  }

  return (
    <Formik
      initialValues={{
        ...getUploadDocumentInitialValues(
          uploadOutsideOfClaim ? null : claim,
          [relatedExposure ? relatedExposure.id : 0],
          !!uploadOutsideOfClaim
        ),
        ...initialValues,
      }}
      validationSchema={Yup.object().shape(documentFieldsValidation(!uploadOutsideOfClaim))}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikPros) => (
        <UploadDocumentInner
          showFileBoxOnly={showFileBoxOnly}
          uploadPercent={uploadPercent}
          urlPrefix={urlPrefix}
          onUpdatePercent={onUpdatePercent}
          {...props}
          {...formikPros}
        />
      )}
    </Formik>
  );
}

UploadDocument.propTypes = {
  claim: requiredIf(PropTypes.object, (props) => !props.alternativeUploadUrl),
  allowedDocumentTypes: requiredIf(PropTypes.array, (props) => !props.claim),
  onCancel: PropTypes.func.isRequired,
  onSubmitDocument: PropTypes.func.isRequired,
  showFileBoxOnly: PropTypes.bool,
  initialValues: PropTypes.object,
  alternativeUploadUrl: requiredIf(PropTypes.string, (props) => !props.claim),
  uploadOutsideOfClaim: PropTypes.bool,
};

function DocumentCoreFields({
  classes,
  claim,
  showOnly,
  fieldsTitle,
  nameFieldAdditionalText,
  hideExposuresLabels,
  allowedDocumentTypes,
  disabled,
}) {
  const { documentTypesDict } = useOrganization();
  const title = fieldsTitle || 'Document';

  let documentTypes;

  if (allowedDocumentTypes) {
    documentTypes = allowedDocumentTypes;
  } else if (claim) {
    documentTypes = getAllowedDocumentTypes(documentTypesDict, claim.type);
  } else {
    reportErrorInProductionOrThrow('If claim was not passed, allowedDocumentTypes must exists');
  }

  return (
    <>
      <Grid item xs={12}>
        <AutocompleteFormik
          id="type"
          label={`${title} Type`}
          options={documentTypes}
          getOptionLabel={(option) => documentTypesDict[option]['desc']}
          showOnly={showOnly}
          disabled={disabled}
          sortAlphabetic
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="document_name"
          label={`${title} Name`}
          className={classes.textField}
          InputLabelProps={inputLabelProps}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
        />
        {nameFieldAdditionalText && (
          <Typography variant="caption" style={{ color: '#D3D3D3' }}>
            {nameFieldAdditionalText}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <DatePickerTextFieldFormik
          id="document_date"
          label={`${title} Date Created`}
          disableFuture
          className={classes.textField}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePickerTextFieldFormik
          id="document_date_received"
          label={`${title} Date Received`}
          disableFuture
          className={classes.textField}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
        />
      </Grid>
      {!hideExposuresLabels && (
        <Grid item xs={12}>
          <ExposureMultiSelectTextFieldFormik claim={claim} showOnly={showOnly} disabled={disabled} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextFieldFormik
          id="summary"
          label="Summary"
          className={classes.textField}
          InputLabelProps={inputLabelProps}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
          multiline
          rows={5}
        />
      </Grid>
    </>
  );
}

DocumentCoreFields.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: requiredIf(PropTypes.object, (props) => !props.hideExposuresLabels),
  allowedDocumentTypes: requiredIf(PropTypes.array, (props) => !props.claim),
  showOnly: PropTypes.bool,
  fieldsTitle: PropTypes.string,
  nameFieldAdditionalText: PropTypes.string,
  hideExposuresLabels: PropTypes.bool,
  disabled: PropTypes.bool,
};

function ExtractDocumentPhotosContainer(props) {
  const { document, claim, onCancel, onUpdate } = props;
  const [werePhotosExtracted, setWerePhotosExtracted] = useState(document.were_photos_extracted);
  const [isExtractionError, setIsExtractionError] = useState(false);
  const [isExtractingPhotos, setIsExtractingPhotos] = useState(false);
  const {
    isLoading: isLoadingExtractedPhotos,
    isError: isErrorExtractedPhotos,
    data: extractedPhotos,
    reloadData,
  } = useDataFetcher(
    werePhotosExtracted ? `/api/v1/claims/${claim.id}/documents/${document.id}/extracted_photos` : undefined
  );

  const resourcePrefix = `/api/v1/claims/${claim.id}/documents/${document.id}`;

  useEffect(() => {
    if (werePhotosExtracted || isExtractingPhotos) {
      return;
    }

    async function extractPhotos() {
      setIsExtractingPhotos(true);
      try {
        await axios.post(`${resourcePrefix}/extract_photos`);
        await onUpdate();
        setWerePhotosExtracted(true);
      } catch (error) {
        reportAxiosError(error);
        setIsExtractionError(true);
        onCancel();
      }
      setIsExtractingPhotos(false);
    }

    extractPhotos();
  }, [werePhotosExtracted, isExtractingPhotos, resourcePrefix, onUpdate, onCancel]);

  if (!werePhotosExtracted) {
    return (
      <LoadingDialog
        text="Extracting photos... This may take a few minutes."
        isError={isExtractionError}
        track="Extract photos"
      />
    );
  }

  if (isLoadingExtractedPhotos) {
    return <LoadingDialog isError={isErrorExtractedPhotos} track="Get extracted photos" onClose={onCancel} />;
  }

  return (
    <ExtractPhotosGalleryContainer
      document={document}
      claim={claim}
      onCancel={onCancel}
      extractedPhotos={extractedPhotos}
      onPhotosUpdate={async () => {
        await onUpdate();
        await reloadData();
      }}
    />
  );
}

ExtractDocumentPhotosContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

function ExtractPhotosGalleryContainer(props) {
  const { document, claim, onCancel, onPhotosUpdate, extractedPhotos } = props;
  const classes = useStyles();
  const [openUploadPhotosDetailsDialog, setOpenUploadPhotosDetailsDialog] = useState(false);
  const [shouldIncludeUploaded, setShouldIncludeUploaded] = useState(true);

  const allPhotosIds = extractedPhotos.map((photo) => photo.id);
  const alreadyUploadedPhotosIds = extractedPhotos
    .filter((photo) => photo.uploaded_document_id !== null)
    .map((photo) => photo.id);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState(
    alreadyUploadedPhotosIds.length === 0 ? allPhotosIds : alreadyUploadedPhotosIds
  );

  const handlePhotoClicked = (isSelected, photo) => {
    if (isSelected) {
      setSelectedPhotoIds([...selectedPhotoIds, photo.id]);
    } else {
      setSelectedPhotoIds(_.without(selectedPhotoIds, photo.id));
    }
  };

  const handlePhotosUpload = async (documentValues) => {
    const photosToUpload = _.difference(selectedPhotoIds, alreadyUploadedPhotosIds);
    const values = { extracted_photos_ids: photosToUpload, document_details: documentValues };
    try {
      await axios.post(`/api/v1/claims/${claim.id}/documents/${document.id}/extracted_photos/upload_photos`, values);
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }

    await onPhotosUpdate();
    setOpenUploadPhotosDetailsDialog(false);

    const notUploadedPhotos = _.difference(allPhotosIds, selectedPhotoIds); // selectedPhotoIds always contains the photo that have already been uploaded
    if (notUploadedPhotos.length === 0) {
      onCancel();
    }
  };

  const getPhotoUrl = (photo_stored_file) =>
    `/api/v1/claims/${claim.id}/documents/${document.id}/extracted_photos/${photo_stored_file.id}/stored_file`;

  const handleRotatePhoto = async (photo) => {
    try {
      await axios.post(`${getPhotoUrl(photo)}/rotate_photo`);
      await onPhotosUpdate();
    } catch (error) {
      reportAxiosError(error);
    }
  };

  let photosToShow = shouldIncludeUploaded
    ? extractedPhotos
    : extractedPhotos.filter((photo) => !alreadyUploadedPhotosIds.includes(photo.id));
  photosToShow.sort((photo1, photo2) => photo1.id - photo2.id); // Keep  a fixed order of the photos (can return in different order from the server)

  const actionsRow = (
    <div className={classes.buttonsContainer}>
      <ToggleButtonGroup
        className={classes.leftButtonDialog}
        id="ToggleShowPhotos"
        value={shouldIncludeUploaded}
        exclusive
        onChange={(_event, newValue) => newValue !== null && setShouldIncludeUploaded(newValue)}
        options={[
          { value: false, key: 'hide', label: 'Hide uploaded' },
          { value: true, key: 'show', label: 'Show uploaded' },
        ]}
      />
      <ButtonGroup variant="text" size="small" className={classes.leftButtonDialog}>
        <Button onClick={() => setSelectedPhotoIds(allPhotosIds)}>All</Button>
        <Button onClick={() => setSelectedPhotoIds(alreadyUploadedPhotosIds)}>None</Button>
      </ButtonGroup>
    </div>
  );

  return (
    <>
      <CardDialog title="Upload Photos" open isDialog maxWidth="xl" fullWidth onClose={onCancel}>
        <div style={{ height: '80vh' }}>
          <GalleryContainerInner
            allMedia={photosToShow}
            actionsRow={actionsRow}
            onPhotoClicked={handlePhotoClicked}
            shouldAllowSelect
            selectedPhotoIds={selectedPhotoIds}
            disableSelectingPhotoIds={alreadyUploadedPhotosIds}
            disableReason="Photo already uploaded"
            onPhotosSelected={() => setOpenUploadPhotosDetailsDialog(true)}
            selectButtonText="Upload selected photos"
            noPhotosFoundText="No photos to upload"
            isStoredFile
            getPhotoUrl={getPhotoUrl}
            onRotatePhoto={handleRotatePhoto}
          />
        </div>
      </CardDialog>
      <UploadDocumentFromStoredFileDialog
        open={openUploadPhotosDetailsDialog}
        claimId={claim.id}
        onUploadAttachment={handlePhotosUpload}
        onCancel={() => setOpenUploadPhotosDetailsDialog(false)}
        isMultiplePhotos
        isDocumentNameRequired
        initialValues={{
          type: 'damage_photo',
          document_name: '',
          document_date: document.document_date,
          document_date_received: document.document_date_received,
          exposure_ids: document.exposure_ids,
          summary: '',
        }}
      />
    </>
  );
}

ExtractPhotosGalleryContainer.propTypes = {
  claim: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  extractedPhotos: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPhotosUpdate: PropTypes.func.isRequired,
};

function UploadDocumentFromStoredFileDialogInner({
  onCancel,
  isDocumentNameRequired,
  claim,
  isMultiplePhotos,
  fileId,
}) {
  const { userOrganization } = useCms();
  const classes = useStyles();
  const { values, setFieldValue, isSubmitting, handleSubmit } = useFormikContext();

  const documentAiFFOn = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DOCUMENT_AI);
  const [displayAIBanner, setDisplayAIBanner] = useState(false);

  useEffect(() => {
    const genAi = async () => {
      try {
        setDisplayAIBanner(true);
        await onGenAiDoc({ fileId, organizationId: userOrganization.id, claimId: claim.id, setFieldValue });
      } catch (error) {
        await reportAxiosError(error);
      }
      setDisplayAIBanner(false);
    };

    if (documentAiFFOn && fileId) {
      setDisplayAIBanner(true);
      genAi();
    }
  }, [claim.id, documentAiFFOn, fileId, setFieldValue, userOrganization.id]);

  const emptyNameFieldMessage = isDocumentNameRequired ? '' : 'Enter name to change photos original file names';

  return (
    <CardDialog
      isDialog
      open={open}
      title="Upload Document"
      maxWidth="sm"
      onClose={onCancel}
      preventClose={isSubmitting}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {displayAIBanner ? (
            <AssistanceAiBanner
              title="AI Assistant"
              subTitle="is working on filling up these forms for you."
              iconSize={32}
              size={AssistanceAiBanner.SIZE_VARIANTS.LG}
              weight={AssistanceAiBanner.WEIGHTS.REGULAR}
              hasBackground
            />
          ) : null}
        </Grid>
        <DocumentCoreFields
          classes={classes}
          claim={claim}
          nameFieldAdditionalText={
            isMultiplePhotos
              ? values['document_name']
                ? `Photos will be named ${values['document_name']} (1), ${values['document_name']} (2)...`
                : emptyNameFieldMessage
              : undefined
          }
          disabled={displayAIBanner}
        />
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isSubmitting || displayAIBanner}
            >
              Upload
            </Button>
          </div>
        </Grid>
      </Grid>
    </CardDialog>
  );
}

UploadDocumentFromStoredFileDialogInner.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  isMultiplePhotos: PropTypes.bool,
  isDocumentNameRequired: PropTypes.bool,
  claim: PropTypes.object.isRequired,
  fileId: PropTypes.number.isRequired,
};

function UploadDocumentFromStoredFileDialog({
  claimId,
  open,
  onCancel,
  onUploadAttachment,
  initialValues,
  isMultiplePhotos,
  isDocumentNameRequired,
  attachment,
}) {
  const [claim, isLoading, isError] = useFetchClaim(claimId);

  if (!open) {
    return <></>;
  }
  if (isLoading || isError) {
    return <LoadingIndicator isError={isError} />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Required'),
        document_name: isDocumentNameRequired
          ? Yup.string().required('Required').max(DOCUMENT_NAME_MAX_SIZE)
          : undefined,
        document_date: Yup.date().required('Required'),
        document_date_received: Yup.date(),
        exposure_ids: Yup.array().required('Required').min(1, 'Required'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onUploadAttachment(values);
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {() => {
        return (
          <UploadDocumentFromStoredFileDialogInner
            onCancel={onCancel}
            isDocumentNameRequired={isDocumentNameRequired}
            claim={claim}
            isMultiplePhotos={isMultiplePhotos}
            fileId={attachment?.stored_file?.id}
          />
        );
      }}
    </Formik>
  );
}

UploadDocumentFromStoredFileDialog.propTypes = {
  initialValues: requiredIf(PropTypes.object, (props) => props.open),
  claimId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  isMultiplePhotos: PropTypes.bool,
  isDocumentNameRequired: PropTypes.bool,
  attachment: PropTypes.object,
};

function CreateSubDocumentDialog(props) {
  const { document, claim, onCancel, onSubDocCreated } = props;
  const classes = useStyles();
  const {
    isLoading,
    isError,
    data: numPages,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/documents/${document.id}/pdf_num_pages`);

  if (isError) {
    onCancel();
  }

  return (
    <>
      <Formik
        initialValues={{
          ...document,
          document_pages: '',
          type: '',
          summary: '',
          document_name: `${document.document_name} (1)`,
        }}
        validationSchema={Yup.object().shape({
          ...documentFieldsValidation(),
          document_date: Yup.date().required('required'),
          document_date_received: Yup.date(),
          file_size: Yup.number(),
          document_pages: Yup.string()
            .required('Invalid pages format, use e.g. 1, 5-7, 9')
            .test('is-valid-pages', 'Invalid pages format, use e.g. 1, 5-7, 9', function (value) {
              if (isLoading) {
                return true;
              }

              if (!value) {
                return false;
              }

              const onlyDigitsRegex = /^\d+$/;
              const splitPages = value.split(',').map((str) => str.trim());

              for (const currentVal of splitPages) {
                if (currentVal.includes('-')) {
                  // currentVal should be a valid page range (e.g. 1-3)

                  const rangeSplit = currentVal.split('-').map((val) => val.trim());
                  if (rangeSplit.length !== 2) {
                    return false;
                  }

                  if (!rangeSplit.every((val) => onlyDigitsRegex.test(val))) {
                    return false;
                  }

                  const minRange = parseInt(rangeSplit[0]);
                  const maxRange = parseInt(rangeSplit[1]);
                  if (minRange > maxRange) {
                    return false;
                  }

                  if (!(_.inRange(minRange, 1, numPages + 1) && _.inRange(maxRange, 1, numPages + 1))) {
                    return this.createError({ message: `Out of bound page reference, limit is ${numPages}` });
                  }
                } else {
                  // currentVal Should be a valid page number

                  if (!onlyDigitsRegex.test(currentVal)) {
                    // should contains only digits
                    return false;
                  }

                  const pageNum = parseInt(currentVal);
                  if (!_.inRange(pageNum, 1, numPages + 1)) {
                    return this.createError({ message: `page number ${pageNum} is out of range` });
                  }
                }
              }
              return true;
            }),
        })}
        onSubmit={async (values, formikProps) => {
          try {
            const res = await axios.post(
              `/api/v1/claims/${claim.id}/documents/${document.id}/create_sub_document`,
              values
            );
            await onSubDocCreated(res.data);
          } catch (error) {
            reportAxiosError(error);
            formikProps.setSubmitting(false);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;

          return (
            <CardDialog
              isDialog={true}
              title={`Create sub Document from '${document.document_name}'`}
              maxWidth="xs"
              onClose={onCancel}
              preventClose={isSubmitting}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextFieldFormik
                    id="document_pages"
                    label="Pages"
                    placeholder="e.g. 1, 5-7, 9"
                    className={classes.textField}
                    InputLabelProps={inputLabelProps}
                    fullWidth
                  />
                </Grid>
                <DocumentCoreFields classes={classes} claim={claim} showOnly={isSubmitting} />
              </Grid>
              <div className={classes.buttonsContainer}>
                {isLoading ? (
                  <LoadingIndicator />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || isLoading}
                  >
                    Add sub document
                  </Button>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

CreateSubDocumentDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubDocCreated: PropTypes.func.isRequired,
};

function EditDocumentDialog(props) {
  const { document, claim, open, onCancel, onUpdateDocument, showOnly, customButtonsComponent } = props;
  const [removeDocumentDialogOpen, setRemoveDocumentDialogOpen] = React.useState(false);
  const classes = useStyles();

  const showOnlyOrRemoved = showOnly || document.is_removed;

  if (!open) {
    return <></>;
  }

  return (
    <>
      <Formik
        initialValues={document}
        validationSchema={Yup.object().shape({ ...documentFieldsValidation(), file_size: undefined })} // we don't need to verify file size
        onSubmit={async (values, formikProps) => {
          try {
            const updatedValues = [
              'type',
              'exposure_ids',
              'document_name',
              'document_date',
              'document_date_received',
              'summary',
            ].reduce((acc, curr) => (values[curr] !== document[curr] ? { ...acc, [curr]: values[curr] } : acc), {});

            if (!_.isEmpty(updatedValues)) {
              await axios.patch(`/api/v1/claims/${document.claim_id}/documents/${document.id}`, updatedValues);
              await onUpdateDocument();
            }

            formikProps.resetForm();
          } catch (error) {
            reportAxiosError(error);
            formikProps.setSubmitting(false);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;

          const action = showOnlyOrRemoved ? undefined : (
            <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CLAIM_DOCUMENT}>
              <IconButton onClick={() => setRemoveDocumentDialogOpen(true)} disabled={isSubmitting}>
                <DeleteIcon />
              </IconButton>
            </PermissionsButtonWrapper>
          );

          return (
            <CardDialog
              isDialog={true}
              open={open}
              title={showOnlyOrRemoved ? 'View Document' : 'Edit Document'}
              maxWidth="xs"
              onClose={onCancel}
              action={action}
              preventClose={isSubmitting}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div>
                    Document #:{' '}
                    <a
                      href={`/api/v1/claims/${document.claim_id}/documents/${document.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {document.claim_internal_id}
                    </a>
                  </div>
                  {document.is_removed && (
                    <Typography variant="subtitle2" color="secondary">
                      Removed at {serverDateTimeToLocal(document.removed_date)}, reason: {document.removed_reason}
                    </Typography>
                  )}
                </Grid>
                <DocumentCoreFields classes={classes} claim={claim} showOnly={showOnlyOrRemoved} />
              </Grid>
              {customButtonsComponent
                ? customButtonsComponent
                : !showOnlyOrRemoved && (
                    <div className={classes.buttonsContainer}>
                      <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                        Update
                      </Button>
                    </div>
                  )}
            </CardDialog>
          );
        }}
      </Formik>
      <RemoveDocumentDialog
        document={document}
        open={removeDocumentDialogOpen}
        onCancel={() => setRemoveDocumentDialogOpen(false)}
        onRemove={async () => {
          await onUpdateDocument();
          setRemoveDocumentDialogOpen(false);
        }}
      />
    </>
  );
}

EditDocumentDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  document: requiredIf(PropTypes.object, (props) => props.open),
  onUpdateDocument: requiredIf(PropTypes.func, (props) => !props.showOnly),
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
  customButtonsComponent: PropTypes.node,
};

function RemoveDocumentDialog(props) {
  const { open, document, onCancel, onRemove } = props;

  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        remove_reason: '',
      }}
      validationSchema={Yup.object().shape({
        remove_reason: Yup.string().required('Required'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.delete(`/api/v1/claims/${document.claim_id}/documents/${document.id}`, { data: values });
          await onRemove();
          formikProps.resetForm();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title="Remove Document"
            maxWidth="xs"
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div>The document will be removed from the claim.</div>
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik id="remove_reason" label="Reason" className={classes.textField} fullWidth />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              ` <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                Remove
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

RemoveDocumentDialog.propTypes = {
  document: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

const MEDIA_TYPES_CATEGORIES = {
  photos: { desc: 'Photos', file_types: 'image/*' },
  videos: { desc: 'Videos', file_types: 'video/*, ' + PROPRIETARY_FORMAT_VIDEO_FILE.map((x) => x.ext).join(', ') },
  other: { desc: 'Documents', file_types: 'application/*, text/*' },
};

const MEDIA_TYPES = {
  damage_photo: { type: MEDIA_TYPES_CATEGORIES.photos },
  video: { type: MEDIA_TYPES_CATEGORIES.videos },
  security_video: { type: MEDIA_TYPES_CATEGORIES.videos },
  cctv_video: { type: MEDIA_TYPES_CATEGORIES.videos },
  other: { type: MEDIA_TYPES_CATEGORIES.other },
};

function UploadMultipleMedia({
  claimId,
  alternativeUploadUrl,
  mediaType,
  exposureIds,
  onSubmit,
  onClose,
  initialValues,
  showFileBoxOnly,
  uploadOutsideOfClaim,
  claimType,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProcess, setUploadProcess] = useState({});
  const { claim } = useClaim(); // Can be empty if uploadOutsideOfClaim is true
  const { user } = useCms();
  const classes = useStyles();
  const urlPrefix = alternativeUploadUrl ? alternativeUploadUrl : `/api/v1/claims/${claimId}/documents`;
  const { documentTypesDict } = useOrganization();
  const fileTypeTitle = MEDIA_TYPES[mediaType].type.desc;

  const handleUploadFiles = async (values) => {
    setIsUploading(true);
    const files = values.files;

    let filesStorageFilename = {};
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // NOTE: upload_url might throw due to not-allowed extension, but since we filter for images only, we'll report it as a general error
      const response = await axios.post(`${urlPrefix}/upload_url`, {
        file_size: file.size,
        file_type: file.type,
        file_name: file.name,
      });
      const { upload_url: uploadUrl, storage_filename: storageFileName } = response.data;

      filesStorageFilename[i] = storageFileName;

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProcess((uploadProcess) => {
            return { ...uploadProcess, [i]: percentCompleted };
          });
        },
      };

      await axios.put(uploadUrl, file, config);
    }

    const uploadFinishedValues = files.map((file, idx) => {
      const documentNameSuffix = files.length > 1 ? ` (${idx + 1})` : ''; // We want to add a (1), (2)... suffix only for more than one file upload
      const documentName = values['document_name']
        ? `${values['document_name']}${documentNameSuffix}`
        : fileNameToDocumentName(file.name);
      return {
        exposure_ids: exposureIds ? exposureIds : values['exposure_ids'],
        storage_filename: filesStorageFilename[idx],
        file_size: file.size,
        file_type: file.type,
        file_name: filenamify(documentName + getFilenameExtensionWithDot(file.name), { replacement: '' }),
        last_modified: new Date(files[0].lastModified).toISOString(),
        type: values['type'],
        summary: values['summary'],
        document_name: documentName,
        document_date: values['document_date'],
      };
    });

    const documentsIdsResponse = await axios.post(`${urlPrefix}/upload_multiple_finished_callback`, {
      documents: uploadFinishedValues,
    });
    await onSubmit(documentsIdsResponse.data.objects);
  };

  let relatedExposure = null;
  if (!uploadOutsideOfClaim) {
    // Normal flow...
    // If exposuresIds is given, it's overrides the user's exposures (for example when adding damage estimation to a specific exposure)
    relatedExposure = exposureIds
      ? exposureIds
      : claim.exposures.find((exposure) => exposure.handling_adjuster_id === user.id);
  }

  return (
    <Formik
      initialValues={{
        ...getUploadDocumentInitialValues(
          uploadOutsideOfClaim ? null : claim,
          [relatedExposure ? relatedExposure.id : 0],
          true
        ),
        files: [],
        type: mediaType,
        ...initialValues,
      }}
      validationSchema={Yup.object().shape({
        ...documentFieldsValidation(!uploadOutsideOfClaim),
        document_name: Yup.string().max(DOCUMENT_NAME_MAX_SIZE),
        document_date: Yup.date().required('required'),
        file_size: Yup.number(),
        files: Yup.array().min(1, 'At least one file required'),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await handleUploadFiles(values);
          formikProps.resetForm();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue, errors } = formikProps;

        const handleDrop = (files) => {
          setFieldValue('files', [...values.files, ...files]);
        };

        const newFileNameAdditionalText =
          values['files'].length > 1
            ? `files will be named ${values['document_name']} (1), ${values['document_name']} (2)...`
            : '';
        const dropzoneRef = React.createRef();
        return (
          <CardDialog
            isDialog
            title={`Upload ${fileTypeTitle}`}
            maxWidth="xs"
            fullWidth
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MultiFileDropZone
                  onDrop={handleDrop}
                  dropzoneRef={dropzoneRef}
                  disabled={isSubmitting}
                  acceptTypes={MEDIA_TYPES[mediaType].type.file_types}
                />
              </Grid>
              <Grid item xs={12}>
                <FormHelperText error>{errors['files']}</FormHelperText>
              </Grid>
              {!showFileBoxOnly && (
                <DocumentCoreFields
                  classes={classes}
                  claim={claim}
                  fieldsTitle={fileTypeTitle}
                  showOnly={isSubmitting}
                  nameFieldAdditionalText={
                    values['document_name']
                      ? newFileNameAdditionalText
                      : 'Enter name to change files original file names'
                  }
                  hideExposuresLabels={uploadOutsideOfClaim}
                  allowedDocumentTypes={
                    uploadOutsideOfClaim ? getAllowedDocumentTypes(documentTypesDict, claimType) : undefined
                  }
                />
              )}
              <Grid item xs={12}>
                {values.files.length > 0 && (
                  <div className={classes.inputContainer}>
                    <Typography variant="subtitle1">Chosen Files:</Typography>
                    {values.files.map((file, idx) => (
                      <div key={idx}>
                        <div key={idx} style={{ display: 'inline-flex', alignItems: 'center' }}>
                          <Typography display="block">{file.name}</Typography>
                          {isUploading ? (
                            <div style={{ margin: 10, width: ' 200px' }}>
                              <Line percent={uploadProcess ? uploadProcess[idx] : 0} strokeWidth="4" />
                            </div>
                          ) : (
                            <IconButton onClick={() => setFieldValue('values', values.files.splice(idx, 1))}>
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                        <br />
                      </div>
                    ))}
                  </div>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Upload
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

UploadMultipleMedia.propTypes = {
  claimId: requiredIf(PropTypes.number, (props) => !props.alternativeUploadUrl),
  alternativeUploadUrl: requiredIf(PropTypes.string, (props) => !props.claimId),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  mediaType: PropTypes.oneOf(Object.keys(MEDIA_TYPES)).isRequired,
  showFileBoxOnly: PropTypes.bool,
  exposureIds: requiredIf(PropTypes.array, (props) => props.showFileBoxOnly),
  initialValues: PropTypes.object,
  uploadOutsideOfClaim: PropTypes.bool,
  claimType: requiredIf(PropTypes.string, (props) => props.uploadOutsideOfClaim),
};

function MultiFileDropZone({ onDrop, dropzoneRef, disabled, acceptTypes }) {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Dropzone onDrop={onDrop} ref={dropzoneRef} accept={acceptTypes} disabled={disabled}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          const styles = isDragActive ? { ...baseStyle, ...activeStyle } : baseStyle;
          return (
            <div
              {...getRootProps({
                // Disable click and keydown behavior
                onClick: (event) => event.stopPropagation(),
                onKeyDown: (event) => {
                  if (event.keyCode === 32 || event.keyCode === 13) {
                    event.stopPropagation();
                  }
                },
              })}
              style={styles}
            >
              {disabled ? (
                <Grid container style={{ height: '100%' }} justify="center" alignItems="center" direction="column">
                  <Grid item>
                    <LoadingIndicator />
                  </Grid>
                  <Grid item>
                    <Typography display="block">Uploading...</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container style={{ height: '100%' }} justify="center" alignItems="center" direction="column">
                  <Grid item>
                    <CloudUploadIcon />
                  </Grid>
                  <Grid item>
                    <input {...getInputProps()} />
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={dropzoneRef.current ? dropzoneRef.current.open : null}
                    >
                      Browse for files
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography display="block">or drag and drop</Typography>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
}

MultiFileDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  dropzoneRef: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  acceptTypes: PropTypes.string,
};

export {
  CreateSubDocumentDialog,
  DOCUMENT_NAME_MAX_SIZE,
  DocumentCoreFields,
  DocumentCoreFieldsInitialValues,
  EditDocumentDialog,
  ExtractDocumentPhotosContainer,
  MultiFileDropZone,
  UploadDocumentFromStoredFileDialog,
  UploadMultipleMedia,
};
export default UploadDocument;
