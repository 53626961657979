import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';

import { CriticalityLevel, SafeComponentPage } from '~/components/core/SafeComponent';

import '@fontsource/roboto'; // Defaults to weight 400

import CmsMain from './components/CmsMain';
import theme from './theme/material-ui-theme';
import { reportReactError } from './Utils';
import addMethodsToYup from './yupInitialization';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    reportReactError(error, info);
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      scope.setTags({ criticality_level: CriticalityLevel.HIGHEST });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // TODO: make something better
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

const muiTheme = createMuiTheme(theme);

class App extends Component {
  render() {
    addMethodsToYup();
    return (
      <SafeComponentPage location="top_level_app_component" criticalityLevel={CriticalityLevel.HIGHEST}>
        <BrowserRouter>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <CmsMain />
          </MuiThemeProvider>
        </BrowserRouter>
      </SafeComponentPage>
    );
  }
}

export default App;
