import PERMISSIONS_DESCRIPTIONS from '~/server_shared/generated-types/PERMISSIONS_DESCRIPTIONS';

import { __DEFAULT_PROFILES__ } from './DefaultProfiles';
import { __PERMISSION_VERBS__ } from './PermissionVerbs';
import { ConfigEnumBase, InnerSectionItem, SectionHeader } from './types';

const ALL_VERBS = Object.values(__PERMISSION_VERBS__);
const READ_WRITE = [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE];
const NONE_WRITE = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.WRITE];
const NONE_READ_WRITE = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE];
const NONE_READ = [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ];

/**
 *
 * @type {InnerSectionsConfig}
 */
const DEFAULT_SECTIONS_DEFINITION = {
  DEFAULT: {
    id: 'DEFAULT',
    title: '',
  },
};

const DEFAULT_PROFILES = new Proxy(__DEFAULT_PROFILES__, {
  get(target, name) {
    return target[name]?.id;
  },
});

class PERMISSION_ACTIONS extends ConfigEnumBase {
  // Claim Section
  CLAIM = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.CLAIM.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CLAIM.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CLAIM.description,
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ],
    sectionHeaderIndex: 0,
    innerSections: {
      GENERAL: {
        id: 'GENERAL',
        title: 'General',
      },
      PAYMENTS: {
        id: 'PAYMENTS',
        title: 'Payments',
      },
      DOCUMENTS_TAB: {
        id: 'DOCUMENTS_TAB',
        title: 'Documents Tab',
      },
      POLICY_TAB: {
        id: 'POLICY_TAB',
        title: 'Policy Tab',
      },
      LEGAL_ACTIONS: {
        id: 'LEGAL_ACTIONS',
        title: 'Legal Actions',
      },
    },
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> General
  DATE_OF_LOSS = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.DATE_OF_LOSS.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.DATE_OF_LOSS.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.DATE_OF_LOSS.description,
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  TIME_OF_LOSS = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.TIME_OF_LOSS.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.TIME_OF_LOSS.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.TIME_OF_LOSS.description,
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  DATE_OF_REPORT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.DATE_OF_REPORT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.DATE_OF_REPORT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.DATE_OF_REPORT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  REPORTER = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.REPORTER.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.REPORTER.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.REPORTER.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXTERNAL_CLAIM_NUMBER = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.EXTERNAL_CLAIM_NUMBER.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.EXTERNAL_CLAIM_NUMBER.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.EXTERNAL_CLAIM_NUMBER.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  COVERAGE_DECISION = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.COVERAGE_DECISION.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.COVERAGE_DECISION.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.COVERAGE_DECISION.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  NOTE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.NOTE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.NOTE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.NOTE.description,
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE, __PERMISSION_VERBS__.FULL],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 2,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  NOTIFICATION = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.NOTIFICATION.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.NOTIFICATION.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.NOTIFICATION.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 3,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CHANGE_CLAIM_OWNER = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CHANGE_CLAIM_OWNER.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CHANGE_CLAIM_OWNER.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CHANGE_CLAIM_OWNER.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CHANGE_EXPOSURE_OWNER = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CHANGE_EXPOSURE_OWNER.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CHANGE_EXPOSURE_OWNER.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CHANGE_EXPOSURE_OWNER.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPORT_CLAIM = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.EXPORT_CLAIM.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.EXPORT_CLAIM.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.EXPORT_CLAIM.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 5,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  LEGAL_ACTIONS = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.LEGAL_ACTIONS.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.LEGAL_ACTIONS.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.LEGAL_ACTIONS.description,
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.LEGAL_ACTIONS,
    index: 6,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Payments
  INDEMNITY_RESERVE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.INDEMNITY_RESERVE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.INDEMNITY_RESERVE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.INDEMNITY_RESERVE.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPENSES_RESERVE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.EXPENSES_RESERVE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.EXPENSES_RESERVE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.EXPENSES_RESERVE.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EXPENSES_RESERVE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_RESERVE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_RESERVE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_RESERVE.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 2,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  INDEMNITY_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.INDEMNITY_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.INDEMNITY_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.INDEMNITY_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 3,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  EXPENSES_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.EXPENSES_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.EXPENSES_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.EXPENSES_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 4,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.GENERAL_EXPENSES_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 5,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_INDEMNITY_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CANCEL_INDEMNITY_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CANCEL_INDEMNITY_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CANCEL_INDEMNITY_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 6,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CANCEL_EXPENSES_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CANCEL_EXPENSES_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CANCEL_EXPENSES_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 7,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CANCEL_GENERAL_EXPENSES_PAYMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CANCEL_GENERAL_EXPENSES_PAYMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CANCEL_GENERAL_EXPENSES_PAYMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CANCEL_GENERAL_EXPENSES_PAYMENT.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 8,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  OFAC_OVERRIDE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.OFAC_OVERRIDE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.OFAC_OVERRIDE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.OFAC_OVERRIDE.description,
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT.description,
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT.description,
    allowedVerbs: NONE_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.PAYMENTS,
    index: 9,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Documents Tab
  CLAIM_DOCUMENT = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CLAIM_DOCUMENT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CLAIM_DOCUMENT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CLAIM_DOCUMENT.description,
    allowedVerbs: [__PERMISSION_VERBS__.READ, __PERMISSION_VERBS__.WRITE, __PERMISSION_VERBS__.FULL],
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.DOCUMENTS_TAB,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Claim -> Policy Tab
  MANUAL_POLICY = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.MANUAL_POLICY.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.MANUAL_POLICY.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.MANUAL_POLICY.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.POLICY_TAB,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  API_POLICY = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.API_POLICY.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.API_POLICY.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.API_POLICY.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.POLICY_TAB,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  MOI = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.MOI.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.MOI.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.MOI.description,
    allowedVerbs: READ_WRITE,
    section: this.CLAIM,
    innerSection: this.CLAIM.innerSections.GENERAL,
    index: 0,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  // Contact
  CONTACT = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.CONTACT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CONTACT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CONTACT.description,
    allowedVerbs: ALL_VERBS,
    sectionHeaderIndex: 1,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    preconditions: [],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CONTACT_PII = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CONTACT_PII.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CONTACT_PII.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CONTACT_PII.description,
    allowedVerbs: NONE_WRITE,
    section: this.CONTACT,
    innerSection: this.CONTACT.innerSections.DEFAULT,
    index: 0,
    preconditions: [
      {
        field: this.CONTACT,
        needsToBeAtLeast: __PERMISSION_VERBS__.WRITE,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.WRITE,
        errorMessage:
          'If the Contact PII permission is set to Write, the Contacts and Parties permission must also be Write or Full',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  W9_TIN = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.W9_TIN.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.W9_TIN.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.W9_TIN.description,
    allowedVerbs: NONE_WRITE,
    section: this.CONTACT,
    innerSection: this.CONTACT.innerSections.DEFAULT,
    index: 1,
  });
  CREATE_FNOL = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.CREATE_FNOL.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CREATE_FNOL.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CREATE_FNOL.description,
    allowedVerbs: NONE_WRITE,
    sectionHeaderIndex: 2,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    preconditions: [
      {
        field: this.CONTACT,
        needsToBeAtLeast: __PERMISSION_VERBS__.WRITE,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.WRITE,
        errorMessage: 'If the FNOL permission is set to Write, the Contacts and Parties permission must also be Write',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  QA = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.QA.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.QA.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.QA.description,
    allowedVerbs: NONE_WRITE,
    sectionHeaderIndex: 3,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  GENERAL_EMAIL_CLASSIFICATION = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.GENERAL_EMAIL_CLASSIFICATION.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.GENERAL_EMAIL_CLASSIFICATION.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.GENERAL_EMAIL_CLASSIFICATION.description,
    allowedVerbs: NONE_READ_WRITE,
    sectionHeaderIndex: 4,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.WRITE,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  REPORT = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.REPORT.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.REPORT.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.REPORT.description,
    allowedVerbs: NONE_READ_WRITE,
    sectionHeaderIndex: 5,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  ADJUSTER_DASHBOARD = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.ADJUSTER_DASHBOARD.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.ADJUSTER_DASHBOARD.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.ADJUSTER_DASHBOARD.description,
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 0,
    preconditions: [
      {
        field: this.REPORT,
        needsToBeAtLeast: __PERMISSION_VERBS__.READ,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.READ,
        errorMessage:
          'If the Adjuster Dashboard permission is set to Read, the Report and Dashboards permission must also be Read',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  MANAGEMENT_REPORTS_AND_DASHBOARDS = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.MANAGEMENT_REPORTS_AND_DASHBOARDS.description,
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    preconditions: [
      {
        field: this.REPORT,
        needsToBeAtLeast: __PERMISSION_VERBS__.READ,
        whenVerbIsEqualOrGreaterThan: __PERMISSION_VERBS__.READ,
        errorMessage:
          'If the Management Reports and Dashboards permission is set to Read, the Report and Dashboards permission must also be Read',
      },
    ],
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.READ,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  VIEW_GENERAL_REPORTS_SUITE = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.VIEW_GENERAL_REPORTS_SUITE.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.VIEW_GENERAL_REPORTS_SUITE.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.VIEW_GENERAL_REPORTS_SUITE.description,
    allowedVerbs: NONE_READ,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  CREATE_REPORTS_AND_DASHBOARDS = new InnerSectionItem({
    title: PERMISSIONS_DESCRIPTIONS.CREATE_REPORTS_AND_DASHBOARDS.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.CREATE_REPORTS_AND_DASHBOARDS.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.CREATE_REPORTS_AND_DASHBOARDS.description,
    allowedVerbs: NONE_WRITE,
    section: this.REPORT,
    innerSection: this.REPORT.innerSections.DEFAULT,
    index: 1,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });
  SYSTEM_CONFIGURATION = new SectionHeader({
    title: PERMISSIONS_DESCRIPTIONS.SYSTEM_CONFIGURATION.title,
    subtitle: PERMISSIONS_DESCRIPTIONS.SYSTEM_CONFIGURATION.subtitle,
    description: PERMISSIONS_DESCRIPTIONS.SYSTEM_CONFIGURATION.description,
    allowedVerbs: [__PERMISSION_VERBS__.FORBIDDEN, __PERMISSION_VERBS__.FULL],
    sectionHeaderIndex: 6,
    innerSections: DEFAULT_SECTIONS_DEFINITION,
    defaultProfiles: {
      [DEFAULT_PROFILES.ADJUSTER]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.SUPERVISOR]: __PERMISSION_VERBS__.FULL,
      [DEFAULT_PROFILES.PHONE_REP]: __PERMISSION_VERBS__.FORBIDDEN,
      [DEFAULT_PROFILES.IT_MANAGER]: __PERMISSION_VERBS__.FULL,
      [DEFAULT_PROFILES.EXTERNAL_USER]: __PERMISSION_VERBS__.FORBIDDEN,
    },
  });

  constructor() {
    super();
    this._setKeysOnChildren();
    Object.values(this).forEach((value) => {
      if (value instanceof SectionHeader && value.preconditions) {
        value.preconditions.forEach((precondition) => {
          if (typeof precondition.field === 'string') {
            precondition.field = this[precondition.field];
          }
        });
      }
    });
  }
}

const __PERMISSION_ACTIONS__ = new PERMISSION_ACTIONS();

export { __PERMISSION_ACTIONS__ };
