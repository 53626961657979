import type { ReactNode } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useMinimizeBars } from '~/components/core/MinimizedBar/Context/ToastifyBarContext';
import { reportErrorInProductionOrThrow } from '~/Utils';

import type { MinimizedComponentBaseProps, MinimizedDialogProps, MinimizedType } from '../types';

type MinimizedDialogContextType<T extends MinimizedComponentBaseProps> = {
  add: (dialogContainerProps: MinimizedDialogProps<T>) => {
    handleCloseDialog: () => void;
    openDialog: () => void;
    updateHeader: (header: ReactNode) => void;
  };
  isDialogContextEmpty: () => boolean;
};

const MinimizedDialogContext = createContext<MinimizedDialogContextType<MinimizedComponentBaseProps>>({
  add: () => {
    reportErrorInProductionOrThrow('Calling Minimized Dialog from CommunicationActionsMenu without context');
    return { handleCloseDialog: noop, openDialog: noop, updateHeader: noop };
  },
  isDialogContextEmpty: () => true,
});

interface DialogContainerType {
  id: string;
  type: MinimizedType;
  Component: React.ComponentType<{ open: boolean }>;
}

export const MinimizedDialogContextProvider: React.FC = ({ children }) => {
  const dialogContainersRef = useRef<DialogContainerType[]>([]);
  const barIdsRef = useRef<string[]>([]);
  const { addBar, canAddBarByType, removeBarById } = useMinimizeBars();
  const [displayedDialogs, setDisplayDialogs] = useState<string[]>([]);

  useEffect(() => {
    // Clean all the bars on component unmount
    return () => {
      barIdsRef.current.forEach(removeBarById);
    };
  }, [barIdsRef, removeBarById]);

  const setDisplayedId = useCallback(
    (id: string, isDisplayed: boolean) => {
      if (!isDisplayed) {
        setDisplayDialogs((prevState) => prevState.filter((currentId) => id !== currentId));
      } else if (!displayedDialogs.includes(id)) {
        setDisplayDialogs((prevState) => [...prevState, id]);
      }
    },
    [displayedDialogs]
  );

  const closeDisplayedDialog = useCallback(
    (id) => {
      setDisplayedId(id, false);
    },
    [setDisplayedId]
  );

  const add = useCallback(
    ({ dialogComponent, type, barHeader, dialogProps }: MinimizedDialogProps<MinimizedComponentBaseProps>) => {
      const disableMinimized = dialogProps?.disableMinimized || !canAddBarByType(type);
      const id = uuidv4();
      let currentUpdateBar = noop;
      let currentRemoveBar = noop;
      let currentBarHeader = barHeader;

      const DialogComponent = withMinimized(dialogComponent, {
        dialogProps,
        disableMinimized,
        dialogOnMinimized: disableMinimized
          ? noop
          : () => {
              const component = {
                type,
                children: currentBarHeader,
                onClick: openDialog,
              };
              const { updateBar, barId } = addBar(component);
              currentUpdateBar = updateBar;
              currentRemoveBar = () => {
                removeBarById(barId);
                barIdsRef.current = barIdsRef.current.filter((currentId) => currentId !== barId);
              };

              setDisplayedId(id, false);
              barIdsRef.current.push(barId);
            },
      });

      dialogContainersRef.current = [
        ...dialogContainersRef.current,
        {
          id,
          type,
          Component: DialogComponent,
        },
      ];
      setDisplayedId(id, true);

      const handleCloseDialog = () => {
        currentRemoveBar();

        dialogContainersRef.current = dialogContainersRef.current.filter(
          (dialogComponent: DialogContainerType) => dialogComponent.id !== id
        );
        setDisplayedId(id, false);
      };

      const openDialog = () => {
        currentRemoveBar();
        setDisplayedId(id, true);
      };

      const updateHeader = (barHeader: ReactNode) => {
        currentBarHeader = barHeader;
        currentUpdateBar({ children: barHeader });
      };

      return { handleCloseDialog, openDialog, updateHeader };
    },
    [addBar, canAddBarByType, setDisplayedId, removeBarById]
  );

  const isDialogContextEmpty = useCallback(() => isEmpty(dialogContainersRef.current), [dialogContainersRef]);

  const contextValue = useMemo(
    () => ({ add, closeDisplayedDialog, isDialogContextEmpty }),
    [add, closeDisplayedDialog, isDialogContextEmpty]
  );

  return (
    <MinimizedDialogContext.Provider value={contextValue}>
      <>
        {dialogContainersRef.current.map(({ id, Component }) => (
          <Component key={id} open={displayedDialogs.includes(id)} />
        ))}
        {children}
      </>
    </MinimizedDialogContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMinimizedDialogs = (): MinimizedDialogContextType<any> => {
  return useContext(MinimizedDialogContext);
};

const withMinimized = (
  WrappedComponent: React.ComponentType<MinimizedComponentBaseProps>,
  {
    dialogOnMinimized,
    disableMinimized,
    dialogProps,
  }: {
    dialogOnMinimized: () => void;
    disableMinimized: boolean;
    dialogProps: MinimizedComponentBaseProps;
  }
) => {
  const WithMinimizedComponent = ({ open }: { open: boolean }) => {
    const { onMinimized, ...rest } = dialogProps;

    return (
      <WrappedComponent
        {...rest}
        disableMinimized={disableMinimized}
        onMinimized={() => {
          dialogOnMinimized();
          if (onMinimized) {
            onMinimized();
          }
        }}
        open={open}
      />
    );
  };

  return WithMinimizedComponent;
};

const withMinimizedDialog = (Component: React.FC): React.FC => {
  const WrappedComponent = (props: Record<string, unknown>) => {
    return (
      <MinimizedDialogContextProvider>
        <Component {...props} />
      </MinimizedDialogContextProvider>
    );
  };

  WrappedComponent.displayName = `WithMinimizedDialog(${Component.displayName || Component.name || 'Component'})`;

  return WrappedComponent;
};

export default withMinimizedDialog;
