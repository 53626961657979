import React from 'react';

import type {
  CoveredItemRow,
  InvoiceAnalysisResponse,
  LineItemRow,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';

interface FooterProps {
  disabled: boolean;
  onClose: () => void;
  onSubmit: (data: InvoiceAnalysisResponse) => void;
  onSave: (data: InvoiceAnalysisResponse) => void;
  lineItems: LineItemRow[];
  aggregateCoverages: CoveredItemRow[];
}

export const Footer: React.FC<FooterProps> = ({
  disabled,
  onClose,
  onSubmit,
  onSave,
  aggregateCoverages,
  lineItems,
}) => (
  <ButtonsContainer>
    {disabled ? (
      <Button
        variant="outlined"
        color="primary"
        className="mr-20"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    ) : (
      <>
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          className="mr-20"
          onClick={() => {
            onSave({ line_items: lineItems, coverages: aggregateCoverages });
          }}
        >
          Save
        </Button>
        <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          className="mr-20"
          onClick={() => {
            onSubmit({ line_items: lineItems, coverages: aggregateCoverages });
          }}
        >
          Save & Finish
        </Button>
      </>
    )}
  </ButtonsContainer>
);
