import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import Typography from '~/components/core/Atomic/Typography';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';

import { serverDateTimeToLocal } from '../../DateTimeUtils';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import LoadingIndicator from '../LoadingIndicator';
import { TextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

const CANELLABLE_PAYMENT_STATUSES = {
  IN_PROGRESS: 'in_progress',
  SUBMITTED: 'submitted',
  ISSUED: 'issued',
};
function CancelPaymentDialog(props) {
  const { open, claimId, exposureFinanceMetadata, payableType, paymentRequestId, onCancel, onUpdate, isClaimClosed } =
    props;

  const baseResourceUrl = exposureFinanceMetadata
    ? `/api/v1/claims/${claimId}/exposures/${exposureFinanceMetadata.id}/${payableType}/payment_requests/${paymentRequestId}`
    : `/api/v1/claims/${claimId}/general_expenses/payment_requests/${paymentRequestId}`;
  const { isLoading, isError, data: paymentRequest } = useDataFetcher(baseResourceUrl);

  // The 'paymentRequest' may initially be undefined and will be populated on the second fetch.
  const {
    shouldMovePendingCancelUponCancellation,
    isPaymentAllowedOnClosedExposureForPayableType,
    adjustReserveAfterPaymentCancellation,
  } = usePaymentsConfiguration(paymentRequest?.payment_method);

  const classes = useStyles();

  if (isLoading) {
    return (
      <CardDialog isDialog open={open} onClose={onCancel} title="Cancel Payment">
        <LoadingIndicator isError={isError} />
      </CardDialog>
    );
  }

  const payeesDescription = paymentRequest.payees.map((paymentPayee) => paymentPayee.contact.full_name).join(', ');

  const isExposureClosed = exposureFinanceMetadata && exposureFinanceMetadata.is_closed;
  const shouldReopenExposureAndClaim =
    (isExposureClosed || isClaimClosed) && !isPaymentAllowedOnClosedExposureForPayableType(payableType);

  return (
    <Formik
      initialValues={{ cancel_note: '' }}
      validationSchema={Yup.object().shape({ cancel_note: Yup.string().required('Required') })}
      onSubmit={async (values, formikProps) => {
        try {
          await axios.delete(baseResourceUrl, {
            data: {
              ...values,
              is_mark_cancelled_only: paymentRequest.was_payment_sent,
              reopen_closed_exposure_and_claim: shouldReopenExposureAndClaim,
            },
          });
          await onUpdate();
          formikProps.resetForm();
        } catch (error) {
          reportAxiosError(error);
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const getConfirmTextComponent = () => {
          const alertText = `
            Canceling the payment will cause ${isExposureClosed ? 'the exposure' : ''} ${
            isExposureClosed && isClaimClosed ? ' and ' : ''
          } ${isClaimClosed ? 'the claim ' : ''} to be re-opened
          `;

          let initialText = `Payment of ${Intl.NumberFormat(localeDetails.locale, {
            style: 'currency',
            currency: paymentRequest.request_currency || localeDetails.currency,
          }).format(
            paymentRequest.decision_amount_orig_currency || paymentRequest.decision_amount
          )} to ${payeesDescription} was requested by ${
            paymentRequest.requester_user.username
          } on ${serverDateTimeToLocal(paymentRequest.request_date)}`;

          const movingToPendingCancellationMessage =
            'Are you sure you want to initiate the cancellation process? The cancellation status will be updated at a later time.';

          const inProgressText = ', but was not yet issued. Are you sure you want to cancel it?';

          const recordOnlyText =
            ' was marked as record only. Marking this payment as cancelled will not affect the payment issuance. Are you sure you want to cancel it?';

          const statusText = paymentRequest.financial_status === 'submitted' ? 'submitted' : 'issued';

          const commonMessageForIssuedAndSubmitted = `, and was already ${statusText} by the system. Marking this payment as cancelled will not prevent the payment from going out.`;

          if (paymentRequest.is_record_only) {
            initialText += recordOnlyText;
          } else {
            switch (paymentRequest.financial_status) {
              case CANELLABLE_PAYMENT_STATUSES.IN_PROGRESS:
                initialText += inProgressText;
                break;
              case CANELLABLE_PAYMENT_STATUSES.SUBMITTED:
              case CANELLABLE_PAYMENT_STATUSES.ISSUED:
                initialText += `${
                  shouldMovePendingCancelUponCancellation
                    ? '. ' + movingToPendingCancellationMessage
                    : commonMessageForIssuedAndSubmitted
                }`;
                break;
            }
          }

          const finalText = adjustReserveAfterPaymentCancellation
            ? 'Reserve will be updated accordingly'
            : 'Reserve will not be affected, so make sure to set it if necessary.';

          return (
            <div className={classes.textField}>
              {shouldReopenExposureAndClaim && (
                <div className={classes.wrapper}>
                  <AlertBanner alertType={AlertBanner.ALERT_TYPES.ERROR} note={alertText} />
                </div>
              )}
              <Typography>{initialText}</Typography>
              <Typography>{finalText}</Typography>
            </div>
          );
        };

        return (
          <CardDialog
            isDialog
            open={open}
            onClose={onCancel}
            title={paymentRequest.was_payment_sent ? 'Mark Issued Payment as Cancelled' : 'Cancel Payment'}
            preventClose={isSubmitting}
          >
            {getConfirmTextComponent()}

            <TextFieldFormik id="cancel_note" label="Reason" fullWidth className={classes.textField} />
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {paymentRequest.was_payment_sent ? 'Mark Cancelled' : 'Cancel Payment'}
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CancelPaymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  claimId: PropTypes.number.isRequired,
  exposureFinanceMetadata: PropTypes.object,
  payableType: PropTypes.oneOf(['indemnity', 'expenses']),
  paymentRequestId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isClaimClosed: PropTypes.bool.isRequired,
};

export default CancelPaymentDialog;
