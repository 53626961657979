import { makeStyles } from '@material-ui/core';

import colors from '../theme/tailwind/colors';

const DRAWER_WIDTH = 48 + 2 * 16;
const CMS_BAR_HEIGHT = 60;
const actionColor = '#32C7CA';

const rootStyles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
  },
  appBar: {
    paddingLeft: DRAWER_WIDTH,
    zIndex: theme.zIndex.drawer - 1,
    maxHeight: CMS_BAR_HEIGHT,
    minHeight: CMS_BAR_HEIGHT,
    backgroundColor: colors.slate[200],
  },
  cmsInner: {
    /* overflow: auto; */
    position: 'absolute',
    bottom: 0,
    top: CMS_BAR_HEIGHT,
    left: DRAWER_WIDTH,
    /* width: 100%, */
    /* height: 100%, */
    display: 'flex',
    flexDirection: 'column',
    right: 0,
  },
  toolbar: {
    maxHeight: CMS_BAR_HEIGHT,
    minHeight: CMS_BAR_HEIGHT,
    paddingLeft: 12,
  },
  appTitle: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  actionButton: {
    backgroundColor: actionColor,
    '&:hover': {
      backgroundColor: '#23aebb',
      borderColor: '#0062cc',
    },
    '&:active': {
      backgroundColor: '#23aebb',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0,0,0,0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.06)',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 8,
      width: 'auto',
    },
  },
  searchIcon: {
    padding: '0px 16px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: actionColor,
  },
  inputRoot: {
    color: 'black ',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
    transition: theme.transitions.create('width'),
    letterSpacing: '-0.3px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
});

// TODO merge this file with styles.js

const useRootStyles = makeStyles(rootStyles, { index: 1 });

export default rootStyles;
export { DRAWER_WIDTH, useRootStyles };
