import React from 'react';
import PropTypes from 'prop-types';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import {
  getPolicyNumberPrefix,
  isCancelledAfterEffectiveDT,
  isExpirationAfterEffectiveDT,
  isRetroactiveBeforeEffectiveDT,
} from '~/PolicyUtils';
import { COUNTRY_TO_STATE_MAP } from '~/Types';
import { isCountryWithStates } from '~/Utils';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import CardDialog from '../../../../CardDialog';
import ContactTextFieldFormik from '../../../../ContactTextFieldFormik';
import { Heading } from '../../../../core';
import CountryAutocompleteFormik from '../../../../CountryAutocompleteFormik';
import ConfiguredFields from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import useOrganization from '../../../../OrganizationContext';
import StateAutocompleteFormik from '../../../../StateAutocompleteFormik';
import TextFieldFormik, {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  NumericTextFieldFormik,
  TimePickerTextFieldFormik,
} from '../../../../TextFieldFormik';

import styles from './EditPolicyDialog.module.scss';

function EditPolicyDialog(props) {
  const { onClose, onSaveClick, policy = {}, isSaving, policyStatus, lob, isEdit = true } = props;
  const classes = useStyles();
  const { lobConfigurationsDict = {} } = useLobConfiguration();
  const { coverageConfiguration } = useOrganization();
  const { incidentConfiguration } = useIncidentConfiguration();
  const configuredFieldsConfiguration = incidentConfiguration?.policy?.configured_fields ?? [];

  const existingPolicyToFormikFields = (policy) => {
    const configuredFieldsInitialValues = {};
    configuredFieldsConfiguration.forEach((fieldConfig) => {
      configuredFieldsInitialValues[fieldConfig.id] = (policy.configured_fields_values ?? {})[fieldConfig.id];
    });
    const customFieldsInitialValues = {};
    if (policy.custom_fields?.length > 0) {
      policy.custom_fields.forEach((field) => {
        const key = Object.keys(field)[0];
        customFieldsInitialValues[key] = field[key].value;
      });
    }
    return {
      ...{
        policy_number: '',
        policy_effective_date: '',
        policy_expiration_date: '',
        insured_contact_id: '',
        is_claims_made: false,
        retroactive_date: '',
      },
      configured_fields_values: configuredFieldsInitialValues,
      ...policy,
      custom_fields: customFieldsInitialValues,
    };
  };

  const initialValues = existingPolicyToFormikFields(policy);

  const customFieldsValidationShape = {};
  if (policy.custom_fields?.length > 0) {
    policy.custom_fields.forEach((field) => {
      const key = Object.keys(field)[0];
      customFieldsValidationShape[key] = Yup.string().nullable();
    });
  }

  const validationSchema = Yup.object().shape({
    policy_number: Yup.string().required('Required'),
    policy_effective_date: Yup.string().required('Required'),
    effective_time: Yup.string()
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
      .nullable(),
    policy_expiration_date: Yup.string()
      .required('Required')
      .test(
        'expiration_after_effective_dt',
        'Expiration date must be after effective date',
        isExpirationAfterEffectiveDT
      ),
    expiration_time: Yup.string()
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
      .nullable(),
    cancelled_date: Yup.string()
      .test('cancelled_after_effective_dt', 'Cancelled date must be after effective date', isCancelledAfterEffectiveDT)
      .nullable(),
    cancelled_time: Yup.string()
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
      .nullable(),
    insured_contact_id: Yup.number().required('Required'),
    configured_fields_values: Yup.object()
      .shape(getAdditionalDataValidations(configuredFieldsConfiguration))
      .nullable(),
    custom_fields: Yup.object().shape(customFieldsValidationShape).nullable(),
    retroactive_date: policy?.is_claims_made
      ? Yup.string()
          .nullable()
          .required('Required')
          .test(
            'retroactive_before_effective_dt',
            'Retroactive date must be before effective date',
            isRetroactiveBeforeEffectiveDT
          )
      : undefined,
    retroactive_time: policy?.is_claims_made
      ? Yup.string()
          .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
          .nullable()
      : undefined,
    runoff_provision: policy?.is_claims_made ? Yup.number().nullable() : undefined,
  });

  const transformCustomFields = (values) => {
    const customFields = policy?.custom_fields ?? [];
    return [
      ...customFields.map((field) => {
        const key = Object.keys(field);
        field[key] = {
          ...field[key],
          value: values[key],
        };
        return {
          ...field,
        };
      }),
    ];
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, formikProps) => {
        try {
          await onSaveClick({
            ...values,
            custom_fields: transformCustomFields(values.custom_fields ?? {}),
          });
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        if (values.policy_country && !isCountryWithStates(values.policy_country)) {
          values.policy_state = null;
        } else if (
          values.policy_country &&
          isCountryWithStates(values.policy_country) &&
          !COUNTRY_TO_STATE_MAP[values.policy_country][values.policy_state]
        ) {
          values.policy_state = null;
        }

        return (
          <CardDialog
            isDialog
            title={`${isEdit ? 'Edit' : 'Set'} Policy Details`}
            maxWidth="sm"
            onClose={onClose}
            footerActions={
              <DialogFooterActions
                primaryLabel="Update"
                onClickPrimary={handleSubmit}
                onClickSecondary={onClose}
                disabled={isSaving}
              />
            }
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="policy_number"
                  label={getPolicyNumberPrefix(lob, lobConfigurationsDict) + ' Policy Number'}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldFormik
                  id="policy_status"
                  label="Status"
                  fullWidth
                  disabled={true}
                  value={policyStatus}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <ContactTextFieldFormik
                  id="insured_contact"
                  label="Policyholder"
                  fullWidth
                  acceptedRoles={['insured']}
                  className={classes.textField}
                  fixedSearchResults
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="policy_effective_date"
                  label="Effective Date"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePickerTextFieldFormik
                  id="effective_time"
                  label="Effective Time"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="cancelled_date"
                  label="Cancellation Date"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePickerTextFieldFormik
                  id="cancelled_time"
                  label="Cancellation Time"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="policy_expiration_date"
                  label="Expiration Date"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePickerTextFieldFormik
                  id="expiration_time"
                  label="Expiration Time"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              {policy?.is_claims_made && (
                <>
                  <Grid item xs={4}>
                    <DatePickerTextFieldFormik
                      id="retroactive_date"
                      label="Retroactive Date"
                      fullWidth
                      className={classes.textField}
                      disableFuture
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePickerTextFieldFormik
                      id="retroactive_time"
                      label="Retroactive Time"
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <NumericTextFieldFormik
                      id="runoff_provision"
                      label={<span className="text-sm">Days of Runoff Provision</span>}
                      fullWidth
                      className={classes.textField}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={isLocaleRegionIsUs() ? 4 : 6}>
                <TextFieldFormik id="policy_type" label="Policy Type" fullWidth className={classes.textField} />
              </Grid>
              {isLocaleRegionIsUs() && (
                <Grid item xs={4}>
                  <StateAutocompleteFormik
                    id="policy_state"
                    label="Policy State"
                    fullWidth
                    className={classes.textField}
                    statesDict={COUNTRY_TO_STATE_MAP[getIn(values, 'policy_country') || 'US']}
                    disabled={!COUNTRY_TO_STATE_MAP[getIn(values, 'policy_country')]}
                  />
                </Grid>
              )}
              <Grid item xs={isLocaleRegionIsUs() ? 4 : 6}>
                <CountryAutocompleteFormik
                  id="policy_country"
                  label="Policy Country"
                  fullWidth
                  className={classes.textField}
                  countriesConfigurationKey="contact_location"
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="limit_per_policy"
                  label="Policy Limit"
                  disabled={!coverageConfiguration.limit_per_policy}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <MonetaryValueTextFieldFormik
                  id="limit_per_claim"
                  label="Claim Limit"
                  disabled={!coverageConfiguration.limit_per_claim}
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerTextFieldFormik
                  id="renewal_date"
                  label="Renewal Date"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="policy_document_url"
                  label="Document URL"
                  fullWidth
                  className={classes.textField}
                />
              </Grid>
            </Grid>

            {(configuredFieldsConfiguration.length > 0 || policy.custom_fields?.length > 0) && (
              <div className={styles.additionalInfoWrapper}>
                <Heading variant="h3">Additional Details</Heading>
                {configuredFieldsConfiguration.length > 0 && (
                  <ConfiguredFields values={values} customFields={configuredFieldsConfiguration} inline />
                )}
                {policy.custom_fields?.length > 0 && (
                  <Grid container spacing={1}>
                    {policy.custom_fields.map((field) => (
                      <Grid item xs={6} key={Object.keys(field)[0]}>
                        <TextFieldFormik
                          id={'custom_fields.' + Object.keys(field)[0]}
                          label={field[Object.keys(field)[0]].display_name}
                          fullWidth
                          className={classes.textField}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            )}
          </CardDialog>
        );
      }}
    </Formik>
  );
}

EditPolicyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  policy: PropTypes.object,
  isSaving: PropTypes.bool.isRequired,
  policyStatus: PropTypes.string.isRequired,
  lob: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

export default EditPolicyDialog;
