import React from 'react';
import type { NumberFormatProps } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { InputLabelProps } from '@material-ui/core';
import { omit } from 'lodash';

import type { TextFieldProps } from '~/components/core/Molecules/Fields/TextField';
import TextField from '~/components/core/Molecules/Fields/TextField';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { isLocalEnv, reportErrorInProductionOrThrow } from '~/Utils';

export interface NumericTextFieldProps extends Omit<NumberFormatProps, 'customInput' | 'onValueChange' | 'value'> {
  id?: string;
  label?: string;
  value?: number | string;
  placeholder?: string;
  symbol?: string;
  groupSeparator?: string;
  decimalSeparator?: string;
  onChange?: (value: number | undefined) => void;
  inlineEditableField?: boolean;
  className?: string;
}

interface MonetaryValueTextFieldProps {
  id?: string;
  className?: string;
  label?: string;
  showOnly?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onChange?: (value: number | undefined) => void;
  InputLabelProps?: Partial<InputLabelProps>;
  value?: number | string;
  inlineEditableField?: boolean;
}

export const NumericTextField: React.FC<NumericTextFieldProps> = ({
  id,
  label,
  value,
  groupSeparator,
  decimalSeparator,
  placeholder,
  symbol,
  onChange,
  CustomInput,
  inlineEditableField,
  className,
  ...rest
}) => {
  return (
    <NumberFormat
      customInput={CustomInput}
      value={value}
      thousandSeparator={groupSeparator ? groupSeparator : true}
      decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
      decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
      id={id}
      label={label}
      prefix={symbol}
      placeholder={placeholder}
      onValueChange={(numberValues) => {
        const { floatValue } = numberValues;
        if (onChange) {
          onChange(floatValue);
        }
      }}
      inlineEditableField={inlineEditableField}
      className={className}
      {...rest}
    />
  );
};

const CustomInput = ({ inlineEditableField, className, ...customInputProps }: TextFieldProps<string>) => (
  <TextField
    id="text-field"
    {...omit(customInputProps, ['inputMode'])}
    preventOverrideOnChange
    inlineEditableField={inlineEditableField}
    className={className}
  />
);

export const MonetaryValueTextField: React.FC<MonetaryValueTextFieldProps> = ({
  id,
  label,
  fullWidth,
  disabled,
  value,
  onChange,
  inlineEditableField,
  className,
  ...rest
}) => {
  const { currencyFormatter, symbol, groupSeparator, decimalSeparator } = useCurrencyFormatter();

  if (Object.keys(rest).length > 0 && isLocalEnv()) {
    reportErrorInProductionOrThrow(
      `Additional unimplemented props was passed to MonetaryValueTextField component (directly or from Multi/Single select fields), ${Object.keys(
        rest
      )}`,
      true
    );
  }

  return (
    <NumericTextField
      id={id}
      label={label}
      thousandSeparator={groupSeparator ? groupSeparator : true}
      decimalSeparator={decimalSeparator || '*'} // decimal separator must have 1 character value
      decimalScale={!decimalSeparator ? 0 : 2} // if currency does not have decimalSeparator set decimalScale to 0
      fixedDecimalScale
      allowNegative
      prefix={symbol}
      fullWidth={fullWidth}
      placeholder={currencyFormatter.format(0)}
      disabled={disabled}
      onChange={onChange}
      value={value}
      CustomInput={CustomInput}
      inlineEditableField={inlineEditableField}
      className={className}
      {...rest}
    />
  );
};
