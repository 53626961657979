import React from 'react';
import { ArrowExpand } from 'mdi-material-ui';

import { AiChatContainer } from '~/components/AiChat/AiChatContainer/AiChatContainer';
import { ChatEmptyState } from '~/components/AiChat/ChatEmptyState';
import { AiAvatar } from '~/components/AiChat/components/Message/AiAvatar';
import { useChatContext } from '~/components/AiChat/hooks/useChat';
import { ChatSidebarDialog } from '~/components/AiChat/SideBarDialog/ChatSidebarDialog';
import { SIDEBAR_DIALOG_CONFIG } from '~/components/AiChat/SideBarDialog/SidebarDialogConstants';
import { useClaim } from '~/components/ClaimContainer';
import { Text } from '~/components/core';
import cn from '~/Utils/cn';

export const ClaimChatDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { claim } = useClaim();

  const { chat, isChatLoading, isMinimized, handleMinimize } = useChatContext();

  const title = isChatLoading
    ? `Loading chat data for claim ${claim.claim_id_display}`
    : chat?.chat_title || `Claim Chat ${claim.claim_id_display}`;

  return (
    <div aria-label="Clive AI Assistant">
      <ChatSidebarDialog onClose={onClose} chatTitle={title} dialogTitle={`Claim ${claim.claim_id_display}`}>
        {!chat && isChatLoading ? <ChatEmptyState /> : null}
        {chat ? (
          <AiChatContainer
            messagesContainerClassName={cn(SIDEBAR_DIALOG_CONFIG.paddingX, 'pb-24')}
            showGreetingAsMessage={false}
            inputFieldType="inline"
            preDefinedMessages={[
              'Who are the involved parties in this claim?',
              'Summarize this claim',
              'What is the status of the claim?',
            ]}
          />
        ) : null}
      </ChatSidebarDialog>
      {isMinimized ? (
        <div className="border-4 fixed bottom-32 right-32 flex h-[72px] w-[220px] cursor-pointer rounded-full border-solid border-slate-600 bg-aiChat bg-cover bg-no-repeat shadow-lg">
          <div
            onClick={handleMinimize}
            className="row flex w-full items-center justify-between gap-12 rounded-full bg-cliveGradiant pl-20 pr-4 align-middle font-bold text-white bg-blend-lighten"
          >
            <ArrowExpand className="text-white" />
            <Text className="text-white" variant={Text.VARIANTS.XL} weight={Text.WEIGHTS.SEMI_BOLD}>
              Clive
            </Text>
            <AiAvatar className="h-[60px] w-[60px]" />
          </div>
        </div>
      ) : null}
    </div>
  );
};
