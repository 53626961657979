import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';

import { CONTACT_GOV_ID_VALIDATION_REGEXES } from '../../Types';

const MAX_SSN_LENGTH = 9;
const ERROR_MESSAGES = {
  MAX_LENGTH: `Cannot exceed ${MAX_SSN_LENGTH} characters`,
  SSN_FORMAT: 'Social security number must be a 9 or 5 digit number',
  NIN_FORMAT: 'Invalid National Insurance Number format',
};
export const LABELS = {
  SSN: 'Social Security number',
  NIN: 'National Insurance number',
  GOVERNMENT_ID: 'Government ID',
};

export const isUsRegion = () => localeDetails.locale.region === 'US';
export const isUkRegion = () => localeDetails.locale.region === 'GB';
export const validateSsn = (number) =>
  !!number && isUsRegion() ? number.match(new RegExp(CONTACT_GOV_ID_VALIDATION_REGEXES.SSN)) : true;
export const validateNin = (number) =>
  !!number && isUkRegion() ? number.match(new RegExp(CONTACT_GOV_ID_VALIDATION_REGEXES.NIN)) : true;
export const governmentIdValidationSchema = Yup.string()
  .nullable()
  .max(MAX_SSN_LENGTH, ERROR_MESSAGES.MAX_LENGTH)
  .uppercase()
  .test('is-ssn-valid', ERROR_MESSAGES.SSN_FORMAT, validateSsn)
  .test('is-nin-valid', ERROR_MESSAGES.NIN_FORMAT, validateNin);

export const getGovernmentIdValidationSchemaByCountry = (country) => {
  if (country === 'US') {
    return Yup.string()
      .nullable()
      .max(MAX_SSN_LENGTH, ERROR_MESSAGES.MAX_LENGTH)
      .uppercase()
      .matches(new RegExp(CONTACT_GOV_ID_VALIDATION_REGEXES.SSN), ERROR_MESSAGES.SSN_FORMAT);
  } else if (country === 'GB') {
    return Yup.string()
      .nullable()
      .max(MAX_SSN_LENGTH, ERROR_MESSAGES.MAX_LENGTH)
      .uppercase()
      .matches(new RegExp(CONTACT_GOV_ID_VALIDATION_REGEXES.NIN), ERROR_MESSAGES.NIN_FORMAT);
  } else {
    return Yup.string().nullable().max(16, 'Cannot exceed 16 characters');
  }
};
