import type React from 'react';

import { ActionFooter } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionFooter';
import { CoverageDecisionActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/CoverageDecision/CoverageDecisionActionInner';
import { InvoiceAnalysisActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/InvoiceAnalysis/InvoiceAnalysisActionInner';
import { InvoiceAnalysisFooter } from '~/components/AiChat/SideBarDialog/Actions/Action/InvoiceAnalysis/InvoiceAnalysisFooter';
import { PaymentActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/Payment/PaymentActionInner';
import { PaymentFooter } from '~/components/AiChat/SideBarDialog/Actions/Action/Payment/PaymentFooter';
import { ReserveUpdateActionInner } from '~/components/AiChat/SideBarDialog/Actions/Action/ReserveUpdate/ReserveUpdateActionInner';
import type { AiAction } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';

export interface ActionsPanelProps {
  action: AiAction;
}

export interface ActionFooterProps {
  onExecute: () => void;
  isDone: boolean;
  action: AiAction;
}

interface InnerTypeDescriptor {
  title: string;
  InnerActionComponent: React.ElementType<ActionsPanelProps>;
  ActionFooterComponent?: React.ElementType<ActionFooterProps>;
}

export const ACTION_TYPE_DESCRIPTOR: Record<string, InnerTypeDescriptor> = {
  [AI_ACTION_TYPES.COVERAGE_DECISION]: {
    title: 'Decide on Coverage',
    InnerActionComponent: CoverageDecisionActionInner,
    ActionFooterComponent: ActionFooter,
  },
  [AI_ACTION_TYPES.RESERVE_UPDATE]: {
    title: 'Update Reserve',
    InnerActionComponent: ReserveUpdateActionInner,
    ActionFooterComponent: ActionFooter,
  },
  [AI_ACTION_TYPES.PAYMENT]: {
    title: 'Settle Payment',
    InnerActionComponent: PaymentActionInner,
    ActionFooterComponent: PaymentFooter,
  },
  [AI_ACTION_TYPES.INVOICE_ANALYSIS_RECOMMENDATION]: {
    title: 'Invoice Analysis',
    InnerActionComponent: InvoiceAnalysisActionInner,
    ActionFooterComponent: InvoiceAnalysisFooter,
  },
};
