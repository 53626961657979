import React from 'react';
import { Divider } from '@material-ui/core';

const DividerCell = () => (
  <div className="px-4">
    <Divider orientation="vertical" variant="middle" className="m-0" />
  </div>
);

export default DividerCell;
