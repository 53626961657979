import React from 'react';

import type { COLORS } from '~/components/core/StatusChipCore';
import StatusChipCore from '~/components/core/StatusChipCore';
import CalledOffIcon from '~/components/icons/CalledOffIcon';
import CheckIcon from '~/components/icons/CheckIcon';
import ClockIcon from '~/components/icons/ClockIcon';
import EyeIcon from '~/components/icons/EyeIcon';
import InProgressIcon from '~/components/icons/InProgressIcon';
import RejectedIcon from '~/components/icons/RejectedIcon';
import WarningCircleIcon from '~/components/icons/WarningCircle';

const STATUSES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  COMPLETED: 'COMPLETED',
  IN_PROGRESS: 'IN PROGRESS',
  PENDING: 'PENDING',
  DONE: 'DONE',
  REJECTED: 'REJECTED',
  CALLED_OFF: 'CALLED OFF',
  NOT_ACTIVE: 'NOT ACTIVE',
  ACTIVE: 'ACTIVE',
  SUBMITTED: 'SUBMITTED',
  ISSUED: 'ISSUED',
  SUCCESS: 'SUCCESS',
  STOPPED: 'STOPPED',
  CANCELLED: 'CANCELLED',
  PENDING_APPROVAL: 'PENDING APPROVAL',
  CANCEL_PENDING: 'CANCEL PENDING',
  FOR_REVIEW: 'PENDING REVIEW',
  ERROR: 'ERROR',
};

type Status = (typeof STATUSES)[keyof typeof STATUSES];

interface StatusChipProps {
  status?: Status;
  withIcon?: boolean;
}

interface StatusChipComponentProps extends React.FC<StatusChipProps> {
  STATUSES: typeof STATUSES;
}

const STATUS_DESCRIPTOR = {
  [STATUSES.OPEN]: { icon: CheckIcon, color: 'green' },
  [STATUSES.CLOSED]: { icon: CheckIcon, color: 'red' },
  [STATUSES.COMPLETED]: { icon: CheckIcon, color: 'green' },
  [STATUSES.IN_PROGRESS]: { icon: InProgressIcon, color: 'yellow' },
  [STATUSES.PENDING]: { icon: ClockIcon, color: 'orange' },
  [STATUSES.DONE]: { icon: CheckIcon, color: 'green' },
  [STATUSES.REJECTED]: { icon: RejectedIcon, color: 'red' },
  [STATUSES.CALLED_OFF]: { icon: CalledOffIcon, color: 'grey' },
  [STATUSES.NOT_ACTIVE]: { icon: WarningCircleIcon, color: 'grey' },
  [STATUSES.ACTIVE]: { icon: CheckIcon, color: 'green' },
  [STATUSES.SUBMITTED]: { icon: CheckIcon, color: 'green' },
  [STATUSES.ISSUED]: { icon: CheckIcon, color: 'green' },
  [STATUSES.SUCCESS]: { icon: CheckIcon, color: 'green' },
  [STATUSES.STOPPED]: { icon: RejectedIcon, color: 'red' },
  [STATUSES.CANCELLED]: { icon: CalledOffIcon, color: 'grey' },
  [STATUSES.PENDING_APPROVAL]: { icon: ClockIcon, color: 'orange' },
  [STATUSES.CANCEL_PENDING]: { icon: ClockIcon, color: 'grey' },
  [STATUSES.FOR_REVIEW]: { icon: EyeIcon, color: 'blue' },
  [STATUSES.ERROR]: { icon: RejectedIcon, color: 'red' },
};

const StatusChip: StatusChipComponentProps = ({ status = STATUSES.OPEN, withIcon = false }) => {
  const { color, icon } = STATUS_DESCRIPTOR[status] || {};

  return <StatusChipCore status={status} Icon={withIcon ? icon : null} color={color as COLORS} />;
};

StatusChip.STATUSES = STATUSES;

export default StatusChip;
