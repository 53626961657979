import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import AssistanceAiBanner from '~/components/core/AssistanceAiBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { getOverrideInitialValues } from '~/components/Documents/GenAiPaymentSuggestion/helper';
import PaymentRequestDynamicContainer from '~/components/exposures/PaymentRequestContainer/PaymentRequestDynamicContainer';

import CardDialog from '../../CardDialog';
import { Sparkle } from '../../icons';

import styles from './GenAiPaymentSuggestion.module.scss';

const InvoicePaymentDialog = ({
  open,
  setOpen,
  claim,
  document,
  exposureId,
  paymentInitialValues,
  onPayment = noop,
}) => {
  const payableType = 'indemnity'; // 'indemnity' or 'expenses';
  const [defaultExposureId] = document.exposure_ids || [];
  const overrideInitialValues = getOverrideInitialValues({
    paymentInitialValues,
    payableType,
  });

  return (
    <CardDialog
      isDialog={true}
      title={
        <AssistanceAiBanner
          title="AI Assistant Payment Suggestion"
          size={AssistanceAiBanner.SIZE_VARIANTS.SM}
          iconSize={20}
          weight={AssistanceAiBanner.WEIGHTS.MEDIUM}
        />
      }
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={() => {
        setOpen(false);
      }}
    >
      <Grid container spacing={1}>
        <Grid item md={4}>
          {paymentInitialValues ? (
            <PaymentRequestDynamicContainer
              claimId={claim.id}
              exposureId={exposureId || defaultExposureId}
              payableWithReserveType={payableType}
              cardDialogProps={{
                isDialog: false,
                maxWidth: 'md',
                fullWidth: true,
              }}
              overrideInitialValues={overrideInitialValues}
              forceManualDataInit
              onClose={() => {
                setOpen(false);
                onPayment();
              }}
              onUpdate={() => {}}
              paymentSuggestion={{ source: 'document_ai', values: paymentInitialValues }}
            />
          ) : (
            <div className={styles.aiLoaderContainer}>
              <Sparkle animated size={48} />
            </div>
          )}
        </Grid>
        <Grid item md={8}>
          {/* pdf viewer */}
          <object
            data={`/api/v1/claims/${claim.id}/documents/${document.id}`}
            style={{ height: '85vh', width: '100%' }}
          />
        </Grid>
      </Grid>
    </CardDialog>
  );
};

InvoicePaymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onPayment: PropTypes.func,
  document: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  exposureId: PropTypes.number,
  paymentInitialValues: PropTypes.object,
};

export default InvoicePaymentDialog;
