import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import { isExpirationAfterEffectiveDT } from '../../PolicyUtils';
import { CONFIGURATION_FEATURES_NAMES } from '../../Types';
import { isFeatureEnabled } from '../../Utils';
import CardDialog from '../CardDialog';
import { useClaim } from '../ClaimContainer';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { useCms } from '../hooks/useCms';
import useGlobalAddresses from '../hooks/useGlobalAddresses';
import { getLocationFields } from '../Location';
import LocationTextFieldFormik from '../LocationTextFieldFormik';
import {
  DatePickerTextFieldFormik,
  MonetaryValueTextFieldFormik,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';
import UsStatesSelectTextFieldFormik from '../UsStatesSelectTextFieldFormik';

import { useStyles } from '../../assets/styles';

function HomeManualPolicyDetailsDialog(props) {
  const { onClose, onSubmitPolicy, isExistingPolicy } = props;
  const { claim } = useClaim();
  const classes = useStyles();
  const { userOrganization } = useCms();

  const isConfigurableCoveragesEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_COVERAGES
  );
  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey: 'loss_location' });
  const is_us = isLocaleRegionIsUs();

  const getHomePolicyDetailsInitialValues = () => ({
    policy_number: '',
    policy_type: '',
    policy_effective_date: '',
    effective_time: '',
    policy_expiration_date: '',
    expiration_time: '',
    underwriting_company: '',
    insured_contact_id: '',
    insured_property_location: getLocationFields(defaultCountry),
    mortgagee_contact_id: '',
    coverage_a_limit: isConfigurableCoveragesEnabled ? undefined : '',
    coverage_b_limit: isConfigurableCoveragesEnabled ? undefined : '',
    coverage_c_limit: isConfigurableCoveragesEnabled ? undefined : '',
    coverage_d_limit: isConfigurableCoveragesEnabled ? undefined : '',
    coverage_e_limit: isConfigurableCoveragesEnabled ? undefined : '',
    coverage_f_limit: isConfigurableCoveragesEnabled ? undefined : '',
    aop_deductible: '',
    ...(is_us && { policy_state: '' }),
  });

  function getPolicyInitialValues(policy) {
    let policyInitialValues = {};
    for (const key of Object.keys(getHomePolicyDetailsInitialValues())) {
      policyInitialValues[key] = policy[key];
    }
    return policyInitialValues;
  }

  const requiredNumberForOldCoverages = isConfigurableCoveragesEnabled ? undefined : Yup.number().required('Required');

  const homePolicyDetailsValidationFields = {
    policy_number: Yup.string().required('Required'),
    policy_effective_date: Yup.string().required('Required'),
    effective_time: Yup.string()
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
      .nullable(),
    policy_expiration_date: Yup.string()
      .required('Required')
      .test(
        'expiration_after_effective_dt',
        'Expiration date must be after effective date',
        isExpirationAfterEffectiveDT
      ),
    expiration_time: Yup.string()
      .matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied')
      .nullable(),
    insured_contact_id: Yup.number().required('Required'),
    insured_property_location: Yup.object().test(
      'is-valid',
      `Must contain address1, city ${is_us ? 'and state' : ''}`,
      (value) => value.address1 && value.city && (value.state || !is_us)
    ),
    mortgagee_contact_id: Yup.number().nullable(),
    coverage_a_limit: requiredNumberForOldCoverages,
    coverage_b_limit: requiredNumberForOldCoverages,
    coverage_c_limit: requiredNumberForOldCoverages,
    coverage_d_limit: requiredNumberForOldCoverages,
    coverage_e_limit: requiredNumberForOldCoverages,
    coverage_f_limit: requiredNumberForOldCoverages,
    aop_deductible: Yup.number().required('Required'),
    ...(is_us && { policy_state: Yup.string().required('Required') }),
  };

  const newHomePolicyFormikInner = (formikProps) => {
    const { isSubmitting, handleSubmit, setFieldValue, values } = formikProps;

    return (
      <CardDialog isDialog={true} title="Policy Details" maxWidth="sm" onClose={onClose} preventClose={isSubmitting}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldFormik id="policy_number" label="Policy Number" fullWidth className={classes.textField} />
          </Grid>
          {is_us && (
            <Grid item xs={6}>
              <UsStatesSelectTextFieldFormik
                id="policy_state"
                label="Policy State"
                fullWidth
                className={classes.textField}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="insured_contact"
              label="Insured"
              className={classes.textField}
              acceptedRoles={['insured']}
              fullWidth
              fixedSearchResults
              claimId={claim.Id}
            />
          </Grid>
          <Grid item xs={6}>
            <ContactTextFieldFormik
              id="mortgagee_contact"
              label="Mortgagee"
              acceptedRoles={['mortgagee']}
              className={classes.textField}
              fullWidth
              fixedSearchResults
              claimId={claim.Id}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_effective_date"
              label="Effective Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="effective_time"
              label="Effective Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerTextFieldFormik
              id="policy_expiration_date"
              label="Expiration Date"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik
              id="expiration_time"
              label="Expiration Time"
              fullWidth
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <LocationTextFieldFormik
              id="insured_property_location"
              label="Insured Property Address"
              location={values['insured_property_location']}
              onChangeLocation={(location) => setFieldValue('insured_property_location', location)}
              countriesConfigurationKey="loss_location"
            />
          </Grid>
          {!isConfigurableCoveragesEnabled && (
            <>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_a_limit"
                  label="Coverage A Limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_b_limit"
                  label="Coverage b limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_c_limit"
                  label="Coverage c limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_d_limit"
                  label="Coverage d limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_e_limit"
                  label="Coverage e limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={4}>
                <MonetaryValueTextFieldFormik
                  id="coverage_f_limit"
                  label="Coverage f limit"
                  allowNegative={false}
                  className={classes.textField}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <MonetaryValueTextFieldFormik
              id="aop_deductible"
              label="AoP Deductible"
              allowNegative={false}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                {isExistingPolicy ? 'Save' : 'Create'}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardDialog>
    );
  };

  return (
    <Formik
      initialValues={
        isExistingPolicy
          ? {
              ...getPolicyInitialValues(claim.policy),
              type: 'home_policy',
              insured_contact_id: claim.policy.insured_contact_id,
              insured_property_location: claim.policy.insured_property_location || getLocationFields(defaultCountry),
            }
          : {
              ...getHomePolicyDetailsInitialValues(),
              type: 'home_policy',
              insured_contact_id: claim.policy.insured_contact_id,
              insured_property_location: claim.policy.insured_property_location || getLocationFields(defaultCountry),
            }
      }
      validationSchema={Yup.object().shape(homePolicyDetailsValidationFields)}
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmitPolicy(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => newHomePolicyFormikInner(formikProps)}
    </Formik>
  );
}

HomeManualPolicyDetailsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmitPolicy: PropTypes.func.isRequired,
  isExistingPolicy: PropTypes.bool,
};

export default HomeManualPolicyDetailsDialog;
