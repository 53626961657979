import React from 'react';
import { bool, string } from 'prop-types';

import { useStyles } from '~/assets/styles';

import SocialSecurityNumberFormat from '../SocialSecurityNumberFormat';
import { TextFieldFormik } from '../TextFieldFormik';

import { LABELS } from './utils';

const GovernmentIdFieldFormik = ({ id, disabled = false, showOnly = false, country = null }) => {
  const classes = useStyles();

  if (!country) {
    return null;
  }

  const isUsCountry = country === 'US';
  const isUkCountry = country === 'GB';
  let label;
  if (isUkCountry) {
    label = LABELS.NIN;
  } else if (isUsCountry) {
    label = LABELS.SSN;
  } else {
    label = LABELS.GOVERNMENT_ID;
  }

  const inputProps = isUsCountry ? { inputComponent: SocialSecurityNumberFormat } : undefined;

  return (
    <TextFieldFormik
      id={id}
      label={label}
      fullWidth
      className={classes.textField}
      InputProps={inputProps}
      disabled={disabled}
      showOnly={showOnly}
    />
  );
};

GovernmentIdFieldFormik.propTypes = {
  id: string.isRequired,
  disabled: bool,
  country: string,
  showOnly: bool,
};

export default GovernmentIdFieldFormik;
