import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { isArray } from 'lodash';

import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { useExposureSubReserves } from '~/components/hooks/useSubReserves';

import { CurrencyFormatterContextProvider } from '../../../CurrencyFormatterContext';
import { useCms } from '../../../hooks/useCms';
import { MonetaryValueTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';
import { SubReservePaymentAmountsFormik } from '../../SubReservesFormik';

import { useStyles } from '../../../../assets/styles';

const PaymentAmountFields = ({ readOnly, payableType, payableWithReserve, exposure }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const { isPrefillReserveAmountsCapabilityEnabled } = usePaymentsConfiguration();
  const { isMultipleSubReservePaymentsEnabled, isSubReservesConfigEnabled } = useExposureSubReserves(
    payableType,
    exposure
  );
  const classes = useStyles();
  const { userOrganization } = useCms();

  const reserveAmount = payableWithReserve.reserve;
  const isReserveZero = reserveAmount === 0;
  const handlePreFill = () => {
    setFieldValue('amount', reserveAmount);
    if (isMultipleSubReservePaymentsEnabled) {
      const subReserves = payableWithReserve.sub_reserves;
      const nonZeroSubReservesList = Object.keys(payableWithReserve.sub_reserves)
        .filter((subReserveKey) => subReserves[subReserveKey] !== 0)
        .map((subReserveKey) => ({ type_key: subReserveKey, amount: subReserves[subReserveKey] }));

      setFieldValue('sub_reserve_amounts', nonZeroSubReservesList);
    }
  };

  const shouldDisplayPreFill =
    isPrefillReserveAmountsCapabilityEnabled && (!isSubReservesConfigEnabled || isMultipleSubReservePaymentsEnabled);

  return (
    <>
      {shouldDisplayPreFill ? (
        <div className="pt-8">
          <Tooltip title="Current reserve amount is 0" disabled={!isReserveZero} placement="right-start" arrow>
            <span>
              <Button onClick={handlePreFill} variant="outlined" color="primary" disabled={isReserveZero}>
                Pre-fill {isMultipleSubReservePaymentsEnabled ? 'sub-reserves amounts' : 'reserve amount'}
              </Button>
            </span>
          </Tooltip>
        </div>
      ) : null}
      {isSubReservesConfigEnabled && !isMultipleSubReservePaymentsEnabled ? null : (
        <CurrencyFormatterContextProvider currency={values.request_currency}>
          <MonetaryValueTextFieldFormik
            id="amount"
            label="Amount"
            className={classes.textField}
            disabled={readOnly}
            showOnly={isMultipleSubReservePaymentsEnabled}
          />
        </CurrencyFormatterContextProvider>
      )}
      <SubReservePaymentAmountsFormik
        showOnly={readOnly}
        isSubmitting={isSubmitting}
        payableType={payableType}
        exposure={exposure}
        payableWithReserve={payableWithReserve}
      />
      {isArray(userOrganization.configuration?.supported_currencies) &&
      userOrganization.configuration.supported_currencies.length > 1 ? (
        <div>
          <TextFieldFormik
            id="request_currency"
            label="Currency"
            className={classes.textField}
            fullWidth
            select
            disabled={readOnly}
          >
            {userOrganization.configuration?.supported_currencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </TextFieldFormik>
        </div>
      ) : null}
    </>
  );
};

PaymentAmountFields.propTypes = {
  readOnly: PropTypes.bool,
  payableType: PropTypes.string.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  exposure: PropTypes.object,
};
export default PaymentAmountFields;
