import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';

import { Caption, Heading } from '../../../core';
import ConfigPanel from '../../../core/ConfigPanel/ConfigPanel';
import { useCurrencyFormatter } from '../../../CurrencyFormatterContext';
import { useCms } from '../../../hooks/useCms';
import useDataFetcher from '../../../useDataFetcher';

import styles from '../financeApprovals.module.scss';

const CurrentBalances = ({
  setIsLoading,
  setIsError,
  selectedPayments,
  shouldReload,
  onReload,
  setAccountKeysAndDisplayNames,
  filters,
}) => {
  const { userOrganization } = useCms();
  const { currencyFormatter } = useCurrencyFormatter();

  const {
    isLoading,
    isError,
    data: organizationBalances,
    reloadData: reloadBalances,
  } = useDataFetcher(`/api/v1/organizations/${userOrganization.id}/balances`);

  useEffect(() => {
    setIsError && setIsError(isError);
  }, [isError, setIsError]);

  useEffect(() => {
    setIsLoading && setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (shouldReload && !isLoading) {
      // noinspection JSIgnoredPromiseFromCall
      reloadBalances();
      onReload();
    }
  }, [shouldReload, isLoading, reloadBalances, onReload]);

  useEffect(() => {
    const accountKeysAndDisplayNames = {};
    organizationBalances &&
      organizationBalances.forEach((balance) => {
        const key = balance.balance_key || balance.sub_organization.id;
        accountKeysAndDisplayNames[key] = {
          filterName: balance.account_display_name || balance.sub_organization.name,
          sub_organization_id: balance.sub_organization?.id,
          sub_organization_ids: balance.sub_organization_ids,
          coverage_config_ids: balance.coverage_config_ids,
          excluded_coverage_config_ids: balance.excluded_coverage_config_ids,
          balance_key: key,
        };
      });
    setAccountKeysAndDisplayNames && setAccountKeysAndDisplayNames(accountKeysAndDisplayNames);
  }, [organizationBalances, setAccountKeysAndDisplayNames]);

  if (_.isEmpty(organizationBalances)) {
    return <div />;
  }

  const getFilteredPayments = (subOrganization, subOrgIds, coverageConfigIds, excludedCoverageConfigIds) => {
    if (!subOrganization && !subOrgIds && !coverageConfigIds && !excludedCoverageConfigIds) {
      return selectedPayments;
    }

    return selectedPayments.filter((payment) => {
      const isSubOrg = subOrganization && payment.sub_organization_id === subOrganization.id;
      const isSubOrgs = subOrgIds && subOrgIds.includes(payment.sub_organization_id);
      const isCoverageConfig =
        coverageConfigIds && coverageConfigIds.includes(payment.exposure_finance_metadata.coverage_config_id);

      const isNotExcludedCoverageConfig =
        excludedCoverageConfigIds &&
        !excludedCoverageConfigIds.includes(payment.exposure_finance_metadata.coverage_config_id);

      // we assume only one filter is passed from the BE and validate it there
      return (isSubOrg || isSubOrgs || isCoverageConfig) && isNotExcludedCoverageConfig;
    });
  };

  const getPaymentsSum = (payments) => {
    return _.sumBy(payments, (p) => p.amount);
  };

  const getSumText = (organizationBalance) => {
    const { sub_organization, sub_organization_ids, coverage_config_ids, excluded_coverage_config_ids } =
      organizationBalance;
    const filteredPayments = getFilteredPayments(
      sub_organization,
      sub_organization_ids,
      coverage_config_ids,
      excluded_coverage_config_ids
    );

    if (_.isEmpty(filteredPayments)) {
      return currencyFormatter.format(organizationBalance.pending_submission_sum);
    }

    const selectedSum = getPaymentsSum(filteredPayments);
    return `${currencyFormatter.format(
      organizationBalance.pending_submission_sum + selectedSum
    )} (${currencyFormatter.format(organizationBalance.pending_submission_sum)} + ${currencyFormatter.format(
      selectedSum
    )})`;
  };

  const shouldIncludeBalanceInFilter = (organizationBalance) => {
    const filteredBalanceKey = filters && filters.balance_key;
    const isMatchBalanceKey = filteredBalanceKey === organizationBalance.balance_key;
    // backwards compatible
    const isMatchSubOrgId =
      organizationBalance.sub_organization && filteredBalanceKey === organizationBalance.sub_organization.id;
    return !filteredBalanceKey || isMatchBalanceKey || isMatchSubOrgId;
  };

  return (
    <Grid container spacing={3} className={styles.balances}>
      {organizationBalances.map((organizationBalance) => (
        <>
          {shouldIncludeBalanceInFilter(organizationBalance) && (
            <Grid item xs={12} key={`organizationBalance_${organizationBalance.id}`}>
              {organizationBalance.account_display_name && (
                <Caption variant={Caption.VARIANTS.LABEL}>{organizationBalance.account_display_name}</Caption>
              )}
              {!organizationBalance.account_display_name && organizationBalance.sub_organization && (
                <Caption variant={Caption.VARIANTS.LABEL}>{organizationBalance.sub_organization.name}</Caption>
              )}
              <Grid container spacing={3}>
                {organizationBalance.balances.map((balance) => (
                  <BalanceCard key={`balance_${balance.balance_id}`} balance={balance} />
                ))}
                <Card
                  header="Sum of pending and selected payments"
                  description={getSumText(organizationBalance)}
                  className={styles.sum}
                />
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

CurrentBalances.propTypes = {
  setIsLoading: PropTypes.func,
  setIsError: PropTypes.func,
  selectedPayments: PropTypes.array,
  shouldReload: PropTypes.bool,
  onReload: PropTypes.func,
  setAccountKeysAndDisplayNames: PropTypes.func,
  filters: PropTypes.object,
};

const BalanceCard = ({ balance }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return <Card header={balance.balance_desc} description={currencyFormatter.format(balance.amount)} />;
};

BalanceCard.propTypes = {
  balance: PropTypes.object.isRequired,
};

const Card = ({ header = '', description = '', className = '' }) => {
  return (
    <Grid item xs={3}>
      <ConfigPanel
        className={className}
        mainActionChild={
          <div>
            <Caption variant={Caption.VARIANTS.LABEL}>{header}</Caption>
            <Heading variant={Heading.TYPES.H2}>{description}</Heading>
          </div>
        }
      />
    </Grid>
  );
};

Card.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default CurrentBalances;
