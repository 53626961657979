import React from 'react';

import { ChatDialogHeaderContainer } from '~/components/AiChat/SideBarDialog/components/ChatDialogHeaderContainer';
import { ChatDialogTitleBar } from '~/components/AiChat/SideBarDialog/components/ChatDialogTitleBar';

interface HeaderProps {
  dialogTitle: string;
}

export const Header: React.FC<HeaderProps> = ({ dialogTitle }) => (
  <ChatDialogHeaderContainer isCollapsed={false} className="w-full p-0">
    <ChatDialogTitleBar dialogTitle={dialogTitle} actions={[]} withAiIcon className="py-4" />
  </ChatDialogHeaderContainer>
);
