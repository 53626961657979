import { useEffect } from 'react';

import { useClaim } from '~/components/ClaimContainer';
import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export default function useFetchExposures() {
  const { userOrganization } = useCms();
  const { claim, reloadingUuid } = useClaim();
  const isEfficientClaimForFe = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const {
    isLoading: isExposuresLoading,
    isError: isExposuresError,
    data,
    reloadData,
  } = useDataFetcher(isEfficientClaimForFe ? `/api/v1/claims/${claim.id}/exposures_and_total_amounts` : undefined);

  // on update claim - update the exposures
  useEffect(() => {
    if (!isExposuresLoading && reloadingUuid) {
      reloadData();
    }
  }, [isExposuresLoading, reloadData, reloadingUuid]);

  if (!isEfficientClaimForFe) {
    return {
      isExposuresLoading: false,
      isExposuresError: false,
      exposures: claim.exposures,
      exposuresTotals: {
        total_indemnity_reserve_including_pending: claim.total_indemnity_reserve_including_pending,
        total_expenses_reserve_including_pending: claim.total_expenses_reserve_including_pending,
        total_indemnity_paid_including_pending_minus_recoveries:
          claim.total_indemnity_paid_including_pending_minus_recoveries,
        total_expenses_paid_including_pending_minus_recoveries:
          claim.total_expenses_paid_including_pending_minus_recoveries,
        total_deductible_amount: claim.total_deductible_amount,
      },
    };
  }

  if (isExposuresLoading) {
    return { isExposuresLoading, isExposuresError, exposures: [], exposuresTotals: {} };
  }

  const { exposures, exposures_totals: exposuresTotals } = data;
  return { isExposuresLoading, isExposuresError, exposures, exposuresTotals, reloadData };
}
