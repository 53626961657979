import React from 'react';
import { useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import iban from 'iban';
import { omit, set, some } from 'lodash';
import * as Yup from 'yup';

import { isVatEnabled } from '~/components/communications/ContactUtils';
import type { ContactFullModel } from '~/components/Contacts/types';
import {
  FINANCE_TAB_KEY,
  FORMIK_FINANCE_FIELD_IDS,
  SUPPORTED_IBAN_LANGUAGES,
} from '~/components/Contacts/UpsertContact/Tabs/Finance/constants';
import { getFullFinancePath } from '~/components/Contacts/UpsertContact/Tabs/Finance/utils';
import type { Tab } from '~/components/Contacts/UpsertContact/types';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import CONTACT_FINANCE_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_FINANCE_CHANGE_REASONS';

import ChangeReason from '../../ChangeReason';
import { getInitializedValues, getReasonValidationSchema } from '../../utils';
import TabWrapper from '../TabWrapper';

import Bank from './Bank';
import Vat from './Vat';

const FinanceTab: React.FC = () => {
  const { isSubmitting } = useFormikContext();
  const { isCreation } = useContact();

  const sharedProps = {
    disabled: isSubmitting,
  };

  return (
    <TabWrapper>
      <div className="mb-32 grid grid-cols-1 gap-20">
        <Vat {...sharedProps} />
        <Bank {...sharedProps} />
        {!isCreation ? (
          <ChangeReason
            reasonFieldId={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.REASON)}
            explanationFieldId={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.EXPLANATION)}
            reasonsDict={CONTACT_FINANCE_CHANGE_REASONS}
            {...sharedProps}
          />
        ) : null}
      </div>
    </TabWrapper>
  );
};

const useFinanceTab = (): Tab => {
  // No Formik Context
  const { originalContact, isCreation, currentCountry } = useContact();
  const { isUkBankTransferEnabledForAny, isIbanBankTransferEnabledForAny } = usePaymentsConfiguration();

  const getInitialValues = (contact?: ContactFullModel) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    setInitialValue(FORMIK_FINANCE_FIELD_IDS.IS_VAT_REGISTERED, contact?.is_vat_registered, null);
    setInitialValue(FORMIK_FINANCE_FIELD_IDS.VAT_NUMBER, contact?.vat_number);

    setInitialValue(FORMIK_FINANCE_FIELD_IDS.IBAN_LANGUAGE, contact?.language);
    setInitialValue(FORMIK_FINANCE_FIELD_IDS.IBAN, contact?.iban);

    setInitialValue(FORMIK_FINANCE_FIELD_IDS.UK_BANK_SORT_CODE, contact?.uk_sort_code);
    setInitialValue(FORMIK_FINANCE_FIELD_IDS.UK_BANK_ACCOUNT_NUMBER, contact?.uk_bank_account_number);

    if (!isCreation) {
      setInitialValue(FORMIK_FINANCE_FIELD_IDS.REASON, '');
      setInitialValue(FORMIK_FINANCE_FIELD_IDS.EXPLANATION, '');
    }

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    addValidation(FORMIK_FINANCE_FIELD_IDS.IS_VAT_REGISTERED, Yup.bool().nullable());
    addValidation(FORMIK_FINANCE_FIELD_IDS.VAT_NUMBER, Yup.string());

    addValidation(FORMIK_FINANCE_FIELD_IDS.IBAN_LANGUAGE, Yup.string().oneOf(SUPPORTED_IBAN_LANGUAGES.concat('other')));
    addValidation(
      FORMIK_FINANCE_FIELD_IDS.IBAN,
      Yup.string().test('finance-iban', 'Invalid IBAN', (value) => !value || iban.isValid(value))
    );

    addValidation(
      FORMIK_FINANCE_FIELD_IDS.UK_BANK_SORT_CODE,
      Yup.string().test(
        'finance-uk-sort-code',
        'Sort code must be of the form 20-00-00 or 200000',
        (sort_code) => !sort_code || /^\d{2}-\d{2}-\d{2}$/.test(sort_code) || /^\d{6}$/.test(sort_code)
      )
    );
    addValidation(
      FORMIK_FINANCE_FIELD_IDS.UK_BANK_ACCOUNT_NUMBER,
      Yup.string().matches(/^\d{8}$/, 'Account number must be 8 digits')
    );

    if (!isCreation) {
      addValidation(
        FORMIK_FINANCE_FIELD_IDS.REASON,
        Yup.string().test(
          'finance-reason',
          'Required',
          getReasonValidationSchema(originalContact, getValuesToCompare, getInitialValues)
        )
      );
      addValidation(
        FORMIK_FINANCE_FIELD_IDS.EXPLANATION,
        Yup.string().when(FORMIK_FINANCE_FIELD_IDS.REASON, {
          is: 'other',
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        })
      );
    }

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    const omittedValues = omit(values, [FORMIK_FINANCE_FIELD_IDS.REASON, FORMIK_FINANCE_FIELD_IDS.EXPLANATION]);
    return getInitializedValues(omittedValues);
  };

  const isHiddenForContact = () => {
    // Hidden Tabs cannot have absolute require fields, only conditionally required fields
    const vatEnabled = isVatEnabled(currentCountry);

    return !some([vatEnabled, isUkBankTransferEnabledForAny, isIbanBankTransferEnabledForAny], Boolean);
  };

  return {
    label: 'Finance',
    tabKey: FINANCE_TAB_KEY,
    tabComponent: FinanceTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
    isHiddenForContact,
  };
};

export default useFinanceTab;
