import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

import Tooltip from '~/components/core/Atomic/Tooltip';
import { adjustGeneralInvolvedProperty } from '~/components/Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties';
import useDataFetcher from '~/components/useDataFetcher';
import cn from '~/Utils/cn';

import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isFeatureEnabled } from '../Utils';
import { findIncidentParty, findInvolvedProperty, useFetchClaim } from '../Utils/ClaimUtils';

import { ShowOnlyVehicleDialog } from './Auto/AutoInvolved/VehicleDetails';
import { NewShowOnlyVehicleDialog } from './Fnol/NewFnolUI/InvolvedParties/AutoParties/VehicleDetails';
import { useCms } from './hooks/useCms';
import { InvolvedPetEditDialog } from './Pet/PetInvolvedCard';
import { ClaimContextProvider } from './ClaimContainer';
import { ContactEntity } from './Contact';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import { BriefcaseIcon, InfoIcon } from './icons';
import InlineIconButton from './InlineIconButton';
import { ShowOnlyGenericInvolvedPropertyDialog } from './InvolvedProperty';
import LoadingDialog from './LoadingDialog';
import OverflowTextWithToolTip from './OverflowTextWithToolTip';
import { PolicyContextProvider } from './PolicyContainer';

import { useStyles } from '../assets/styles';

function InvolvedPropertyHoverInfo(props) {
  const { claimId, involvedProperty, textualOwnerButton, textualExtraInfo } = props;
  const [openInfo, setOpenInfo] = useState(false);
  const classes = useStyles();

  function getPropertyTypeDesc(involvedProperty) {
    if (involvedProperty.type === 'generic_involved_property') {
      return 'Property Owner';
    } else if (involvedProperty.type === 'involved_vehicle') {
      return 'Vehicle Owner';
    } else if (involvedProperty.type === 'involved_pet') {
      return 'Pet Owner';
    } else {
      throw Error(`Unknown involved Property type ${involvedProperty.type}`);
    }
  }

  return (
    <>
      {textualOwnerButton ? (
        <Link
          component="button"
          variant="body2"
          onClick={() => setOpenInfo(true)}
          className={`${classes.primaryLink} text-base font-normal`}
        >
          {getPropertyTypeDesc(involvedProperty)}
          {textualExtraInfo ? ` (${textualExtraInfo})` : ''}
        </Link>
      ) : (
        <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.READ}>
          <InlineIconButton
            className={cn(classes.inLineButtonsContainer, classes.hoverableIcon)}
            iconStyle={{ paddingLeft: '2px' }}
            onClick={() => setOpenInfo(true)}
            icon={InfoIcon}
          />
        </RestrictedPermissions>
      )}
      {openInfo && (
        <ShowOnlyInvolvedPropertyDialog
          claimId={claimId}
          pureInvolvedProperty={involvedProperty}
          onClose={() => setOpenInfo(false)}
        />
      )}
    </>
  );
}

InvolvedPropertyHoverInfo.propTypes = {
  involvedProperty: PropTypes.object.isRequired,
  claimId: PropTypes.number,
  textualOwnerButton: PropTypes.bool,
  textualExtraInfo: PropTypes.string,
};

function ShowOnlyInvolvedPropertyDialog({ claimId, pureInvolvedProperty, onClose }) {
  const [claim, isLoadingClaim, isErrorClaim, reloadClaim] = useFetchClaim(claimId);
  const { userOrganization } = useCms();
  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const {
    isLoading: isLoadingInvolved,
    isError: isErrorInvolved,
    data: involvedFetchedOnNewFnol,
  } = useDataFetcher(`/api/v1/claims/${claimId}/involved_property/${pureInvolvedProperty.id}`);

  const isLoading = isLoadingClaim || isLoadingInvolved;
  const isError = isErrorClaim || isErrorInvolved;

  if (isLoading || isError) {
    return <LoadingDialog isError={isError} onClose={onClose} track="Show Only Involved Property" />;
  }

  let fullInvolvedProperty = isNewFnolUIEnabled
    ? involvedFetchedOnNewFnol
    : findInvolvedProperty(claim, pureInvolvedProperty);

  if (claim.type === 'general_claim') {
    fullInvolvedProperty = adjustGeneralInvolvedProperty(fullInvolvedProperty);
  }

  const getShowOnlyPropertyDialog = (pureInvolvedProperty, onClose) => {
    if (fullInvolvedProperty.type === 'generic_involved_property') {
      return <ShowOnlyGenericInvolvedPropertyDialog involvedProperty={fullInvolvedProperty} onCancel={onClose} open />;
    } else if (fullInvolvedProperty.type === 'involved_vehicle' && isNewFnolUIEnabled) {
      return (
        <NewShowOnlyVehicleDialog
          vehicleInvolved={fullInvolvedProperty}
          isInsured={findIncidentParty(claim, fullInvolvedProperty.incident_party_id).is_first_party}
          onCancel={onClose}
        />
      );
    } else if (fullInvolvedProperty.type === 'involved_vehicle' && !isNewFnolUIEnabled) {
      return (
        <ShowOnlyVehicleDialog
          vehicleInvolved={fullInvolvedProperty}
          isInsured={findIncidentParty(claim, fullInvolvedProperty.incident_party_id).is_first_party}
          onCancel={onClose}
        />
      );
    } else if (fullInvolvedProperty.type === 'involved_pet' && claim.type === 'pet_claim') {
      // PETDEBT - remove once Boost is migrated
      return <InvolvedPetEditDialog petInvolved={fullInvolvedProperty} onCancel={onClose} showOnly />;
    } else {
      throw Error(`Unknown involved Property type ${fullInvolvedProperty.type}`);
    }
  };

  return (
    <ClaimContextProvider claim={claim} refreshData={reloadClaim}>
      <PolicyContextProvider policy={claim.policy}>
        {getShowOnlyPropertyDialog(pureInvolvedProperty, onClose)}
      </PolicyContextProvider>
    </ClaimContextProvider>
  );
}

ShowOnlyInvolvedPropertyDialog.propTypes = {
  claimId: PropTypes.number,
  pureInvolvedProperty: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

function InvolvedPropertyInfoTitle({ claimId, involvedProperty, hideOwner }) {
  const classes = useStyles();

  return (
    <span style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <OverflowTextWithToolTip maxWidth="170px">{involvedProperty.display_name}</OverflowTextWithToolTip>
      <InvolvedPropertyHoverInfo claimId={claimId} involvedProperty={involvedProperty} />
      {involvedProperty.attorney_contact && (
        <Tooltip title="Represented by an attorney">
          <span style={{ display: 'flex', paddingLeft: '6px' }}>
            <BriefcaseIcon />
          </span>
        </Tooltip>
      )}
      {involvedProperty.owner_contact_id && !hideOwner && (
        <span style={{ paddingLeft: '6px' }}>
          <ContactEntity
            classes={classes}
            inline
            contactId={involvedProperty.owner_contact_id}
            contactDisplayName={involvedProperty.owner_contact_full_name}
          />
        </span>
      )}
    </span>
  );
}

InvolvedPropertyInfoTitle.propTypes = {
  involvedProperty: PropTypes.object.isRequired,
  claimId: PropTypes.number.isRequired,
  hideOwner: PropTypes.bool,
};

export { InvolvedPropertyHoverInfo };
export default InvolvedPropertyInfoTitle;
