import React from 'react';

import { useStyles } from '~/assets/styles';
import { FsTooltip, Heading, Text } from '~/components/core';
import AlertBanner from '~/components/core/AlertBanner';
import InnerCard from '~/components/core/Cards/InnerCard';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { getNameFieldComplianceValue } from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/AdditionalPaymentVerification/additionalPaymentVerificationUtils';

import TextFieldFormik, { ShowOnlyTextField } from '../../../../TextFieldFormik';

import styles from './additionalPaymentVerificationDialog.module.scss';

interface CheckResult {
  confidence_score?: number;
  name?: string;
  alt_names?: string[];
  entity_type?: string;
  gender?: string;
  address?: string[];
  country_residence?: string[];
  date_of_birth?: string[];
  year_of_birth?: number[];
  place_of_birth?: string[];
  nationality?: string[];
  data_source?: {
    name?: string;
    short_name?: string;
  };
  source_list_url?: string;
  source_information_url?: string;
  start_date?: string;
  program?: string[];
  remarks?: string;
  additional_information?: {
    last_updated?: string;
    alias_type?: string[];
    regime?: string[];
    position?: string;
    date_designated?: string[];
    uk_statement_of_reasons?: string;
    uk_sanctions_list_ref?: string;
    gender?: string;
  };
  si_identifier?: string;
  entity_number?: number;
  data_hash?: string;
}

interface CheckDetails {
  issue_title: string;
  issue_dialog_details: string;
  details: CheckResult[];
  is_override_allowed: boolean;
}

interface SanctionsDetailsFormikFragmentProps {
  checkDetails: CheckDetails;
  responseFieldId: string;
  showReasonInput: boolean;
  isRequestFailed: boolean;
}

const SanctionsDetailsFormikFragment: React.FC<SanctionsDetailsFormikFragmentProps> = ({
  checkDetails,
  responseFieldId,
  showReasonInput,
  isRequestFailed,
}) => {
  return (
    <>
      {isRequestFailed ? (
        <div />
      ) : (
        <div className={styles.specificCheckContainer}>
          <div className={styles.internalContainer}>
            <Heading variant={Heading.TYPES.H4}>{checkDetails.issue_title}</Heading>
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              {checkDetails.issue_dialog_details}
            </Text>
          </div>
          <SanctionsDetailsContainer checkDetailsList={checkDetails.details} />
          {showReasonInput ? (
            <div>
              <TextFieldFormik
                className={styles.reason}
                id={responseFieldId}
                label="Sanctions override reason"
                fullWidth
              />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

interface SanctionsDetailsContainerProps {
  checkDetailsList: CheckResult[];
}

const SanctionsDetailsContainer: React.FC<SanctionsDetailsContainerProps> = ({ checkDetailsList }) => {
  const checkDetailsListLength = checkDetailsList?.length || 0;

  if (checkDetailsListLength === 0) {
    return <AlertBanner note="Missing Sanctions details" alertType={AlertBanner.ALERT_TYPES.WARNING} />;
  }
  if (checkDetailsListLength === 1) {
    return <SanctionsDetailsInner complianceResult={checkDetailsList[0]} title="Sanctions Details" />;
  }
  return (
    <>
      {checkDetailsList.map((complianceResult) => (
        <CollapsibleWrapper
          title={`${complianceResult.name} - ${complianceResult?.data_source?.short_name}`}
          actionCard
          key={complianceResult.si_identifier}
        >
          <SanctionsDetailsInner complianceResult={complianceResult} />
        </CollapsibleWrapper>
      ))}
    </>
  );
};

interface SanctionsDetailsInnerProps {
  complianceResult: CheckResult;
  title?: string;
}

const SanctionsDetailsInner: React.FC<SanctionsDetailsInnerProps> = ({ complianceResult, title }) => {
  const classes = useStyles();
  const addresses = complianceResult?.address || [];
  const addressesLength = addresses.length;

  return (
    <InnerCard title={title}>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Source"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult.data_source?.name || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-5">
          <ShowOnlyTextField
            classes={classes}
            label="Entity Number"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.entity_number || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-5">
          <ShowOnlyTextField
            classes={classes}
            label="Type"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.entity_type || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-5">
          <ShowOnlyTextField
            classes={classes}
            label="Name"
            showOnlyValueComponent={getNameFieldComplianceValue('name', complianceResult)}
          />
        </div>
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Source List URL"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.source_list_url || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Source Information URL"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.source_information_url || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Date of Birth"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.date_of_birth?.join(' / ') || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Places of Birth"
            showOnlyValueComponent={
              <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                {complianceResult?.place_of_birth?.join(' / ') || ''}
              </Text>
            }
          />
        </div>
        <div className="col-span-12">
          <ShowOnlyTextField
            classes={classes}
            label="Addresses"
            showOnlyValueComponent={
              <>
                {addressesLength > 0 ? (
                  <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
                    {addresses[0]}
                    {addressesLength > 1 ? (
                      <FsTooltip title={addresses.slice(1)[0]} placement="top">
                        <span className="text-teal-700"> +{addressesLength - 1}</span>
                      </FsTooltip>
                    ) : null}
                  </Text>
                ) : null}
              </>
            }
          />
        </div>
      </div>
    </InnerCard>
  );
};

export default SanctionsDetailsFormikFragment;
