import React, { useEffect } from 'react';
import { getIn, useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';
import { omit, set } from 'lodash';
import * as Yup from 'yup';

import type { ContactFullModel } from '~/components/Contacts/types';
import type { Tab } from '~/components/Contacts/UpsertContact/types';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import AddressAutocompleteFormik from '~/components/core/AddressAutocomplete/AddressAutocompleteFormik';
import CountryAutocompleteFormik from '~/components/CountryAutocompleteFormik';
import { StateFieldFormik } from '~/components/GlobalLossLocation';
import useGlobalAddresses from '~/components/hooks/useGlobalAddresses';
import TextFieldFormik, { usePrevious } from '~/components/TextFieldFormik';
import CONTACT_ADDRESS_CHANGE_REASONS from '~/server_shared/generated-types/CONTACT_ADDRESS_CHANGE_REASONS';
import { getPostalCodeYupTestParams } from '~/Utils/postalCodeUtils';

import ChangeReason from '../ChangeReason';
import { getInitializedValues, getReasonValidationSchema } from '../utils';

import TabWrapper from './TabWrapper';

const ADDRESS_TAB_KEY = 'address';

const getFullFieldPath = (fieldId: string) => `${ADDRESS_TAB_KEY}.${fieldId}`;

const FORMIK_ADDRESS_FIELD_IDS = {
  COUNTRY: `country`,
  STREET_ADDRESS_1: `street_address1`,
  STREET_ADDRESS_2: `street_address2`,
  CITY: `city`,
  COUNTY: `county`,
  STATE: `state`,
  ZIPCODE: `zipcode`,
  REASON: `reason`,
  EXPLANATION: `explanation`,
};

const AddressTab: React.FC = () => {
  const { isSubmitting, values, setFieldValue } = useFormikContext();
  const { setCurrentCountry } = useContact();
  const { isCreation } = useContact();

  const country = getIn(values, getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTRY));
  const isCountryUs = country === 'US';

  const prevCountryRef = usePrevious(country);

  useEffect(() => {
    if (prevCountryRef && prevCountryRef !== country) {
      // to prevent clearing the initial values
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_1), '');
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_2), '');
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.CITY), '');
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTY), '');
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STATE), '');
      setFieldValue(getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.ZIPCODE), '');
    }
    setCurrentCountry(country);
  }, [country, setFieldValue, prevCountryRef, setCurrentCountry]);

  return (
    <TabWrapper>
      <div className="mb-32 grid grid-cols-2 gap-20">
        <div className="col-span-full">
          <CountryAutocompleteFormik
            id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTRY)}
            label="Country"
            countriesConfigurationKey="contact_location"
            disabled={isSubmitting}
          />
        </div>
        <AddressAutocompleteFormik
          className="col-span-full"
          id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_1)}
          label="Address 1"
          fullWidth
          disabled={isSubmitting}
          helperText={isCountryUs ? '' : 'Street name & number, P.O. box, C/O, etc.'}
          streetAddress1FieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_1)}
          countyFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTY)}
          countryFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTRY)}
          zipcodeFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.ZIPCODE)}
          cityFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.CITY)}
          stateFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STATE)}
        />
        <TextFieldFormik
          className="col-span-full"
          id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_2)}
          label="Address 2"
          fullWidth
          disabled={isSubmitting}
          helperText={isCountryUs ? '' : 'Apartment, suite, unit, building name, floor, etc.'}
        />
        <TextFieldFormik
          id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.CITY)}
          label="City"
          fullWidth
          disabled={isSubmitting}
        />
        <TextFieldFormik
          id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.ZIPCODE)}
          label="Postal Code"
          fullWidth
          disabled={isSubmitting}
        />
        {isCountryUs ? (
          <TextFieldFormik
            id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTY)}
            label="County"
            fullWidth
            disabled={isSubmitting}
          />
        ) : null}
        <StateFieldFormik
          id={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.STATE)}
          disabled={isSubmitting}
          country={getIn(values, getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.COUNTRY))}
        />

        {!isCreation ? (
          <div className="col-span-full">
            <ChangeReason
              reasonFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.REASON)}
              explanationFieldId={getFullFieldPath(FORMIK_ADDRESS_FIELD_IDS.EXPLANATION)}
              reasonsDict={CONTACT_ADDRESS_CHANGE_REASONS}
              disabled={isSubmitting}
            />
          </div>
        ) : null}
      </div>
    </TabWrapper>
  );
};

const useAddressTab = (): Tab => {
  // No Formik Context
  const { originalContact, isCreation } = useContact();
  const { defaultCountry } = useGlobalAddresses({ countriesConfigurationKey: 'contact_location' });

  const getInitialValues = (contact?: ContactFullModel) => {
    const values = {};

    const setInitialValue = (fieldId: string, initialValue: unknown, defaultInitialValue: unknown = '') =>
      set(values, fieldId, initialValue ?? defaultInitialValue);

    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.COUNTRY, contact?.country, defaultCountry);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_1, contact?.street_address1);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_2, contact?.street_address2);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.CITY, contact?.city);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.COUNTY, contact?.county);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.STATE, contact?.state);
    setInitialValue(FORMIK_ADDRESS_FIELD_IDS.ZIPCODE, contact?.zipcode);

    if (!isCreation) {
      setInitialValue(FORMIK_ADDRESS_FIELD_IDS.REASON, '');
      setInitialValue(FORMIK_ADDRESS_FIELD_IDS.EXPLANATION, '');
    }

    return values;
  };

  const getValidationSchema = () => {
    const validations = {};

    const addValidation = (fieldId: string, fieldValidation: Yup.AnySchema) =>
      set(validations, fieldId, fieldValidation);

    addValidation(FORMIK_ADDRESS_FIELD_IDS.COUNTRY, Yup.string().required('Required'));
    addValidation(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_1, Yup.string());
    addValidation(FORMIK_ADDRESS_FIELD_IDS.STREET_ADDRESS_2, Yup.string());
    addValidation(FORMIK_ADDRESS_FIELD_IDS.CITY, Yup.string());
    addValidation(FORMIK_ADDRESS_FIELD_IDS.COUNTY, Yup.string());
    addValidation(FORMIK_ADDRESS_FIELD_IDS.STATE, Yup.string());
    addValidation(
      FORMIK_ADDRESS_FIELD_IDS.ZIPCODE,
      Yup.string().when(FORMIK_ADDRESS_FIELD_IDS.COUNTRY, (country, currSchema) =>
        currSchema.test(...getPostalCodeYupTestParams(false, country))
      )
    );

    if (!isCreation) {
      addValidation(
        FORMIK_ADDRESS_FIELD_IDS.REASON,
        Yup.string().test(
          'address-reason',
          'Required',
          getReasonValidationSchema(originalContact, getValuesToCompare, getInitialValues)
        )
      );
      addValidation(
        FORMIK_ADDRESS_FIELD_IDS.EXPLANATION,
        Yup.string().when('reason', {
          is: 'other',
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        })
      );
    }

    return validations;
  };

  const getValuesToCompare = (values: FormikValues) => {
    const omittedValues = omit(values, [FORMIK_ADDRESS_FIELD_IDS.REASON, FORMIK_ADDRESS_FIELD_IDS.EXPLANATION]);
    return getInitializedValues(omittedValues);
  };

  return {
    label: 'Address',
    tabKey: ADDRESS_TAB_KEY,
    tabComponent: AddressTab,
    getInitialValues,
    getValidationSchema,
    getValuesToCompare,
  };
};

export default useAddressTab;
