import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Typography from '~/components/core/Atomic/Typography';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import { useRootStyles } from '../assets/rootStyles';
import { CONFIGURATION_FEATURES_NAMES } from '../Types';
import { isUserFiveSigma, isUserOshaRep } from '../UserUtils';
import { getFrontendVersion, isFeatureEnabled } from '../Utils';

import KpiDashboardsContainer from './ClientSpecific/Inshur/KpiDashboardsContainer';
import IncomingCallAvailabilityStatus from './communications/IncomingCallAvailabilityStatus';
import { useCms } from './hooks/useCms';
import ImpersonateContainer from './Test/ImpersonateContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper } from './core';
import BarSearchField from './SearchBar';

function CmsBar() {
  const classes = useRootStyles();
  const { user, pageTitle, userOrganization } = useCms();

  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" style={{ fontSize: 20 }}>
            <span style={{ whiteSpace: 'nowrap' }}>{pageTitle}</span>
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              alignItems: 'center',
            }}
          >
            {isUserFiveSigma(user) && (
              <div style={{ paddingRight: 12 }}>
                <Typography variant="body2">v{getFrontendVersion()}</Typography>
              </div>
            )}
            <ImpersonateContainer />
            {!isUserOshaRep(user) && !isUserFiveSigma(user) && (
              <>
                <div style={{ paddingRight: 12 }}>
                  <BarSearchField />
                </div>
                {(user.is_org_level_supervisor || user.is_manager) &&
                  isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.KPI_DASHBOARD) && (
                    <div style={{ paddingRight: 12 }}>
                      <KpiDashboardsContainer />
                    </div>
                  )}
                <PermissionsRenderWrapper verb={PERMISSION_VERBS.WRITE}>
                  <IncomingCallAvailabilityStatus />
                </PermissionsRenderWrapper>
                {userOrganization.fnol_enabled &&
                  !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL) && (
                    <PermissionsRenderWrapper verb={PERMISSION_VERBS.WRITE} action={PERMISSION_ACTIONS.CREATE_FNOL}>
                      <IconButton
                        style={{ color: 'white', width: 40, height: 40 }}
                        className={classes.actionButton}
                        component={Link}
                        to="/fnol"
                        disabled={user.role.is_view_only}
                      >
                        <AddIcon className={classes.drawerIcon} />
                      </IconButton>
                    </PermissionsRenderWrapper>
                  )}
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default CmsBar;
