import React, { useCallback, useEffect, useState } from 'react';
import { ListItemText } from '@material-ui/core';
import axios from 'axios';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import CancelButton from '~/components/core/Buttons/CancelButton';
import Autocomplete from '~/components/core/Molecules/Fields/AutoComplete';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import DynamicTemplateFields from '~/components/GenericTemplates/FromTemplate/DynamicTemplateFields';
import type { TemplateMetadata, TemplateTypeKey } from '~/components/GenericTemplates/types';
import {
  FROM_TEMPLATE_FORM_KEYS,
  htmlToTextTemplateParser,
  TEMPLATES_TYPES,
} from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES from '~/server_shared/generated-types/GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import {
  getAxiosParamsSerializer,
  getOrganizationCountryCode,
  isFeatureEnabled,
  reportAxiosError,
  reportErrorInProductionOrThrow,
} from '~/Utils';

const FETCH_PARAMS_SERIALIZER = getAxiosParamsSerializer('none');
const CLAIM_LEVEL_OPTION = 'claim_level';
const EXPOSURE_LEVEL_OPTION = 'exposure_level';

interface GenericTemplateSelectionContainerFormikProps {
  templateType: TemplateTypeKey;
  handleClose: () => void;
  titleFieldId?: string;
  bodyFieldId: string;
  shouldConvertBodyHtmlToText?: boolean;
  signature?: string;
  setFailedTokens?: (tokens: string[]) => void;
}

interface FormValues {
  recipient_contact_id?: number | string;
  contact_id?: number | string;
  document_id?: number | string;
  payment_request_id?: number | string;
}

interface GetFilledTemplateParams {
  templateId: number;
  recipientContactId?: number | string;
  contactId?: number | string;
  documentId?: number | string;
  paymentRequestId?: number | string;
}

const GenericTemplateSelectionContainerFormik: React.FC<GenericTemplateSelectionContainerFormikProps> = ({
  templateType,
  handleClose,
  titleFieldId,
  bodyFieldId,
  shouldConvertBodyHtmlToText,
  signature,
  setFailedTokens,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId } = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const multiCountryOrganization = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [availableTemplates, setAvailableTemplates] = useState<TemplateMetadata[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
  const [searchParams, setSearchParams] = useState({});
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [selectedTemplateLevel, setSelectedTemplateLevel] = useState<string | number>();
  const [selectedExposureId, setSelectedExposureId] = useState(NaN);

  const isClaimLevel = selectedTemplateLevel === CLAIM_LEVEL_OPTION;
  const coverageType =
    !claim || isClaimLevel
      ? undefined
      : claim.exposures.find((exposure: { id: number; coverage_type: string }) => exposure.id === selectedExposureId)
          ?.coverage_type;

  const getGenericTemplates = useCallback(
    async (claimLevel: boolean, coverageType?: string) => {
      if (!claim) {
        return;
      }
      try {
        const searchParams = {
          template_type: templateType,
          is_claim_level: claimLevel,
          lobs: [claim.lob],
          is_enabled: true,
          sub_organization_ids: claim.policy.sub_organization?.id ? [claim.policy.sub_organization.id] : [],
          coverage_keys: claimLevel ? [] : [coverageType],
          states: claim.policy.policy_state ? [claim.policy.policy_state] : [],
          country: multiCountryOrganization
            ? claim.policy.policy_country || getOrganizationCountryCode(userOrganization)
            : undefined,
        };

        setIsLoadingTemplates(true);

        const { data } = await axios.get(GENERIC_TEMPLATES_ROUTES.GET_TEMPLATES(organizationId), {
          params: searchParams,
          paramsSerializer: FETCH_PARAMS_SERIALIZER,
        });
        setAvailableTemplates(data?.templates_metadata);
        setSearchParams(searchParams);
        setIsLoadingTemplates(false);
      } catch (error) {
        await reportAxiosError(error);
      }
    },
    [claim, organizationId, templateType, userOrganization, multiCountryOrganization]
  );

  useEffect(() => {
    if (!selectedTemplateLevel || (selectedTemplateLevel === EXPOSURE_LEVEL_OPTION && !selectedExposureId)) {
      return;
    }

    getGenericTemplates(isClaimLevel, coverageType);
  }, [coverageType, isClaimLevel, selectedTemplateLevel, getGenericTemplates, selectedExposureId]);

  if (!claim) {
    reportErrorInProductionOrThrow('Tried to Render TemplateSelectionContainerFormik without claim it context');
    return (
      <CardDialog
        isDialog
        title={`Select ${TEMPLATES_TYPES[templateType]?.display_name} Template`}
        fullWidth
        width="sm"
        onClose={handleClose}
      >
        <div>No claim in context, cant select template</div>
      </CardDialog>
    );
  }

  const dynamicTokenFields =
    availableTemplates.find((template) => template.id === selectedTemplateId)?.dynamic_token_fields || [];
  const getFilledTemplate = async ({
    templateId,
    recipientContactId,
    contactId,
    documentId,
    paymentRequestId,
  }: GetFilledTemplateParams) => {
    try {
      const { data } = await axios.get(
        GENERIC_TEMPLATES_ROUTES.GENERATE_FROM_TEMPLATE({
          organizationId,
          templateId,
          claimId: claim.id,
          exposureId: selectedExposureId,
          recipientContactId,
          contactId,
          documentId,
          paymentRequestId,
        })
      );

      if (
        setFailedTokens &&
        Array.isArray(data?.failed_token_display_names) &&
        data?.failed_token_display_names.length > 0
      ) {
        setFailedTokens(data?.failed_token_display_names);
      }

      let bodyContent = shouldConvertBodyHtmlToText
        ? htmlToTextTemplateParser(data.body_template, true)
        : data.body_template;

      if (signature) {
        bodyContent += `\n${signature}`;
      }

      setFieldValue(bodyFieldId, bodyContent);
      setFieldTouched(bodyFieldId, true);

      setFieldValue(FROM_TEMPLATE_FORM_KEYS.TEMPLATE_ID, selectedTemplateId);
      setFieldValue(FROM_TEMPLATE_FORM_KEYS.TEMPLATE_CONTEXT, searchParams);

      if (titleFieldId) {
        setFieldValue(titleFieldId, htmlToTextTemplateParser(data.title_template, false));
        setFieldTouched(titleFieldId, true);
      }

      handleClose();
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const shouldDisplayWarningBanner =
    !isLoadingTemplates &&
    ((selectedTemplateLevel === CLAIM_LEVEL_OPTION && availableTemplates.length === 0) ||
      (selectedTemplateLevel === EXPOSURE_LEVEL_OPTION && selectedExposureId && availableTemplates.length === 0));

  return (
    <CardDialog
      isDialog
      title={`Select ${TEMPLATES_TYPES[templateType]?.display_name} Template`}
      fullWidth
      width="sm"
      onClose={handleClose}
    >
      {shouldDisplayWarningBanner ? (
        <AlertBanner
          note="No templates exist for the currently selected context"
          alertType={AlertBanner.ALERT_TYPES.WARNING}
        />
      ) : null}
      <div className="my-12 w-full">
        <div className="my-12 w-full">
          <SingleSelectField
            label="Template Level"
            className="w-full"
            onChange={(value: string | number) => {
              setSelectedTemplateLevel(value);
              setSelectedTemplateId(undefined);
            }}
            select
            disabled={isLoadingTemplates}
          >
            <MenuItem value={CLAIM_LEVEL_OPTION}>
              <ListItemText primary="Claim Level" />
            </MenuItem>

            <MenuItem value={EXPOSURE_LEVEL_OPTION}>
              <ListItemText primary="Exposure Level" />
            </MenuItem>
          </SingleSelectField>
        </div>

        <div className="my-12 w-full">
          <SingleSelectField
            label="Exposure"
            className="w-full"
            onChange={(value: unknown) => {
              setSelectedExposureId(value as number);
              setSelectedTemplateId(undefined);
            }}
            disabled={isLoadingTemplates || selectedTemplateLevel !== EXPOSURE_LEVEL_OPTION}
          >
            {claim.exposures.map((exposure: { id: number; label_text: string }) => (
              <MenuItem key={exposure.id} value={exposure.id}>
                <ListItemText primary={exposure.label_text} />
              </MenuItem>
            ))}
          </SingleSelectField>
        </div>

        <div className="my-12 w-full">
          <Autocomplete<{ template_name: string; id: number }>
            id="template_id"
            options={availableTemplates}
            getOptionLabel={(option: { template_name: string; id: number }) => option.template_name}
            disabled={availableTemplates.length === 0}
            onChange={(_, selectedTemplate) => setSelectedTemplateId(selectedTemplate?.id)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={availableTemplates.find((template) => template.id === selectedTemplateId) || null}
            label="Template"
          />
        </div>
        <Formik<FormValues>
          initialValues={{ recipient_contact_id: '', contact_id: '', document_id: '', payment_request_id: '' }}
          validationSchema={Yup.object().shape({
            recipient_contact_id: dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.RECIPIENT)
              ? Yup.number().nullable().required('Required')
              : Yup.number().nullable(),
            contact_id: dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.CONTACT)
              ? Yup.number().nullable().required('Required')
              : Yup.number().nullable(),
            document_id: dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.DOCUMENT)
              ? Yup.number().nullable().required('Required')
              : Yup.number().nullable(),
            payment_request_id: dynamicTokenFields.includes(GENERIC_TEMPLATES_DYNAMIC_TOKEN_TYPES.PAYMENT)
              ? Yup.number().nullable().required('Required')
              : Yup.number().nullable(),
          })}
          onSubmit={async (values) => {
            if (!selectedTemplateId) {
              return reportErrorInProductionOrThrow(
                'getFilledTemplate should be disabled if there is no selectedTemplateId'
              );
            }
            await getFilledTemplate({
              templateId: selectedTemplateId,
              recipientContactId: values?.recipient_contact_id,
              contactId: values?.contact_id,
              documentId: values?.document_id,
              paymentRequestId: values?.payment_request_id,
            });
          }}
        >
          {() => (
            <DynamicFieldsInnerFormik
              dynamicTokenFields={dynamicTokenFields}
              selectedTemplateId={selectedTemplateId}
              handleClose={handleClose}
              availableTemplates={availableTemplates}
              exposureId={selectedExposureId}
            />
          )}
        </Formik>
      </div>
    </CardDialog>
  );
};

interface DynamicFieldsFormProps {
  dynamicTokenFields: string[];
  selectedTemplateId?: number;
  handleClose: () => void;
  availableTemplates: TemplateMetadata[];
  exposureId?: number;
}

const DynamicFieldsInnerFormik: React.FC<DynamicFieldsFormProps> = ({
  dynamicTokenFields,
  selectedTemplateId,
  handleClose,
  availableTemplates,
  exposureId,
}) => {
  const { submitForm, resetForm } = useFormikContext();
  useEffect(() => {
    resetForm();
  }, [selectedTemplateId, resetForm]);

  return (
    <>
      {dynamicTokenFields ? (
        <DynamicTemplateFields dynamicTokenFields={dynamicTokenFields} exposureId={exposureId} />
      ) : null}
      <div className="mt-16 flex w-full justify-end">
        <CancelButton onClick={handleClose} />
        <Button
          variant="contained"
          color="primary"
          disabled={availableTemplates.length === 0 || !selectedTemplateId}
          onClick={submitForm}
        >
          Select
        </Button>
      </div>
    </>
  );
};

export default GenericTemplateSelectionContainerFormik;
