import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useLob } from '~/components/hooks/useLob';

import { CONFIGURATION_FEATURES_NAMES, SIU_CRITERIA_DICT } from '../Types';
import { isBoostOrganization, isFeatureEnabled } from '../Utils';

import { AutoIncidentDetailsCard } from './Auto/AutoIncidentDetails';
import NewFnolIncidentDetailsCard from './Fnol/NewFnolUI/IncidentDetails/NewFnolIncidentDetailsCard';
import { GLIncidentDetailsCard } from './GL/GLIncidentDetails';
import { HomeIncidentDetailsCard } from './Home/HomeIncidentDetails';
import { useCms } from './hooks/useCms';
import { OldPetIncidentDetailsCard } from './Pet/OldPetIncidentDetails';
import { TravelIncidentDetailsCard } from './Travel/TravelIncidentDetails';
import { WCIncidentDetailsCard } from './WC/WCIncidentDetails';
import { useClaim, withClaim } from './ClaimContainer';
import ClaimNotificationsContainer from './ClaimNotificationsContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, PermissionsRenderWrapper } from './core';
import NotesSummary from './NotesSummary';
import useOrganization from './OrganizationContext';
import RelatedClaimsContainer from './RelatedClaimsContainer';

import { useStyles } from '../assets/styles';

const ClaimSummary = ({ classes, claim, onClaimUpdate, onAsyncClaimUpdate, isTabActive = true, ...rest }) => {
  const incidentDetailsCardRef = useRef();
  const { userOrganization } = useCms();
  const { claimType } = useLob();
  const [incidentDetailsHeight, setIncidentDetailsHeight] = useState(null);
  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const isNewLayoutEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CLAIM_SUMMARY_NEW_LAYOUT);

  useEffect(() => {
    setIncidentDetailsHeight(incidentDetailsCardRef.current.offsetHeight);
  }, [incidentDetailsCardRef]);

  let incidentDetailsCard = null;
  if (
    ['auto_claim', 'general_claim'].includes(claimType) &&
    isNewFnolUIEnabled &&
    !isEmpty(claim.incident_configuration)
  ) {
    incidentDetailsCard = (
      <NewFnolIncidentDetailsCard classes={classes} claim={claim} onUpdate={onAsyncClaimUpdate} {...rest} />
    );
  } else if (claimType === 'auto_claim') {
    incidentDetailsCard = (
      <AutoIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />
    );
  } else if (claimType === 'home_claim') {
    incidentDetailsCard = (
      <HomeIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />
    );
  } else if (claimType === 'gl_claim') {
    incidentDetailsCard = <GLIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />;
  } else if (claimType === 'wc_claim') {
    incidentDetailsCard = <WCIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />;
  } else if (claimType === 'pet_claim' && isBoostOrganization(userOrganization)) {
    // PETDEBT: remove once Boost migrated
    incidentDetailsCard = (
      <OldPetIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />
    );
  } else if (claimType === 'travel_claim') {
    // GC TODO: remove here once this are GC
    incidentDetailsCard = (
      <TravelIncidentDetailsCard classes={classes} claim={claim} onUpdate={onClaimUpdate} {...rest} />
    );
  } else {
    throw Error(`Unknown claim type ${claimType} in ClaimSummary`);
  }

  // TODO: handle auto claims HomeIncidentDetailsCard and PolicyDetailsCard aren't the same
  return (
    <Grid container>
      <Grid item md={isNewLayoutEnabled ? 12 : 4}>
        <div ref={incidentDetailsCardRef} className={classes.cardDivRow}>
          {incidentDetailsCard}
        </div>
      </Grid>
      <Grid item md={isNewLayoutEnabled ? 12 : 8}>
        {!isNewLayoutEnabled && isTabActive ? (
          <div className={classes.cardDivRow}>
            <RelatedClaimsContainer />
          </div>
        ) : null}
        <PermissionsRenderWrapper action={PERMISSION_ACTIONS.NOTIFICATION} verb={PERMISSION_VERBS.READ}>
          <div className={classes.cardDivRow}>
            <ClaimNotificationsContainer
              shouldDisplayNotifications={isTabActive}
              maxHeight={incidentDetailsHeight && !isNewLayoutEnabled ? `${incidentDetailsHeight - 150}px` : undefined}
            />
          </div>
        </PermissionsRenderWrapper>
      </Grid>
      {!isNewLayoutEnabled && (
        <PermissionsRenderWrapper action={PERMISSION_ACTIONS.NOTE} verb={PERMISSION_VERBS.READ}>
          <Grid item md={12}>
            <div className={classes.cardDivRow}>
              <NotesSummary isActive={isTabActive} claim={claim} onNoteClick={() => {}} />
            </div>
          </Grid>
        </PermissionsRenderWrapper>
      )}
    </Grid>
  );
};

ClaimSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  isTabActive: PropTypes.bool,
  claim: PropTypes.object.isRequired, // from claim context
  onClaimUpdate: PropTypes.func.isRequired, // from claim context
  onAsyncClaimUpdate: PropTypes.func.isRequired, // from claim context
};

function TooltipOverflowChip({ tooltipTitle, text }) {
  const classes = useStyles();

  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        size="small"
        color="primary"
        label={
          <div style={{ maxWidth: '300px' }} className={classes.overflowText}>
            {text}
          </div>
        }
        className={classes.chip}
      />
    </Tooltip>
  );
}

TooltipOverflowChip.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function SiuClaimSummaryChip() {
  const { claim } = useClaim();
  const incident = claim.incident;

  let siuChipAdditionalText =
    incident.siu_criteria && incident.siu_criteria.length > 0
      ? ` - ${incident.siu_criteria.map((criteria) => SIU_CRITERIA_DICT[criteria]['desc']).join(', ')}`
      : '';

  if (incident.siu_finished_datetime) {
    siuChipAdditionalText += ' - Finished';
  }

  return (
    <TooltipOverflowChip
      tooltipTitle={incident.siu_finished_datetime ? incident.siu_result : incident.siu_report_status}
      text={`SIU${siuChipAdditionalText}`}
    />
  );
}

function ControlFileClaimSummaryChip() {
  const { claim } = useClaim();
  const { controlFileOptions } = useOrganization();
  const incident = claim.incident;

  const controlFileChipAdditionalText =
    incident.control_file_criteria && incident.control_file_criteria.length > 0
      ? ` - ${incident.control_file_criteria.map((criteria) => controlFileOptions[criteria]['short_desc']).join(', ')}`
      : '';

  return (
    <TooltipOverflowChip
      tooltipTitle={incident.control_file_report_status}
      text={`Control File${controlFileChipAdditionalText}`}
    />
  );
}

function LitigationClaimSummaryChip() {
  const classes = useStyles();

  return <Chip size="small" color="primary" label="In Litigation" className={classes.chip} />;
}

function DeferredFileClaimSummaryChip() {
  const { claim } = useClaim();
  const incident = claim.incident;

  return <TooltipOverflowChip tooltipTitle={incident.deferred_file_report_status} text="Misc" />;
}

export { ControlFileClaimSummaryChip, DeferredFileClaimSummaryChip, LitigationClaimSummaryChip, SiuClaimSummaryChip };
export default withClaim(ClaimSummary);
