import React from 'react';

import SvgIcon from '../core/SvgIcon';

const DocumentIcon = (props) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M4.53125 16.875C5.02853 16.875 5.50544 16.6775 5.85708 16.3258C6.20871 15.9742 6.40625 15.4973 6.40625 15C6.40625 14.5027 6.20871 14.0258 5.85708 13.6742C5.50544 13.3225 5.02853 13.125 4.53125 13.125H3.4375V16.875H4.53125Z"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1562 16.875C11.1055 16.875 11.875 16.0355 11.875 15C11.875 13.9645 11.1055 13.125 10.1562 13.125C9.20701 13.125 8.4375 13.9645 8.4375 15C8.4375 16.0355 9.20701 16.875 10.1562 16.875Z"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5625 16.4453C16.2635 16.7187 15.8739 16.8717 15.4688 16.875C14.5156 16.875 13.75 16.0391 13.75 15C13.75 13.9609 14.5156 13.125 15.4688 13.125C15.8739 13.1283 16.2635 13.2813 16.5625 13.5547"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.75 10V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.875L16.25 6.875V10"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.875 2.5V6.875H16.25" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default DocumentIcon;
