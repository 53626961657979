import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import { isVatEnabled } from '~/components/communications/ContactUtils';
import { useContact } from '~/components/Contacts/UpsertContact/useContact';
import Text from '~/components/core/TextComponents/Text';
import RadioButtonFormik from '~/components/RadioButtonFormik';
import { TextFieldFormik } from '~/components/TextFieldFormik';

import { FORMIK_FINANCE_FIELD_IDS } from './constants';
import { getFullFinancePath } from './utils';

const Vat = ({ disabled }) => {
  const { currentCountry } = useContact();
  const { values } = useFormikContext();
  if (!isVatEnabled(currentCountry)) {
    return null;
  }
  return (
    <div className="grid grid-cols-3 items-center gap-20">
      <Text variant="small" weight="regular">
        VAT Registered
      </Text>
      <div>
        <RadioButtonFormik
          id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.IS_VAT_REGISTERED)}
          label="No"
          optionValue={false}
          disabled={disabled}
        />
        <RadioButtonFormik
          id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.IS_VAT_REGISTERED)}
          label="Yes"
          optionValue={true}
          disabled={disabled}
        />
      </div>
      {getIn(values, getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.IS_VAT_REGISTERED)) ? (
        <div>
          <TextFieldFormik
            id={getFullFinancePath(FORMIK_FINANCE_FIELD_IDS.VAT_NUMBER)}
            label="VAT Number"
            fullWidth
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
};

Vat.propTypes = {
  disabled: PropTypes.bool,
};

export default Vat;
