const PERMISSIONS_DESCRIPTIONS = {
  CLAIM: {
    title: 'Access Claim',
    subtitle: 'View claim details',
    description: '',
  },
  DATE_OF_LOSS: {
    title: 'Date of Loss',
    subtitle: 'Edit the incident date of loss ',
    description: '',
  },
  TIME_OF_LOSS: {
    title: 'Time of Loss',
    subtitle: 'Edit the incident time of loss ',
    description: '',
  },
  DATE_OF_REPORT: {
    title: 'Date of Report',
    subtitle: 'Edit the claim report date ',
    description: '',
  },
  REPORTER: {
    title: 'Reporter',
    subtitle: 'Edit the claim Reporter',
    description: '',
  },
  EXTERNAL_CLAIM_NUMBER: {
    title: 'External Claim Number',
    subtitle: 'Set/Edit the external claim numbers',
    description: '',
  },
  COVERAGE_DECISION: {
    title: 'Coverage Decision',
    subtitle: 'Set/Edit the coverage decision',
    description: '',
  },
  NOTE: {
    title: 'Notes',
    subtitle: 'View, create, delete, and set notes as confidential.',
    description: '',
  },
  NOTIFICATION: {
    title: 'Notifications',
    subtitle: 'View and create notifications',
    description: '',
  },
  CHANGE_CLAIM_OWNER: {
    title: 'Change file owner',
    subtitle: 'Select a new file owner',
    description: '',
  },
  CHANGE_EXPOSURE_OWNER: {
    title: 'Change exposure owner',
    subtitle: 'Select a new exposure owner',
    description: '',
  },
  EXPORT_CLAIM: {
    title: 'Export Claim',
    subtitle: 'Export claim to PDF',
    description: '',
  },
  LEGAL_ACTIONS: {
    title: 'Legal Actions Tab',
    subtitle: 'View and Edit Legal Actions',
    description: '',
  },
  INDEMNITY_RESERVE: {
    title: 'Set Indemnity Reserve',
    subtitle: 'Set and edit the indemnity reserve',
    description: '',
  },
  EXPENSES_RESERVE: {
    title: 'Set Expenses Reserve',
    subtitle: 'Set and edit the expenses reserve',
    description: '',
  },
  GENERAL_EXPENSES_RESERVE: {
    title: 'Set General Expenses Reserve',
    subtitle: 'Set and edit the general expenses reserve',
    description: '',
  },
  INDEMNITY_PAYMENT: {
    title: 'Issue Indemnity Payments',
    subtitle: 'Issue indemnity payments',
    description: '',
  },
  EXPENSES_PAYMENT: {
    title: 'Issue Expenses Payments',
    subtitle: 'Issue expenses payments',
    description: '',
  },
  GENERAL_EXPENSES_PAYMENT: {
    title: 'Issue General Expenses Payments',
    subtitle: 'Issue general expenses payments',
    description: '',
  },
  CANCEL_INDEMNITY_PAYMENT: {
    title: 'Cancel Indemnity Payments',
    subtitle: 'Cancel indemnity payments',
    description: '',
  },
  CANCEL_EXPENSES_PAYMENT: {
    title: 'Cancel Expenses Payments',
    subtitle: 'Cancel expenses payments',
    description: '',
  },
  CANCEL_GENERAL_EXPENSES_PAYMENT: {
    title: 'Cancel General Expenses Payments',
    subtitle: 'Cancel general expenses payments',
    description: '',
  },
  OFAC_OVERRIDE: {
    title: 'Approve Sanction lists',
    subtitle: 'Approve payments after sanction lists hit',
    description: '',
  },
  APPROVE_INDEMNITY_PAYMENT_OVER_LIMIT: {
    title: 'Approve indemnity payment over limit',
    subtitle: 'Allow approve indemnity payment over policy limit',
    description: '',
  },
  APPROVE_CHANGE_INDEMNITY_RESERVE_OVER_LIMIT: {
    title: 'Approve indemnity reserve over limit',
    subtitle: 'Allow approve indemnity reserve over policy limit',
    description: '',
  },
  CLAIM_DOCUMENT: {
    title: 'Documents tab',
    subtitle: 'Add new documents, view, edit and delete existing documents',
    description: '',
  },
  MANUAL_POLICY: {
    title: 'Manual Policy',
    subtitle: 'Edit manual policy',
    description: '',
  },
  API_POLICY: {
    title: 'API Policy',
    subtitle: 'Edit API policy',
    description: '',
  },
  MOI: {
    title: 'MOI',
    subtitle: 'View and edit MOI',
    description: '',
  },
  CONTACT: {
    title: 'Contacts and parties',
    subtitle: 'View and edit contacts and party cards',
    description: '',
  },
  CONTACT_PII: {
    title: 'Contact PII',
    subtitle: 'View and edit contact PII',
    description: '',
  },
  W9_TIN: {
    title: 'W9_TIN',
    subtitle: 'Upload a contact’s W9 and view and edit TIN',
    description: '',
  },
  CREATE_FNOL: {
    title: 'Create FNOL',
    subtitle: 'Create a new FNOL',
    description: '',
  },
  QA: {
    title: 'QA',
    subtitle: 'View QA results and run QA on selected claims',
    description: '',
  },
  GENERAL_EMAIL_CLASSIFICATION: {
    title: 'General Email Classification',
    subtitle: 'Classify and attach incoming emails to claims',
    description: '',
  },
  REPORT: {
    title: 'View reports and dashboards',
    subtitle: 'View reports and dashboards',
    description: '',
  },
  ADJUSTER_DASHBOARD: {
    title: 'View Adjuster Suite',
    subtitle: 'View the reports and dashboards adjuster suite',
    description: '',
  },
  MANAGEMENT_REPORTS_AND_DASHBOARDS: {
    title: 'View Management Suite',
    subtitle: 'View the reports and dashboard management suite',
    description: '',
  },
  VIEW_GENERAL_REPORTS_SUITE: {
    title: 'View General Suite',
    subtitle: 'View general reports and dashboards',
    description: '',
  },
  CREATE_REPORTS_AND_DASHBOARDS: {
    title: 'Create Reports and Dashboards',
    subtitle: 'Create reports and dashboards and share them with your organization',
    description: '',
  },
  SYSTEM_CONFIGURATION: {
    title: 'System configurations',
    subtitle: ' View and edit system configurations',
    description: '',
  },
} as const;

export default PERMISSIONS_DESCRIPTIONS;
