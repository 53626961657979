import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';

import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import cn from '~/Utils/cn';

import { CancelIcon } from '../../../../icons';

import { useStyles } from '../../../../../assets/styles';
import styles from '../../financeApprovals.module.scss';

const PAYABLE_TYPES = {
  indemnity: 'Indemnity',
  expenses: 'Expenses',
};

const FILTER_KEYS = {
  payable_types_filter: 'payable_types_filter',
  payment_method: 'payment_method',
  balance_key: 'balance_key',
  sub_organization_id: 'sub_organization_id',
  sub_organization_ids: 'sub_organization_ids',
  coverage_config_ids: 'coverage_config_ids',
  excluded_coverage_config_ids: 'excluded_coverage_config_ids',
};

const Filters = ({ filters, onUpdateFilters, disabled, accountKeysAndDisplayNames }) => {
  const classes = useStyles();

  const resetFilters = () => {
    onUpdateFilters({
      [FILTER_KEYS.payable_types_filter]: [],
      [FILTER_KEYS.balance_key]: '',
      [FILTER_KEYS.sub_organization_id]: '',
      [FILTER_KEYS.sub_organization_ids]: [],
      [FILTER_KEYS.coverage_config_ids]: [],
      [FILTER_KEYS.excluded_coverage_config_ids]: [],
    });
  };

  return (
    <Grid container spacing={3} className={styles.paymentSubmissionFilters}>
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Filters
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <SingleSelectField
          value={filters[FILTER_KEYS.payable_types_filter]?.[0] || ''}
          label="Payable Type"
          className={classes.textField}
          fullWidth
          disabled={disabled}
          onChange={(value) => onUpdateFilters({ [FILTER_KEYS.payable_types_filter]: [value] })}
        >
          {Object.keys(PAYABLE_TYPES).map((type) => (
            <MenuItem key={type} value={type}>
              {PAYABLE_TYPES[type]}
            </MenuItem>
          ))}
        </SingleSelectField>
      </Grid>
      <Grid item xs={3}>
        <SingleSelectField
          value={filters[FILTER_KEYS.balance_key] || ''}
          label="Account name"
          className={classes.textField}
          fullWidth
          disabled={disabled}
          onChange={(accountKey) => {
            onUpdateFilters({
              [FILTER_KEYS.balance_key]: accountKeysAndDisplayNames[accountKey].balance_key || '',
              [FILTER_KEYS.sub_organization_id]: accountKeysAndDisplayNames[accountKey].sub_organization_id || '',
              [FILTER_KEYS.sub_organization_ids]: accountKeysAndDisplayNames[accountKey].sub_organization_ids || [],
              [FILTER_KEYS.coverage_config_ids]: accountKeysAndDisplayNames[accountKey].coverage_config_ids || [],
              [FILTER_KEYS.excluded_coverage_config_ids]:
                accountKeysAndDisplayNames[accountKey].excluded_coverage_config_ids || [],
            });
          }}
        >
          {Object.keys(accountKeysAndDisplayNames).map((key) => (
            <MenuItem key={key} value={key}>
              {accountKeysAndDisplayNames[key].filterName}
            </MenuItem>
          ))}
        </SingleSelectField>
      </Grid>
      <Grid item xs={3} className={styles.clearFilters}>
        <Link
          href=""
          onClick={(e) => {
            e.preventDefault();
            resetFilters();
          }}
          className={cn(classes.alignedContainerForGrid, classes.secondaryLink, disabled ? styles.disabled : '')}
        >
          <CancelIcon className={cn(classes.filledIcon, classes.leftButtonIcon, disabled ? styles.disabled : '')} />
          Clear all filters
        </Link>
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  onUpdateFilters: PropTypes.func,
  filters: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  accountKeysAndDisplayNames: PropTypes.object,
};

export { FILTER_KEYS };

export default Filters;
