import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Radio from '~/components/core/Atomic/Radio';
import Typography from '~/components/core/Atomic/Typography';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import { getPolicyStatusDuringLoss, getPolicyStatusSpan } from '../../PolicyUtils';
import { CUSTOM_FNOL_FIELDS_TYPES } from '../../Types';
import { isTscPolicy } from '../../Utils';
import CardDialog from '../CardDialog';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { LossLocationTextFieldFormik } from '../GlobalLossLocation';
import { useCms } from '../hooks/useCms';
import HoverChangeField from '../HoverChangeField';
import { usePolicy } from '../PolicyContainer';
import {
  DatePickerTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
  TimePickerTextFieldFormik,
} from '../TextFieldFormik';

import InsuredInfoVerificationCard from './InsuredInfoVerificationCard';

import { useStyles } from '../../assets/styles';

const incidentDetailsFields = {
  date_of_loss: '',
  time_of_loss: '',
  reporter_id: '',
  primary_contact_id: '',
  public_adjuster_contact_id: '',
  description: '',
  special_internal_note: '',
  loss_location: null,
  verified_insured_info: false,
};

function getIncidentDetailsValidationFields() {
  const is_us = isLocaleRegionIsUs();
  let lossLocationErrText = `Must contain address1, city ${is_us ? 'and state' : ''}`;
  return {
    date_of_loss: Yup.date().required('Required'),
    time_of_loss: Yup.string().matches(/^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/, 'Invalid time supplied'),
    reporter_id: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    primary_contact_id: Yup.string().required('Required'),
    loss_location: Yup.object().test(
      'is-valid',
      lossLocationErrText,
      (value) => value.address1 && value.city && (value.state || !is_us)
    ),
    verified_insured_info: Yup.boolean().oneOf([true], 'Must verify insured info'),
  };
}

function IncidentDetailsFormikInner(props) {
  const { setFieldValue, values } = props;
  const spacing = 1;
  const classes = useStyles();
  const { policy } = usePolicy();

  const [showInsuredVerificationCard, setShowInsuredVerificationCard] = useState(false);
  const insuredDetailsVerified = getIn(values, 'verified_insured_info');

  const policyStatusDuringLoss = values.date_of_loss
    ? getPolicyStatusDuringLoss(policy, values.date_of_loss, values.time_of_loss)
    : null;

  return (
    <>
      <CardDialog
        title="Incident Details"
        action={
          insuredDetailsVerified ? (
            <Button onClick={() => setShowInsuredVerificationCard(true)}>Insured info is verified &gt;</Button>
          ) : (
            <>
              <Button
                color="secondary"
                onClick={() => {
                  setShowInsuredVerificationCard(true);
                }}
              >
                <ErrorIcon />
                Verify insured info &gt;
              </Button>
              <ErrorHelperTextFormik id="verified_insured_info" />
            </>
          )
        }
      >
        <Grid container spacing={spacing}>
          <Grid item xs={6} />
          <Grid item xs={6} />
          <Grid item xs={12}>
            <LossLocationTextFieldFormik id="loss_location" label="Loss Location" />
          </Grid>
          <Grid item xs={6}>
            <DateOfLossFormikPicker />
          </Grid>
          <Grid item xs={6}>
            <TimePickerTextFieldFormik id="time_of_loss" label="Time of Loss" fullWidth className={classes.textField} />
          </Grid>

          {getPolicyStatusSpan(policyStatusDuringLoss, policy)}

          <Grid item xs={4}>
            <ContactTextFieldFormik
              id="reporter"
              label="Reporter"
              className={classes.textField}
              acceptedRoles={[
                'insured',
                'agent',
                'claimant',
                'spouse',
                'family_member',
                'public_adjuster',
                'insurer',
                'attorney',
                'other',
              ]}
              contactSearchProps={{ newContactRole: 'claimant' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ContactTextFieldFormik
              id="primary_contact"
              label="Preferred Contact"
              acceptedRoles={['insured', 'spouse', 'family_member', 'public_adjuster', 'attorney', 'agent', 'other']}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <ContactTextFieldFormik
              id="public_adjuster_contact"
              label="Public Adjuster"
              acceptedRoles={['public_adjuster']}
              excludedContactIdsList={policy.contacts.map((contact) => contact.id)}
              className={classes.textField}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="special_internal_note"
              label="Special Internal Note"
              className={classes.textField}
              fullWidth
              multiline
              rows="3"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldFormik
              id="description"
              label="Incident description"
              className={classes.textField}
              fullWidth
              multiline
              rows="8"
            />
          </Grid>
        </Grid>
      </CardDialog>
      {showInsuredVerificationCard && (
        <InsuredInfoVerificationCard
          insuredContactId={values.insured_contact.id}
          onDetailsVerified={() => {
            setFieldValue('verified_insured_info', true);
          }}
          onClose={() => setShowInsuredVerificationCard(false)}
        />
      )}
    </>
  );
}

IncidentDetailsFormikInner.propTypes = {
  setFieldValue: PropTypes.func.isRequired, // from Formik
  values: PropTypes.object.isRequired, // from Formik
};

function DateOfLossFormikPicker() {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const { policy } = usePolicy();

  const { user } = useCms();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  function handleChange(newDateOfLoss) {
    const formattedDate = newDateOfLoss && newDateOfLoss.isValid() ? newDateOfLoss.format('YYYY-MM-DD') : '';

    if (isTscPolicy(policy)) {
      if (
        formattedDate &&
        !policy.is_manual &&
        !(formattedDate >= policy.policy_effective_date && formattedDate < policy.policy_expiration_date)
      ) {
        setOpenConfirmDialog(true);
      }
    }

    setFieldValue('date_of_loss', formattedDate);
  }

  function handleDialogSubmit() {
    if (!user.is_org_level_supervisor) {
      setFieldValue('date_of_loss', '');
    }

    setOpenConfirmDialog(false);
  }

  return (
    <>
      <DatePickerTextFieldFormik
        id="date_of_loss"
        label="Date of Loss"
        className={classes.textField}
        fullWidth
        disabled={values.should_lock_date_of_loss}
        onChange={handleChange}
        disableFuture
      />

      {openConfirmDialog && (
        <CardDialog isDialog title="Date of loss is outside of the policy limits" maxWidth="xs" fullWidth>
          {user.is_org_level_supervisor ? (
            <Typography>Are you sure you want to proceed?</Typography>
          ) : (
            <>
              <Typography>Please choose a valid date to proceed</Typography>
              <Typography display="block" variant="body2" color="textSecondary">
                Only supervisor can take FNOL outside of policy limits
              </Typography>
            </>
          )}

          <div className={classes.buttonsContainer}>
            {user.is_org_level_supervisor && (
              <Button
                variant="contained"
                className={classes.leftButtonDialog}
                onClick={() => {
                  setFieldValue('date_of_loss', '');
                  setOpenConfirmDialog(false);
                }}
                color="secondary"
              >
                Cancel
              </Button>
            )}
            <Button variant="contained" onClick={handleDialogSubmit} color="primary">
              {user.is_org_level_supervisor ? 'Proceed' : 'Ok'}
            </Button>
          </div>
        </CardDialog>
      )}
    </>
  );
}

function CustomIncidentDetailsFieldForm({ text, type, field_name }) {
  if (type === CUSTOM_FNOL_FIELDS_TYPES['yes_no_question']) {
    return <YesNoQuestionFormik key={field_name} questionText={text} id={`custom_extra.${field_name}`} />;
  } else if (type === CUSTOM_FNOL_FIELDS_TYPES['yes_no_na_question']) {
    return <YesNoQuestionFormik key={field_name} questionText={text} id={`custom_extra.${field_name}`} na_option />;
  } else if (type === CUSTOM_FNOL_FIELDS_TYPES['text']) {
    return <TextFieldFormik key={field_name} label={text} id={`custom_extra.${field_name}`} />;
  }
  // TODO: implement more custom fields options
  return null;
}

CustomIncidentDetailsFieldForm.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field_name: PropTypes.string.isRequired,
};

function CustomIncidentDetailsFieldEditor(props) {
  const { text, field_name, type, onUpdate, disabled, currentValues } = props;
  const classes = useStyles();

  if (type === CUSTOM_FNOL_FIELDS_TYPES['yes_no_question']) {
    return (
      <>
        <Grid item container xs={12}>
          <Grid item xs={7}>
            <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>{text}</span>
          </Grid>
          <Grid item xs={5}>
            <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <FormControl>
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      color="primary"
                      checked={currentValues[field_name] === false}
                      onChange={async () => await onUpdate(field_name, false)}
                      disabled={disabled}
                      size="small"
                    />
                  }
                  label="No"
                  labelPlacement="end"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      color="primary"
                      checked={currentValues[field_name] === true}
                      onChange={async () => await onUpdate(field_name, true)}
                      disabled={disabled}
                      size="small"
                    />
                  }
                  label="Yes"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </>
    );
  } else if (type === CUSTOM_FNOL_FIELDS_TYPES['yes_no_na_question']) {
    return (
      <>
        <Grid item container xs={12}>
          <Grid item xs={7}>
            <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>{text}</span>
          </Grid>
          <Grid item xs={5}>
            <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
              <FormControl>
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      color="primary"
                      checked={currentValues[field_name] === false}
                      onChange={async () => await onUpdate(field_name, false)}
                      disabled={disabled}
                      size="small"
                    />
                  }
                  label="No"
                  labelPlacement="end"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      color="primary"
                      checked={currentValues[field_name] === true}
                      onChange={async () => await onUpdate(field_name, true)}
                      disabled={disabled}
                      size="small"
                    />
                  }
                  label="Yes"
                  labelPlacement="end"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      color="primary"
                      checked={currentValues[field_name] === 'N/A'}
                      onChange={async () => await onUpdate(field_name, 'N/A')}
                      disabled={disabled}
                      size="small"
                    />
                  }
                  label="N/A"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          </Grid>
        </Grid>
      </>
    );
  } else if (type === CUSTOM_FNOL_FIELDS_TYPES['text']) {
    return (
      <Grid item container xs={12}>
        <HoverChangeField
          name={field_name}
          value={currentValues[field_name]}
          label={text}
          onUpdate={(updatedValues) => onUpdate(field_name, updatedValues[field_name])}
          fullWidth
          showOnly
          disabled={disabled}
        >
          <ShowOnlyTextField classes={classes} showOnlyValueComponent={currentValues[field_name]} label={text} />
        </HoverChangeField>
      </Grid>
    );
  }
}

CustomIncidentDetailsFieldEditor.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  field_name: PropTypes.string.isRequired,
  currentValues: PropTypes.object.isRequired,
};

export default IncidentDetailsFormikInner;
export {
  CustomIncidentDetailsFieldEditor,
  CustomIncidentDetailsFieldForm,
  DateOfLossFormikPicker,
  getIncidentDetailsValidationFields,
  incidentDetailsFields,
};
