import React from 'react';
import { isEmpty } from 'lodash';

import type { InvoiceData } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import Heading from '~/components/core/TextComponents/Heading';
import Text from '~/components/core/TextComponents/Text';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import DocumentIcon from '~/components/icons/DocumentIcon';

interface SummaryProps {
  invoiceData: InvoiceData;
}

export const Summary: React.FC<SummaryProps> = ({ invoiceData }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return (
    <div className="space-between mb-20 inline-flex w-full items-center rounded-lg bg-white p-20 ">
      {!isEmpty(invoiceData) ? (
        <DocumentIcon className="ml-4 mr-32 box-content rounded-lg bg-pink-100 stroke-pink-600 p-20" size={32} />
      ) : null}
      {[
        { title: 'Invoice Date', content: invoiceData.date },
        { title: 'Invoice Number', content: invoiceData.number },
        { title: 'Vendor', content: invoiceData.vendor },
        {
          title: 'Invoice Total Amount',
          content: invoiceData.total_amount ? currencyFormatter.format(invoiceData.total_amount) : null,
        },
      ].map(({ title, content }, index) =>
        content ? <SummaryItem title={title} content={content} displayBorder={index !== 0} key={title} /> : null
      )}
    </div>
  );
};

interface SummaryItemProps {
  displayBorder: boolean;
  title: string;
  content: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({ displayBorder, title, content }) => (
  <>
    {displayBorder ? <div className="ml-80 mr-20 border-0 border-l-1 border-solid border-slate-600 py-20" /> : null}
    <div className="p-4">
      <Heading variant={Heading.TYPES.H4} className="pb-8 text-slate-800">
        {title}
      </Heading>
      <Text className="text-slate-900">{content}</Text>
    </div>
  </>
);
