import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';

import { reportAxiosError } from '../../Utils';
import { useClaim } from '../ClaimContainer';
import { useCms } from '../hooks/useCms';

import { reminderFormValuesToRequestParams } from './formHelpers';
import { getDefaultReminderTarget, ReminderDialogCard, reminderValidationSchema } from '.';

import { useStyles } from '../../assets/styles';

const AddReminderDialog = (props) => {
  const { open, onCancel, onSubmit, onMinimized, disableMinimized } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user } = useCms();
  const classes = useStyles();

  if (!claim) {
    throw Error('Creating a new reminder should be within claim context');
  }

  const submitReminder = async (values, { setSubmitting, resetForm }) => {
    const processedValues = reminderFormValuesToRequestParams(values);
    try {
      await axios.post(`/api/v1/claims/${claim.id}/reminders`, processedValues);
      await onAsyncClaimUpdate();
      await onSubmit();
      resetForm();
    } catch (error) {
      setSubmitting(false);
      await reportAxiosError(error);
    }
  };

  const onClose = (resetForm) => {
    resetForm();
    onCancel();
  };

  return (
    <Formik
      initialValues={{
        title: '',
        details: '',
        due_date: '',
        target_id: getDefaultReminderTarget(user, claim),
        other_adjuster_id: '',
      }}
      validationSchema={reminderValidationSchema}
      enableReinitialize
      onSubmit={submitReminder}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, resetForm } = formikProps;
        const buttonsComponent = (
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
              Set notification
            </Button>
          </div>
        );

        return (
          <ReminderDialogCard
            buttonsComponent={buttonsComponent}
            cardDialogProps={{
              open,
              isDialog: true,
              fullWidth: true,
              maxWidth: 'sm',
              onClose: () => onClose(resetForm),
              title: 'Add new notification',
              onMinimized,
              disableMinimized,
            }}
            claim={claim}
          />
        );
      }}
    </Formik>
  );
};

AddReminderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onMinimized: PropTypes.func,
  disableMinimized: PropTypes.bool,
};

export default AddReminderDialog;
