import React from 'react';
import PropTypes from 'prop-types';

import { useArrayText } from '../../hooks/useArrayText';
import FsTooltip from '../FsWrappers/FsTooltip/FsTooltip';
import { Text } from '../TextComponents';

const OverflowArrayTextDisplayWithTooltip = ({ value, renderItem, additionalLabelWidth }) => {
  const getLabel = React.useCallback((item) => item, []);

  const { containerRef, displayedItems, remainingCount } = useArrayText({
    array: value,
    renderLabel: getLabel,
    labelAdditionalWidth: additionalLabelWidth,
  });

  const remainingCountString = remainingCount > 0 ? ` +${remainingCount}` : '';
  const text = displayedItems.join(', ');

  return (
    <FsTooltip arrow title={value?.join(', ') ?? ''} disabled={!value}>
      <div ref={containerRef}>
        <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="flex flex-nowrap items-center">
          {renderItem ? displayedItems.map(renderItem) : text}
          {remainingCountString}
        </Text>
      </div>
    </FsTooltip>
  );
};

OverflowArrayTextDisplayWithTooltip.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  renderItem: PropTypes.func,
  additionalLabelWidth: PropTypes.number,
};

export default OverflowArrayTextDisplayWithTooltip;
