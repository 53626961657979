import React, { Component, createRef } from 'react';
import requiredIf from 'react-required-if';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect, getIn, setIn } from 'formik';

import cn from '~/Utils/cn';

import { reportGenericException } from '../Utils';

import { getAllSearchableContactRoles } from './communications/ContactUtils';
import ContactSearchContainer from './ContactSearch/ContactSearchContainer';
import ContactSearchContainerOutOfClaim from './ContactSearch/ContactSearchContainerOutOfClaim';
import { ContactEntity } from './Contact';
import { ContactIcon } from './icons';
import { withOrganization } from './OrganizationContext';
import { ShowOnlyTextField } from './TextFieldFormik';

import styles from '../assets/styles';

const ContactShowOnlyTextField = (props) => {
  const {
    classes,
    onEdit,
    contactId,
    contactDisplayName,
    label,
    disabled,
    claimId,
    shallRefetchOnContactIdChange,
    maxWidth,
    staticEditIcon,
  } = props;

  var valueComponent;
  if (contactId) {
    valueComponent = (
      <ContactEntity
        classes={classes}
        contactId={contactId}
        contactDisplayName={contactDisplayName}
        claimId={claimId}
        shallRefetchOnContactIdChange={shallRefetchOnContactIdChange}
        maxWidth={maxWidth}
      />
    );
  } else {
    valueComponent = <ContactIcon />;
  }

  return (
    <ShowOnlyTextField
      classes={classes}
      onEdit={onEdit}
      showOnlyValueComponent={valueComponent}
      label={label}
      disabled={disabled}
      doNotRenderInTypography
      maxWidth={maxWidth}
      staticEditIcon={staticEditIcon}
    />
  );
};

ContactShowOnlyTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  contactId: PropTypes.number,
  contactDisplayName: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  claimId: PropTypes.number,
  shallRefetchOnContactIdChange: PropTypes.bool,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  staticEditIcon: PropTypes.bool,
};

class ContactTextFieldFormikInner extends Component {
  static contextTypes = {
    formik: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.textInputRef = createRef();
  }

  render() {
    const {
      id,
      label,
      classes,
      disallowNew,
      showOnly,
      onEdit,
      claimId,
      updateOnInit,
      contactSearchProps,
      formik,
      onSelectContact,
      fixedSearchResults,
      excludedContactIdsList,
      acceptedRoles,
      outOfContactsContextSearch,
      organizationContactRolesDict,
      fullWidth,
      overrideOfContactsContextSearchOrganizationId,
      acceptedExpertise,
      nullIfEmpty,
      staticEditIcon,
    } = this.props;

    const { values, touched, errors, setFieldTouched, setValues } = formik;

    const contactIdFieldName = id + '_id';
    const contactDisplayNameFieldName = id + '_full_name';

    const currContactId = getIn(values, contactIdFieldName);
    const currContactDisplayName = getIn(values, contactDisplayNameFieldName);

    if (showOnly) {
      const { label, disabled } = this.props;

      if (!currContactId) {
        return (
          <ShowOnlyTextField
            classes={classes}
            onEdit={onEdit}
            showOnlyValueComponent=""
            label={label}
            disabled={disabled}
            staticEditIcon={staticEditIcon}
          />
        );
      }

      return (
        <ContactShowOnlyTextField
          classes={classes}
          onEdit={onEdit}
          contactId={currContactId}
          contactDisplayName={currContactDisplayName}
          label={label}
          disabled={disabled}
          claimId={claimId}
          staticEditIcon={staticEditIcon}
        />
      );
    }
    // Allow errors on both the contact_id field and the actual contact field
    let errorIdOrNone = undefined;
    [id, contactIdFieldName].forEach((currId) => {
      if (getIn(errors, currId) && getIn(touched, currId)) {
        errorIdOrNone = currId;
      }
    });

    if (!acceptedRoles) {
      try {
        throw Error(`ContactTextFieldFormik must have accepted roles, id: ${id}, label: ${label}`);
      } catch (error) {
        reportGenericException(error);
      }
    }

    const getEmptyValue = () => (nullIfEmpty ? null : '');

    const ContactSearchComponent = outOfContactsContextSearch
      ? ContactSearchContainerOutOfClaim
      : ContactSearchContainer;
    return (
      <div
        onBlur={(e) => {
          if (this.textInputRef.current === e.target) {
            [id, contactIdFieldName, contactDisplayNameFieldName].forEach((fieldName) =>
              setFieldTouched(fieldName, true)
            );
          }
        }}
        className={cn({ 'w-full': fullWidth })}
      >
        <ContactSearchComponent
          textInputRef={this.textInputRef}
          overrideOfContactsContextSearchOrganizationId={
            ContactSearchContainerOutOfClaim && overrideOfContactsContextSearchOrganizationId
          }
          disallowNew={disallowNew}
          fixedSearchResults={fixedSearchResults}
          excludedContactIdsList={excludedContactIdsList}
          onSelectContact={(contact) => {
            // calling setFieldValue three times leads to a race bug in Formik - each setFieldValue runs validation with the old value and the new field
            let valuesWithContact = { ...values };
            // use setIn to account for complex id names like payee[0].contact_id
            valuesWithContact = setIn(valuesWithContact, contactIdFieldName, contact ? contact.id : getEmptyValue());
            valuesWithContact = setIn(valuesWithContact, contactDisplayNameFieldName, contact.full_name);
            valuesWithContact = setIn(valuesWithContact, id, contact);
            setValues(valuesWithContact);
            if (onSelectContact) {
              onSelectContact(contact);
            }
          }}
          selectedContactId={currContactId}
          selectedContactDisplayName={currContactDisplayName}
          updateOnInit={updateOnInit}
          TextFieldProps={{
            fullWidth,
            label,
            error: !!errorIdOrNone,
            helperText: errorIdOrNone && getIn(errors, errorIdOrNone),
          }}
          acceptedRoles={acceptedRoles || getAllSearchableContactRoles(organizationContactRolesDict)}
          acceptedExpertise={acceptedExpertise}
          {...contactSearchProps}
        />
      </div>
    );
  }
}

ContactTextFieldFormikInner.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired, // from styles
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disallowNew: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showOnly: PropTypes.bool,
  onEdit: PropTypes.func,
  claimId: PropTypes.number,
  updateOnInit: PropTypes.bool,
  contactSearchProps: PropTypes.object,
  formik: PropTypes.object, // from formik
  onSelectContact: PropTypes.func,
  fixedSearchResults: PropTypes.bool,
  excludedContactIdsList: PropTypes.array,
  acceptedRoles: requiredIf(PropTypes.array, (props) => !props.showOnly),
  acceptedExpertise: PropTypes.array,
  organizationContactRolesDict: PropTypes.object.isRequired,
  outOfContactsContextSearch: PropTypes.bool,
  overrideOfContactsContextSearchOrganizationId: PropTypes.number,
  nullIfEmpty: PropTypes.bool,
  staticEditIcon: PropTypes.bool,
};

const ContactShowOnlyTextFieldStyled = withStyles(styles)(ContactShowOnlyTextField);
const ContactTextFieldFormik = withOrganization(withStyles(styles)(connect(ContactTextFieldFormikInner)));

export { ContactShowOnlyTextFieldStyled as ContactShowOnlyTextField };

export default ContactTextFieldFormik;
