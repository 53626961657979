import { Color } from '@tiptap/extension-color';
import { FontFamily } from '@tiptap/extension-font-family';
import { Highlight } from '@tiptap/extension-highlight';
import { Image } from '@tiptap/extension-image';
import { Link } from '@tiptap/extension-link';
import { Mention } from '@tiptap/extension-mention';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { Underline } from '@tiptap/extension-underline';
import type { Extensions } from '@tiptap/react';
import { mergeAttributes } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import FontSize from '~/components/core/Editor/extensions/FontSize';
import { flattenOptions, getSuggestionConfig } from '~/components/core/Editor/MenuBar/suggestion/suggestion';
import type { DisplayConfigProps, SuggestionOption } from '~/components/core/Editor/types';

export const getExtensions = (
  suggestionOptions: SuggestionOption[],
  displayConfig: DisplayConfigProps,
  disabled?: boolean
): Extensions => {
  const extensions: Extensions = [
    StarterKit,
    Paragraph.extend({
      parseHTML() {
        return [{ tag: 'div' }];
      },
      renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { style: 'min-height: 1lh' }), 0];
      },
    }),
    TextStyle,
  ];

  if (disabled || displayConfig.textStyle) {
    extensions.push(Underline);
  }
  if (disabled || displayConfig.align) {
    extensions.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      })
    );
  }

  if (disabled || displayConfig.textColor) {
    extensions.push(Highlight.configure({ multicolor: true }));
    extensions.push(Color);
  }

  if (disabled || displayConfig.font) {
    extensions.push(FontFamily);
    extensions.push(FontSize);
  }

  if (disabled || displayConfig.link) {
    extensions.push(
      Link.configure({
        openOnClick: false,
      })
    );
  }

  if (disabled || displayConfig.suggestions) {
    extensions.push(getMentionExtensionConfig(suggestionOptions));
  }

  const ExtendedImage = getExtendedImageExtension();

  extensions.push(
    ExtendedImage.configure({
      inline: true,
      allowBase64: true,
    })
  );

  extensions.push(Table);
  extensions.push(TableRow);
  extensions.push(TableCell);
  extensions.push(TableHeader);

  return extensions;
};

// code from tiptap-extension-resize-image - https://www.npmjs.com/package/tiptap-extension-resize-image
const ImageResize = Image.extend({
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      style: {
        default: 'width: auto; height: auto; cursor: pointer;',
        parseHTML: (element) => {
          const width = element.getAttribute('width');
          return width ? `width: ${width}px; height: auto; cursor: pointer;` : `${element.style.cssText}`;
        },
      },
      title: {
        default: null,
      },
      loading: {
        default: null,
      },
      srcset: {
        default: null,
      },
      sizes: {
        default: null,
      },
      crossorigin: {
        default: null,
      },
      usemap: {
        default: null,
      },
      ismap: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      referrerpolicy: {
        default: null,
      },
      longdesc: {
        default: null,
      },
      decoding: {
        default: null,
      },
      class: {
        default: null,
      },
      id: {
        default: null,
      },
      name: {
        default: null,
      },
      draggable: {
        default: true,
      },
      tabindex: {
        default: null,
      },
      'aria-label': {
        default: null,
      },
      'aria-labelledby': {
        default: null,
      },
      'aria-describedby': {
        default: null,
      },
    };
  },
  addNodeView() {
    return ({ node, editor, getPos }) => {
      const {
        view,
        options: { editable },
      } = editor;
      const { style } = node.attrs;
      const $wrapper = document.createElement('div');
      const $container = document.createElement('div');
      const $img = document.createElement('img');
      const iconStyle = 'width: 24px; height: 24px; cursor: pointer;';
      const dispatchNodeView = () => {
        if (typeof getPos === 'function') {
          const newAttrs = Object.assign(Object.assign({}, node.attrs), { style: `${$img.style.cssText}` });
          view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
        }
      };
      $wrapper.setAttribute('style', `display: inline-flex;`);
      $wrapper.appendChild($container);
      $container.setAttribute('style', `${style}`);
      $container.appendChild($img);
      Object.entries(node.attrs).forEach(([key, value]) => {
        if (value === undefined || value === null) return;
        $img.setAttribute(key, value);
      });
      if (!editable) return { dom: $img };
      const dotPosition = '-4px';
      const dotsPosition = [
        `top: ${dotPosition}; left: ${dotPosition}; cursor: nwse-resize;`,
        `top: ${dotPosition}; right: ${dotPosition}; cursor: nesw-resize;`,
        `bottom: ${dotPosition}; left: ${dotPosition}; cursor: nesw-resize;`,
        `bottom: ${dotPosition}; right: ${dotPosition}; cursor: nwse-resize;`,
      ];
      let isResizing = false;
      let startX: number, startWidth: number;
      $container.addEventListener('click', () => {
        //remove remaining dots and position controller
        if ($container.childElementCount > 3) {
          for (let i = 0; i < 5; i++) {
            $container.lastChild && $container.removeChild($container.lastChild);
          }
        }
        $container.setAttribute('style', `position: relative; border: 1px dashed #6C6C6C; ${style} cursor: pointer;`);
        Array.from({ length: 4 }, (_, index) => {
          const $dot = document.createElement('div');
          $dot.setAttribute(
            'style',
            `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${dotsPosition[index]}`
          );
          $dot.addEventListener('mousedown', (e) => {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;
            const onMouseMove = (e: MouseEvent) => {
              if (!isResizing) return;
              const deltaX = index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
              const newWidth = startWidth + deltaX;
              $container.style.width = newWidth + 'px';
              $img.style.width = newWidth + 'px';
            };
            const onMouseUp = () => {
              if (isResizing) {
                isResizing = false;
              }
              dispatchNodeView();
              document.removeEventListener('mousemove', onMouseMove);
              document.removeEventListener('mouseup', onMouseUp);
            };
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
          });
          $dot.addEventListener(
            'touchstart',
            (e) => {
              e.cancelable && e.preventDefault();
              isResizing = true;
              startX = e.touches[0].clientX;
              startWidth = $container.offsetWidth;
              const onTouchMove = (e: TouchEvent) => {
                if (!isResizing) return;
                const deltaX = index % 2 === 0 ? -(e.touches[0].clientX - startX) : e.touches[0].clientX - startX;
                const newWidth = startWidth + deltaX;
                $container.style.width = newWidth + 'px';
                $img.style.width = newWidth + 'px';
              };
              const onTouchEnd = () => {
                if (isResizing) {
                  isResizing = false;
                }
                dispatchNodeView();
                document.removeEventListener('touchmove', onTouchMove);
                document.removeEventListener('touchend', onTouchEnd);
              };
              document.addEventListener('touchmove', onTouchMove);
              document.addEventListener('touchend', onTouchEnd);
            },
            { passive: false }
          );
          $container.appendChild($dot);
        });
      });
      document.addEventListener('click', (e) => {
        const $target = e.target;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const isClickInside = $container.contains($target) || $target.style.cssText === iconStyle;
        if (!isClickInside) {
          const containerStyle = $container.getAttribute('style');
          const newStyle =
            containerStyle === null || containerStyle === void 0
              ? void 0
              : containerStyle.replace('border: 1px dashed #6C6C6C;', '');
          newStyle && $container.setAttribute('style', newStyle);
          if ($container.childElementCount > 3) {
            for (let i = 0; i < 5; i++) {
              $container.lastChild && $container.removeChild($container.lastChild);
            }
          }
        }
      });
      return {
        dom: $wrapper,
      };
    };
  },
});

const getExtendedImageExtension = () => {
  return ImageResize.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        height: {
          default: null,
          parseHTML: (element) => element.getAttribute('height'),
          renderHTML: (attributes) => ({
            height: attributes.height,
          }),
        },
        width: {
          default: null,
          parseHTML: (element) => element.getAttribute('width'),
          renderHTML: (attributes) => ({
            width: attributes.width,
          }),
        },
      };
    },
  });
};

const getMentionExtensionConfig = (suggestionOptions: SuggestionOption[]) => {
  // order matters, .extend() needs to happen before .configure()
  // this gets called only when mounting the editor, so any changes
  // here require to unmount and remount the editor to take place

  const transformBoldText = (text: string) => {
    const regex = /\*\*(.*?)\*\*/g;
    return text.split(regex).map((part, index) => {
      // If the part is surrounded by **, wrap it in a span with class "font-bold"
      if (index % 2 === 1) {
        return ['span', { class: 'font-bold' }, part];
      }
      return part;
    });
  };

  return Mention.extend({
    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('id'),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {};
            }
            return {
              id: attributes.id,
            };
          },
        },
        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) {
              return {};
            }
            return {
              'data-label': attributes.label,
            };
          },
        },
        class: {
          default: 'bg-slate-400 rounded-full pl-12 pr-12 pt-2 pb-2',
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'mention',
        },
      ];
    },
    renderHTML({ HTMLAttributes, node }) {
      return [
        'mention',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ...transformBoldText(node.attrs.label || node.attrs.id),
      ];
    },
  }).configure({
    HTMLAttributes: {
      class: 'mention',
    },
    renderHTML({ node }) {
      return [
        'mention',
        { class: 'bg-slate-400 rounded-full pl-12 pr-12 pt-2 pb-2' },
        `${node.attrs.label ?? node.attrs.id}`,
      ];
    },
    suggestion: Array.isArray(suggestionOptions) ? getSuggestionConfig(flattenOptions(suggestionOptions)) : undefined,
  });
};
