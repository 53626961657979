import React from 'react';

import Chip from '~/components/core/Atomic/Chip/Chip';
import type { IconProps } from '~/components/icons/types';
import cn from '~/Utils/cn';

export type COLORS = 'blue' | 'green' | 'red' | 'yellow' | 'orange' | 'lightBlue' | 'grey';

const COLORS_MAP = {
  ['blue']: 'border-l-blueSky-700',
  ['green']: 'border-l-green-700',
  ['red']: 'border-l-red-700',
  ['yellow']: 'border-l-yellow-700',
  ['orange']: 'border-l-orange-700',
  ['lightBlue']: 'border-l-lightBlue-700',
  ['grey']: 'border-l-slate-700',
};

interface StatusChipProps {
  status: string;
  Icon?: React.FC<IconProps> | null;
  color?: COLORS;
}

const StatusChipCore: React.FC<StatusChipProps> = ({ status, Icon, color }) => {
  const StatusIcon = Icon ? <Icon size={14} iconColor="rgba(0, 0, 0, 0.54)" /> : null;
  const leftBorderColor = (color && COLORS_MAP[color]) || COLORS_MAP.grey;
  return (
    <div>
      <Chip
        className={cn(
          'h-20 justify-start bg-slate-200 text-xs uppercase text-slate-800',
          'border-l-4 rounded-[3px] border-y-0 border-r-0 border-solid',
          { [leftBorderColor as string]: !!leftBorderColor }
        )}
        icon={StatusIcon as React.ReactElement}
        label={status}
      />
    </div>
  );
};

export default StatusChipCore;
