import React, { useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import _ from 'lodash';

import { useStyles } from '~/assets/styles';
import Button from '~/components/core/Atomic/Buttons/Button';
import Checkbox from '~/components/core/Atomic/Checkboxes/Checkbox';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Radio from '~/components/core/Atomic/Radio';
import Typography from '~/components/core/Atomic/Typography';
import { useMinimizedDialogs } from '~/components/core/MinimizedBar/Context';
import AutoComplete from '~/components/core/Molecules/Fields/AutoComplete';
import TextField from '~/components/core/Molecules/Fields/TextField';
import Text from '~/components/core/TextComponents/Text';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import DocumentMoreActionsContainer from '~/components/Documents/DocumentMoreActions/DocumentMoreActionsContainer';
import FileIcon from '~/components/icons/FileIcon';
import { isoDateToUs, serverDateTimeToLocal } from '~/DateTimeUtils';
import { MIXPANEL_EVENT_SOURCES, MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getExposuresArrayFromClaimByIds, getExposuresLabels, isFeatureEnabled, isPolicyNotFound } from '~/Utils';
import { isClaimWriteDisabled } from '~/Utils/ClaimUtils';

import mixpanel from '../CmsMain/mixpanel';
import { SendDocumentViaVendorCommunication } from '../communications/VendorCommunicationCard';
import {
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
  SortableTable,
} from '../core';
import ExposureLabelChips from '../ExposureLabelChips';
import ExposuresLabelFilter from '../exposures/ExposuresLabelsFilter';
import { getUserRelatedExposuresId } from '../exposures/ExposureUtils';
import { useCms } from '../hooks/useCms';
import InlineIconButton from '../InlineIconButton';
import useOrganization from '../OrganizationContext';

import GenAiPaymentSuggestion from './GenAiPaymentSuggestion/GenAiPaymentSuggestion';
import MultiUploadDocuments from './UploadDocumentsCard/UploadDocumentsCard';
import { isDocumentAPhoto } from './DocumentUtils';
import UploadDocumentFromTemplate from './UploadDocumentFromTemplate';

const getDocumentsTableColumnData = (documentTypesDict, userOrganization) => {
  return [
    {
      id: 'id',
      numeric: true,
      rightPaddingOnly: true,
      label: '#',
      // eslint-disable-next-line react/display-name
      specialCell: (document) => document.claim_internal_id,
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'view_action',
      label: 'Watch File',
      leftPaddingOnly: true,
      disableSort: true,
      specialCell: (document) => {
        const isDisabled = document.is_uploading;
        return (
          <a
            href={isDisabled ? null : `/api/v1/claims/${document.claim_id}/documents/${document.id}`}
            target="_blank"
            rel="noreferrer"
            className="flex justify-center p-0"
          >
            <InlineIconButton
              tooltipTitle={isDisabled ? 'Document Uploading' : 'Open Document in new tab'}
              icon={FileIcon}
              ignorePermissions
              disabled={isDisabled}
            />
          </a>
        );
      },
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'exposure_ids',
      numeric: false,
      disablePadding: false,
      label: 'Label',
      specialCell: (document) => <ExposureLabelChips claimObject={document} disablePadding />,
    },
    {
      id: 'type',
      numeric: false,
      nowrap: true,
      disablePadding: true,
      label: 'Type',
      specialCell: (document) => documentTypesDict?.[document.type]?.desc || document.type,
    },
    {
      id: 'document_name',
      numeric: false,
      maxWidthPercentage: 16,
      overflow: true,
      disablePadding: false,
      label: 'Document Name',
    },
    { id: 'summary', numeric: false, maxWidthPercentage: 16, overflow: true, disablePadding: false, label: 'Summary' },
    {
      id: 'document_date',
      width: 135,
      numeric: false,
      disablePadding: true,
      label: 'Document Date',
      specialCell: (document) => isoDateToUs(document.document_date),
    },
    {
      id: 'date_received',
      width: 135,
      numeric: false,
      disablePadding: true,
      label: 'Date Received',
      specialCell: (document) => (document.date_received ? isoDateToUs(document.date_received) : ''),
    },
    {
      id: 'uploaded_by',
      numeric: false,
      disablePadding: true,
      label: 'Uploaded by',
      specialCell: (document) => (document?.document_extra?.created_by_ai ? 'Clive' : document.uploaded_by),
    },
    {
      id: 'datetime_uploaded',
      width: 160,
      numeric: false,
      disablePadding: false,
      label: 'Uploaded Time',
      specialCell: (document) =>
        document.is_uploading ? 'Uploading...' : serverDateTimeToLocal(document.datetime_uploaded),
    },
    {
      id: 'more_actions',
      rightPaddingOnly: true,
      numeric: false,
      specialCell: (document) => <DocumentMoreActionsContainer document={document} />,
    },
    // eslint-disable-next-line react/display-name
    {
      id: 'send_to_vendor',
      isAction: true,
      disableSort: true,
      numeric: false,
      disablePadding: true,
      width: 20,
      specialCell: (document) => (
        <SendDocumentViaVendorCommunication
          document={document}
          displayIcon
          vendorNameDisplay="medlogix"
          sentIndicationKeyName="was_sent_to_medlogix"
          isDocumentRelevant={(document, claim) =>
            claim.exposures
              .filter((exposure) => document.exposure_ids.includes(exposure.id))
              .some((exposure) => ['coverage_pip', 'coverage_apip'].includes(exposure.coverage_type)) &&
            isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.MEDLOGIX_INTEGRATION)
          }
          getSubmitRoute={(claimId) => `/api/v1/claims/${claimId}/vendors/medlogix/send_document`}
        />
      ),
    },
  ].filter(Boolean);
};

const DocumentsTable = (props) => {
  const {
    documents,
    selectedDocumentIds,
    onDocumentsClick,
    disableChooseMultiple,
    tableColumns,
    defaultOrderColumn,
    maxHeight,
    autoPaginateRowsPerPage,
  } = props;
  const classes = useStyles();
  const { documentTypesDict } = useOrganization();
  const { userOrganization } = useCms();

  const docSelectionColumn = {
    id: 'select_doc',
    disableSort: true,
    width: 20,
    disablePadding: true,
    numeric: false,
    // eslint-disable-next-line react/display-name
    specialCell: (document) =>
      document.is_uploading ? null : disableChooseMultiple ? (
        // eslint-disable-next-line react/prop-types
        <Radio color="primary" checked={selectedDocumentIds.includes(document.id)} />
      ) : (
        // eslint-disable-next-line react/prop-types
        <Checkbox color="primary" checked={selectedDocumentIds.includes(document.id)} />
      ), // no need for onChange callback - clicking anywhere on the row select the row and mark the checkbox
  };

  let documentTableColumns = tableColumns || getDocumentsTableColumnData(documentTypesDict, userOrganization);
  documentTableColumns = onDocumentsClick ? [docSelectionColumn].concat(documentTableColumns) : documentTableColumns;

  const handleDocumentsClick = (documentId) => {
    // Ignore not uploaded documents
    const isDocumentUploading = documents.find((document) => document.id === documentId)?.is_uploading;
    if (isDocumentUploading) return;

    if (disableChooseMultiple) {
      onDocumentsClick([documentId]);
    } else {
      // Add the documentId to the selected if not selected already, otherwise remove it from selected
      onDocumentsClick(
        selectedDocumentIds.includes(documentId)
          ? _.without(selectedDocumentIds, documentId)
          : [...selectedDocumentIds, documentId]
      );
    }
  };

  return (
    <>
      {onDocumentsClick && !disableChooseMultiple && (
        <div className={classes.cardDivRow}>
          <Grid container justify="space-between">
            <Typography variant="subtitle1">
              <strong>{selectedDocumentIds.length}</strong>&nbsp; documents are selected
            </Typography>
            <ButtonGroup variant="text" size="small">
              <Button
                onClick={() => onDocumentsClick(_.uniq(selectedDocumentIds.concat(documents.map((doc) => doc.id))))}
              >
                All
              </Button>
              <Button
                onClick={() =>
                  onDocumentsClick(
                    _.difference(
                      selectedDocumentIds,
                      documents.map((doc) => doc.id)
                    )
                  )
                }
              >
                None
              </Button>
            </ButtonGroup>
          </Grid>
        </div>
      )}
      <SortableTable
        columns={documentTableColumns}
        defaultOrderColumn={defaultOrderColumn || documentTableColumns.findIndex((column) => column.id === 'id')} // If no defaultOrderColumn, it should be the #id column regardless if we add the select_doc column
        order="desc"
        rows={documents}
        selectedRowsIds={selectedDocumentIds}
        onRowClick={onDocumentsClick ? handleDocumentsClick : undefined}
        maxHeight={maxHeight}
        stickyHeader
        autoPaginateRowsPerPage={autoPaginateRowsPerPage}
      />
    </>
  );
};

DocumentsTable.propTypes = {
  documents: PropTypes.array.isRequired,
  selectedDocumentIds: requiredIf(PropTypes.array, (props) => props.onDocumentsClick),
  onDocumentsClick: PropTypes.func,
  disableChooseMultiple: PropTypes.bool,
  tableColumns: PropTypes.array,
  defaultOrderColumn: PropTypes.number,
  maxHeight: PropTypes.string,
  autoPaginateRowsPerPage: PropTypes.number,
};

function DocumentsContainer(props) {
  const {
    classes,
    claim,
    documentsFilter,
    selectedDocumentIds,
    onDocumentsClick,
    disableChooseMultiple,
    onNewDocumentCreated,
    newDocumentDefaultType,
    maxHeight,
    autoPaginateRowsPerPage,
    removeUploadedTime,
    hideFromTemplate,
    initialValues,
    selectOnly,
    disableExposuresFilter,
  } = props;
  const [newDocumentOpen, setNewDocumentOpen] = useState(false);
  const [shouldIncludePhotos, setShouldIncludePhotos] = useState(false);
  const [newDocumentFromTemplateOpen, setNewDocumentFromTemplateOpen] = useState(false);
  const [genAiPaymentModalDocument, setGenAiPaymentModalDocument] = useState(false);
  const { add } = useMinimizedDialogs();

  const { user, userOrganization } = useCms();

  const [filteredExposuresIds, setFilteredExposuresIds] = useState(
    () => initialValues.exposure_ids || (!disableExposuresFilter ? getUserRelatedExposuresId(user, claim) : [])
  );
  const [filteredDocumentType, setFilteredDocumentType] = useState(initialValues.type || null);
  const [searchDocumentFilter, setSearchDocumentFilter] = useState(null);

  const { documentTypesDict } = useOrganization();

  let UploadDocumentInitialValues = {};
  if (filteredExposuresIds) {
    UploadDocumentInitialValues['exposure_ids'] = filteredExposuresIds;
  }

  if (filteredDocumentType) {
    UploadDocumentInitialValues['type'] = filteredDocumentType;
  }

  const handleSubmitDocument = (document, handleClose) => {
    // TODO: do some sort of "loading" mechanism between the time we close the dialog until the refreshed answer comes in
    onNewDocumentCreated(document.id);
    handleClose();

    if (
      isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.DOCUMENT_AI) &&
      ['invoice'].includes(document?.type)
    ) {
      setGenAiPaymentModalDocument(document);
    }
  };

  const handleCloseFromTemplate = () => setNewDocumentFromTemplateOpen(false);

  const readOnly = isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true });

  let { documents } = claim;

  documents = documents.filter((document) => !document.is_removed);

  let documentTypes = _.uniq(documents.map((doc) => doc.type));
  documentTypes.sort();

  if (documentsFilter) {
    documents = documents.filter(documentsFilter);
  }

  if (!shouldIncludePhotos) {
    documents = documents.filter((document) => !(isDocumentAPhoto(document) && document.type === 'damage_photo'));
  }

  if (filteredExposuresIds.length !== 0) {
    documents = documents.filter((document) => _.intersection(filteredExposuresIds, document.exposure_ids).length > 0);
  }

  if (filteredDocumentType) {
    documents = documents.filter((document) => document.type === filteredDocumentType);
  }

  if (searchDocumentFilter) {
    documents = documents.filter((document) =>
      document.document_name.toLowerCase().includes(searchDocumentFilter.toLowerCase())
    );
  }

  let tableColumns = getDocumentsTableColumnData(documentTypesDict, userOrganization);
  // If selecting documents is enabled, remove datetime_upload column to save space in the row for the checkbox
  if (removeUploadedTime || onDocumentsClick) {
    tableColumns = tableColumns.filter((column) => column.id !== 'datetime_uploaded');
  }
  if (selectOnly) {
    tableColumns = tableColumns.filter((column) => !column.isAction);
  }

  const exposures = getExposuresArrayFromClaimByIds(claim, filteredExposuresIds);

  const uploadDocumentFromTemplateProps = {
    classes,
    claim,
    onSubmitDocument: (document) => handleSubmitDocument(document, handleCloseFromTemplate),
    onCancel: handleCloseFromTemplate,
    open: true,
  };

  const handleClickFromTemplate = () => {
    mixpanel.track(MIXPANEL_EVENTS.DOCUMENT_TEMPLATE_OPENED, {
      ...mixpanel.getMixpanelAttributes(claim, exposures, {
        source: MIXPANEL_EVENT_SOURCES.DOCUMENT_TEMPLATE_OPENED_FROM_DOCUMENT_CONTAINER,
      }),
    });

    const type = 'DOCUMENT';
    const { handleCloseDialog } = add({
      barHeader: `New Document Template`,
      type,
      dialogComponent: UploadDocumentFromTemplate,
      dialogProps: {
        ...uploadDocumentFromTemplateProps,
        onMinimized: () => {
          mixpanel.track(MIXPANEL_EVENTS.MINIMIZED_DIALOG, {
            source: MIXPANEL_EVENT_SOURCES.DOCUMENT_TEMPLATE_OPENED_FROM_DOCUMENT_CONTAINER,
            type,
          });
        },
        onCancel: () => handleCloseDialog(),
        onSubmitDocument: (document) => {
          handleSubmitDocument(document, handleCloseDialog);
        },
      },
    });
  };

  return (
    <RestrictedPermissions action={PERMISSION_ACTIONS.CLAIM_DOCUMENT} verb={PERMISSION_VERBS.WRITE}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={3}>
          <div className="mr-12 flex items-center">
            <AutoComplete
              id="document_type"
              label="Filter by Document Type"
              options={documentTypes}
              getOptionLabel={(option) => documentTypesDict[option]['desc']}
              className="w-[350px]"
              onChange={(_, newVal) => setFilteredDocumentType(newVal)}
              value={filteredDocumentType}
            />
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="flex items-center">
            <TextField
              label="Search"
              style={{ width: 350 }}
              onChange={_.debounce((value) => setSearchDocumentFilter(value), 300)}
            />
          </div>
        </Grid>
        <Grid item md={2}>
          <div className="ml-20 mt-12 flex items-center">
            <Checkbox
              color="primary"
              checked={shouldIncludePhotos}
              onChange={(_event, newValue) => newValue !== null && setShouldIncludePhotos(newValue)}
            />
            <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
              Show photos
            </Text>
          </div>
        </Grid>
        <Grid item md={4}>
          <FormGroup row>
            <div className={classes.spacer} />
            {!hideFromTemplate && !selectOnly && (
              <PermissionsButtonWrapper>
                <Button
                  color="primary"
                  disabled={readOnly || isPolicyNotFound(claim)}
                  onClick={handleClickFromTemplate}
                >
                  <AddIcon className={classes.leftButtonIcon} />
                  From Template
                </Button>
              </PermissionsButtonWrapper>
            )}
            {!selectOnly && (
              <PermissionsButtonWrapper>
                <Button color="primary" disabled={readOnly} onClick={() => setNewDocumentOpen(true)}>
                  <CloudUploadIcon className={classes.leftButtonIcon} />
                  Upload Documents
                </Button>
              </PermissionsButtonWrapper>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      {!disableExposuresFilter && (
        <div style={{ padding: '10px 0' }}>
          <ExposuresLabelFilter filterList={filteredExposuresIds} onUpdateFiltered={setFilteredExposuresIds} />
        </div>
      )}
      <DocumentsTable
        classes={classes}
        documents={documents}
        selectedDocumentIds={selectedDocumentIds}
        onDocumentsClick={onDocumentsClick}
        disableChooseMultiple={disableChooseMultiple}
        maxHeight={maxHeight}
        autoPaginateRowsPerPage={autoPaginateRowsPerPage}
        tableColumns={tableColumns}
      />
      {newDocumentOpen && (
        <MultiUploadDocuments
          classes={classes}
          newDocumentDefaultType={newDocumentDefaultType}
          claim={claim}
          onSubmitDocument={(document) => handleSubmitDocument(document, () => setNewDocumentOpen(false))}
          onCancel={() => setNewDocumentOpen(false)}
          initialValues={UploadDocumentInitialValues}
        />
      )}
      {newDocumentFromTemplateOpen && <UploadDocumentFromTemplate {...uploadDocumentFromTemplateProps} />}
      {genAiPaymentModalDocument ? (
        <GenAiPaymentSuggestion
          open={!!genAiPaymentModalDocument}
          setOpen={setGenAiPaymentModalDocument}
          document={genAiPaymentModalDocument}
          claim={claim}
        />
      ) : null}
    </RestrictedPermissions>
  );
}

DocumentsContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  documentsFilter: PropTypes.func,
  onNewDocumentCreated: PropTypes.func,
  selectedDocumentIds: requiredIf(PropTypes.array, (props) => props.onDocumentsClick),
  onDocumentsClick: PropTypes.func,
  disableChooseMultiple: PropTypes.bool,
  newDocumentDefaultType: PropTypes.string,
  removeUploadedTime: PropTypes.bool,
  maxHeight: PropTypes.string,
  autoPaginateRowsPerPage: PropTypes.number,
  hideFromTemplate: PropTypes.bool,
  initialValues: PropTypes.object,
  selectOnly: PropTypes.bool,
  disableExposuresFilter: PropTypes.bool,
};

DocumentsContainer.defaultProps = {
  initialValues: {},
};

export { DocumentsTable, getDocumentsTableColumnData, getExposuresLabels };
export default DocumentsContainer;
